import Uniflow from 'uniflow';
import apiRequest from '../common/api-request';
import isUnauthorized from '../common/is-unauthorized';
import env from '../config/env';
import RouterActions from '../actions/router';
import { inIframe } from '../common/in-iframe';

export const LOAD_SESSION_SUCCESS = 'session.load-success';

export const creators = {
  loadSessionSuccess: session => ({ type: LOAD_SESSION_SUCCESS, session })
};

let isSessionRetrievalPending = false;

let checkSessionExpiredInterval = null;

const minutesToMs = minutes => minutes * 60 * 1000;
const SESSION_EXPIRATION_CHECK_INTERVAL = minutesToMs(5);

const addMs = (date, ms) => new Date(date.getTime() + ms);

const isSessionExpiringBeforeNextRequest = session => {
  if (!session) {
    return true;
  }

  const expiresAt = new Date(session.expiresAt);
  const nextRequestTime = addMs(new Date(), SESSION_EXPIRATION_CHECK_INTERVAL);
  return expiresAt && expiresAt <= nextRequestTime;
};

export const backgroundSessionRenewal = async sid => {
  const request = apiRequest({
    path: `/sessions/${sid}`
  });

  try {
    const response = await request.promise();
    SessionActions.emit('session-retrieval-success', response.body);
    return response.body;
  } catch (error) {
    console.error(error);
  }
  return null;
};

const initiateSessionRefresh = sid => {
  if (checkSessionExpiredInterval) {
    clearInterval(checkSessionExpiredInterval);
  }

  checkSessionExpiredInterval = setInterval(async () => {
    const session = await backgroundSessionRenewal(sid);
    if (isSessionExpiringBeforeNextRequest(session)) {
      clearInterval(checkSessionExpiredInterval);
      RouterActions.transitionTo('/errors/session-expired');
    }
  }, SESSION_EXPIRATION_CHECK_INTERVAL);
};

const SessionActions = Uniflow.createActions({
  async getCurrentSession (sid) {
    if (isSessionRetrievalPending) {
      return false;
    }
    this.emit('session-retrieval-pending');
    isSessionRetrievalPending = true;

    const request = apiRequest({
      path: `/sessions/${sid}`
    });

    try {
      const response = await request.promise();
      this.emit('session-retrieval-success', response.body);
      initiateSessionRefresh(sid);
      this.loadSessionSuccess(response.body); // forward to redux reducers
      isSessionRetrievalPending = false;
      return response.body;
    } catch (err) {
      if (isUnauthorized(err)) {
        window.localStorage.removeItem('SESSION_ID');
        env.SESSION_ID = null;
      }
      this.emit('session-retrieval-error', err);
      isSessionRetrievalPending = false;
    }
  },

  cancelShare () {
    this.emit('cancel-share');
  },

  logout () {
    apiRequest({ path: '/sessions/logout' }).promise();

    // signal logout
    window.localStorage[`LOGOUT:${env.SESSION_ID}`] = 'logout';
    window.localStorage.removeItem(`LOGOUT:${env.SESSION_ID}`);
    killSession();
  }
});

function killSession () {
  env.SESSION_ID = null;
  window.localStorage.removeItem('SESSION_ID');
  SessionActions.emit('session-clear');
  if (inIframe()) {
    RouterActions.transitionTo('/errors/logged-out');
  } else {
    RouterActions.transitionTo('/search');
  }
}

// recieve logout signal
function handleLocalStorageChange (event) {
  if (env.SESSION_ID && event.key === `LOGOUT:${env.SESSION_ID}`) {
    killSession();
  }
}
window.addEventListener('storage', handleLocalStorageChange, false);

export default SessionActions;
