import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Text } from '@instructure/ui-text';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import HandlesErrors from '../../common/handles-errors';
import ErrorHandler from '../../common/ErrorHandler.js';
import ImagePicker from './image-picker';
import ContentLicensing from './content-licensing';
import LicenseSpecifications from './license-specifications';
import FormGroup from '../../common/form-group';
import Tags from '../../common/tags';
import Outcomes from '../../common/outcomes';
import CroppedImg from '../../common/cropped-img';
import Button from '../../common/button';
import UpdateSelector from './update-selector';
import PreviewCard from '../preview-card';
import RangeMultiSlider from './range-multi-slider';
import usGrades from './grade-schemes/us';
import Header from './header';
import SharedWith from './shared-with';
import * as announce from '../../../common/announce';
import appConstants from '../../../common/app-constants';
import tracker from '../../../common/analytics/analytics';
import ContentCategoryTypeSelector from './content-category-type-selector';
import { getLocalizedUrl } from '../../../common/localized-urls';

function hasK12Id (gradeIds) {
  return (
    Array.isArray(gradeIds) &&
    gradeIds.some((gradeId) => {
      const k12Ids = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
      return k12Ids.includes(gradeId);
    })
  );
}

export default createReactClass({
  displayName: 'ResourceForm',

  mixins: [HandlesErrors],

  propTypes: {
    images: PropTypes.object,
    contentItemUrl: PropTypes.string,
    cancel: PropTypes.func,
    submitButtonText: PropTypes.string,
    preventSubmission: PropTypes.bool,
    newVersionUpdateErrors: PropTypes.array,
    showUpdateOptions: PropTypes.bool,
    defaultUpdateOption: PropTypes.object,
    sessionAccount: PropTypes.object,
    handleSubmit: PropTypes.func,
    user: PropTypes.object,
    resource: PropTypes.object,
    formChanged: PropTypes.func,
    isUpdate: PropTypes.bool,
    updateSelectionChanged: PropTypes.func,
    account: PropTypes.object,
    session: PropTypes.object,
    outcomes: PropTypes.object,
    groups: PropTypes.array,
    consortiums: PropTypes.object,
    licenses: PropTypes.object,
    search: PropTypes.object,
  },

  getDefaultProps () {
    return {
      user: {},
      account: {},
      resource: {},
      groups: [],
    };
  },

  getInitialState () {
    return {
      fieldsChanged: [],
    };
  },

  track (action, label) {
    const category = this.props.resource.id ? 'Update' : 'Share';
    tracker.logAction(category, action, label);
  },

  trackFieldChange (field) {
    if (this.state.fieldsChanged.includes(field)) return;
    this.track(`Input - ${field}`);
    this.setState({
      fieldsChanged: this.state.fieldsChanged.concat([field]),
    });
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.errors && nextProps.errors.length > 0) {
      this.setState(
        {
          errors: nextProps.errors,
        },
        () => {
          // using .form-group.error because .error-message would cut off top of message
          var element = document.querySelector('.form-group.error');
          element && element.scrollIntoView();
        }
      );

      var screenReaderAnnouncement = nextProps.errors.map((error) => error.msg).join('. ');
      announce.assertively(screenReaderAnnouncement);
    }

    // if they switched from an update to not an update then we should hide it
    this.setState({
      shouldHideLicenseSpecs: !nextProps.isUpdate && this.props.isUpdate,
    });
  },

  componentWillUnmount () {
    announce.reset();
  },

  handleSubmit (event) {
    this.track('TriggerExport');
    event.preventDefault();
    this.props.handleSubmit();
  },

  updateStateData (event) {
    var data = {};
    if (event.target.type === 'checkbox') {
      if (event.target.checked) {
        data[event.target.name] = Object.assign([], this.props.resource[event.target.name]);
        data[event.target.name].push(event.target.value);
      } else {
        data[event.target.name] = this.props.resource[event.target.name].filter((value) => {
          return value !== event.target.value;
        });
      }
    } else {
      data[event.target.name] = event.target.value;
    }
    this.props.formChanged(data);
  },

  handleTagsChange (value) {
    this.trackFieldChange('tags');
    this.props.formChanged({
      tags: value,
    });
  },

  handleOutcomesChange (value) {
    this.trackFieldChange('outcomes');
    this.props.formChanged({
      outcomes: value,
    });
  },

  handleFieldChange (event) {
    this.trackFieldChange(event.target.name);
    this.updateStateData(event);
  },

  handleLicenseChange (licenseId) {
    this.trackFieldChange('licenseIds');
    this.props.formChanged({
      licenseIds: licenseId,
    });
  },

  handleLicenseSpecsChange (event) {
    this.trackFieldChange('licenseSpecification');
    this.updateStateData(event);
  },

  handleScopeIdChange (scopeIds) {
    this.trackFieldChange('scopeId');
    this.props.formChanged({ scopeIds });
  },

  handleFeaturedChange (featured) {
    this.trackFieldChange('featured');
    this.props.formChanged({ featured });
  },

  handleContentCategoryTypeChange (contentCategoryTypes) {
    this.trackFieldChange('contentCategoryTypes');
    this.props.formChanged({ contentCategoryTypes });
  },

  shouldShowVersionNotes () {
    return !!(
      this.props.resource.id &&
      (this.props.isUpdate ||
        (this.props.resource.versions && this.props.resource.versions.length > 1))
    );
  },

  getVersionNotes () {
    var resource = this.props.resource;
    if (this.props.isUpdate) return resource.versionNotes || '';
    return (
      (resource.versionNotes == null
        ? resource.versions && resource.versions[0] && resource.versions[0].versionNotes
        : resource.versionNotes) || ''
    );
  },

  render () {
    const scopeIds = this.props.resource.scopeIds;
    const privateScopeId = this.props.resource.createUserId || this.props.user.id;
    const sharingOnlyWithMe = scopeIds.length === 1 && scopeIds[0] === privateScopeId;

    let initialSearchQuery = '';
    if (this.props.resource.tags.length) {
      initialSearchQuery = this.props.resource.tags[0];
    } else if (this.props.resource.title.length) {
      initialSearchQuery = this.props.resource.title;
    } else if (this.props.resource.description.length) {
      initialSearchQuery = this.props.resource.description.split(' ')[0];
    }

    var thumbnailComponent;
    const thumbnail = this.props.resource.thumbnail || {};
    if (this.props.resource.type === 'image') {
      thumbnailComponent = (
        <CroppedImg
          className="SearchResultThumbnail-image"
          src={thumbnail.url || this.props.contentItemUrl}
          alt={formatMessage('Cropped thumbnail of shared image')}
        />
      );
    } else {
      thumbnailComponent = (
        <div>
          <ErrorHandler
            render={(errorHelpers) => (
              <ImagePicker
                {...errorHelpers}
                description={thumbnail.description}
                imageState={this.props.images}
                id="thumbnail"
                initialSearchQuery={initialSearchQuery}
                learningObjectId={this.props.resource.id}
                name="thumbnail"
                onChange={this.handleFieldChange}
                value={thumbnail.url}
              />
            )}
          />
        </div>
      );
    }

    let outcomes = this.props.resource.outcomes || [];
    outcomes = outcomes.filter((outcome) => !outcome.related);
    const {
      showFederalStandards,
      showStateStandards,
      allowApprovedContent,
      accountCuratedBannerColor,
      accountCuratedBannerImage,
    } = this.props.account.settings;

    const isGradeIdsEmpty = !this.props.resource.gradeIds || !this.props.resource.gradeIds.length;
    const hasOutcomes = Boolean(outcomes.length);
    const isOutcomesEnabled =
      isGradeIdsEmpty || hasK12Id(this.props.resource.gradeIds) || hasOutcomes;
    const displayApprovedBanner =
      scopeIds.some((scopeId) => scopeId.includes('curated-')) && allowApprovedContent;

    return (
      <div className="LearningObjectForm">
        <form className="form">
          <Header>{formatMessage('Sharing and License')}</Header>
          {this.props.showUpdateOptions && (
            <UpdateSelector
              accountName={this.props.sessionAccount && this.props.sessionAccount.name}
              allowApprovedContent={allowApprovedContent}
              authorId={this.props.resource.title && this.props.user.id}
              isAccountCurator={this.props.session.isAccountCurator}
              isAdmin={this.props.session.isAdmin}
              isUpdate={this.props.isUpdate}
              newVersionUpdateErrors={this.props.newVersionUpdateErrors}
              onChange={this.props.updateSelectionChanged}
              search={this.props.search}
              selection={this.props.resource}
              type={this.props.resource.type}
            />
          )}

          <div>
            {!this.props.session.publicOnly && (
              <SharedWith
                account={this.props.account}
                errorMessage={this.getFirstErrorMsgWithId('scopeIds')}
                consortiums={this.props.consortiums}
                groups={this.props.groups}
                hasErrors={this.hasErrorsWithId('scopeIds')}
                isUpdate={this.props.isUpdate}
                onScopeIdChange={this.handleScopeIdChange}
                onFeaturedChange={this.handleFeaturedChange}
                resource={this.props.resource}
                session={this.props.session}
                sessionAccount={this.props.sessionAccount}
                user={this.props.user}
              />
            )}

            <div className="form-group">
              <ContentCategoryTypeSelector
                value={this.props.resource.contentCategoryTypes || []}
                onChange={this.handleContentCategoryTypeChange}
                label={
                  <span>
                    <Text weight="bold">{formatMessage('Content Type (Optional)')}</Text>
                  </span>
                }
              />
            </div>

            <FormGroup
              className="lor-share-licenses-form-group"
              label={formatMessage('License')}
              htmlFor="licenseIds"
              required={!sharingOnlyWithMe}
              helpText={formatMessage('Help Me Choose')}
              helpLink={getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-types-of-content-licenses-are-available-in-Commons/ta-p/1809')}
              hasErrors={this.hasErrorsWithId('licenseIds')}
              errorMessage={this.getFirstErrorMsgWithId('licenseIds')}
            >
              <ContentLicensing
                onChange={this.handleLicenseChange}
                licenseId={this.props.resource.licenseIds[0]}
                licenses={this.props.licenses.licenses}
                error={this.props.licenses.error}
              />
            </FormGroup>

            <LicenseSpecifications
              licenseId={this.props.resource.licenseIds[0]}
              onChange={this.handleLicenseSpecsChange}
              licenseSpecs={this.props.resource.licenseSpecs}
              shouldHideLicenseSpecs={this.state.shouldHideLicenseSpecs}
              hasErrors={this.hasErrorsWithId('licenseSpecs')}
              errorMessage={this.getFirstErrorMsgWithId('licenseSpecs')}
            />

            {this.shouldShowVersionNotes() && (
              <FormGroup
                label={formatMessage('Version notes')}
                htmlFor="versionNotes"
                required
                hasErrors={this.hasErrorsWithId('versionNotes')}
                errorMessage={this.getFirstErrorMsgWithId('versionNotes')}
              >
                <textarea
                  className="form-control"
                  name="versionNotes"
                  id="versionNotes"
                  value={this.getVersionNotes()}
                  rows={4}
                  placeholder={formatMessage('Please inform users on what you updated.')}
                  onChange={this.handleFieldChange}
                />
              </FormGroup>
            )}
            <Header topBorder>{formatMessage('Metadata')}</Header>
            <FormGroup
              legend={formatMessage('Title')}
              htmlFor="title"
              required
              hasErrors={this.hasErrorsWithId('title')}
              errorMessage={this.getFirstErrorMsgWithId('title')}
            >
              <input
                name="title"
                id="title"
                onChange={this.handleFieldChange}
                value={this.props.resource.title}
                placeholder={formatMessage('Give your object a title')}
                className="form-control"
                type="text"
              />
            </FormGroup>

            <FormGroup
              legend={formatMessage('Description')}
              htmlFor="description"
              required
              hasErrors={this.hasErrorsWithId('description')}
              errorMessage={this.getFirstErrorMsgWithId('description')}
            >
              <textarea
                className="form-control"
                name="description"
                id="description"
                value={this.props.resource.description}
                rows={4}
                onChange={this.handleFieldChange}
                placeholder={formatMessage('Please describe your object')}
              />
            </FormGroup>

            <FormGroup
              className="lor-share-tags-form-group"
              legend={formatMessage('Tags')}
              helpText={formatMessage('(Hit enter to start a new tag)')}
              hasErrors={this.hasErrorsWithId('tags')}
              errorMessage={this.getFirstErrorMsgWithId('tags')}
            >
              <Tags
                name="tags"
                id="tags"
                isPublic={this.props.resource.scopeIds.includes(appConstants.scope.public)}
                onChange={this.handleTagsChange}
                value={this.props.resource.tags}
              />
            </FormGroup>

            <FormGroup
              label={formatMessage('Image')}
              htmlFor="thumbnail"
              hasErrors={this.hasErrorsWithId('thumbnail')}
              errorMessage={this.getFirstErrorMsgWithId('thumbnail')}
            >
              {thumbnailComponent}
            </FormGroup>

            <Header topBorder>
              {appConstants.showOutcomes
                ? formatMessage('Grades and Outcomes')
                : formatMessage('Grades')}
            </Header>
            <FormGroup
              legend={formatMessage('Grade/Level')}
              hasErrors={this.hasErrorsWithId('gradeIds')}
              errorMessage={this.getFirstErrorMsgWithId('gradeIds')}
            >
              <RangeMultiSlider
                name="gradeIds"
                id="gradeIds"
                rangeStartLabel={formatMessage('US grade range start')}
                rangeEndLabel={formatMessage('US grade range end')}
                grades={usGrades}
                values={this.props.resource.gradeIds || []}
                onChange={this.handleFieldChange}
              />
            </FormGroup>

            {appConstants.showOutcomes && (showFederalStandards || showStateStandards) && (
              <FormGroup
                className="lor-share-outcomes-form-group"
                label={formatMessage('Outcomes')}
                helpText={formatMessage('Kindergarten - 12th grades only')}
                hasErrors={this.hasErrorsWithId('outcomes')}
                errorMessage={this.getFirstErrorMsgWithId('outcomes')}
              >
                <Outcomes
                  account={this.props.account}
                  outcomes={this.props.outcomes}
                  name="outcomes"
                  isDisabled={!isOutcomesEnabled}
                  id="outcomes"
                  onChange={this.handleOutcomesChange}
                  value={outcomes}
                />
              </FormGroup>
            )}

            {this.renderErrorsWithId('learning-object')}
            <div className="form-buttons">
              <Button className="form-cancel-button" onClick={this.props.cancel}>
                {formatMessage('Cancel')}
              </Button>
              <Button
                className="form-submit-button"
                interaction={this.props.preventSubmission ? Button.interaction.disabled : Button.interaction.enabled}
                aria-disabled={this.props.preventSubmission}
                color={Button.color.primary}
                onClick={this.handleSubmit}
              >
                {this.props.submitButtonText}
              </Button>
            </div>
          </div>
        </form>
        <ScreenReaderContent>{formatMessage('Learning object preview')}</ScreenReaderContent>
        <PreviewCard
          resource={this.props.resource}
          isLink={false}
          showPreview={false}
          curatedBanner={{
            color: accountCuratedBannerColor,
            image: accountCuratedBannerImage,
          }}
          displayApprovedBanner={displayApprovedBanner}
        />
      </div>
    );
  },
});
