import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AnalyticsActions from '../../../actions/analytics';
import RouterStore from '../../../stores/router';
import { SearchActions } from '../../../actions/';
import SearchInfo from '../info';
import ResultCards from './cards';
import ResultsList from './list';
import { SORTING_ORDER_DESCENDING, SORT_MATCHING, SORT_RECENT } from '../../../constants/search';
import LoadMore from './load-more';
import ResourcePagination from '../../common/result-pagination';
import { calculatePaginationPages } from '../../../common/util/calculate-pagination-pages';
import { CARD_VIEW, LIST_VIEW } from '../../../constants/results-view';
import SearchInfoStats from './search-info-stats';
import { ResultsErrorWithImage } from './error';
import { usePaginationScrolling } from '../../../common/util/use-pagination-scrolling';
import { isCursorLimitReachedError } from '../../../common/is-cursor-limit-reached-error';
import { CursorLimitReachedError } from './errors/cursor-limit-reached';
import { showFilterTray } from '../../../actions/filter';
import { RESOURCE_CURSOR_LIMIT } from '../../../constants/resource-cursor-limit';

const createParamsFromProps = (props) => ({
  ...props.baseParams,
  ...props.router.query
});

const SearchResultsWrapper = (props) => {
  const [isNewSearch, setIsNewSearch] = useState(true);
  const router = useRef();
  const sortBy = RouterStore.state.query.sortBy || (RouterStore.state.query.q ? SORT_MATCHING : SORT_RECENT);
  const sortOrder = RouterStore.state.query.sortOrder || SORTING_ORDER_DESCENDING;
  const isPaginationEnabled = props.featureFlags.flags.resource_pagination;
  const isListViewEnabled = props.featureFlags.flags.search_page_list_view;
  const hasError = !!props.results.error;
  const shouldShowError = isPaginationEnabled && hasError;
  const shouldShowEmptyMessage = props.results.store.showEmpty() && props.results.store.getPlaceholdersCount() === 0;
  const loadMoreFocusContainerRef = useRef(null);
  const fetchResults = (cursor) => props.fetchResults(createParamsFromProps(props), cursor);

  useEffect(() => {
    if (router.current !== props.router) {
      router.current = props.router;
      fetchResults(null);
      setIsNewSearch(!props.router.query.page);
    }

    if (isNewSearch && props.results.searchSuccessful) {
      setIsNewSearch(false);
      AnalyticsActions.viewedSearchResults(props.results.count);
    }
  }, [props]);

  const getPaginationPages = () => {
    const pageFromQuery = parseInt(RouterStore.state.query.page, 10) || 1;
    return calculatePaginationPages(
      pageFromQuery,
      // TODO: handle initial load: CLAB-363, we should be able to remove `|| 1` after that
      props.results.store.getFilteredResultsCount() || 1,
      props.resultsPerPage
    );
  };

  const paginationEndRef = usePaginationScrolling(isPaginationEnabled, props, getPaginationPages);

  const renderCards = () => {
    return (
      <ResultCards
        {...props}
        onRetry={fetchResults}
        loadMoreFocusContainerRef={loadMoreFocusContainerRef}
      />
    );
  };

  const renderList = () => (
    <ResultsList
      results={props.results}
      resultsPerPage={props.resultsPerPage}
      sortOrder={sortOrder}
      sortBy={sortBy}
      onListItemRender={props.onListItemRender}
      onGetListHeaders={props.onGetListHeaders}
    />
  );

  const renderLoadMore = () => (
    <LoadMore
      hasMore={props.results.hasMore}
      count={props.results.items.length}
      onLoadMore={() => fetchResults(props.results.nextCursor)}
      isLoading={props.results.loading}
      focusContainerRef={loadMoreFocusContainerRef}
    />
  );

  const renderPagination = (isCardView) => {
    const { totalPages, currentPage, hasTooManyResults } = getPaginationPages();
    return (
      <div className={classnames({
        'wrapper-pagination-card': isCardView,
        'wrapper-pagination-list': !isCardView
      })}>
        <ResourcePagination
          totalPages={totalPages}
          currentPage={currentPage}
          hasTooManyResults={hasTooManyResults}
          onPageChange={page => SearchActions.update({ page })}
          margin="none"
        />
        <div ref={paginationEndRef} />
      </div>
    );
  };

  const renderResults = () => {
    const isCardView = !isListViewEnabled || props.layout === CARD_VIEW;

    return (
      <React.Fragment>
        <SearchInfoStats
          results={props.results}
          statsMessage={props.statsMessage}
          includeToggleResultsViewButton={props.canToggleLayout}
        />
        {isCardView ? renderCards() : renderList()}
        {isPaginationEnabled ? renderPagination(isCardView) : renderLoadMore()}
      </React.Fragment>
    );
  };

  const redirectToLastValidPage = () => {
    SearchActions.redirectToLastPage(
      Math.min(props.results.store.getFilteredResultsCount(), RESOURCE_CURSOR_LIMIT),
      props.resultsPerPage
    );
  };

  const renderError = () => {
    return isCursorLimitReachedError(props.results.error)
      ? <CursorLimitReachedError onBackButtonClick={redirectToLastValidPage} onFilterClick={() => showFilterTray()} />
      : <ResultsErrorWithImage onRetryClick={fetchResults} />;
  };

  const renderEmptyMessage = () => props.emptyMessage;

  return (
    <div>
      <SearchInfo
        consortiums={props.consortiums}
        groups={props.groups}
        router={props.router}
        session={props.session}
        canShowPublic={props.canShowPublic}
      />
      {shouldShowError
        ? renderError()
        : shouldShowEmptyMessage
          ? renderEmptyMessage()
          : renderResults()}
    </div>
  );
};

SearchResultsWrapper.defaultProps = {
  baseParams: {},
  layout: CARD_VIEW,
};

SearchResultsWrapper.propTypes = {
  baseParams: PropTypes.object,
  fetchResults: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  banner: PropTypes.node,
  emptyMessage: PropTypes.node.isRequired,
  statsMessage: PropTypes.node,
  searchPrivateObjects: PropTypes.bool,
  canToggleLayout: PropTypes.bool,
  queryArialLabel: PropTypes.string,
  onListItemRender: PropTypes.func,
  onGetListHeaders: PropTypes.func,
  layout: PropTypes.oneOf([CARD_VIEW, LIST_VIEW]),
};

export default SearchResultsWrapper;
