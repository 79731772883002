import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import _ from 'lodash';
import { View } from '@instructure/ui-view';
import { IconImportLine } from '@instructure/ui-icons';
import { Alert } from '@instructure/ui-alerts';
import { ApplyTheme } from '@instructure/ui-themeable/lib/ApplyTheme';

import { exportStatus } from '../../common/app-constants';
import DownloadButton from './download-button';
import ImportButton from './import-button';
import Thumbnail from './thumbnail';
import iconApprovedSrc from '../../../assets/svg/approved-check.svg';
import { displayAsApprovedContent } from '../../common/util/approved-content';
import ToggleFavoriteButton from './toggle-favorite-button';
import CopyResourceLinkButton from './copy-resource-link-button';
import Tray from '../common/tray';
import Button from '../common/button';
import ReportContent from './report-content/report-content';
import { getState } from '../../store';

export default class LearningObjectInfo extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired,
    limited: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    courses: PropTypes.object
  }

  constructor (props) {
    super(props);

    this.state = {
      showPanel: false
    };
  }

  renderSizeWarning = () => {
    const version = (this.props.learningObject.versions || [])[0] || {};
    const limit = 500 * 1000 * 1000; // 500 MB
    if (version.size && +version.size > limit) {
      return (
        <span className="lor-import-size-warning">
          {formatMessage(
            `This file exceeds the standard Canvas Import Quota Limit of 500MB,
          which will likely result in an unsuccessful import.`
          )}
        </span>
      );
    }
  }

  togglePanel = () => {
    this.setState({ showPanel: !this.state.showPanel });
  }

  renderBytes (size) {
    let num = size;
    const isNeg = num < 0;
    let unit = 'B';
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (isNeg) num = -num;

    if (num >= 1) {
      // borrowed from sindresorhus/pretty-bytes
      const exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1000)),
        units.length - 1
      );
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];
    }
    if (isNeg) num = -num;

    return formatMessage(
      `{byteSizeUnit, select,
      B {{size, number} B}
      kB {{size, number} kB}
      MB {{size, number} MB}
      GB {{size, number} GB}
      TB {{size, number} TB}
      PB {{size, number} PB}
      EB {{size, number} EB}
      ZB {{size, number} ZB}
      YB {{size, number} YB}
      other {{size, number}}
    }`,
      { size: num, byteSizeUnit: unit }
    );
  }

  handleThumbnailClick = () => {
    const type = this.props.learningObject.type;
    if (['document', 'image', 'video', 'audio'].includes(type)) {
      const tabs = document.querySelectorAll(
        '.lor-course-article *[role="tablist"] > *[id*="tab-"]'
      );
      const previewTab = Array.from(tabs).find(tab =>
        tab.textContent.includes(formatMessage('Preview'))
      );
      previewTab && previewTab.click();
    }
  }

  render () {
    const shouldShowReportContentButton = _.get(getState(), 'featureFlags.flags.report_content');
    const { session } = this.props.session;
    const learningObject = this.props.learningObject || {};
    const version = (learningObject.versions || [])[0] || {};

    const displayApprovedBanner = displayAsApprovedContent({
      allowApprovedContent: session.allowApprovedContent,
      scopeIds: learningObject.scopeIds,
      accountId: session.accountId,
      learningObjectAccountId: learningObject.account
        ? learningObject.account.id
        : ''
    });

    const {
      accountCuratedBannerColor: approvedColor = null,
      accountCuratedBannerImage: approvedIcon = null
    } =
      this.props.accountSettings || {};

    const showPanel = this.props.showImportPanel || this.state.showPanel;

    return (
      <section className="lor-course-info">
        {this.props.learningObject.thumbnail && (
          <Thumbnail
            image={this.props.learningObject.thumbnail}
            onClick={this.handleThumbnailClick}
          />
        )}
        {displayApprovedBanner && (
          <div
            className="lor-course-approved"
            style={{ backgroundColor: approvedColor }}
          >
            <div className="lor-course-wrapper">
              <img
                aria-hidden
                className="lor-course-approved-icon"
                src={approvedIcon || iconApprovedSrc}
              />
              <div className="lor-course-approved-message">
                {formatMessage('{accountName} Approved', {
                  accountName: session.accountName
                })}
              </div>
            </div>
          </div>
        )}
        {(!this.props.isAuthenticated) &&
           <ApplyTheme theme={{ [Alert.theme]: { boxShadow: 'none' } }}>
             <Alert
               variant="info"
               liveRegion={() => document.getElementById('screenreader-announcements-polite')}
               margin="0"
             >
               {formatMessage('Log in to Import or Download Commons Resources')}
             </Alert>
           </ApplyTheme>}
        <Button
          renderIcon={IconImportLine}
          onClick={() => this.props.isAuthenticated && this.togglePanel()}
          className="lor-course-import-button"
          color={Button.color.primary}
          display={Button.display.block}
          interaction={
            (!this.props.isAuthenticated || (this.props.limited &&
            version.exportStatus !== exportStatus.complete)) ? Button.interaction.disabled : Button.interaction.enabled
          }
        >
          {formatMessage('Import/Download')}
        </Button>

        {version.size &&
          !this.props.limited && (
          <View
            elementRef={view =>
              view &&
                view.classList.add('lor-course-download-size', 'lor-csv-value')}
            as="div"
          >
            {`${this.renderBytes(version.size)}${
                version.fileType ? ` - ${version.fileType}` : ''
              }`}
          </View>
        )}

        {this.props.isAuthenticated &&
          !this.props.limited && (
          <ToggleFavoriteButton
            learningObject={{
              ...this.props.learningObject,
              isFavorited:
                  this.props.favorites &&
                  this.props.favorites.list.some(
                    ({ id }) => id === this.props.learningObject.id
                  )
            }}
            isToggling={this.props.favorites.isToggling}
          />
        )}

        {this.props.isAuthenticated &&
          !this.props.limited && (
          <CopyResourceLinkButton
            learningObject={this.props.learningObject}
          />
        )}

        {!!shouldShowReportContentButton && !!this.props.isAuthenticated && (
          <ReportContent
            learningObject={this.props.learningObject}
          />
        )}

        <Tray
          label={formatMessage('Import/Download')}
          show={showPanel}
          onClose={() => {
            this.setState({ showPanel: false });
          }}
          ref={child => {
            this._tray = child;
          }}
        >
          <View as="div" padding="medium small small">
            {!this.props.limited && (
              <ImportButton
                learningObject={learningObject}
                courses={this.props.courses.courses}
                coursesLoadError={this.props.courses.getCoursesErrors}
                coursesLoadPending={this.props.courses.getCoursesPending}
                onImport={() => {
                  this._tray.closePanel();
                  this.props.onUpdate();
                }}
              />
            )}

            {version.exportStatus === exportStatus.complete &&
            this.props.isAuthenticated && (
              <DownloadButton
                limited={this.props.limited}
                learningObject={learningObject}
                onUpdate={this.props.onUpdate}
              />
            )}

            {this.renderSizeWarning()}
          </View>
        </Tray>
      </section>
    );
  }
}
