import Uniflow from 'uniflow';
import UpdatesActions from '../actions/updates';
import LearningObjectActions from '../actions/resource';
import NotificationCenterActions from '../actions/notification-center';
import { updateNotificationId } from '../components/common/notification-center/updates-notification';

let bootstrapped = false;
let lastUpdateId = +window.localStorage.lastUpdateId || 0;

function sendNotification (updates) {
  // we only want to show updates that are newer than the last most recent update shown
  const newUpdates = updates.filter((update) => +(new Date(update.updateDate)) > lastUpdateId);

  if (newUpdates.length > 0) {
    NotificationCenterActions.closeAllNotificationsOfType('update');
    const id = String(updateNotificationId(newUpdates));
    NotificationCenterActions.showNotification({
      id,
      data: newUpdates,
      type: 'update',
      onClose: (id) => {
        lastUpdateId = id;
        window.localStorage.lastUpdateId = id;
      }
    });
  }
}

const UpdatesStore = Uniflow.createStore({
  state: {
    // get updates
    getUpdatesPending: false,
    getUpdatesError: null,
    getUpdatesSuccessful: false,
    updates: [],
    userId: null,

    // update for learning object
    updateForLearningObjectPending: false,
    updateForLearningObjectError: null,
    updateForLearningObjectCourseErrors: {},
    updateForLearningObjectSuccessful: false,
    learningObjectUpdate: null,

    // ignore for courses
    ignoreForCoursesPending: false,
    ignoreForCoursesError: null,
    ignoreForCoursesSuccessful: false
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    // Pending
    UpdatesActions.on('get-updates-pending', UpdatesStore.handlePending);
    UpdatesActions.on('get-updates-next-page', UpdatesStore.handleNextPage);
    UpdatesActions.on('get-update-for-learning-object', UpdatesStore.handleGetForLearningObjectPending);
    UpdatesActions.on('ignore-update-for-courses-pending', UpdatesStore.handleIgnoreForCoursesPending);

    // Error
    UpdatesActions.on('get-updates-error', UpdatesStore.handleGetError);
    UpdatesActions.on('get-update-for-learning-object', UpdatesStore.handleGetForLearningObjectError);
    UpdatesActions.on('ignore-update-for-courses-error', UpdatesStore.handleIgnoreForCoursesError);
    UpdatesActions.on('remove-errors-learning-object-course', UpdatesStore.removeCourseErrors);

    // Successful
    UpdatesActions.on('get-updates-successful', UpdatesStore.handleGetSuccessful);
    UpdatesActions.on('get-update-for-learning-object-successful', UpdatesStore.handleGetForLearningObjectSuccessful);
    UpdatesActions.on('ignore-update-for-courses-successful', UpdatesStore.handleIgnoreForCoursesSuccessful);

    // Successful import
    LearningObjectActions.on('learning-object-import-result', UpdatesStore.handleImportResult);

    // Refresh
    UpdatesActions.on('refresh-updates', UpdatesStore.handleRefresh);

    bootstrapped = true;
  },

  // convenience methods
  hasMore () {
    return this.state.cursor != null && !this.state.getUpdatesError; // stop requesting if requests fail
  },
  getUpdateForLearningObject (resourceId) {
    return (this.state.learningObjectUpdate && this.state.learningObjectUpdate.resource.id === resourceId)
      ? this.state.learningObjectUpdate : this.state.updates.find((update) => update.resource.id === resourceId);
  },
  hasUpdates () {
    return (
      !!this.state.userId &&
      this.state.updates.length > 0
    );
  },

  // get updates
  handlePending (userId) {
    this.setState({
      getUpdatesPending: true,
      getUpdatesError: null,
      getUpdatesSuccessful: false,
      updates: [],
      userId
    });
  },
  handleNextPage () {
    this.setState({
      getUpdatesPending: true,
      getUpdatesError: null,
      getUpdatesSuccessful: false
    });
  },
  handleGetError (err) {
    this.setState({
      getUpdatesPending: false,
      getUpdatesError: err,
      getUpdatesSuccessful: false
    });
  },
  handleGetSuccessful (updates, count, cursor) {
    const newUpdates = this.state.updates.concat(updates || []);
    this.setState({
      getUpdatesPending: false,
      getUpdatesSuccessful: true,
      updates: newUpdates,
      count,
      cursor
    });
    sendNotification(newUpdates);
  },

  // update for learning object
  handleGetForLearningObjectPending () {
    this.setState({
      updateForLearningObjectPending: true,
      updateForLearningObjectError: null,
      updateForLearningObjectSuccessful: false
    });
  },
  handleGetForLearningObjectError (error) {
    this.setState({
      updateForLearningObjectPending: false,
      updateForLearningObjectError: error
    });
  },
  handleGetForLearningObjectSuccessful (update) {
    this.setState({
      updateForLearningObjectPending: false,
      updateForLearningObjectSuccessful: true,
      learningObjectUpdate: update
    });
  },

  // imported/updated
  handleImportResult (resultsByCourse) {
    const removed = {};
    resultsByCourse.forEach((result) => {
      if (result.status === 'success') {
        removed[result.id] = true;
      }
    });
    const learningObjectId = resultsByCourse[0].resourceId;
    let updates = this.state.updates;
    const update = updates.find((update) => update.resource.id === learningObjectId);
    let removedUpdateCount = 0;
    let courseErrors;

    if (update) {
      const courses = update.courses.filter(({ courseId }) => !removed[courseId]);
      if (courses.length) {
        updates = updates.map((update) => {
          if (update.resource.id !== learningObjectId) return update;
          return Object.assign({}, update, { courses });
        });
      } else {
        updates = updates.filter((update) => update.resource.id !== learningObjectId);
        removedUpdateCount = 1;
      }

      courseErrors = this.state.updateForLearningObjectCourseErrors;
      courseErrors[update.resource.id] = resultsByCourse
        .filter((result) => result.status === 'failed')
        .map((error) => {
          error.learningObjectId = learningObjectId;
          return error;
        });
    }

    const learningObjectUpdate = this.state.learningObjectUpdate;
    const count = this.state.count - removedUpdateCount;

    if (learningObjectUpdate && learningObjectUpdate.resource.id === learningObjectId) {
      this.setState({
        updates,
        count,
        updateForLearningObjectCourseErrors: courseErrors,
        updateForLearningObjectError: null,
        updateForLearningObjectSuccessful: false,
        learningObjectUpdate: null
      });
    } else {
      this.setState({
        updates,
        count
      });
    }
    sendNotification(updates);
  },

  // makes sure the list is re-fetched when next needed
  handleRefresh () {
    this.setState({
      getUpdatesError: null,
      getUpdatesSuccessful: false,
      updates: [],
      userId: null,
      cursor: null
    });
  },

  handleIgnoreForCoursesPending () {
    this.setState({
      ignoreForCoursesPending: true,
      ignoreForCoursesError: null,
      ignoreForCoursesSuccessful: false
    });
  },

  handleIgnoreForCoursesError (error) {
    this.setState({
      ignoreForCoursesPending: false,
      ignoreForCoursesError: error
    });
  },

  handleIgnoreForCoursesSuccessful (resourceId, courseIds) {
    const updates = this.state.updates.slice();
    const index = updates.map((update) => update.resource.id).indexOf(resourceId);
    if (index > -1) {
      const courses = updates[index].courses.filter((course) => courseIds.indexOf(course.courseId) === -1);
      let removedUpdateCount = 0;

      if (courses.length === 0) {
        updates.splice(index, 1);
        removedUpdateCount = 1;
      } else {
        updates[index] = Object.assign({}, updates[index]);
        updates[index].courses = courses;
      }
      this.setState({
        updates,
        count: this.state.count - removedUpdateCount,
        ignoreForCoursesPending: false,
        ignoreForCoursesSuccessful: true
      });
    }
  },

  removeCourseErrors (learningObjectId, courseIds) {
    const currentErrors = Object.assign({}, this.state.updateForLearningObjectCourseErrors);
    const errors = Object.keys(currentErrors)
      .filter((updateId) => {
        return currentErrors[updateId].learningObjectId === learningObjectId &&
          courseIds.includes(currentErrors[updateId].id);
      }).map((updateId) => currentErrors[updateId]);

    this.setState({
      updateForLearningObjectCourseErrors: errors
    });
  }
});

export default UpdatesStore;
