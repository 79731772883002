import Uniflow from 'uniflow';
import RouterActions from '../actions/router';

let bootstrapped = false;

const ResultPathStore = Uniflow.createStore({
  state: {
    pathname: '/search',
    search: ''
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    RouterActions.on('matchResult', ({ location }) => {
      ResultPathStore.setState({
        pathname: location.pathname,
        search: location.search
      });
    });

    bootstrapped = true;
  }
});

export default ResultPathStore;
