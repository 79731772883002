import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../common/checkbox';
import formatMessage from 'format-message';
import ComplexMessage from '../common/complex-message';
import NotifyMessage from '../common/notify-message';
import Warning from '../common/warning';

export default class UpdatesListItemCourse extends React.Component {
  static propTypes = {
    isSingle: PropTypes.bool,
    course: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange (evt) {
    this.props.onChange(this.props.course, evt);
  }

  render () {
    const course = this.props.course;
    const importDate = new Date(course.importDate);
    const versionCreateDate =
      course.versionCreateDate && new Date(course.versionCreateDate);
    const id =
      'update_course_' + course.courseId + '_' + +new Date(course.importDate);
    return (
      <li className="Updates-list-item-course">
        <div className="Updates-list-item-course-column1">
          {!this.props.isSingle && (
            <Checkbox
              id={id}
              checked={this.props.selected}
              onChange={this.onChange}
            />
          )}
        </div>

        <div className="Updates-list-item-course-column2">
          <label className="Updates-list-item-course-title" htmlFor={id}>
            {course.courseName}
          </label>

          <div className="Updates-list-item-course-timestamp">
            {versionCreateDate
              ? formatMessage(
                'Version: { created, date, medium } - { created, time, short }',
                {
                  created: versionCreateDate
                }
              )
              : formatMessage(
                'Imported: { imported, date, medium } - { imported, time, short }',
                {
                  imported: importDate
                }
              )}
          </div>
        </div>
        {this.props.error && (
          <NotifyMessage
            icon={<Warning width="25px" height="25px" />}
            kind="danger"
          >
            {this.props.error && this.props.error.msg ? (
              this.props.error.msg
            ) : (
              <ComplexMessage>
                {formatMessage(
                  '{strong} There was an error updating "{course}". Please try again or contact your Administrator.',
                  {
                    strong: ComplexMessage.placeholder('strong'),
                    course: this.props.error.name
                  }
                )}
                <strong key="strong">{formatMessage('Oh no!')}</strong>
              </ComplexMessage>
            )}
          </NotifyMessage>
        )}
      </li>
    );
  }
}
