import React from 'react';
import { getState } from '../../store';
import FavoriteResourceCardComponentFavoritesTray from './favorite-resource-card-component-favorites-tray';
import FavoriteResourceCardComponent from './favorite-resource-card-component';
import {
  canvasNotificationData,
  transformToLti
} from '../../common/canvas-postmessage-data';

export default class FavoriteResourceCard extends React.Component {
  ref = React.createRef();

  handleClick = event => {
    const { startImport, resource } = this.props;
    if (event != null) {
      event.preventDefault();
    }
    startImport(resource);
    if (this.ref && this.ref.current) {
      this.ref.current.focus();
    }
  };

  handleDismiss = ev => {
    const { dismissErrorForResource, resource } = this.props;
    dismissErrorForResource(resource);
    if (this.ref && this.ref.current) {
      this.ref.current.focus();
    }
  };

  render () {
    const {
      resource,
      returnUrl,
      courseId,
      importStatus,
    } = this.props;
    const { error, isImporting, importResult: importedUrl, showSuccess } = importStatus;
    const componentProps = {
      body: transformToLti(
        canvasNotificationData(courseId, resource, importedUrl)
      ),
      resource,
      handleClick: this.handleClick,
      handleDismiss: this.handleDismiss,
      error,
      isImporting,
      showSuccess,
      returnUrl
    };
    const isFavoritesTraySet = getState().featureFlags.flags.favorites_tray;
    return isFavoritesTraySet ? (
      <FavoriteResourceCardComponentFavoritesTray
        {...componentProps}
        ref={this.ref}
      />
    ) : (
      <FavoriteResourceCardComponent {...componentProps} />
    );
  }
}
