import { checkProgress, shouldWaitForProgressComplete } from './import-progress';
import { importIntoCourse, getFileUrl } from './course';
import { createModuleItem } from './module';
import { throwOnNullishParams } from '../validation';

export const doAfterUploadWork = async ({
  canvasUrl,
  courseId,
  learningObjectId,
  contentId,
  target
}) => {
  if (!target) {
    return {
      learningObjectId
    };
  }
  if (target.type === 'module') {
    return createModuleItem({
      courseId,
      learningObjectId,
      contentId,
      moduleId: target.id
    });
  } else if (target.type === 'rce') {
    return {
      importedUrl: getFileUrl({
        canvasUrl,
        courseId,
        contentId
      })
    };
  } else if (target.type === 'course') {
    return {
      learningObjectId
    };
  }
};

export const VALID_TARGETS = ['rce', 'course', 'module', 'assignment'];
export const isTargetValid = target => {
  return VALID_TARGETS.includes(target.type);
};

const validateParams = params => {
  throwOnNullishParams(params);
  if (!isTargetValid(params.target)) {
    throw new Error('Not a valid target for canvas import!');
  }
  return true;
};

export const importIntoCanvas = async ({
  canvasUrl,
  courseId,
  courseName,
  learningObject,
  target = {
    type: 'course'
  }
}) => {
  validateParams({
    canvasUrl,
    courseId,
    courseName,
    learningObject,
    target
  });
  const response = await importIntoCourse({
    courseId,
    courseName,
    learningObject,
    target
  });
  if (response.status === 'failed') {
    throw new Error(response.msg);
  }
  if (shouldWaitForProgressComplete(learningObject.type)) {
    if (!response.canvasUploadUrl) {
      throw new Error('Canvas upload url not found!');
    }
    const progressResult = await checkProgress(response.canvasUploadUrl);
    const afterUploadResult = await doAfterUploadWork({
      canvasUrl,
      courseId,
      learningObjectId: learningObject.id,
      contentId: progressResult.results.id,
      target
    });
    return afterUploadResult;
  }
  return {
    learningObjectId: learningObject.id
  };
};
