import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Autocomplete from '../../common/react-autocomplete';
import classnames from 'classnames';
import formatMessage from 'format-message';
import ErrorMessage from '../../common/error-message';
import * as announce from '../../../common/announce';
import Announce from '../../common/announce';
import UsersNoResults from '../../common/users-no-results';

export default createReactClass({
  displayName: 'GroupMemberCreate',

  propTypes: {
    members: PropTypes.array,
    onCreateMember: PropTypes.func.isRequired,
    onSearchUsers: PropTypes.func.isRequired,
    resetSearchUsers: PropTypes.func,
    users: PropTypes.object
  },

  getInitialState () {
    return {
      isCreating: false,
      value: ''
    };
  },

  onChange (event, value) {
    this.setState({ value }, () => {
      if (value === '') this.props.resetSearchUsers();
      if (value.length >= 3) {
        this.props.onSearchUsers(value);
      }
    });
  },

  highlightSearch (name) {
    // http://stackoverflow.com/a/9310752
    const regexEscapedInputValue = this.state.value.replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      '\\$&'
    );
    const searchExp = new RegExp(`(${regexEscapedInputValue})`, 'gi');
    const splitName = name.split(searchExp);

    return splitName.map((text, i) => {
      // the split that ends up being used does not remove matches
      const className = classnames({
        'UserResult-highlighted': text.search(searchExp) !== -1
      });
      return (
        <span className={className} key={i}>
          {text}
        </span>
      );
    });
  },

  isMember (user) {
    return (
      <div className="MemberInGroup">
        {user} <span className="InGroup">{formatMessage('In Group')}</span>
      </div>
    );
  },

  renderResult (item, isHighlighted) {
    const isDisabled = this.props.members.some(member => {
      return item.uuid === member.userId;
    });
    const className = classnames('UserResult', {
      'UserResult-selected': isHighlighted,
      'UserResult-disabled': isDisabled
    });
    if (isHighlighted) {
      const index = this.props.users.items.indexOf(item);
      announce.assertively(`${index + 1} ${item.fullName}`);
    }

    return (
      <div
        className={className}
        id={item.uuid}
        key={item.uuid}
        aria-disabled={isDisabled}
      >
        {isDisabled
          ? this.isMember(item.fullName)
          : this.highlightSearch(item.fullName)}
        <div className="UserResult-email">{item.email}</div>
      </div>
    );
  },

  componentWillUnmount () {
    announce.reset();
  },

  getItemValue () {
    return ''; // disabling autocomplete feature for now because of SR annoyances
  },

  renderMenu (items, value, style) {
    if (
      this.state.value &&
      this.props.users.usersAreLoaded &&
      items.length === 0
    ) {
      return <UsersNoResults />;
    }
    if (items.length === 0) {
      return <div />;
    }
    return <div className="GroupMemberCreate-search-menu">{items}</div>;
  },

  onSelect (value, user) {
    if (
      this.props.members.some(member => {
        return user.uuid === member.userId;
      })
    ) {
      return;
    }
    this.setState({ value: '' }, () => {
      this.props.resetSearchUsers();
    });
    this.props.onCreateMember(user);
    announce.assertively(
      formatMessage('{fullName} has been added', {
        fullName: user.fullName
      })
    );
  },

  render () {
    const users = this.props.users;

    return (
      <div className="GroupMemberCreate">
        <Autocomplete
          inputProps={{
            className: 'GroupMemberCreate-input',
            placeholder: formatMessage('Search users to add'),
            type: 'text',
            autoComplete: 'off',
            autoCorrect: 'off'
          }}
          ariaLabelText={formatMessage('Search users to add')}
          getItemValue={this.getItemValue}
          value={this.state.value}
          onChange={this.onChange}
          items={users.items}
          renderItem={this.renderResult}
          renderMenu={this.renderMenu}
          onSelect={this.onSelect}
        />
        {users.usersLoadError && (
          <ErrorMessage
            className="GroupMemberCreate-error"
            inline
            message={users.usersLoadError.message}
          />
        )}
        {users.meta.count > 0 && users.usersAreLoaded && (
          <Announce
            message={formatMessage(
              `{count, plural,
            one {# user found}
            other {# users found}
          }`,
              {
                count: users.meta.count || 0
              }
            )}
          />
        )}
      </div>
    );
  }
});
