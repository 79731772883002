import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Notification from './notification';

// the NotificationCenter will need to know about some special
// notifications such as updates
import UpdatesNotification from './updates-notification';

function renderUpdatesNotification (updates) {
  return <UpdatesNotification updates={updates} />;
}

export default createReactClass({
  displayName: 'NotificationCenter',

  propTypes: {
    notificationCenter: PropTypes.object
  },

  notificationRenderers: {
    update: renderUpdatesNotification
  },

  render () {
    return (
      <div className="NotificationCenter">
        {this.props.notificationCenter.currentNotifications.map(
          notification => {
            return (
              <Notification
                key={notification.id}
                notificationId={notification.id}
                onClose={notification.onClose || null}
              >
                {this.notificationRenderers[notification.type](
                  notification.data
                )}
              </Notification>
            );
          }
        )}
      </div>
    );
  }
});
