const identity = id => row => row[id];

const csvString = str => {
  if (str === undefined || str === null) {
    return '';
  }
  str = str.toString().replace(/"/g, '""');
  if (str.includes(',')) str = `"${str}"`;
  return str;
};

const areColumnsMalformed = columns => {
  if (!columns || columns.length === 0 || !Array.isArray(columns)) {
    return true;
  }
  const isMissingId = columns.some(column => !column.id);
  return isMissingId;
};
export const getCSVObject = (columns, data) => {
  if (areColumnsMalformed(columns)) {
    return null;
  }
  const headers = columns.map(column => column.title || '');
  const accessors = columns.reduce((acc, column) => {
    acc[column.id] = column.format || identity(column.id);
    return acc;
  }, {});

  const surelyOrderedAccessors = columns.map(column => accessors[column.id]);
  const processedData = data.map(row => {
    const mappedRow = surelyOrderedAccessors.map(accessor => accessor(row));
    return mappedRow;
  });
  return [
    headers,
    ...processedData
  ];
};
export const convertToCSVString = (columns, data) => {
  const csvObject = getCSVObject(columns, data);
  if (!csvObject) {
    return null;
  }
  return csvObject.map(row => row.map(cell => csvString(cell)).join(',')).join('\r\n');
};
