import Uniflow from 'uniflow';
import RouterActions from '../actions/router';

let bootstrapped = false;

const RouterStore = Uniflow.createStore({
  state: {
    params: {},
    query: {},
    pathname: ''
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    RouterActions.on('match', ({ location, params }) => {
      RouterStore.setState({
        ...location,
        params
      });
    });

    RouterActions.on('back-to-route', ({ name, uri }) => {
      RouterStore.setState({
        backTo: { name, uri }
      });
    });

    bootstrapped = true;
  },

  isSharePage () {
    return /^\/share($|\?|\/)/.test(this.state.path);
  },
  isSearchPage () {
    return /^\/search($|\?|\/)/.test(this.state.path);
  }
});

export default RouterStore;
