import React from 'react';
import notFoundSrc from '../../../../assets/svg/not-found.svg';
import formatMessage from 'format-message';

export default function RedSorry ({ children }) {
  return (
    <div className="lor-error-layout">
      {children}
      <img
        alt={formatMessage('404 Oops, we couldn\'t find that page.')}
        src={notFoundSrc}
      />
    </div>
  );
}
