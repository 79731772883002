import React, { useMemo } from 'react';
import { ResultsError } from './error';
import ResultPlaceholder from '../result/placeholder';
import { isResourcePaginated } from '../../../common/util/is-resource-paginated';
import ResultsStore from '../../../stores/results';

const loadMoreNumberOfPlaceholders = 16;

const getNumberOfPlaceholders = (props) => {
  return isResourcePaginated() ? props.resultsPerPage : loadMoreNumberOfPlaceholders;
};

const PlaceholderCards = (props) => {
  const placeholderData = Array.from(
    { length: props.count },
    (_, index) => ({ id: index })
  );
  return <Cards data={placeholderData} onRender={() => <ResultPlaceholder />} />;
};

const Cards = (props) => {
  return props.data.map((learningObject) => (
    <li key={learningObject.id}>
      {props.onRender(learningObject)}
    </li>
  ));
};

const ResultCards = (props) => {
  const placeholders = useMemo(() => <PlaceholderCards count={getNumberOfPlaceholders(props)} />, []);
  const results = props.results.store.get();
  const isInitialLoading = props.results.store.showLoading();
  const onRetry = () => props.onRetry(props.results.nextCursor);
  const placeholdersCount = ResultsStore.getPlaceholdersCount();

  return (
    <React.Fragment>
      <ol className="card-layout" ref={props.loadMoreFocusContainerRef}>
        {
          isInitialLoading
            ? placeholders
            : <Cards data={results} onRender={props.onCardRender} />
        }
        {
          !!placeholdersCount && <PlaceholderCards count={placeholdersCount} />
        }
      </ol>
      {props.results.error && <ResultsError onRetryClick={onRetry} />}
    </React.Fragment>
  );
};

export default ResultCards;
