import formatMessage from 'format-message';
import { replaceErrors } from './replace';

export const reviewErrors = {
  'root-reviews': formatMessage('Root reviews array of exactly one review is required.'),
  'rating-values': formatMessage('Rating must be between 1-5'),
  'body-required': formatMessage('Body or rating is required.'),
  'body-invalid': formatMessage('Review must be 1,000 characters or less.'),
  'rating-text-required': formatMessage('Rating or text is required.')
};

export default function (err) {
  return replaceErrors(err, reviewErrors);
}
