// Make sure it goes to jsdom virtual console instead of node console during tests
const console = window.console;

let lastPage = null;

export default {
  logPageView (page) {
    if (lastPage !== page) {
      console.log('(lor:analytics) Page viewed:', page);
      lastPage = page;
    }
  },
  logAction (action, ...args) {
    console.log('(lor:analytics) Action:', action, ...args);
  },
  setUserDimension (dimensionValue) {
    console.log('(lor:analytics) Set User Domain', dimensionValue);
  },
  logTime  (category, action, time) {
    console.log('(lor:analytics) Action: Time', category, action, time);
  }
};
