import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Flex } from '@instructure/ui-flex';
import { Tooltip } from '@instructure/ui-tooltip';
import {
  IconFilesCopyrightSolid,
  IconFilesCreativeCommonsSolid,
  IconFilesPublicDomainSolid
} from '@instructure/ui-icons';

import * as License from '../../models/license';
import Link from '../common/link';

export default createReactClass({
  displayName: 'License',

  propTypes: {
    licenseId: PropTypes.string,
    licenseSpecs: PropTypes.string
  },

  getDefaultProps () {
    return {
      licenseId: null,
      licenseSpecs: ''
    };
  },

  getInitialState () {
    return {
      license: {},
      licenses: []
    };
  },

  componentDidMount () {
    License.find(this.setLicenses);
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setLicenseById(nextProps.licenseId, this.state.licenses);
  },

  setLicenses (err, licensesResult) {
    const { items: licenses } = licensesResult;
    if (err || !licensesResult || (licenses && licenses.errors)) return;
    this.setLicenseById(this.props.licenseId, licenses);
  },

  setLicenseById (id, licenses) {
    var license = licenses.find(license => license.id === id) || {};
    this.setState({ license, licenses });
  },

  render () {
    if (this.props.licenseId === 'copyright_reserve') {
      const content = (
        <div
          tabIndex="0"
          className="lor-instui-cursor-default lor-instui-simple-button license"
        >
          {formatMessage('Copyrighted')}
        </div>
      );

      return (
        <Flex>
          <Flex.Item>
            <IconFilesCopyrightSolid />
          </Flex.Item>
          <Flex.Item padding="0 x-small 0 x-small">
            {this.props.licenseSpecs ? (
              <Tooltip
                color="inverse"
                renderTip={this.props.licenseSpecs}
                placement="bottom"
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </Flex.Item>
        </Flex>
      );
    }

    const { license } = this.state;
    const icon =
      license.id === 'public_domain'
        ? IconFilesPublicDomainSolid
        : IconFilesCreativeCommonsSolid;

    if (license && license.title && license.link) {
      const content = (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          renderIcon={icon}
          href={license.link}
          className="license"
        >
          {license.title.replace('CC - ', '')}
        </Link>
      );

      return (
        <Flex>
          <Flex.Item padding="0">
            <span className="License-container">
              {this.props.licenseSpecs ? (
                <Tooltip
                  color="inverse"
                  renderTip={this.props.licenseSpecs}
                  placement="bottom"
                >
                  {content}
                </Tooltip>
              ) : (
                content
              )}
            </span>
          </Flex.Item>
        </Flex>
      );
    }

    return <div />;
  }
});
