import qs from 'qs';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import urlUtil from 'url';
import classnames from 'classnames';
import defaultAvatar1Src from '../../../assets/img/avatars/default1.png';
import defaultAvatar2Src from '../../../assets/img/avatars/default2.png';
import defaultAvatar3Src from '../../../assets/img/avatars/default3.png';

export default createReactClass({
  displayName: 'Avatar',

  propTypes: {
    className: PropTypes.string,
    url: PropTypes.string
  },

  getRandomDefault () {
    var defaults = [defaultAvatar1Src, defaultAvatar2Src, defaultAvatar3Src];
    return defaults[Math.floor(Math.random() * defaults.length)];
  },

  isBadTransparentAvatarDefaultFromCanvas (url) {
    return (
      url &&
      decodeURIComponent(url).match(
        new RegExp(
          '^https://canvas.instructure.com/images/messages/avatar-50.png$'
        )
      )
    );
  },

  isGravatarUrl (url) {
    var matches =
      url &&
      decodeURIComponent(url).match(/^https?:\/\/secure\.gravatar\.com.*$/);
    return matches && matches.length > 0;
  },

  stripBadCanvasFallbackImageFromGravatar (url) {
    var urlParts = urlUtil.parse(url);
    var queryParams = qs.parse(urlParts.query);
    delete queryParams.d;
    urlParts.search = qs.stringify(queryParams);
    return urlUtil.format(urlParts);
  },

  genImageUrl () {
    let url = this.props.url;
    if (this.isBadTransparentAvatarDefaultFromCanvas(url)) {
      url = null;
    } else if (this.isGravatarUrl(url)) {
      url = this.stripBadCanvasFallbackImageFromGravatar(url);
    }
    if (!url) {
      url = this.getRandomDefault();
    }
    return `url(${url})`;
  },

  render () {
    return (
      <div
        style={{ backgroundImage: this.genImageUrl() }}
        className={classnames('lor-avatar', this.props.className)}
      />
    );
  }
});
