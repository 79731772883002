import React from 'react';
import formatMessage from 'format-message';
import LearningObjectActions from '../../actions/resource';
import ConfirmAction from '../common/confirm-action';
import PreviewCardWrapper from './preview-card-wrapper';
import { IconTrashSolid } from '@instructure/ui-icons';
import { focusSiblingPreviewCard } from './util/focus-sibling-preview-card';

export default function PreviewCardDeletable (props) {
  const { id, title } = props.resource;

  const onDelete = async (event) => {
    LearningObjectActions.destroyById(id);
    focusSiblingPreviewCard(event.target);
  };

  return (
    <ConfirmAction
      key={`delete-${id}`}
      onPerformAction={onDelete}
      confirmMessage={formatMessage('Delete {title}', {
        title
      })}
      onConfirmMessage={formatMessage('{title} Deleted', {
        title
      })}
      confirmationMessage={formatMessage(
        'You will permanently delete {name}.',
        { name: title }
      )}
      confirmationTitle={
        formatMessage('Delete {name}?', {
          name: title
        })
      }
      confirmationTextButton={formatMessage('Delete')}
      actionIcon={<IconTrashSolid color="primary" />}
    >
      <PreviewCardWrapper {...props} />
    </ConfirmAction>
  );
}
