import formatMessage from 'format-message';

const tableCaption = title => {
  return {
    descending: formatMessage('Stats table sorted by {title} descending.', { title }),
    ascending: formatMessage('Stats table sorted by {title} ascending.', { title }),
  };
};

export const headers = [
  {
    id: 'resource-head',
    title: formatMessage('Commons Resource'),
    sort: 'title',
    width: () => '30%',
    textAlign: 'start',
    onClickSortingLabel: formatMessage('Click here to sort by Commons Resource.'),
    onSortedTableCaptions: tableCaption('Commons Resource'),
    automation: 'CommonsResourceHead'
  },
  {
    id: 'author-head',
    title: formatMessage('Author'),
    sort: 'authorName',
    width: () => '16%',
    textAlign: 'start',
    onClickSortingLabel: formatMessage('Click here to sort by Author.'),
    onSortedTableCaptions: tableCaption('Author'),
    automation: 'AuthorHead'
  },
  {
    id: 'email-head',
    title: formatMessage('Email'),
    sort: 'authorEmail',
    width: () => '20%',
    textAlign: 'start',
    onClickSortingLabel: formatMessage('Click here to sort by Email.'),
    onSortedTableCaptions: tableCaption('Email'),
    automation: 'EmailHead'
  },
  {
    id: 'approved-head',
    title: formatMessage('Approved'),
    sort: 'approved',
    width: isWidthFixed => isWidthFixed ? '100px' : '8%',
    textAlign: 'start',
    onClickSortingLabel: formatMessage('Click here to sort by Approved.'),
    onSortedTableCaptions: tableCaption('Approved'),
    automation: 'ApprovedHead'
  },
  {
    id: 'reported-head',
    title: formatMessage('Reported'),
    sort: 'reports',
    width: isWidthFixed => isWidthFixed ? '100px' : '8%',
    textAlign: 'start',
    onClickSortingLabel: formatMessage('Click here to sort by Reported.'),
    onSortedTableCaptions: tableCaption('Reported'),
    automation: 'ReportedHead'
  },
  {
    id: 'favorites-head',
    title: formatMessage('Favorites'),
    sort: 'favorite',
    width: isWidthFixed => isWidthFixed ? '100px' : '8%',
    textAlign: 'start',
    justifyRight: true,
    onClickSortingLabel: formatMessage('Click here to sort by Favorites.'),
    onSortedTableCaptions: tableCaption('Favorites'),
    automation: 'FavoritesHead'
  },
  {
    id: 'downloads-head',
    title: formatMessage('Downloads'),
    sort: 'downloads',
    width: isWidthFixed => isWidthFixed ? '110px' : '8%',
    textAlign: 'start',
    justifyRight: true,
    onClickSortingLabel: formatMessage('Click here to sort by Downloads.'),
    onSortedTableCaptions: tableCaption('Downloads'),
    automation: 'DownloadsHead'
  },
  {
    id: 'source-head',
    title: formatMessage('Canvas Source'),
    width: isWidthFixed => isWidthFixed ? '120px' : '10%',
    textAlign: 'center',
  }
];

export const csvColumns =
[
  {
    title: formatMessage('Commons Resource'),
    id: 'title'
  },
  {
    title: formatMessage('Author'),
    id: 'authorName',
    format: resource => resource.authors[0].name,
  },
  {
    title: formatMessage('Email'),
    id: 'authorEmail',
    format: resource => resource.authors[0].email
  },
  {
    title: formatMessage('Approved'),
    id: 'approved',
    format: resource => resource.scopeIds.some(scopeId => scopeId.includes('curated-'))
      ? formatMessage('Yes')
      : '-',
  },
  {
    title: formatMessage('Reported'),
    id: 'reportCount'
  },
  {
    title: formatMessage('Favorites'),
    id: 'favoritedCount'
  },
  {
    title: formatMessage('Downloads'),
    id: 'downloadCount'
  },
];
