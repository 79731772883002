import formatMessage from 'format-message';

export function getDescription (url, description) {
  // eslint-disable-next-line no-useless-escape
  if (/\/api\/media\/[^\/]+\/thumbnail/.test(url)) {
    return formatMessage('A thumbnail of the video.');
  }
  return description;
}

export function localizeDescription (object) {
  if (object && object.thumbnail) {
    object.thumbnail.description = (object.type === 'image')
      ? formatMessage('Cropped thumbnail of shared image')
      : getDescription(object.thumbnail.url, object.thumbnail.description);
  }
  return object;
}
