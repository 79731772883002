import { bindActionCreators } from 'redux';
import formatMessage from 'format-message';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import ResourceActions from './resource';
import RouterActions from '../actions/router';

export const RESET_CONSORTIUMS = 'consortiums.reset';
export const LOAD_LIST_START = 'consortiums.load-list-start';
export const LOAD_LIST_SUCCESS = 'consortiums.load-list-success';
export const LOAD_LIST_FAILURE = 'consortiums.load-list-failure';
export const CHANGE_CREATE_NAME = 'consortiums.change-create-name';
export const CREATE_CONSORTIUM_START = 'consortiums.create-start';
export const CREATE_CONSORTIUM_SUCCESS = 'consortiums.create-success';
export const CREATE_CONSORTIUM_FAILURE = 'consortiums.create-failure';
export const DELETE_CONSORTIUM_START = 'consortiums.delete-start';
export const DELETE_CONSORTIUM_SUCCESS = 'consortiums.delete-success';
export const DELETE_CONSORTIUM_FAILURE = 'consortiums.delete-failure';
export const LEAVE_CONSORTIUM_START = 'consortiums.leave-start';
export const LEAVE_CONSORTIUM_SUCCESS = 'consortiums.leave-success';
export const LEAVE_CONSORTIUM_FAILURE = 'consortiums.leave-failure';
export const UPDATE_STATUS_START = 'consortiums.update-status-start';
export const UPDATE_STATUS_SUCCESS = 'consortiums.update-status-success';
export const UPDATE_STATUS_FAILURE = 'consortiums.update-status-failure';

export const creators = {
  reset: () => ({ type: RESET_CONSORTIUMS }),
  loadListStart: () => ({ type: LOAD_LIST_START }),
  loadListSuccess: (list) => ({ type: LOAD_LIST_SUCCESS, list }),
  loadListFailure: (error) => ({ type: LOAD_LIST_FAILURE, error }),
  changeCreateName: (name) => ({ type: CHANGE_CREATE_NAME, name }),
  createConsortiumStart: () => ({ type: CREATE_CONSORTIUM_START }),
  createConsortiumSuccess: (consortium) => ({ type: CREATE_CONSORTIUM_SUCCESS, consortium }),
  createConsortiumFailure: (error) => ({ type: CREATE_CONSORTIUM_FAILURE, error }),
  deleteConsortiumStart: () => ({ type: DELETE_CONSORTIUM_START }),
  deleteConsortiumSuccess: (consortium) => ({ type: DELETE_CONSORTIUM_SUCCESS, consortium }),
  deleteConsortiumFailure: (error) => ({ type: DELETE_CONSORTIUM_FAILURE, error }),
  leaveConsortiumStart: () => ({ type: LEAVE_CONSORTIUM_START }),
  leaveConsortiumSuccess: (consortium) => ({
    type: LEAVE_CONSORTIUM_SUCCESS,
    consortium
  }),
  leaveConsortiumFailure: (error) => ({ type: LEAVE_CONSORTIUM_FAILURE, error }),
  updateMembershipStatusStart: () => ({ type: UPDATE_STATUS_START }),
  updateMembershipStatusSuccess: (membership) => ({ type: UPDATE_STATUS_SUCCESS, membership }),
  updateMembershipStatusFailure: (error) => ({ type: UPDATE_STATUS_FAILURE, error })
};

export default function createConsortiumsActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadList (passive = false) {
      if (!passive) {
        actions.loadListStart();
      }
      const request = apiRequest({
        path: '/consortiums'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.loadListSuccess(response.body.items);
        return response.body.items;
      } catch (err) {
        actions.loadListFailure(err);
      }
    },

    async createConsortium ({ name }) {
      if (name === '') {
        actions.createConsortiumFailure(new Error(formatMessage('Please name your consortium')));
        return;
      }

      actions.createConsortiumStart();
      const request = apiRequest({
        path: '/consortiums',
        method: 'post',
        body: { name }
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.createConsortiumSuccess(response.body);
        RouterActions.transitionTo(`/account/consortiums/${encodeURIComponent(response.body.id)}`);
        return response.body;
      } catch (err) {
        actions.createConsortiumFailure(err);
      }
    },

    async leaveConsortium (consortium) {
      const path = `/consortiums/${consortium.id}/members/${consortium.membershipId}`;
      actions.leaveConsortiumStart();
      const request = apiRequest({
        path,
        method: 'delete'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.leaveConsortiumSuccess(consortium);
        ResourceActions.clearStore();
        return consortium;
      } catch (err) {
        actions.leaveConsortiumFailure(err);
      }
    },

    async deleteConsortium (consortium) {
      actions.deleteConsortiumStart();
      const request = apiRequest({
        path: `/consortiums/${consortium.id}`,
        method: 'delete'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.deleteConsortiumSuccess(consortium);
        ResourceActions.clearStore();
        return consortium;
      } catch (err) {
        actions.deleteConsortiumFailure(err);
      }
    },
    async updateMembershipStatus (membershipId, consortiumId, status) {
      actions.updateMembershipStatusStart();
      const request = apiRequest({
        path: `/consortiums/${consortiumId}/members/${membershipId}/status`,
        method: 'put',
        body: { status }
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.updateMembershipStatusSuccess(response.body);
        return response.body;
      } catch (err) {
        actions.updateMembershipStatusFailure(err);
      }
    }
  };
}
