let heapInitialized = false;
let ffAllowed = null;
let accFfAllowed = null;

export function hasHeap () {
  return !!window.queap && !!ffAllowed && !!accFfAllowed;
}

export function setFeatureFlag (val) {
  ffAllowed = val === true;
}

export function setAccountFeatureFlag (val) {
  accFfAllowed = val === true;
}

// this is only for testing
export function clearInit () {
  heapInitialized = false;
}

// decision was made to not allow Heap in anonymous mode;
// if this changes, change accountFeatureFlag's default value to true
export function heapInitializer (accountFeatureFlag = false, userId = '', userProps = {}, eventProps = {}) {
  setAccountFeatureFlag(accountFeatureFlag);
  if (!hasHeap() || heapInitialized) return;

  window.queap.launchPrep(userId, userProps, eventProps);
  heapInitialized = true;
  // ...eventually when we got the consent, we'll call window.queap.run():
  window.queap.run();
}

export function logoutHeapUser () {
  if (!hasHeap()) return;
  // decision was made to not allow Heap in anonymous mode;
  // if this changes, change setAccountFeatureFlag's value to true
  setAccountFeatureFlag(false);
  window.queap.setSystemUserId('');
  window.queap.identificate();
}

// decision was made to not allow Heap in anonymous mode;
// if this changes, change accountFeatureFlag's default value to true
export function loginHeapUser (accountFeatureFlag = false, userId, userProps = {}, eventProps = {}) {
  setAccountFeatureFlag(accountFeatureFlag);
  if (!hasHeap()) return;
  if (!heapInitialized) {
    heapInitializer(accountFeatureFlag, userId, userProps, eventProps);
  } else {
    window.queap.setSystemUserId(userId);
    window.queap.identificate();
    window.queap.addUserProperties(userProps);
    window.queap.setCommonEventProps(window.queap.mergeBaseEventProps(eventProps));
  }
}

export function trackEvent (eventName, eventProps = {}) {
  if (!hasHeap()) return;
  window.queap.track(eventName, Object.assign(eventProps, { 'Base.syntheticEvent': true }));
}

export function addUserProperties (properties = {}) {
  if (hasHeap() && Object.keys(properties).length > 0) {
    window.queap.addUserProperties(properties);
  }
}
