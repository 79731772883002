import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import LicensesActions from '../../../actions/licenses';
import { Select } from '../../common/select';
import Message from '../../common/message';
import ComplexMessage from '../../common/complex-message';
import Link from '../../common/link';

export default class ContentLicensing extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.updateLicenseStateData = this.updateLicenseStateData.bind(this);

    this.state = {
      licenseId: ''
    };
  }

  static propTypes = {
    onChange: PropTypes.func,
    licenseId: PropTypes.string,
    licenses: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object
  }

  componentDidMount () {
    if (this.props.licenses.length === 0) {
      LicensesActions.getLicenses();
    }
  }

  UNSAFE_componentWillUpdate (nextProps) {
    if (nextProps.licenseId !== this.state.licenseId) {
      this.setState({
        licenseId: nextProps.licenseId
      });
    }
  }

  licenseWithId (id) {
    return this.props.licenses.filter(license => license.id === id)[0];
  }

  updateLicenseStateData (_, option) {
    this.setState({
      licenseId: option.value
    });
    this.props.onChange([option.value]);
  }

  render () {
    var currentLicense = this.licenseWithId(this.state.licenseId);

    if (this.props.error) {
      return (
        <Message type="error2 message-with-icon">
          <ComplexMessage>
            {formatMessage(
              '{error_message} Please try again by {clicking_here}.',
              {
                error_message: ComplexMessage.placeholder('error'),
                clicking_here: ComplexMessage.placeholder('here')
              }
            )}
            <strong key="error">
              {formatMessage('Sorry, we couldn’t load licenses.')}
            </strong>
            <Link
              key="here"
              onClick={LicensesActions.getLicenses}
            >
              {formatMessage('clicking here')}
            </Link>
          </ComplexMessage>
        </Message>
      );
    }

    return (
      <div className="ContentLicensing-container">
        {this.props.licenses && this.props.licenses.length && (
          <div className="ContentLicensing-container-select">
            <Select
              id="licenseIds"
              className="ContentLicensing-select"
              onChange={this.updateLicenseStateData}
              value={this.props.licenseId}
              options={this.props.licenses.map(license => ({
                id: license.id,
                label: license.title,
                value: license.id
              }))}
            />
          </div>
        )}
        {this.state.licenseId &&
          this.state.licenseId !== 'copyright_reserve' &&
          currentLicense && (
          <a href={currentLicense.link} rel="license noopener noreferrer" target="_blank">
            <img
              src={currentLicense.image}
              className="ContentLicensing-license-image"
              alt={currentLicense.title}
            />
          </a>
        )}
      </div>
    );
  }
}
