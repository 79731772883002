
import * as notifyCanvas from './canvas-postmessage';

export const transformToLti = (canvasData) => {
  const { id, mediaType, title, url, fileType, embedText } = canvasData;
  return {
    '@context': 'http:/purl.imsglobal.org/ctx/lti/v1/ContentItem',
    '@graph': [
      {
        '@type': fileType,
        '@id': id,
        url,
        title,
        text: embedText,
        mediaType: mediaType,
        placementAdvice: {
          presentationDocumentTarget: 'embed'
        }
      }
    ]
  };
};

export const notifyCanvasWithPostMessage = (canvasData) => {
  const { title, embedText, id, mediaType, courseId } = canvasData;
  notifyCanvas.ltiResourceImported(title, embedText, id, mediaType, { parentId: courseId, parentWasCreated: false });
};

export const canvasNotificationData = (courseId, resource, importedUrl) => {
  const fileType = resource.type === 'image' ? 'Image' : 'FileItem';
  const previewType = resource.type === 'video' ? 'instructure_video_link'
    : resource.type === 'audio' ? 'instructure_audio_link' : 'instructure_scribd_file';
  const mediaId = resource.type === 'video' || resource.type === 'audio' ? 'id="media_comment_maybe"' : null;
  const embedText = fileType === 'Image' ? resource.title
    : `<p><a ${mediaId} class="instructure_file_link ${previewType}" title=" target=" href=${importedUrl}
       data-api-endpoint=${importedUrl} data-api-returntype="File">${resource.title}</a></p>`;
  return {
    id: resource.id,
    mediaType: resource.type,
    title: resource.title,
    url: importedUrl,
    fileType,
    embedText,
    courseId: courseId
  };
};
