import React from 'react';

export default class SadPanda extends React.Component {
  render () {
    return (
      <svg width="70" height="71" viewBox="0 0 70 71">
        <g fill="none" fillRule="evenodd">
          <circle fillOpacity=".5" fill="#18AB27" cx="35" cy="35" r="35" />
          <path d="M34.214 20.984c-.323 0-.58-.128-.77-.385-.19-.258-.367-.657-.528-1.2l-1.342-4.664c-.132-.484-.165-.928-.1-1.33.067-.404.214-.753.44-1.046.228-.293.536-.52.925-.682.388-.16.83-.242 1.33-.242h.55c.528 0 .99.08 1.386.242.396.16.715.39.957.682.242.293.396.642.462 1.045.066.403.033.847-.1 1.33l-1.34 4.665c-.162.543-.346.942-.55 1.2-.206.256-.478.384-.815.384h-.506zm-.044 5.214c-1.38 0-2.068-.682-2.068-2.046v-.418c0-.704.17-1.22.506-1.55.337-.33.858-.496 1.562-.496h.594c.748 0 1.287.165 1.617.495.33.33.496.847.496 1.55v.42c0 .703-.17 1.22-.506 1.55-.337.33-.873.495-1.606.495h-.594z" fill="#FFF" />
          <path d="M14.94 37.848c0 2.952-2.392 5.345-5.344 5.345-2.952 0-5.345-2.393-5.345-5.345 0-2.953 2.394-5.346 5.346-5.346s5.345 2.393 5.345 5.346M65.797 37.848c0 2.952-2.393 5.345-5.345 5.345s-5.345-2.393-5.345-5.345c0-2.953 2.393-5.346 5.345-5.346s5.345 2.393 5.345 5.346" fill="#464A5E" />
          <path d="M60.452 37.812c-17.005-5.825-33.908-5.648-50.718 0v21.9c6.292 6.853 15.323 11.152 25.36 11.152 10.036 0 19.066-4.3 25.358-11.152v-21.9" fill="#FFF" />
          <path d="M13.162 44.132c-.283.794-.437 1.65-.437 2.54 0 4.177 3.385 7.563 7.562 7.563 4.177 0 7.562-3.386 7.562-7.563 0-2.256-.99-4.28-2.556-5.667-1.323.888-3.078 1.713-5.07 2.322-2.698.825-5.253 1.078-7.062.805z" fill="#464A5E" />
          <path d="M24.74 46.672c0 1.163-.943 2.107-2.106 2.107-1.164 0-2.106-.945-2.106-2.108 0-1.163.942-2.106 2.106-2.106 1.163 0 2.106.943 2.106 2.106" fill="#FFF" />
          <path d="M24.057 46.672c0 .786-.637 1.424-1.423 1.424s-1.423-.638-1.423-1.424c0-.786.638-1.423 1.424-1.423.786 0 1.423.636 1.423 1.422" fill="#464A5E" />
          <path d="M23.545 46.075c0 .204-.166.37-.37.37s-.37-.166-.37-.37.166-.37.37-.37.37.166.37.37" fill="#FFF" />
          <path d="M44.985 40.923c-1.62 1.387-2.648 3.448-2.648 5.75 0 4.176 3.385 7.562 7.562 7.562 4.176 0 7.56-3.386 7.56-7.563 0-.88-.15-1.725-.426-2.51-1.794.224-4.26-.04-6.857-.835-2.054-.628-3.855-1.484-5.192-2.404z" fill="#464A5E" />
          <path d="M49.658 46.672c0 1.163-.942 2.107-2.106 2.107-1.163 0-2.106-.945-2.106-2.108 0-1.163.943-2.106 2.106-2.106 1.164 0 2.106.943 2.106 2.106" fill="#FFF" />
          <path d="M48.975 46.672c0 .786-.637 1.424-1.423 1.424s-1.423-.638-1.423-1.424c0-.786.636-1.423 1.422-1.423.786 0 1.423.636 1.423 1.422" fill="#464A5E" />
          <path d="M48.463 46.075c0 .204-.166.37-.37.37s-.37-.166-.37-.37.166-.37.37-.37.37.166.37.37" fill="#FFF" />
          <path d="M36.984 50.885c0 1.044-.847 1.89-1.89 1.89-1.045 0-1.89-.846-1.89-1.89s.845-1.89 1.89-1.89c1.043 0 1.89.846 1.89 1.89" fill="#464A5E" />
          <path d="M32.5 56.5h5" stroke="#464A5E" strokeLinecap="round" />
        </g>
      </svg>
    );
  }
}
