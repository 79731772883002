import { ACTION_REMOVE_ALERT_BY_ID, ACTION_ADD_ALERT, ACTION_REMOVE_ALL_ALERTS } from '../constants/alert';

const initialState = {
  currentAlerts: [],
};

export default function alertReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_ADD_ALERT:
      return {
        ...state,
        currentAlerts: [...state.currentAlerts, action.payload],
      };
    case ACTION_REMOVE_ALERT_BY_ID:
      return {
        ...state,
        currentAlerts: state.currentAlerts.filter(alert => alert.id !== action.payload),
      };
    case ACTION_REMOVE_ALL_ALERTS:
      return {
        ...state,
        currentAlerts: [],
      };
    default:
      return state;
  }
}
