import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@instructure/ui-alerts';
import { Text } from '@instructure/ui-text';
import { getState } from '../../store';
import { hasMotdCookie, setMotdCookie } from '../../stores/showMotd';

// Change these values to show motd, and turn on the new_motd FF. Do not forget to turn off the FF once the MOTD is no longer relevant.
// 'motdVersion' should be the date when the MOTD is created, in 'yymmdd' format. Replace this with the current date when the text changes.
// 'motdText' is the message that will be displayed in the MOTD., it should be in JSX format.
const motdVersion = '240909';
const motdText = <div />;

function isAuthenticated (state) {
  return state && state.session && state.session.isAuthenticated;
}

function isAdmin (state) {
  return state && state.session && state.session.isAdmin;
}

function isFeatureFlagTurnedOn (state) {
  return state && state.featureFlags && state.featureFlags.flags.new_motd;
}

function shouldShowMotd (state, onlyForAdmins = false) {
  if (!isFeatureFlagTurnedOn(state) || !isAuthenticated(state)) {
    return false;
  }

  if (onlyForAdmins && !isAdmin(state)) {
    return false;
  }

  return !hasMotdCookie(motdVersion);
}

function onMotdDismiss () {
  setMotdCookie(motdVersion);
}

const MotdAlert = ({ onlyForAdmins }) => {
  const state = getState();
  const showMotd = shouldShowMotd(state, onlyForAdmins);

  return showMotd ? (
    <div className="motdAlertContainer" data-testid="motdAlertContainer">
      <Alert
        variant="info"
        margin="none none small none"
        renderCloseButtonLabel="Close"
        onDismiss={onMotdDismiss}
        transition="none"
      >
        <Text weight="bold">{motdText}</Text>
      </Alert>
    </div>
  ) : null;
};

MotdAlert.propTypes = {
  onlyForAdmins: PropTypes.bool
};

export default MotdAlert;
