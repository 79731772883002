import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

// components
import ComplexMessage from './complex-message';

export default class CharactersLeft extends React.Component {
  static propTypes = {
    countClassName: PropTypes.string,
    count: PropTypes.number.isRequired
  }

  shouldComponentUpdate (nextProps) {
    return (
      nextProps.countClassName !== this.props.countClassName ||
      nextProps.count !== this.props.count
    );
  }

  render () {
    const { countClassName, count, ...props } = this.props;
    return (
      <ComplexMessage aria-live="polite" aria-atomic="true" {...props}>
        {formatMessage(
          `{count, plural,
            one   {{countWrapper} character remaining}
            other {{countWrapper} characters remaining}
          }`,
          {
            count: count,
            countWrapper: ComplexMessage.placeholder('count')
          }
        )}
        <span key="count" className={countClassName}>
          {formatMessage('{count, number}', { count: count })}
        </span>
      </ComplexMessage>
    );
  }
}
