import React from 'react';
import Message from './common/message';
import formatMessage from 'format-message';

export default class SetupComplete extends React.Component {
  render () {
    return (
      <Message>
        {formatMessage('Setup complete. You can close this popup window.')}
      </Message>
    );
  }
}
