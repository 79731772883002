import env from '../config/env';

const getLanguage = () => {
  return env.LANG;
};

export const getLocalizedUrl = (url) => {
  const language = getLanguage();
  return language && URLS[url] && URLS[url][language]
    ? URLS[url][language]
    : url;
};

const URLS = {
  'https://community.canvaslms.com/t5/Commons/How-do-I-use-Commons/ta-p/1795': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/C%C3%B3mo-uso-Commons/ta-p/423808',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-fa%C3%A7o-para-usar-o-Commons/ta-p/423847',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-fa%C3%A7o-para-usar-o-Commons/ta-p/423847'
  },
  'https://community.canvaslms.com/t5/Commons/How-do-I-import-and-view-a-Commons-resource-in-Canvas/ta-p/1808': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/C%C3%B3mo-importo-y-veo-un-recurso-de-Commons-en-Canvas/ta-p/423811',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-importo-e-visualizo-um-recurso-do-Commons-no-Canvas/ta-p/423846',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-importo-e-visualizo-um-recurso-do-Commons-no-Canvas/ta-p/423846'
  },
  'https://community.canvaslms.com/t5/Commons/What-types-of-content-licenses-are-available-in-Commons/ta-p/1809': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/Qu%C3%A9-tipos-de-licencias-de-contenido-est%C3%A1n-disponibles-en-Commons/ta-p/423812',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Quais-tipos-de-licen%C3%A7as-de-conte%C3%BAdo-est%C3%A3o-dispon%C3%ADveis-no-Commons/ta-p/423848',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Quais-tipos-de-licen%C3%A7as-de-conte%C3%BAdo-est%C3%A3o-dispon%C3%ADveis-no-Commons/ta-p/423848'
  },
  'https://community.canvaslms.com/t5/Commons/How-do-I-share-a-resource-to-Commons/ta-p/1798': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/C%C3%B3mo-puedo-compartir-un-recurso-en-Commons/ta-p/423813',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-compartilho-um-recurso-para-o-Commons/ta-p/423849',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-compartilho-um-recurso-para-o-Commons/ta-p/423849'
  },
  'https://community.canvaslms.com/t5/Commons/What-types-of-document-and-media-files-can-be-shared-to-Commons/ta-p/1792': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/Qu%C3%A9-tipos-de-documentos-y-archivos-de-medios-se-pueden-compartir/ta-p/423810',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Quais-tipos-de-documentos-e-arquivos-de-m%C3%ADdia-podem-ser/ta-p/423852',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Quais-tipos-de-documentos-e-arquivos-de-m%C3%ADdia-podem-ser/ta-p/423852'
  },
  'https://community.canvaslms.com/t5/Commons/How-do-I-view-updates-to-resources-I-previously-imported-from/ta-p/1797': {
    es: 'https://community.canvaslms.com/t5/Gu%C3%ADa-de-Commons/C%C3%B3mo-veo-las-actualizaciones-a-los-recursos-que-import%C3%A9/ta-p/423809',
    pt: 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-visualizo-as-atualiza%C3%A7%C3%B5es-dos-recursos-que-importei/ta-p/423850',
    'pt-BR': 'https://community.canvaslms.com/t5/Guia-do-Commons/Como-visualizo-as-atualiza%C3%A7%C3%B5es-dos-recursos-que-importei/ta-p/423850'
  },
  'https://community.canvaslms.com/t5/Commons/What-is-Canvas-Commons/ta-p/1788': {},
  'https://community.canvaslms.com/t5/Commons/tkb-p/commons': {},
  'https://community.canvaslms.com/t5/Commons-Users/gh-p/commons': {},
  'https://community.canvaslms.com/t5/Student-Guide/How-do-I-add-an-additional-email-address-as-a-contact-method-in/ta-p/412': {},
  'https://www.canvaslms.com/policies/privacy': {},
};
