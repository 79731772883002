import uniflow from 'uniflow';
import LearningObjectActions from '../actions/resource';

let bootstrapped = false;

export const reportTypes = ['spam', 'infringing', 'inappropriate'];

const DEFAULT_LEARNING_OBJECT = {
  id: '',
  title: '',
  description: '',
  tags: [],
  gradeIds: [],
  subjectIds: [],
  type: 'course',
  thumbnail: {
    url: '',
    description: ''
  },
  authors: [],
  licenseIds: [],
  licenseSpecs: '',
  scopeIds: [],
  favoritedCount: 0,
  downloadCount: 0,
  meta: {
    links: {},
    permissions: {
      read: false,
      write: false
    }
  }
};

function setInitialState (learningObject) {
  LearningObjectStore.setState({
    learningObject: DEFAULT_LEARNING_OBJECT,
    reviews: [],
    updateSuccessful: false,
    updateWithNewVersionSuccessful: false,
    createSuccessful: false,
    findSuccessful: false,
    findReviewsSuccessful: false,
    createErrors: [],
    updateErrors: [],
    updateWithNewVersionErrors: [],
    findErrors: null,
    findReviewsError: null,
    createPending: false,
    updatePending: false,
    updateWithNewVersionPending: false,
    findPending: false,
    findReviewsPending: false,
    importPending: false,
    importResults: []
  });
}

const LearningObjectStore = uniflow.createStore({
  bootstrap () {
    if (bootstrapped) {
      return;
    }

    setInitialState();

    LearningObjectActions.on('learning-object-clear', () => {
      setInitialState();
    });

    // pending
    LearningObjectActions.on('learning-object-create-pending', () => {
      LearningObjectStore.setState({
        createPending: true,
        createErrors: [],
        createSuccessful: false
      });
    });
    LearningObjectActions.on('learning-object-update-pending', () => {
      LearningObjectStore.setState({
        updatePending: true,
        updateErrors: [],
        updateSuccessful: false
      });
    });
    LearningObjectActions.on('learning-object-update-new-version-pending', () => {
      LearningObjectStore.setState({
        updateWithNewVersionPending: true,
        updateWithNewVersionErrors: [],
        updateWithNewVersionSuccessful: false
      });
    });
    LearningObjectActions.on('learning-object-find-pending', () => {
      LearningObjectStore.setState({
        findPending: true,
        findErrors: null,
        findSuccessful: false,
        importResults: [] // we don't want the import results to show after failed update
      });
    });
    LearningObjectActions.on('find-reviews-resource-pending', () => {
      LearningObjectStore.setState({
        findReviewsPending: true,
        findReviewsError: null,
        findReviewsSuccessful: false
      });
    });

    LearningObjectActions.on('learning-object-import-pending', () => {
      LearningObjectStore.setState({
        importPending: true,
        importResults: []
      });
    });

    // errors
    LearningObjectActions.on('learning-object-create-error', createErrors => {
      LearningObjectStore.setState({
        createErrors,
        createPending: false,
        createSuccessful: false
      });
    });
    LearningObjectActions.on('learning-object-update-error', updateErrors => {
      LearningObjectStore.setState({
        updateErrors,
        updatePending: false,
        updateSuccessful: false
      });
    });
    LearningObjectActions.on(
      'learning-object-update-new-version-error',
      updateErrors => {
        LearningObjectStore.setState({
          updateWithNewVersionErrors: updateErrors,
          updateWithNewVersionPending: false,
          updateWithNewVersionSuccessful: false
        });
      }
    );
    LearningObjectActions.on('learning-object-find-error', findErrors => {
      LearningObjectStore.setState({
        findErrors,
        findPending: false,
        findSuccessful: false
      });
    });
    LearningObjectActions.on('find-reviews-resource-error', findReviewsError => {
      LearningObjectStore.setState({
        findReviewsPending: false,
        findReviewsError,
        findReviewsSuccessful: false
      });
    });

    // successes
    LearningObjectActions.on('learning-object-create-success', learningObject => {
      LearningObjectStore.setState({
        createErrors: [],
        createPending: false,
        createSuccessful: true,
        learningObject
      });
    });
    LearningObjectActions.on('learning-object-update-success', learningObject => {
      LearningObjectStore.setState({
        updateErrors: [],
        updatePending: false,
        updateSuccessful: true,
        learningObject
      });
    });
    LearningObjectActions.on(
      'learning-object-update-new-version-success',
      learningObject => {
        LearningObjectStore.setState({
          updateWithNewVersionErrors: [],
          updateWithNewVersionPending: false,
          updateWithNewVersionSuccessful: true,
          learningObject
        });
      }
    );

    LearningObjectActions.on('learning-object-find-success', learningObject => {
      LearningObjectStore.setState({
        findErrors: null,
        findPending: false,
        findSuccessful: true,
        learningObject
      });
    });

    LearningObjectActions.on('learning-object-clear', learningObject => {
      LearningObjectStore.setState({
        findErrors: null,
        findPending: false,
        findSuccessful: false,
        learningObject: DEFAULT_LEARNING_OBJECT
      });
    });

    LearningObjectActions.on('learning-object-find-aborted', () => {
      LearningObjectStore.setState({
        findErrors: null,
        findPending: false,
        findSuccessful: false
      });
    });
    LearningObjectActions.on('find-reviews-resource-success', reviews => {
      LearningObjectStore.setState({
        findReviewsPending: false,
        findReviewsError: null,
        findReviewsSuccessful: true,
        reviews
      });
    });

    LearningObjectActions.on('learning-object-update', fields => {
      const learningObject = Object.assign(
        {},
        LearningObjectStore.state.learningObject,
        fields
      );
      LearningObjectStore.setState({
        learningObject,
        pending: false
      });
    });

    LearningObjectActions.on('learning-object-import-result', resultsByCourse => {
      LearningObjectStore.setState({
        importPending: false,
        importResults: resultsByCourse
      });
    });

    LearningObjectActions.on('learning-object-increment-download-count', resourceId => {
      if (resourceId !== LearningObjectStore.getCurrentId()) return;
      LearningObjectStore.incrementDownloadCount();
    });

    bootstrapped = true;
  },

  updateFavoriteCounterBy (increment) {
    if (!increment) return;
    const learningObject = this.state.learningObject;
    learningObject.favoritedCount += increment;
    this.setState({ learningObject: learningObject });
  },
  incrementDownloadCount (increment = 1) {
    if (!increment) return;
    const learningObject = this.state.learningObject;
    learningObject.downloadCount += increment;
    this.setState({ learningObject: learningObject });
  },
});

export default LearningObjectStore;
