import apiRequest from '../api-request';

export const shouldWaitForProgressComplete = type => {
  return ['document', 'image', 'video', 'audio'].indexOf(type) !== -1;
};

export const checkProgress = canvasUploadUrl => {
  return new Promise((resolve, reject) => {
    const checkProgressInterval = setInterval(() => {
      apiRequest({
        path: `/lti/check-upload-status?url=${canvasUploadUrl}`
      })
        .promise()
        .then(async response => {
          if (response.body.completion === 100.0 && response.body.results.id) {
            resolve(response.body);
            clearInterval(checkProgressInterval);
          } else {
            console.log(
              `Upload in progress: ${response.body.completion}% complete`
            );
          }
        })
        .catch(error => {
          clearInterval(checkProgressInterval);
          reject(error);
        });
    }, 2000);
  });
};
