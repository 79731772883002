import compare from '../common/natural-compare';
import {
  RESET_GROUP,
  LOAD_GROUP_START,
  LOAD_GROUP_SUCCESS,
  LOAD_GROUP_FAILURE,
  CHANGE_NAME,
  UPDATE_GROUP_START,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  LOAD_MEMBERS_START,
  LOAD_MEMBERS_SUCCESS,
  LOAD_MEMBERS_FAILURE,
  CREATE_MEMBER_START,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  DELETE_MEMBER_START,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  CHANGE_MANAGEMENT_STATUS_START,
  CHANGE_MANAGEMENT_STATUS_SUCCESS,
  CHANGE_MANAGEMENT_STATUS_FAILURE
} from '../actions/group';

import Error from './error-record';

export const initialState = {
  group: null,
  currentName: '',
  members: [],

  groupIsLoading: false,
  groupIsLoaded: false,
  groupLoadError: null,

  membersAreLoading: false,
  membersAreLoaded: false,
  membersLoadError: null,

  createMemberPending: false,
  createMemberSuccess: false,
  createMemberError: null,

  deleteMemberPending: false,
  deleteMemberSuccess: false,
  deleteMemberError: null,

  updateGroupPending: false,
  updateGroupSuccess: false,
  updateGroupError: null,

  changeManagementStatusPending: false,
  changeManagementStatusSuccess: false,
  changeManagementStatusError: null
};

export default function groupReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_GROUP:
      return initialState;

    case LOAD_GROUP_START:
      return {
        ...state,
        groupIsLoading: true,
        groupIsLoaded: false,
        groupLoadError: null,
        group: null
      };

    case LOAD_GROUP_SUCCESS:
      return {
        ...state,
        groupIsLoading: false,
        groupIsLoaded: true,
        groupLoadError: null,
        group: action.group,
        currentName: action.group.name
      };

    case LOAD_GROUP_FAILURE:
      return {
        ...state,
        groupIsLoading: false,
        groupIsLoaded: false,
        groupLoadError: new Error(action.error),
        group: null
      };

    case CHANGE_NAME:
      return {
        ...state,
        updateGroupSuccess: false,
        updateGroupError: null,
        currentName: action.name
      };

    case UPDATE_GROUP_START:
      return {
        ...state,
        updateGroupPending: true,
        updateGroupSuccess: false,
        updateGroupError: null
      };

    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        group: action.group,
        updateGroupPending: false,
        updateGroupSuccess: true,
        updateGroupError: null
      };

    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        updateGroupPending: false,
        updateGroupSuccess: false,
        updateGroupError: new Error(action.error)
      };

    case LOAD_MEMBERS_START:
      return {
        ...state,
        membersAreLoading: true,
        membersAreLoaded: false,
        membersLoadError: null,
        members: []
      };

    case LOAD_MEMBERS_SUCCESS:
      return {
        ...state,
        membersAreLoading: false,
        membersAreLoaded: true,
        membersLoadError: null,
        members: action.members
      };

    case LOAD_MEMBERS_FAILURE:
      return {
        ...state,
        membersAreLoading: false,
        membersAreLoaded: true,
        membersLoadError: new Error(action.error),
        members: []
      };

    case CREATE_MEMBER_START:
      return {
        ...state,
        createMemberPending: true,
        createMemberSuccess: false,
        createMemberError: null
      };

    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        createMemberPending: false,
        createMemberSuccess: true,
        createMemberError: null,
        members: state.members
          .concat(action.member)
          .sort((a, b) => compare(a.userName, b.userName))
      };

    case CREATE_MEMBER_FAILURE:
      return {
        ...state,
        createMemberPending: false,
        createMemberSuccess: false,
        createMemberError: new Error(action.error)
      };

    case DELETE_MEMBER_START:
      return {
        ...state,
        deleteMemberPending: true,
        deleteMemberSuccess: false,
        deleteMemberError: null
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMemberPending: false,
        deleteMemberSuccess: true,
        deleteMemberError: null,
        members: state.members.filter((member) => member.id !== action.member.id)
      };

    case DELETE_MEMBER_FAILURE:
      return {
        ...state,
        deleteMemberPending: false,
        deleteMemberSuccess: false,
        deleteMemberError: new Error(action.error)
      };

    case CHANGE_MANAGEMENT_STATUS_START:
      return {
        ...state,
        changeManagementStatusPending: true,
        changeManagementStatusSuccess: false,
        changeManagementStatusError: null
      };

    case CHANGE_MANAGEMENT_STATUS_SUCCESS:
      return {
        ...state,
        changeManagementStatusPending: false,
        changeManagementStatusSuccess: true,
        changeManagementStatusError: null,
        members: state.members.map((member) => {
          if (member.id === action.membership.id) {
            return action.membership;
          }
          return member;
        })
      };

    case CHANGE_MANAGEMENT_STATUS_FAILURE:
      return {
        ...state,
        changeManagementStatusPending: false,
        changeManagementStatusSuccess: false,
        changeManagementStatusError: new Error(action.error)
      };

    default:
      return state;
  }
}
