/** @type {(seconds: number) => string} */
export default function format (seconds) {
  let str = ('00' + (seconds % 60)).slice(-2);

  const minutes = Math.floor(seconds / 60);
  if (minutes < 1) return '0:' + str;
  str = (minutes % 60) + ':' + str;

  const hours = Math.floor(minutes / 60);
  if (hours < 1) return str;
  str = hours + ':' + ('00' + str).slice(-5);

  return str;
}
