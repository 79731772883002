import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';

export default createReactClass({
  displayName: 'MediaElement',

  propTypes: {
    onDidMount: PropTypes.func,
    onWillUnmount: PropTypes.func,
    onStart: PropTypes.func,
    onStop: PropTypes.func,
    type: PropTypes.oneOf(['audio', 'video']),
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    poster: PropTypes.string,
    preload: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
  },

  getInitialState () {
    return {
      requestStart: null,
      requestTime: null
    };
  },

  componentDidMount () {
    if (this.props.onDidMount) {
      this.props.onDidMount();
    }
    this.addEventListeners();
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.type !== this.props.type) {
      this.removeEventListeners();
    }
  },

  componentDidUpdate (prevProps) {
    if (prevProps.type !== this.props.type) {
      this.addEventListeners();
    }
  },

  componentWillUnmount () {
    if (this.props.onWillUnmount) {
      this.props.onWillUnmount();
    }
    this.removeEventListeners();
  },

  addEventListeners () {
    var media = ReactDOM.findDOMNode(this.refs.media);
    Object.keys(this.events).forEach(name => {
      media.addEventListener(name, this);
    });
  },

  removeEventListeners () {
    var media = ReactDOM.findDOMNode(this.refs.media);
    Object.keys(this.events).forEach(name => {
      media.removeEventListener(name, this);
    });
  },

  events: {
    abort: 'onAbort',
    canplay: 'onCanPlay',
    canplaythrough: 'onCanPlayThrough',
    durationchange: 'onDurationChange',
    emptied: 'onEmptied',
    ended: 'onEnded',
    error: 'onError',
    interruptbegin: 'onInterruptBegin',
    interruptend: 'onInterruptEnd',
    loadeddata: 'onLoadedData',
    loadedmetadata: 'onLoadedMetaData',
    loadstart: 'onLoadStart',
    pause: 'onPause',
    play: 'onPlay',
    playing: 'onPlaying',
    progress: 'onProgress',
    ratechange: 'onRateChange',
    seeked: 'onSeeked',
    seeking: 'onSeeking',
    stalled: 'onStalled',
    suspend: 'onSuspend',
    timeupdate: 'onTimeUpdate',
    volumeChange: 'onVolumeChange',
    waiting: 'onWaiting'
  },

  handleEvent (evt) {
    switch (evt.type) {
      case 'timeupdate':
        this.checkStart();
        break;
      case 'play':
      case 'playing':
        this.requestStart();
        break;
      case 'abort':
      case 'ended':
      case 'pause':
      case 'waiting':
        this.onStop();
        break;
      default:
        break;
    }
    var prop = this.events[evt.type];
    if (prop && this.props[prop]) {
      this.props[prop](evt);
    }
  },

  // helpers for convenience events
  requestStart () {
    var media = ReactDOM.findDOMNode(this.refs.media);
    this.setState({
      requestStart: true,
      requestTime: media.currentTime
    });
  },

  checkStart () {
    var media = ReactDOM.findDOMNode(this.refs.media);
    if (
      this.state.requestStart &&
      this.state.currentTime !== media.currentTime
    ) {
      this.onStart();
    }
  },

  // convenience events
  onStart () {
    this.setState({
      requestStart: null,
      requestTime: null
    });
    if (this.props.onStart) {
      this.props.onStart();
    }
  },

  onStop () {
    this.setState({
      requestStart: null,
      requestTime: null
    });
    if (this.props.onStop) {
      this.props.onStop();
    }
  },

  render () {
    var Media = this.props.type;
    return (
      <Media
        ref="media"
        autoPlay={this.props.autoPlay}
        className={this.props.className}
        controls={this.props.controls}
        loop={this.props.loop}
        muted={this.props.muted}
        poster={this.props.poster}
        preload={this.props.preload}
      >
        {this.props.children}
      </Media>
    );
  }
});
