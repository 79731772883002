import apiRequest from '../common/api-request';

export const searchImages = ({ query = '', page = 0, itemsPerPage = 30 }, done) => {
  apiRequest({
    path: '/images/search',
    query: {
      query,
      page,
      itemsPerPage
    }
  })
    .promise()
    .then(x => done(undefined, x && x.body))
    .catch(error => done(error));
};

export const trackImage = (id, done) => {
  const request = apiRequest({
    method: 'POST',
    path: `/images/track/${id}`
  });

  request.end((err, res) => {
    done && done(err, res && res.body);
  });
};
