import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import isUnauthorized from '../common/is-unauthorized';
import RouterActions from '../actions/router';
import getTitle from './license-get-title';

export function find (done) {
  const request = apiRequest({
    path: '/licenses',
    bodyTransform: (body) => {
      // TODO: make more functional (use map instead of forEach)
      (body.items || []).forEach((license) => {
        license.title = getTitle(license.id) || license.title;
      });
      return body;
    }
  });

  request.end((err, res) => {
    if (isUnauthorized(err)) return RouterActions.transitionTo('/errors/not-authenticated');

    if (isErroneous(err, res)) {
      return done(err, res ? res.body : null);
    }

    done(null, res.body);
  });
}
