import React from 'react';

const defaultState = {
  isImporting: false,
  error: null,
  importResult: null,
  showSuccess: false
};
export default function withFavoriteResourceImportStatusHandler (
  WrappedComponent
) {
  return class FavoriteResourceImportStatusHandler extends React.Component {
    state = {
      importStatus: {}
    };

    getImportStatusForResource = resource => {
      const { importStatus } = this.state;
      return importStatus[resource.id] || defaultState;
    };

    setImportStatusForResource = (statusData, resource, cb) => {
      const { importStatus } = this.state;
      const oldImportStatusForResource = this.getImportStatusForResource(
        resource
      );
      const newImportStatus = {
        ...importStatus,
        [resource.id]: {
          resource,
          ...oldImportStatusForResource,
          ...statusData
        }
      };
      this.setState({ importStatus: newImportStatus }, cb);
    };

    setImportInProgressForResource = (resource, cb) => {
      this.setImportStatusForResource(
        {
          ...defaultState,
          isImporting: true,
        },
        resource,
        cb
      );
    };

    setImportFinishedForResource = (importResult, resource, cb) => {
      const setFinished = showSuccess => {
        this.setImportStatusForResource(
          {
            ...defaultState,
            importResult,
            showSuccess
          },
          resource,
          cb
        );
      };
      setTimeout(() => {
        setFinished(true);
        setTimeout(() => {
          setFinished(false);
        }, 10000);
      }, 1700);
    };

    setImportErrorForResource = (error, resource, cb) => {
      this.setImportStatusForResource(
        {
          ...defaultState,
          error,
        },
        resource,
        cb
      );
    };

    dismissErrorForResource = resource => {
      this.setImportStatusForResource(
        {
          ...defaultState
        },
        resource
      );
    };

    render () {
      const imporStatusSetterMethods = {
        setImportInProgressForResource: this.setImportInProgressForResource,
        setImportErrorForResource: this.setImportErrorForResource,
        setImportFinishedForResource: this.setImportFinishedForResource
      };
      const importStatusMethods = {
        dismissErrorForResource: this.dismissErrorForResource,
        getImportStatusForResource: this.getImportStatusForResource
      };
      const { importStatus } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          {...imporStatusSetterMethods}
          {...importStatusMethods}
          importStatus={importStatus}
        />
      );
    }
  };
}
