import { createStore, combineReducers } from 'redux';

// redux reducers
import account from './reducers/account';
import accounts from './reducers/accounts';
import consortium from './reducers/consortium';
import consortiums from './reducers/consortiums';
import group from './reducers/group';
import groups from './reducers/groups';
import outcomes from './reducers/outcomes';
import users from './reducers/users';
import favorites from './reducers/favorites';
import featureFlags from './reducers/feature-flags';
import localStorage from './reducers/local-storage';
import alert from './reducers/alert';
import dialog from './reducers/dialog';
import filter from './reducers/filter';

// uniflow stores and actions
import CoursesStore from './stores/courses';
import ImageStore from './stores/images';
import LicensesStore from './stores/licenses';
import NotificationCenterStore from './stores/notification-center';
import ResourceStore from './stores/resource';
import ResultPathStore from './stores/result-path';
import ResultsStore from './stores/results';
import RouterStore from './stores/router';
import SearchStore from './stores/search';
import SessionStore from './stores/session';
import UpdatesStore from './stores/updates';
import CoursesActions from './actions/courses';
import ImageActions from './actions/images';
import LicensesActions from './actions/licenses';
import NotificationCenterActions from './actions/notification-center';
import ResourceActions from './actions/resource';
import RouterActions from './actions/router';
import SearchActions from './actions/search';
import SessionActions from './actions/session';
import UpdatesActions from './actions/updates';

let bootstrapped = false;
let appReducer;
let uniflows;
let uniflowStores;
let uniflowActions;
let store;
let reduxSubscribe;
let reduxGetState;

const bootstrap = () => {
  if (bootstrapped) {
    return;
  }

  appReducer = combineReducers({
    accounts,
    account,
    consortium,
    consortiums,
    group,
    groups,
    outcomes,
    users,
    favorites,
    featureFlags,
    localStorage,
    alert,
    dialog,
    filter
  });

  uniflows = {
    courses: {
      store: CoursesStore,
      actions: CoursesActions
    },
    images: {
      store: ImageStore,
      actions: ImageActions
    },
    licenses: {
      store: LicensesStore,
      actions: LicensesActions
    },
    notificationCenter: {
      store: NotificationCenterStore,
      actions: NotificationCenterActions
    },
    resource: {
      store: ResourceStore,
      actions: ResourceActions
    },
    resultPath: {
      store: ResultPathStore,
      actions: null
    },
    results: {
      store: ResultsStore,
      actions: null
    },
    router: {
      store: RouterStore,
      actions: RouterActions
    },
    search: {
      store: SearchStore,
      actions: SearchActions
    },
    session: {
      store: SessionStore,
      actions: SessionActions
    },
    updates: {
      store: UpdatesStore,
      actions: UpdatesActions
    }
  };

  uniflowStores = Object.entries(uniflows)
    .reduce((acc, [key, value]) => {
      if (value.store === null) {
        return acc;
      }

      if (typeof value.store.bootstrap === 'function') {
        value.store.bootstrap();
      }

      acc[key] = value.store;
      return acc;
    }, {});

  uniflowActions = Object.entries(uniflows)
    .reduce((acc, [key, value]) => {
      if (value.actions === null) {
        return acc;
      }

      if (typeof value.actions.bootstrap === 'function') {
        value.actions.bootstrap();
      }

      acc[key] = value.actions;
      return acc;
    }, {});

  const addDevTool = process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION__;

  store = createStore(
    appReducer,
    undefined, /* initial state */
    addDevTool ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
  );

  reduxSubscribe = store.subscribe;
  reduxGetState = store.getState;

  bootstrapped = true;
};

const uniflowStoreStates = () => {
  return Object.entries(uniflows)
    .reduce((acc, [key, value]) => { acc[key] = value.store.state; return acc; }, {});
};

export function getState () {
  return {
    ...uniflowStoreStates(),
    ...reduxGetState()
  };
}

export function subscribe (listener) {
  Object.values(uniflowStores)
    .map(store => store.on('change', listener));
  const reduxUnsubscribe = reduxSubscribe(listener);

  return function unsubscribe () {
    Object.values(uniflowStores)
      .map(store => store.removeListener('change', listener));
    reduxUnsubscribe();
  };
}

export const dispatch = (action) => {
  // redux actions are store-namespaced with a '/' which gives us a good way to
  // differentiate between uniflow and redux store actions
  const actionType = action.type.split('/', 2);

  return (uniflowActions[actionType[0]])
    ? uniflowActions[actionType[0]].dispatch(action)
    : store.dispatch(action);
};

export const getSession = () => {
  return getState().session;
};

bootstrap();
