import {
  SEARCH_ACCOUNTS_START,
  SEARCH_ACCOUNTS_SUCCESSFUL,
  SEARCH_ACCOUNTS_FAILED,
  RESET_ACCOUNTS
} from '../actions/accounts';
import Error from './error-record';

export const initialState = {
  accounts: [],
  cursor: null,
  count: 0,

  searchAccountsPending: false,
  searchAccountsSuccessful: false,
  searchAccountsError: null
};

export default function accountsReducer (state = initialState, action) {
  switch (action.type) {
    case SEARCH_ACCOUNTS_START:
      return {
        ...state,
        searchAccountsPending: true,
        searchAccountsSuccessful: false,
        searchAccountsError: null
      };

    case SEARCH_ACCOUNTS_FAILED:
      return {
        ...state,
        searchAccountsPending: false,
        searchAccountsSuccessful: false,
        searchAccountsError: new Error(action.error)
      };

    case SEARCH_ACCOUNTS_SUCCESSFUL:
      return {
        ...state,
        searchAccountsPending: false,
        searchAccountsSuccessful: true,
        searchAccountsError: null,
        accounts: action.items,
        cursor: action.meta.cursor,
        count: action.meta.count
      };

    case RESET_ACCOUNTS:
      return {
        accounts: [],
        cursor: null,
        count: 0
      };

    default:
      return state;
  }
}
