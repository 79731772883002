import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@instructure/ui-buttons';
import {
  addClassNamesToElement,
  addAttributeByVariableToElement,
  addAttributesByPrefixToElement
} from '../../common/modify-elements';

const disabled = 'disabled';

const addAttributes = (button, props, className, interaction, tabIndex) => {
  addClassNamesToElement(button, className, ['Button']);

  addAttributesByPrefixToElement(button, props, 'data-');
  addAttributesByPrefixToElement(button, props, 'aria-');

  addAttributeByVariableToElement(button, tabIndex, 'tabIndex');

  if (interaction && interaction !== CommonsButton.interaction.enabled) {
    button.setAttribute(disabled, true);
  } else if (interaction === CommonsButton.interaction.enabled) {
    button.removeAttribute(disabled);
  }

  if (interaction && interaction !== CommonsButton.interaction.enabled) {
    button.setAttribute('aria-disabled', 'true');
  } else if (props['aria-disabled'] && props['aria-disabled'].toString()) {
    button.setAttribute('aria-disabled', props['aria-disabled'].toString());
  } else if (interaction === CommonsButton.interaction.enabled) {
    button.setAttribute('aria-disabled', 'false');
  }

  return true;
};

const CommonsButton = forwardRef((props, ref) => {
  const {
    children,
    onClick,
    interaction,
    className,
    tabIndex,
    elementRef,
    ...restOfProps
  } = {
    className: '',
    type: CommonsButton.type.button,
    ...props,
  };
  const buttonRef = useRef(null);
  const addAttributesToElement = (button) => addAttributes(
    button, props, className, interaction, tabIndex
  );
  const onClickCallback = useCallback((evt) => {
    if (interaction !== disabled && onClick) {
      onClick(evt);
    }
  }, [onClick, interaction]);
  const setButtonRef = useCallback((button) => {
    if (!buttonRef.current) {
      buttonRef.current = button;
      addAttributesToElement(button);
    }
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      addAttributesToElement(buttonRef.current);
    }
  }, [props]);

  return (
    <Button
      {...restOfProps}
      ref={ref}
      interaction={interaction}
      onClick={onClickCallback}
      elementRef={button => {
        if (button) {
          setButtonRef(button);
          if (elementRef) {
            elementRef(button);
          }
        }
      }}
    >
      {children}
    </Button>
  );
});

CommonsButton.textAlign = {
  start: 'start',
  center: 'center',
};

CommonsButton.size = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};

CommonsButton.color = {
  primary: 'primary',
  primaryInverse: 'primary-inverse',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger'
};

CommonsButton.display = {
  inlineBlock: 'inline-block',
  block: 'block'
};

CommonsButton.type = {
  button: 'button',
  submit: 'submit',
  reset: 'reset'
};

CommonsButton.interaction = {
  enabled: 'enabled',
  disabled: disabled,
  readonly: 'readonly'
};

CommonsButton.propTypes = {
  className: PropTypes.string,
  renderIcon: PropTypes.func || PropTypes.node,
  size: PropTypes.oneOf(Object.values(CommonsButton.size)),
  color: PropTypes.oneOf(Object.values(CommonsButton.color)),
  display: PropTypes.oneOf(Object.values(CommonsButton.display)),
  textAlign: PropTypes.oneOf(Object.values(CommonsButton.textAlign)),
  type: PropTypes.oneOf(Object.values(CommonsButton.type)),
  margin: PropTypes.string,
  screenReaderLabel: PropTypes.string,
  withBackground: PropTypes.bool,
  themeOverride: PropTypes.object,
  interaction: PropTypes.oneOf(Object.values(CommonsButton.interaction)),
  onClick: PropTypes.func,
  children: PropTypes.node,
  elementRef: PropTypes.func,
  tabIndex: PropTypes.number,
  'data-automation': PropTypes.string,
  'aria-controls': PropTypes.string,
  'aria-label': PropTypes.string,
  'aria-disabled': PropTypes.string,
};

export default CommonsButton;
