import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import ResourceTypeIcon from '../icons/resource-type';
import { resourceTypes } from '../../common/app-constants';

export default createReactClass({
  displayName: 'TypePicker',

  propTypes: {
    hidden: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  },

  getInitialState () {
    return {
      focused: null
    };
  },

  onBlur () {
    this.setState({ focused: null });
  },

  onFocus () {
    this.setState({ focused: true });
  },

  onChange (evt) {
    const { type, value } = this.props;
    this.props.onChange(
      evt.target.checked
        ? value.concat(type).sort()
        : value.filter(name => name !== type)
    );
  },

  render () {
    const { type, hidden, value } = this.props;
    const { focused } = this.state;
    const selected = value && value.indexOf(type) !== -1;
    const labelText = selected
      ? resourceTypes[type].pluralSelected
      : resourceTypes[type].pluralName;
    return (
      <label
        className={classnames(
          'ResourceTypePicker-type',
          `type-${type}`,
          {
            'font-color': selected,
            'is-checked': selected,
            'is-focused': focused
          }
        )}
      >
        <ResourceTypeIcon
          aria-hidden
          type={type || 'course'}
          className="ResourceTypePicker-typeIcon"
          colored={selected}
        />
        <span className="ResourceTypePicker-typeName" aria-hidden="true">
          {resourceTypes[type].pluralName}
        </span>
        <input
          checked={selected}
          className="ResourceTypePicker-checkbox"
          disabled={hidden}
          name={resourceTypes[type].pluralName}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          type="checkbox"
          value={type}
          aria-label={labelText}
        />
      </label>
    );
  }
});
