import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import FormGroup from './common/form-group';
import formatMessage from 'format-message';
import ComplexMessage from './common/complex-message';
import Button from './common/button';
import { url } from '../common/api-request';

export default createReactClass({
  displayName: 'Setup',

  propTypes: {
    session: PropTypes.object
  },

  render () {
    return (
      <form
        className="Setup-form"
        method="POST"
        action={url(`/oauth?session-id=${this.props.session.session.sid}`)}
      >
        <p className="Setup-paragraph">
          <ComplexMessage>
            {formatMessage('Go to {keysUrl} and click Add Developer Key.', {
              keysUrl: ComplexMessage.placeholder('keysUrl')
            })}
            <strong key="keysUrl">
              https://your-canvas-domain.com/developer_keys
            </strong>
          </ComplexMessage>
        </p>

        <p className="Setup-paragraph">
          <ComplexMessage>
            {formatMessage('Use {redirectUrl} for your redirect URI.', {
              redirectUrl: ComplexMessage.placeholder('redirectUrl')
            })}
            <strong key="redirectUrl">https://lor.instructure.com</strong>
          </ComplexMessage>
        </p>

        <FormGroup label={formatMessage('Client ID')} htmlFor="clientId">
          <input
            name="clientId"
            id="clientId"
            className="form-control"
            type="text"
            required="true"
          />
        </FormGroup>

        <FormGroup
          label={formatMessage('Client Secret (Key)')}
          htmlFor="clientSecret"
        >
          <input
            name="clientSecret"
            id="clientSecret"
            className="form-control"
            type="text"
            required="true"
          />
        </FormGroup>

        <Button
          className="form-submit-button"
          color={Button.color.primary}
          type={Button.type.submit}
        >
          {formatMessage('Continue setup')}
        </Button>
      </form>
    );
  }
});
