import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import ResourceActions from './resource';

export const RESET_GROUPS = 'groups.reset';
export const LOAD_LIST_START = 'groups.load-list-start';
export const LOAD_LIST_SUCCESS = 'groups.load-list-success';
export const LOAD_LIST_FAILURE = 'groups.load-list-failure';
export const CHANGE_CREATE_NAME = 'groups.change-create-name';
export const CREATE_GROUP_START = 'groups.create-start';
export const CREATE_GROUP_SUCCESS = 'groups.create-success';
export const CREATE_GROUP_FAILURE = 'groups.create-failure';
export const DELETE_GROUP_START = 'groups.delete-start';
export const DELETE_GROUP_SUCCESS = 'groups.delete-success';
export const DELETE_GROUP_FAILURE = 'groups.delete-failure';
export const FILTER_LIST = 'groups.filter-list';

export const creators = {
  reset: () => ({ type: RESET_GROUPS }),
  loadListStart: () => ({ type: LOAD_LIST_START }),
  loadListSuccess: (list) => ({ type: LOAD_LIST_SUCCESS, list }),
  loadListFailure: (error) => ({ type: LOAD_LIST_FAILURE, error }),
  changeCreateName: (name) => ({ type: CHANGE_CREATE_NAME, name }),
  createGroupStart: () => ({ type: CREATE_GROUP_START }),
  createGroupSuccess: (group) => ({ type: CREATE_GROUP_SUCCESS, group }),
  createGroupFailure: (error) => ({ type: CREATE_GROUP_FAILURE, error }),
  filterList: (filter) => ({ type: FILTER_LIST, filter }),
  deleteGroupStart: () => ({ type: DELETE_GROUP_START }),
  deleteGroupSuccess: (group) => ({ type: DELETE_GROUP_SUCCESS, group }),
  deleteGroupFailure: (error) => ({ type: DELETE_GROUP_FAILURE, error })
};

export default function createGroupsActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadList (passive = false) {
      if (!passive) {
        actions.loadListStart();
      }
      const request = apiRequest({
        path: '/groups'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.loadListSuccess(response.body.items);
        return response.body.items;
      } catch (err) {
        actions.loadListFailure(err);
      }
    },

    async createGroup (data) {
      actions.createGroupStart();
      const request = apiRequest({
        path: '/groups',
        method: 'post',
        body: data
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.createGroupSuccess(response.body);
        return response.body;
      } catch (err) {
        actions.createGroupFailure(err);
      }
    },

    async deleteGroup (group) {
      actions.deleteGroupStart();
      const request = apiRequest({
        path: `/groups/${group.id}`,
        method: 'delete'
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.deleteGroupSuccess(group);
        ResourceActions.clearStore();
        return group;
      } catch (err) {
        actions.deleteGroupFailure(err);
      }
    }
  };
}
