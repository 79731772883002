import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import localizeErrors from './error-messages/reviews';

export function list (learningObjectId, done) {
  const path = `/resources/${learningObjectId}/reviews`;
  const request = apiRequest({
    path
  });
  request.end(function (err, res) {
    if (isErroneous(err, res)) {
      return done(err, res ? res.body : null);
    }
    done(null, res.body);
  });
}

export function update (learningObjectId, review, done) {
  const path = `/resources/${learningObjectId}/reviews/self`;
  const request = apiRequest({
    method: 'put',
    path,
    body: review
  });
  request.end(function (err, res) {
    if (isErroneous(err, res)) {
      localizeErrors(err);
      return done(err, res ? res.body : null);
    }
    done(null, res.body);
  });
}

export function destroy (learningObjectId, done) {
  const path = `/resources/${learningObjectId}/reviews/self`;
  const request = apiRequest({
    method: 'delete',
    path
  });

  request.end(function (err, res) {
    if (isErroneous(err, res)) {
      return done(err, res ? res.body : null);
    }
    done(err, { status: res.status, body: res.body });
  });
}
