import React from 'react';
import PropTypes from 'prop-types';

const NoResultsQuote = ({
  message,
  quote,
  photoUrl,
  photoAlt,
  removeImage,
  className
}) => {
  return (
    <div>
      {!removeImage && (
        <div className="no-results-quote-image-container" aria-hidden="true">
          <img className={`Quote-image ${className || ''}`} src={photoUrl} alt={photoAlt} />
        </div>
      )}
      <div className="no-results-quote">
        <div className="no-results-quote-message">{message}</div>
        <div className="no-results-quote-quote">{quote}</div>
      </div>
    </div>
  );
};

NoResultsQuote.displayName = 'NoResultsQuote';
NoResultsQuote.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  quote: PropTypes.string,
  photoUrl: PropTypes.string,
  photoAlt: PropTypes.string,
  removeImage: PropTypes.bool
};

export default NoResultsQuote;
