import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { politely, assertively, reset } from '../../common/announce';

export default createReactClass({
  displayName: 'Announce',

  propTypes: {
    assertively: PropTypes.bool,
    message: PropTypes.string.isRequired
  },

  getDefaultProps () {
    return {
      assertively: false
    };
  },

  componentWillUnmount () {
    reset();
  },

  componentDidMount () {
    this.announce();
  },

  shouldComponentUpdate (nextProps, nextState) {
    const sameMessage = this.props.message === nextProps.message;
    const samePoliteness = this.props.assertively === nextProps.assertively;
    if (sameMessage && samePoliteness) {
      return false;
    }
    return true;
  },

  componentDidUpdate () {
    this.announce();
  },

  announce () {
    if (this.props.assertively) {
      assertively(this.props.message);
    } else {
      politely(this.props.message);
    }
  },

  render () {
    return <noscript />;
  }
});
