import { ACTION_REMOVE_ALERT_BY_ID, ACTION_ADD_ALERT, ACTION_REMOVE_ALL_ALERTS } from '../constants/alert';
import { dispatch } from '../store';

export const addAlert = (alert) => {
  return dispatch({
    type: ACTION_ADD_ALERT,
    payload: alert,
  });
};

export const removeAlertById = (id) => {
  return dispatch({
    type: ACTION_REMOVE_ALERT_BY_ID,
    payload: id,
  });
};

export const removeAllAlerts = () => {
  return dispatch({
    type: ACTION_REMOVE_ALL_ALERTS,
  });
};
