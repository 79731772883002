import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import Member from './member';
import CreateMember from './member-create';
import compare from '../../../common/natural-compare';
import debounce from 'lodash/function/debounce';
import Announce from '../../common/announce';
import ErrorMessage from '../../common/error-message';

export default createReactClass({
  displayName: 'ConsortiumMemberList',

  propTypes: {
    changeContributorStatus: PropTypes.func.isRequired,
    createMember: PropTypes.func.isRequired,
    createMemberError: PropTypes.object,
    deleteMember: PropTypes.func.isRequired,
    creatorAccountId: PropTypes.string.isRequired,
    members: PropTypes.array,
    accounts: PropTypes.array,
    accountsCount: PropTypes.number,
    searchAccounts: PropTypes.func.isRequired,
    searchAccountsError: PropTypes.object,
    resetAccounts: PropTypes.func.isRequired
  },

  UNSAFE_componentWillMount () {
    this.searchAccounts = debounce(this.props.searchAccounts.bind(this), 300);
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.searchAccounts !== nextProps.searchAccounts) {
      this.searchAccounts = debounce(this.props.searchAccounts.bind(this), 300);
    }
  },

  renderRow (member) {
    const { deleteMember, changeContributorStatus } = this.props;
    return (
      <Member
        changeContributorStatus={changeContributorStatus}
        deleteMember={deleteMember}
        disabled={false}
        key={member.id}
        member={member}
      />
    );
  },

  render () {
    const {
      members,
      createMember,
      searchAccountsError,
      accounts,
      creatorAccountId,
      accountsCount,
      createMemberError,
      resetAccounts
    } = this.props;

    const statusOrder = { pending: 1, declined: 2, member: 3 };
    const sortedMembers = members
      .filter(member => member.accountId !== creatorAccountId)
      .sort((one, two) => {
        const status = statusOrder[one.status] - statusOrder[two.status];
        if (status) return status;
        return compare(one.accountName, two.accountName);
      });

    const memberAccountIds = sortedMembers
      .filter(({ status }) => status !== 'declined')
      .map(({ accountId }) => accountId);
    memberAccountIds.push(creatorAccountId);
    const modifiedAccounts = accounts.map(account => ({
      ...account,
      isMember: memberAccountIds.includes(account.id)
    }));

    let errorMessage = '';
    if (searchAccountsError) {
      errorMessage = formatMessage(
        'There was an error searching for Institutions'
      );
    } else if (createMemberError) {
      errorMessage = createMemberError.errors
        .map(
          ({ message }) =>
            message ||
            formatMessage('There was an error inviting that Institution')
        )
        .join('. ');
    }

    return (
      <div className="ConsortiumMemberList">
        <div className="ConsortiumMemberList-heading">
          <h2 className="heading-type-2">
            {formatMessage('Invite other Institutions')}
          </h2>
          {errorMessage && (
            <div className="ConsortiumMemberList-error">
              <ErrorMessage message={errorMessage} inline />
              <Announce message={errorMessage} />
            </div>
          )}
        </div>
        <CreateMember
          createMember={createMember}
          accounts={modifiedAccounts}
          accountsCount={accountsCount}
          searchAccounts={this.searchAccounts}
          resetAccounts={resetAccounts}
        />

        {sortedMembers.length > 0 && (
          <table className="ConsortiumMemberList-table">
            <thead>
              <tr className="ConsortiumMemberList-header-row">
                <th className="ConsortiumMemberList-header ConsortiumMemberList-contributor-column">
                  {formatMessage('Contributor')}
                </th>
                <th className="ConsortiumMemberList-header ConsortiumMemberList-institution-column">
                  {formatMessage('Institution')}
                </th>
                <th className="ConsortiumMemberList-header ConsortiumMemberList-delete-column" />
              </tr>
            </thead>
            <tbody className="ConsortiumMemberList-tbody">
              {sortedMembers.map(this.renderRow)}
            </tbody>
          </table>
        )}
      </div>
    );
  }
});
