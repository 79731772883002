import formatMessage from 'format-message';
import {
  SORT_MATCHING,
  SORT_RECENT,
  SORT_FAVORITE,
  SORT_DOWNLOADS,
  SORTING_ORDER_DESCENDING,
  SORTING_ORDER_ASCENDING,
  SORT_TITLE,
  SORT_AUTHOR
} from '../../../constants/search';
import { createSearchSortingOptions, joinSortingValues } from './util';

const RECENT_DESC = joinSortingValues(SORT_RECENT, SORTING_ORDER_DESCENDING);
const RECENT_ASC = joinSortingValues(SORT_RECENT, SORTING_ORDER_ASCENDING);
const FAVORITES_DESC = joinSortingValues(SORT_FAVORITE, SORTING_ORDER_DESCENDING);
const FAVORITES_ASC = joinSortingValues(SORT_FAVORITE, SORTING_ORDER_ASCENDING);
const DOWNLOADS_DESC = joinSortingValues(SORT_DOWNLOADS, SORTING_ORDER_DESCENDING);
const DOWNLOADS_ASC = joinSortingValues(SORT_DOWNLOADS, SORTING_ORDER_ASCENDING);
const TITLE_DESC = joinSortingValues(SORT_TITLE, SORTING_ORDER_DESCENDING);
const TITLE_ASC = joinSortingValues(SORT_TITLE, SORTING_ORDER_ASCENDING);
const AUTHOR_DESC = joinSortingValues(SORT_AUTHOR, SORTING_ORDER_DESCENDING);
const AUTHOR_ASC = joinSortingValues(SORT_AUTHOR, SORTING_ORDER_ASCENDING);

const options = [
  {
    id: SORT_MATCHING,
    label: formatMessage({
      default: 'Most relevant',
      description: 'Label for option to sort resources with the closest match to the query first'
    })
  },
  {
    id: RECENT_DESC,
    label: formatMessage({
      default: 'Updated, newest first',
      description: 'Label for option to sort resources most recently modified first'
    })
  },
  {
    id: RECENT_ASC,
    label: formatMessage({
      default: 'Updated, oldest first',
      description: 'Label for option to sort resources last recently modified first'
    })
  },
  {
    id: FAVORITES_DESC,
    label: formatMessage({
      default: 'Favorites, highest first',
      description: 'Label for option to sort by most favorited resources first'
    })
  },
  {
    id: FAVORITES_ASC,
    label: formatMessage({
      default: 'Favorites, lowest first',
      description: 'Label for option to sort by least favorited resources first'
    })
  },
  {
    id: DOWNLOADS_DESC,
    label: formatMessage({
      default: 'Downloads, highest first',
      description: 'Label for option to sort most downloaded resources first'
    })
  },
  {
    id: DOWNLOADS_ASC,
    label: formatMessage({
      default: 'Downloads, lowest first',
      description: 'Label for option to sort least downloaded resources first'
    })
  },
  {
    id: TITLE_ASC,
    label: formatMessage({
      default: 'Title, A → Z',
      description: 'Label for option to sort alphabetically by resource title'
    })
  },
  {
    id: TITLE_DESC,
    label: formatMessage({
      default: 'Title, Z → A',
      description: 'Label for option to sort reverse alphabetically by resource title'
    })
  },
  {
    id: AUTHOR_ASC,
    label: formatMessage({
      default: 'Author, A → Z',
      description: 'Label for option to sort alphabetically by author name'
    })
  },
  {
    id: AUTHOR_DESC,
    label: formatMessage({
      default: 'Author, Z → A',
      description: 'Label for option to sort reverse alphabetically by author name'
    })
  }
];

export const getDefaultSearchSortingOptions = () => createSearchSortingOptions(options);
