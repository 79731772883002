import Uniflow from 'uniflow';
import * as UpdatesModel from '../models/updates';
import { localizeDescription } from '../models/thumbnail-get-description';

let isRetreivalForUserPending = false;

export default Uniflow.createActions({
  getUpdates (userId) {
    this.emit('get-updates-pending', userId);
    if (isRetreivalForUserPending) {
      return false;
    }
    isRetreivalForUserPending = true;
    UpdatesModel.getUpdatesForUser(userId, null, this.handleGetResponse);
  },

  handleGetResponse (error, response) {
    isRetreivalForUserPending = false;
    if (error) {
      return this.emit('get-updates-error', error);
    }

    (response.items || []).forEach(({ resource }) => localizeDescription(resource));
    this.emit('get-updates-successful', response.items, response.meta.count, response.meta.cursor);
  },

  getUpdateForLearningObject (userId, learningObjectId) {
    this.emit('get-update-for-learning-object-pending');
    UpdatesModel.getUpdatesForUserForLearningObject(userId, learningObjectId, this.handleGetUpdateForLearningObject);
  },

  handleGetUpdateForLearningObject (error, response) {
    if (error) {
      return this.emit('get-update-for-learning-object-error', error);
    }

    localizeDescription(response && response.resource);
    this.emit('get-update-for-learning-object-successful', response);
  },

  loadNextPage (userId, cursor) {
    if (isRetreivalForUserPending) {
      return false;
    }
    isRetreivalForUserPending = true;
    this.emit('get-updates-next-page');
    UpdatesModel.getUpdatesForUser(userId, cursor, this.handleGetResponse);
  },

  // makes sure the list is re-fetched and up-to-date
  refresh () {
    this.emit('refresh-updates');
  },

  ignoreUpdateForCourses (userId, learningObjectId, courseIds) {
    this.removeErrorsForLearningObjectForCourse(learningObjectId, courseIds);
    this.emit('ignore-update-for-courses-pending');
    UpdatesModel.ignoreUpdateForCourses(userId, learningObjectId, courseIds, this.handleIgnoreUpdateForCourses.partial(learningObjectId, courseIds));
  },

  handleIgnoreUpdateForCourses (learningObjectId, courseIds, err, res) {
    if (err) {
      this.emit('ignore-update-for-courses-error', err);
    }

    this.emit('ignore-update-for-courses-successful', learningObjectId, courseIds);
  },

  removeErrorsForLearningObjectForCourse (learningObjectId, courseIds) {
    this.emit('remove-errors-learning-object-course', learningObjectId, courseIds);
  }
});
