import React from 'react';
import PropTypes from 'prop-types';

export default function Message ({ type, children }) {
  return (
    <div tabIndex="-1" className={'message message-' + type}>
      {children}
    </div>
  );
}

Message.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
};
