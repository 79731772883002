import React from 'react';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';

export default createReactClass({
  displayName: 'AccountGroupsSearch',

  render () {
    return (
      <div className="AccountGroupsSearch" data-heap-redact-text>
        <input
          data-testid="AccountGroupSearch-input"
          className="AccountGroupsSearch-input"
          placeholder={formatMessage('Search groups')}
          aria-label={formatMessage('Search groups')}
          type="search"
          {...this.props}
        />
      </div>
    );
  }
});
