import React from 'react';
import YellowOops from './layouts/yellow-oops';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

export default function BadMedia () {
  const message = formatMessage('That media is not ready to be shared yet. Please go back and try again in a few minutes.');
  announce.assertively(message);
  return (
    <YellowOops>
      <span>
        {message}
      </span>
    </YellowOops>
  );
}
