import React from 'react';
import formatMessage from 'format-message';

export default class Trash extends React.Component {
  static defaultProps = {
    width: '15px',
    height: '16px'
  };

  render () {
    return (
      <svg viewBox="0 0 15 16" {...this.props}>
        <title>{formatMessage('Trash')}</title>
        <g
          fill="currentColor"
          style={{ fillRule: 'evenodd' }}
        >
          <path d="M13 6v9c0 .55-.44 1-1 1H3c-.55 0-1-.45-1-1V6H0V4c0-.55.45-1 1-1h13c.55 0 1 .44 1 1v2h-2zM4 1.5C4 .67 4.67 0 5.5 0h4c.83 0 1.5.67 1.5 1.5V3H4V1.5zm0 5c0-.28.23-.5.5-.5.28 0 .5.23.5.5v7c0 .28-.23.5-.5.5-.28 0-.5-.23-.5-.5v-7zm3 0c0-.28.23-.5.5-.5.28 0 .5.23.5.5v7c0 .28-.23.5-.5.5-.28 0-.5-.23-.5-.5v-7zm3 0c0-.28.23-.5.5-.5.28 0 .5.23.5.5v7c0 .28-.23.5-.5.5-.28 0-.5-.23-.5-.5v-7z" />
        </g>
      </svg>
    );
  }
}
