import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import FilterPill from './filter-pill';
import { tokenizeFunctions, removeFunctions } from '../../common/tokenize-query';

export default class FilterPills extends React.Component {
  static propTypes = {
    query: PropTypes.object,
    account: PropTypes.object,
    consortiums: PropTypes.object,
    groups: PropTypes.object,
    onClose: PropTypes.func,
    canShowPublic: PropTypes.bool,
  }

  constructor (props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.state = { filters: this.getNewFilters(props) };
  }

  getNewFilters (props) {
    if (!props.query) return [];

    const filters = tokenizeFunctions()
      .map(f => f(props.query))
      .reduce((flatArray, childArray) => {
        if (Array.isArray(childArray)) {
          flatArray.push(...childArray);
        }
        return flatArray;
      }, []);

    props.query.scopeIds &&
      props.query.scopeIds.split(',').map(scope => {
        if (!props.canShowPublic && scope === props.account.id) return;

        this.findScopeValue(scope, props) &&
          filters.push({
            param: 'scopeIds',
            alias: this.findScopeValue(scope, props),
            value: scope
          });
      });

    if (props.query.accountId && props.query.accountName) {
      filters.push({
        param: 'accountId',
        alias: props.query.accountName,
        value: props.query.accountId
      });
    }

    return filters;
  }

  findMissingValue (largerArray, smallerArray) {
    return largerArray.reduce((found, value) => {
      if (found != null) {
        return found;
      }
      return smallerArray.find(v => v.value === value.value) ? null : value;
    }, null);
  }

  findScopeValue (scope, props) {
    scope = scope.replace(/(group-|consortium-)/gi, '');

    const find = [
      props.account,
      ...props.consortiums.list,
      ...props.groups.list
    ].find(el => el.id === scope);

    return find ? find.name : false;
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const params = this.getNewFilters(nextProps);
    const filters = this.state.filters.slice();

    if (params.length === 0) {
      filters.length = 0;
    }

    const newestParam = this.findMissingValue(params, this.state.filters);
    if (newestParam) {
      filters.push(newestParam);
    }

    const missingParam = this.findMissingValue(this.state.filters, params);
    if (missingParam) {
      const index = filters.indexOf(missingParam);
      filters.splice(index, 1);
    }

    if (filters) {
      this.setState({ filters });
    }
  }

  onClose (key, param) {
    const newQuery = removeFunctions()
      .reduce((newQuery, f) => {
        return {
          ...newQuery,
          ...f(this.props.query, key, param)
        };
      }, {});

    if (key === 'scopeIds') {
      newQuery.scopeIds = this.props.query.scopeIds
        .split(',')
        .filter(scope => scope !== param);

      if (newQuery.scopeIds.length === 0 && this.props.canShowPublic) {
        newQuery.showPublic = true;
      }
    }

    if (key === 'accountId') {
      newQuery.accountId = null;
      newQuery.accountName = null;
    }

    const button = ReactDOM.findDOMNode(this.refs[param]);
    const previousSibling = button && button.previousSibling;
    this.props.onClose(newQuery);

    if (previousSibling) {
      previousSibling.focus();
    } else {
      const filterDropdowns = document.querySelectorAll('.SearchOptions-filter');
      filterDropdowns[filterDropdowns.length - 1] &&
        filterDropdowns[filterDropdowns.length - 1].focus();
    }
  }

  render () {
    return (
      <div className="FilterPills">
        {this.state.filters.map(filterParam => (
          <FilterPill
            key={filterParam.value}
            ref={filterParam.value}
            filterParam={filterParam}
            onClose={this.onClose}
          />
        ))}
      </div>
    );
  }
}
