import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import NotFound from '../components/errors/404-not-found';

export default function ({ resolve, render }) {
  render(
    <div>
      <Head title={formatMessage('Not Found')} />
      <NotFound />
    </div>
  );
  resolve();
}
