import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import YellowOops from './layouts/yellow-oops';
import * as announce from '../../common/announce';

export default function NoAccess403 ({ code }) {
  let message, secondaryMessage;
  if (code === 'INSUFFICIENT_CONTEXTUAL_ROLE') {
    message = formatMessage('You do not have the share/import permission for this resource.');
    secondaryMessage = formatMessage('Please check your role in the course.');
  } else {
    message = formatMessage('You do not have access to Commons.');
    secondaryMessage = formatMessage('Please notify your Canvas administrator.');
  }

  announce.assertively(message);
  return (
    <YellowOops>
      <ScreenReaderContent as="h1">
        {message}
      </ScreenReaderContent>
      <h2 className="lor-warning-title">{message}</h2>
      <p>{secondaryMessage}</p>
    </YellowOops>
  );
}

NoAccess403.propTypes = {
  code: PropTypes.string
};
