import React from 'react';
import Robots500 from './layouts/robots-500';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

export default function ServiceNotAvailable503 () {
  const message = formatMessage('Commons is now in maintenance mode. We are sorry for the inconvenience. Check back soon.');
  announce.assertively(message);
  return (
    <Robots500>
      <h1>{formatMessage('Service Temporarily Unavailable')}</h1>
      <h2>{formatMessage('Scheduled maintenance is underway. Check back soon.')}</h2>
    </Robots500>
  );
}
