import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import { getMediaSources } from '../../../models/media';

export default class LearningObjectMediaPreview extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleMediaSources = this.handleMediaSources.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      hasError: false,
      sources: []
    };
  }

  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  UNSAFE_componentWillReceiveProps () {
    this.setState({ hasError: false });
  }

  componentDidMount () {
    getMediaSources(this.props.learningObject.id, this.handleMediaSources);
  }

  handleMediaSources (err, response) {
    const hasError = !!err || !response;
    const sources = (response || {}).items || [];
    this.setState({ hasError, sources });
  }

  handleKeyDown (event) {
    const mediaElement = this.refs.media;
    const isCurrentlyPlaying =
      mediaElement.currentTime > 0 &&
      mediaElement.paused === false &&
      mediaElement.ended === false;

    switch (event.key) {
      case ' ':
        event.preventDefault();
        if (isCurrentlyPlaying) {
          mediaElement.pause();
        } else {
          mediaElement.play();
        }
        break;
      case 'ArrowLeft':
        event.preventDefault();
        mediaElement.currentTime -= 5;
        break;
      case 'ArrowRight':
        event.preventDefault();
        mediaElement.currentTime += 5;
        break;
      case 'ArrowUp':
        event.preventDefault();
        setVolume(mediaElement, 0.1);
        break;
      case 'ArrowDown':
        event.preventDefault();
        setVolume(mediaElement, -0.1);
        break;
    }
  }

  render () {
    var style = {};
    if (this.state.hasError) {
      style.display = 'none';
    }

    var Media = this.props.learningObject.type;
    var label =
      Media === 'audio'
        ? formatMessage('Audio Preview')
        : formatMessage('Video Preview');
    var thumbnail = this.props.learningObject.thumbnail;

    // ensure only one source per mime
    var sources = {};
    this.state.sources.forEach(
      // eslint-disable-next-line camelcase
      ({ mime_type, url }) => (sources[mime_type] = url)
    );

    return (
      <section className="LearningObjectMediaPreview" style={style}>
        <ScreenReaderContent as="h2">{label}</ScreenReaderContent>
        <Media
          className="LearningObjectMediaPreview-player"
          controls
          onKeyDown={this.handleKeyDown}
          poster={thumbnail && thumbnail.url}
          preload="none"
          ref="media"
          tabIndex={0}
        >
          {Object.keys(sources).map(type => (
            <source key={type} type={type} src={sources[type]} />
          ))}
        </Media>
      </section>
    );
  }

  handleError () {
    this.setState({ hasError: true });
  }
}

// because browsers aren't smart enough to handle 1 > volume < 0
function setVolume (mediaElement, volumeDifference) {
  const presentVolume = mediaElement.volume;
  const newVolume = presentVolume + volumeDifference;
  if (newVolume >= 0 && newVolume <= 1) {
    mediaElement.volume = newVolume;
  } else {
    mediaElement.volume = newVolume < 0 ? 0 : 1;
  }
}
