import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CheckIcon = ({ className, ...restProps }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    className={classnames('CheckMediumIcon', className)}
    viewBox="0 0 16 14"
    role="img"
    {...restProps}
  >
    <path
      fill="currentColor"
      d="M6.34 13.853l9.526-10.26c.187-.21.18-.56-.034-.76L13.874.838c-.207-.194
      -.543-.19-.73.034L5.81 8.723 2.79 5.726c-.21-.205-.544-.2-.764.035L.136
      7.793c-.19.21-.183.56.032.758l5.31 5.337c.134.114.31.16.464.138.15-.01.29
      -.067.4-.172z"
    />
  </svg>
);

CheckIcon.displayName = 'CheckMediumIcon';
CheckIcon.propTypes = {
  className: PropTypes.string
};

export default CheckIcon;
