import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import ButtonConfirm from '../common/button-confirm';
import Button from '../common/button';
import LearningObjectActions from '../../actions/resource';
import UpdatesActions from '../../actions/updates';
import LearningObjectStore from '../../stores/resource';

export default class UpdateButton extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleAccept = this.handleAccept.bind(this);

    this.state = {
      updating: false
    };
  }

  static propTypes = {
    onUpdate: PropTypes.func,
    children: PropTypes.node,
    learningObjectId: PropTypes.string.isRequired,
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string
      })
    ).isRequired
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.updating && !LearningObjectStore.importPending) {
      this.setState({ updating: false });
    }
  }

  handleAccept () {
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.learningObjectId);
    }
    UpdatesActions.removeErrorsForLearningObjectForCourse(
      this.props.learningObjectId,
      this.props.courses
    );
    LearningObjectActions.importIntoMultiCourses(
      this.props.learningObjectId,
      this.props.courses
    );
    this.setState({ updating: true });
  }

  render () {
    var prompt = formatMessage(`
      This update will replace your current resource.
      Do you still want to update?
    `);

    return (
      <div className="UpdateButton">
        <ButtonConfirm
          color={Button.color.primary}
          ref="menu"
          buttonClassName="UpdateButton-button"
          onAccept={this.handleAccept}
          prompt={prompt}
          disabled={this.state.updating || !this.props.courses.length}
        >
          {this.state.updating
            ? formatMessage('Updating...')
            : this.props.children}
        </ButtonConfirm>
      </div>
    );
  }
}
