export default function outcomesAuthorityFilter (
  outcomes,
  authorityCode
) {
  if (!authorityCode || authorityCode === 'NONE') {
    authorityCode = 'CC';
  }

  return outcomes.filter((outcome) => (
    // correct authority
    outcome.authorityCode === authorityCode ||
    // user added and no related outcome with correct authority
    (outcome.related == null && outcomes.find((related) => {
      return (
        related.related === outcome.id &&
        related.authorityCode === authorityCode
      );
    }) == null)
  ));
}
