import Uniflow from 'uniflow';
import { debounce } from 'lodash';
import * as imageModel from '../models/image';

const SEARCH_DEBOUNCE = 250;
let lastQuery;
let lastPage;

const ImageActions = Uniflow.createActions({
  searchImages: debounce(
    (...args) => ImageActions._searchImages(...args),
    SEARCH_DEBOUNCE
  ),
  _searchImages ({ query = '', page = 1 }) {
    query = query.trim();

    // do not waste network on redundant searches
    if (query === lastQuery && page === lastPage) {
      return;
    } else {
      lastQuery = query;
      lastPage = page;
    }

    if (page === 1) {
      ImageActions.emit('get-photos-pending', query);
    }

    imageModel.searchImages({ query, page }, (err, data) => {
      // do not display the results if the query changed in the meantime
      // which can happen with a quick handed user and slow network connection
      if (lastQuery !== query) {
        return;
      }
      if (err) {
        ImageActions.emit('get-photos-error', err);
      }
      ImageActions.emit('get-photos-success', data, query);
    });
  },
  trackImage (id) {
    imageModel.trackImage(id, err => {
      // do nothing when the image tracking fails
      // we have zero fallback options and this info is irrelevant for the user
      if (err && process.env.NODE_ENV === 'development') {
        console.log('Third party image tracking failed', err);
      }
    });
  }
});

export default ImageActions;
