import formatMessage from 'format-message';
import { replaceErrors } from './replace';

export const CONSORTIUM_ERRORS = {
  'account-id-invalid': formatMessage('The account id provided was invalid'),
  'no-public-only': formatMessage('Can not invite a public only account')
};

export default function (err) {
  return replaceErrors(err, CONSORTIUM_ERRORS);
}
