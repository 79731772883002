import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Checkbox } from '@instructure/ui-checkbox';
import { Text } from '@instructure/ui-text';
import { RadioInput } from '@instructure/ui-radio-input';

import SearchActions from '../../../actions/search';
import SearchStore from '../../../stores/search';
import LoadMore from '../../common/load-more';
import CuratedPopover from './curated-popover';
import FormGroup from '../../common/form-group';
import Link from '../../common/link';

export default class UpdateSelector extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      initialResourceId: null,
      initialScopes: [],
      hasLoadedInitialScopes: false
    };
  }

  static propTypes = {
    accountName: PropTypes.string,
    allowApprovedContent: PropTypes.bool,
    authorId: PropTypes.string.isRequired,
    isAccountCurator: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isUpdate: PropTypes.bool,
    newVersionUpdateErrors: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    selection: PropTypes.object, // this could be a learning object but minimum should be {id: '', title: ''}
    type: PropTypes.string.isRequired
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.selection.id && !this.state.hasLoadedInitialScopes) {
      this.setState({
        initialResourceId: props.selection.id,
        initialScopes: props.selection.scopeIds,
        hasLoadedInitialScopes: true
      });
    }
  }

  componentDidUpdate () {
    if (
      this.props.authorId &&
      !this.props.search.searchPending &&
      !this.props.search.searchSuccessful &&
      !this.props.search.searchError
    ) {
      SearchActions.search({
        where: {
          type: this.props.type,
          authorIds: this.props.authorId,
          size: 48
        }
      });
    }
  }

  learningObjectToOption = lo => {
    const scopes =
      lo.id === this.state.initialResourceId
        ? this.state.initialScopes
        : lo.scopeIds;
    const disabled =
      scopes &&
      scopes.some(item => item.includes('curated-')) &&
      this.props.allowApprovedContent &&
      !(this.props.isAccountCurator || this.props.isAdmin);
    return {
      name: lo.title,
      value: lo.id,
      id: lo.id,
      disabled
    };
  }

  optionToLearningObject = option => {
    return {
      id: option.name,
      title: option.value
    };
  }

  clearOptionSelection = () => {
    const isUpdate =
      this.props.search.results && this.props.search.results.length > 1;
    this.props.onChange({
      option: null,
      isUpdate
    });
  }

  handleToggleChange = event => {
    this.props.onChange({
      isUpdate: event.target.checked
    });
  }

  handleResourceChange = event => {
    const option = this.optionToLearningObject(event.target);
    this.props.onChange({
      option
    });
  }

  renderResourceOption = ({
    label,
    value,
    name,
    checked = false,
    defaultSelected = false,
    multipleResults = false,
    disabled
  }) => {
    return (
      <div className="UpdateSelector-inputOptions" key={`${name}-${value}`}>
        <div className="UpdateSelector-left">
          <RadioInput
            label={label}
            value={name}
            name={value}
            checked={checked}
            disabled={disabled}
            onChange={this.handleResourceChange}
          />
        </div>
        <div className="UpdateSelector-right">
          {defaultSelected && multipleResults && (
            <Link onClick={this.clearOptionSelection} margin="small">
              {formatMessage('Change')}
            </Link>
          )}
          {disabled && <CuratedPopover accountName={this.props.accountName} />}
        </div>
      </div>
    );
  }

  render () {
    var results = this.props.search.results.map(this.learningObjectToOption);
    var selection = this.props.selection.id
      ? this.learningObjectToOption(this.props.selection)
      : null;
    const hasErrors =
      (this.props.newVersionUpdateErrors &&
        this.props.newVersionUpdateErrors.length) > 0;
    const errorObject =
      hasErrors &&
      this.props.newVersionUpdateErrors.find(
        errorMessage => errorMessage.param === 'resourceId'
      );

    const errorMessage = errorObject ? errorObject.msg : '';

    return (
      <div className="UpdateSelector">
        <label className="UpdateSelector-toggle">
          <Checkbox
            checked={this.props.isUpdate}
            label={<Text size="medium">{formatMessage('This is an update to a previously shared resource')}</Text>}
            onChange={this.handleToggleChange}
            size="small"
            variant="toggle"
            value="toggleUpdatedResource"
          />
        </label>
        {this.props.isUpdate && (
          <div className="UpdateSelector-list">
            <LoadMore
              hasMore={selection ? false : SearchStore.hasMore()}
              loadMore={SearchActions.loadNextPage.partial(
                this.props.search.cursor,
                this.props.search.lastQuery
              )}
              isLoading={this.props.search.searchPending}
            >
              <FormGroup
                label={formatMessage('Select which resource you are updating:')}
                htmlFor="resourceId"
                required
                hasErrors={!!errorObject}
                errorMessage={errorMessage}
              >
                <div
                  className="UpdateSelector-options"
                  aria-labelledby="list-label"
                  ref="options"
                >
                  {selection
                    ? this.renderResourceOption({
                      label: selection.name,
                      value: selection.value,
                      name: selection.name,
                      checked: true,
                      defaultSelected: true,
                      multipleResults: results.length > 1,
                      disabled: selection.disabled
                    })
                    : results.map(result => {
                      return this.renderResourceOption({
                        label: result.name,
                        value: result.value,
                        name: result.name,
                        disabled: result.disabled
                      });
                    })}
                </div>
              </FormGroup>
            </LoadMore>
          </div>
        )}
      </div>
    );
  }
}
