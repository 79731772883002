import { setLocalStorageItem } from '../common/localstorage';
import { SEARCH_PAGE_RESULTS_VIEW_KEY, UPDATE_SEARCH_PAGE_RESULTS_VIEW_ACTION } from '../constants/local-storage';
import { dispatch } from '../store';

export const updateResultsView = (view) => {
  setLocalStorageItem(SEARCH_PAGE_RESULTS_VIEW_KEY, view);

  return dispatch({
    type: UPDATE_SEARCH_PAGE_RESULTS_VIEW_ACTION,
    view
  });
};
