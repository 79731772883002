import React from 'react';
import PropTypes from 'prop-types';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import FavoriteResourcesFilter from './favorite-resources-filter';
import CanvasResourceSelector from './canvas-resource-selector';
import FavoriteResourcesHandler from './favorite-resources-handler';
import { FavoritesActions, SearchActions } from '../../actions';
import withFavoriteResourceImportHandler from './favorite-resource-import-handler';
import PageTitle from '../common/page-title';

/* eslint-disable react/prop-types */
class FavoriteResources extends React.Component {
  static propTypes = {
    session: PropTypes.object,
    favorites: PropTypes.object,
    featureFlags: PropTypes.object,
    q: PropTypes.object
  };

  constructor (props) {
    super(props);

    const isLoading = this.props.favorites.listIsLoading;
    const isAlreadyLoaded = this.props.favorites.listIsLoaded;

    if (!isAlreadyLoaded && !isLoading) {
      setTimeout(() => {
        FavoritesActions.loadList();
      }, 0);
    }
  }

  render () {
    const {
      startImport,
      getImportStatusForResource,
      dismissErrorForResource,
      importTargets,
      currentImportTarget,
      handleSubTargetSelected,
      isCanvasResourceSelectAvailable,
      importStatus,
      ...rest
    } = this.props;
    const {
      canvasResourceType,
      acceptedCanvasResourceTypes,
      canvasApiDomain,
      canvasCourseId,
      contextTitle
    } = this.props.session;
    const { flags } = this.props.featureFlags;

    const resourceImportProps = {
      startImport: startImport(
        canvasApiDomain,
        canvasCourseId,
        canvasResourceType,
        contextTitle
      ),
      dismissErrorForResource,
      getImportStatusForResource,
      importStatus
    };

    return (
      <div className="FavoriteResources responsive-results-width">
        <Head title="Commons Favorites" />
        <main className="Main disable-scrolling FavouriteResourcesMain">
          <div className="page-title-container">
            <PageTitle title={formatMessage('Commons Favorites')} />
          </div>
          {canvasResourceType && flags.favorites_tray && (
            <p className="reload-warning">
              {formatMessage('Items added will be visible in Canvas after you close this tray.')}
            </p>
          )}
          {isCanvasResourceSelectAvailable() && (
            <CanvasResourceSelector
              canvasResourceType={canvasResourceType}
              availableCanvasResources={importTargets}
              onChangeHandler={handleSubTargetSelected}
              currentSelected={currentImportTarget}
            />
          )}
          <FavoriteResourcesFilter
            query={this.props.q}
            queryAriaLabel={formatMessage('Filter Favorite resources by Title')}
            queryPlaceholder={formatMessage('Search by Title')}
            onChange={SearchActions.update}
            flags={flags}
            acceptedCanvasResourceTypes={acceptedCanvasResourceTypes}
          />
          <FavoriteResourcesHandler {...rest} {...resourceImportProps} />
        </main>
      </div>
    );
  }
}

export default withFavoriteResourceImportHandler(FavoriteResources);
