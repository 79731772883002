import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function FormHeader (props) {
  const classes = classNames('FormHeader', {
    'FormHeader--top-border': props.topBorder,
  });
  return (
    <h2 className={classes}>{props.children}</h2>
  );
}

FormHeader.propTypes = {
  topBorder: PropTypes.bool,
  children: PropTypes.any,
};
