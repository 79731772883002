import { canvas as standardTheme, canvasHighContrast } from '@instructure/ui-themes/lib';
import './store';
import './actions/analytics';
import env, { fetchConfigFromApi } from './config/env';

import router from './router';
import apiRequest from './common/api-request';
import { RouterActions, FeatureFlagActions } from './actions';

if (env.HIGH_CONTRAST) {
  canvasHighContrast.use();
} else {
  standardTheme.use();
}

let currentMaintenanceState = null;
let maintenanceCheckTimer = null;
async function getMaintenanceState () {
  const request = apiRequest({ path: '/maintenance/state' });
  try {
    const response = await request.promise();
    if (currentMaintenanceState !== response.body.maintenanceMode) {
      const prevWasNull = currentMaintenanceState === null;
      currentMaintenanceState = response.body.maintenanceMode;
      RouterActions.setMaintenanceMode(currentMaintenanceState);
      if (response.body.maintenanceMode === true) {
        window.clearInterval(maintenanceCheckTimer);
        RouterActions.replaceWith('/errors/service-unavailable');
      } else if (!prevWasNull) {
        console.log('Maintenance mode is now cleared.');
      }
    }
  } catch (err) {
    console.log('Will retry maintenance mode check later.');
  }
}

// these resources should be present even before we render our app for the first time
(async function initApp () {
  await Promise.all([
    fetchConfigFromApi(),
    FeatureFlagActions.getFeatureFlags()
  ]);

  if (env.shouldPollMaintenanceMode === true) {
    maintenanceCheckTimer = window.setInterval(getMaintenanceState, 10000);
    await getMaintenanceState();
  }

  router.start();
})();
