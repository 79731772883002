import { useState, useEffect } from 'react';

export const useUniflowStore = (store) => {
  const [state, setState] = useState(store.state);

  useEffect(() => {
    let isMounted = true;

    const handleChange = () => {
      if (isMounted) {
        setState(store.state);
      }
    };

    store.on('change', handleChange);

    return () => {
      isMounted = false;
      store.removeListener('change', handleChange);
    };
  }, [store]);

  return state;
};
