import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Message from './message';

export default function Error ({ className, error }) {
  if (error.errors) {
    error = error.errors[0];
  }
  const message = error.message || error.msg;

  return (
    <Message type={classnames(className, 'error2 message-with-icon')}>
      {message}
    </Message>
  );
}

Error.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object.isRequired
};
