import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './error-message';
import classnames from 'classnames';

export default class FormGroup extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    helpText: PropTypes.string,
    helpLink: PropTypes.string,
    htmlFor: PropTypes.string,
    required: PropTypes.bool,
    hasErrors: PropTypes.bool,
    label: PropTypes.string,
    legend: PropTypes.string,
    children: PropTypes.node
  }

  renderHelpLink () {
    if (!this.props.helpText || !this.props.helpLink) return null;
    return (
      <a
        className="form-group-help-link"
        href={this.props.helpLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="differentiating-space"> </span>
        {this.props.helpText}
      </a>
    );
  }

  renderHelpText () {
    if (!this.props.helpText) return null;
    return (
      <span className="form-group-help-text">{' ' + this.props.helpText}</span>
    );
  }

  renderFieldSet (classes) {
    return (
      <fieldset className={classes}>
        <legend className="form-group-label">
          <span className={this.props.required ? 'form-group-required' : null}>
            {this.props.legend}
          </span>
          {this.props.helpLink ? this.renderHelpLink() : this.renderHelpText()}
          {this.renderInlineError()}
        </legend>
        {this.props.children}
      </fieldset>
    );
  }

  renderDiv (classes) {
    return (
      <div className={classes}>
        <div className="form-group-label">
          <label
            htmlFor={this.props.htmlFor}
            className={this.props.required ? 'form-group-required' : null}
          >
            {this.props.label}
          </label>
          {this.props.helpLink ? this.renderHelpLink() : this.renderHelpText()}
          {this.renderInlineError()}
        </div>
        {this.props.children}
      </div>
    );
  }

  renderInlineError () {
    if (this.props.hasErrors) {
      return <ErrorMessage message={this.props.errorMessage} inline />;
    }
  }

  render () {
    var classMap = {
      'form-group': true,
      error: this.props.hasErrors
    };
    classMap[this.props.className] = this.props.className;
    var classes = classnames(classMap);

    if (this.props.legend) {
      return this.renderFieldSet(classes);
    } else {
      return this.renderDiv(classes);
    }
  }
}
