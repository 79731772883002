import Uniflow from 'uniflow';
import ImageActions from '../actions/images';

let bootstrapped = false;

const defaultResults = {
  photos: [],
  meta: {
    perpage: 0,
    pages: 0,
    page: 0,
    total: 0
  },
  stat: 'pending'
};

const ImageStore = Uniflow.createStore({
  state: {
    results: defaultResults,
    searchPending: false,
    searchError: null,
    searchSuccessful: false,
    query: '',
    lastSuccessfulQuery: ''
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    ImageActions.on('get-photos-pending', query => {
      ImageStore.setState({
        results: defaultResults,
        searchPending: true,
        searchError: null,
        searchSuccessful: false,
        query
      });
    });

    ImageActions.on('get-photos-error', error => {
      ImageStore.setState({
        searchPending: false,
        searchError: error,
        searchSuccessful: false
      });
    });

    ImageActions.on('get-photos-success', (results, query) => {
      if (results.meta.page > 1) {
        results.photos = ImageStore.state.results.photos.concat(results.photos);
      }

      ImageStore.setState({
        results,
        searchPending: false,
        searchError: null,
        searchSuccessful: true,
        lastSuccessfulQuery: query
      });
    });

    bootstrapped = true;
  },

  hasMore () {
    const { meta } = this.state.results;
    return !meta.page || Boolean(meta.page < meta.pages);
  }
});

export default ImageStore;
