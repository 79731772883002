import { RESOURCE_CURSOR_LIMIT } from '../../constants/resource-cursor-limit';

const isValidPositiveNumber = (value) => {
  return Number.isFinite(value) && value > 0;
};

const areParametersValid = (totalCount, resultsPerPage) => {
  return isValidPositiveNumber(totalCount) && isValidPositiveNumber(resultsPerPage);
};

const clamp = (value, lower, upper) => {
  return Math.min(Math.max(value, lower), upper);
};

const clampPage = (page, totalPages) => {
  const value = clamp(Math.floor(page), 1, totalPages);
  return Number.isFinite(value) ? value : 1;
};

export function calculatePaginationPages (page, totalCount, resultsPerPage) {
  if (!areParametersValid(totalCount, resultsPerPage)) {
    throw new Error(`Invalid pagination parameters: totalCount=${totalCount}, resultsPerPage=${resultsPerPage}`);
  }

  const totalPages = Math.ceil(totalCount / resultsPerPage);
  const currentPage = clampPage(page, totalPages);
  const hasTooManyResults = totalCount > RESOURCE_CURSOR_LIMIT;
  const maxPageByCursorLimit = RESOURCE_CURSOR_LIMIT / resultsPerPage;
  return {
    currentPage,
    totalPages: hasTooManyResults
      ? Math.ceil(maxPageByCursorLimit) + 1
      : totalPages,
    hasTooManyResults,
    lastAvailablePage: Math.min(Math.floor(maxPageByCursorLimit), totalPages),
  };
}
