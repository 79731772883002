import apiRequest from '../api-request';
import { throwOnNullishParams } from '../validation';

export const createModuleItem = async ({
  courseId,
  moduleId,
  learningObjectId,
  contentId
} = {}) => {
  throwOnNullishParams({
    courseId,
    moduleId,
    learningObjectId,
    contentId
  });

  const result = await apiRequest({
    method: 'post',
    path: `/resources/${learningObjectId}/modules/${moduleId}/items`,
    body: {
      id: contentId,
      course: {
        id: courseId
      }
    }
  }).promise();

  if (result.status !== 201) {
    throw new Error(result.status);
  }

  return result.body;
};

export const createNewModule = async (courseId, name) => {
  throwOnNullishParams({
    courseId,
    name
  });

  const result = await apiRequest({
    method: 'post',
    path: '/resources/modules/new',
    body: { course: { id: courseId }, module: { name } }
  }).promise();

  if (result.status !== 201) {
    throw new Error(result.status);
  }

  return result.body;
};
