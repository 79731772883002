import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import Errors from '../components/errors';

export default function ({ params, resolve, render }) {
  render(
    <div>
      <Head title={formatMessage('Error')} />
      <Errors {...params} />
    </div>
  );
  resolve();
}
