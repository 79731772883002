let scheduledReset = setTimeout(function () {}, 0);

const POLITE_ID = 'screenreader-announcements-polite';
const ASSERTIVE_ID = 'screenreader-announcements-assertive';

function internalReset (id) {
  var element = document.getElementById(id);
  if (element && !element.querySelector('*[id^="Alert_"]')) {
    element.textContent = '';
  } else {
    console.warn('Unable to reset screenreader announcement', id);
  }
}

/** @type {(text: string) => void} */
export function politely (text) {
  clearTimeout(scheduledReset);
  var element = document.getElementById(POLITE_ID);
  element.textContent = text;
}

/** @type {(text: string) => void} */
export function assertively (text) {
  clearTimeout(scheduledReset);
  var element = document.getElementById(ASSERTIVE_ID);
  element.textContent = text;
}

/** @type {() => void} */
export function reset () {
  internalReset(POLITE_ID);
  internalReset(ASSERTIVE_ID);
}

const FIVE_SECONDS = 5000;
export function delayedReset () {
  scheduledReset = setTimeout(reset, FIVE_SECONDS);
}
