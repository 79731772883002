/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Loading from '../../common/loading';
import { getMediaSources } from '../../../models/media';
import MediaElement from '../../common/media-element';
import formatMessage from 'format-message';
import videoPlaySrc from '../../../../assets/svg/video_play.svg';
import videoPauseSrc from '../../../../assets/svg/video_pause.svg';

var clipLength = 10; // seconds

export default createReactClass({
  displayName: 'MediaHoverPreview',

  propTypes: {
    learningObject: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    className: PropTypes.string,
    isHovered: PropTypes.bool
  },

  getInitialState () {
    return {
      sourcesRequest: null,
      sourcesRetrieved: false,
      sourceErrors: null,
      isAudioPlaying: false, // for making audio require a click to start
      isPlaying: false, // for showing loader when should be but is not playing
      isStopped: false, // for making sure not to show loader after complete
      sources: [],
      buffered: [],
      currentTime: 0,
      duration: null
    };
  },

  componentDidMount () {
    const sourcesRequest = getMediaSources(
      this.props.learningObject.id,
      this.updateSources
    );
    this.setState({ sourcesRequest });
    this._isMounted = true;
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    var learningObjectId = this.props.learningObject.id;
    if (nextProps.learningObject.id !== learningObjectId) {
      if (this.state.sourcesRequest) this.state.sourcesRequest.abort();
      var state = this.getInitialState();
      state.sourcesRequest = getMediaSources(
        learningObjectId,
        this.updateSources
      );
      this.setState(state);
    }
  },

  componentWillUnmount () {
    this._isMounted = false;
    if (this.state.sourcesRequest) this.state.sourcesRequest.abort();
  },

  updateSources (err, json) {
    if (!this._isMounted || err) {
      return;
    }
    var mimes = {}; // keep only one source per mime
    var sources = Array.isArray(json.items) ? json.items : [];
    sources = sources.filter(({ mime_type, url } = {}) => {
      if (mimes[mime_type]) {
        return false;
      }
      mimes[mime_type] = true;
      return mimes[mime_type];
    });
    var sourcesRetrieved = true;
    var sourceErrors = err || null;
    var sourcesRequest = null;
    this.setState({ sourcesRetrieved, sourceErrors, sources, sourcesRequest });
  },

  setupMedia (media) {
    if (media) {
      this.setState({
        buffered: media.buffered || [],
        duration: media.duration || null,
        currentTime: media.currentTime || 0,
        isPlaying: false,
        isStopped: false
      });
    }
  },

  resetMedia () {
    if (!this._isMounted) {
      return;
    }
    this.setState({
      buffered: [],
      duration: null,
      currentTime: 0,
      isAudioPlaying: false,
      isPlaying: false,
      isStopped: false
    });
  },

  handleProgress (evt) {
    var buffered = evt.target.buffered;
    this.setState({ buffered });
  },

  handleDurationChange (evt) {
    var duration = evt.target.duration;
    this.setState({ duration });
  },

  handleTimeUpdate (evt) {
    var currentTime = evt.target.currentTime;
    var buffered = evt.target.buffered;
    this.setState({ currentTime, buffered });
  },

  handleClickPlay (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ isAudioPlaying: true });
  },

  handleClickPause (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ isAudioPlaying: false });
  },

  handleStart () {
    this.setState({
      isPlaying: true,
      isStopped: false
    });
  },

  handleStop () {
    this.setState({
      isPlaying: false,
      isStopped: true
    });
  },

  isActive () {
    var isAudio = this.props.learningObject.type === 'audio';
    return isAudio
      ? this.props.isHovered
      : this.props.isHovered || this.props.isFocused;
  },

  isPlaying () {
    // playing has been requested
    var type = this.props.learningObject.type;
    var isAudioPlaying = this.state.isAudioPlaying;
    return this.isActive() && (type !== 'audio' || isAudioPlaying);
  },

  isLoading () {
    return (
      this.isPlaying() &&
      (!this.state.sourcesRetrieved ||
        (!this.state.isPlaying && !this.state.isStopped))
    );
  },

  isPauseable () {
    return (
      this.isPlaying() &&
      !this.isLoading() &&
      this.props.learningObject.type === 'audio'
    );
  },

  render () {
    var className = ((this.props.className || '') + ' MediaHoverPreview').trim();
    if (this.state.sourceErrors) {
      return (
        <div className={className}>
          <span className="MediaHoverPreview-error">
            {formatMessage('Preview unavailable')}
          </span>
        </div>
      );
    }

    if (!this.isPlaying()) {
      return (
        <div className={className}>
          {this.props.learningObject.type === 'audio' && (
            <img
              className="MediaHoverPreview-play-icon"
              src={videoPlaySrc}
              alt={formatMessage('Play a preview')}
              onClick={this.handleClickPlay}
            />
          )}
        </div>
      );
    }

    var thumb = 0;
    if (this.state.duration) {
      var shown = Math.min(this.state.duration, clipLength); // we are clipping to ten seconds
      thumb = Math.min(1, Math.max(0, this.state.currentTime / shown));
    }

    return (
      <div className={className}>
        {this.isPlaying() && this.state.sources.length > 0 && (
          <MediaElement
            ref={this.setupMedia}
            autoPlay
            className="MediaHoverPreview-media"
            muted={this.props.learningObject.type !== 'audio'}
            type={this.props.learningObject.type}
            onDurationChange={this.handleDurationChange}
            onProgress={this.handleProgress}
            onStart={this.handleStart}
            onStop={this.handleStop}
            onTimeUpdate={this.handleTimeUpdate}
            onWillUnmount={this.resetMedia}
          >
            {this.state.sources.map(({ mime_type, url }) => (
              <source
                key={mime_type}
                type={mime_type}
                src={url + '#t=,' + clipLength}
              />
            ))}
          </MediaElement>
        )}
        {this.isLoading() && <Loading className="MediaHoverPreview-loading" />}
        {this.isPauseable() && (
          <img
            alt={formatMessage('Play a preview')}
            className="MediaHoverPreview-pause-icon"
            onClick={this.handleClickPause}
            src={videoPauseSrc}
          />
        )}
        <div className="MediaHoverPreview-progress">
          <div className="MediaHoverPreview-progress-track">
            {this.renderBuffers()}
          </div>
          {this.state.sources.length > 0 && (
            <div
              className="MediaHoverPreview-thumb"
              style={{ left: thumb * 100 + '%' }}
            />
          )}
        </div>
      </div>
    );
  },

  renderBuffers () {
    var buffered = this.state.buffered;
    var duration = Math.min(this.state.duration, clipLength);
    var elems = [];
    for (var i = 0; i < buffered.length; ++i) {
      var left = buffered.start(i) / duration;
      var width = buffered.end(i) / duration - left;
      elems.push(
        <div
          className="MediaHoverPreview-progress-fill"
          key={i}
          style={{
            left: left * 100 + '%',
            width: width * 100 + '%'
          }}
        />
      );
    }
    return elems;
  }
});
