import RouterStore from './stores/router';

function focusWithoutScroll (node) {
  if (!node) return;
  var x = window.scrollX;
  var y = window.scrollY;
  node.focus();
  window.scrollTo(x, y);
}

export function focusIfTabParamPresent (node) {
  var params = RouterStore.state.params;
  if (params && params.tab) {
    focusWithoutScroll(node);
  }
}
