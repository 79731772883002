import { bindActionCreators } from 'redux';
import userModel from '../models/user';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';

export const SEARCH_USERS_START = 'users.search-user-start';
export const SEARCH_USERS_SUCCESS = 'users.search-user-success';
export const SEARCH_USERS_FAILURE = 'users.search-user-failure';
export const RESET_SEARCH_USERS = 'users.reset-search-users';
export const CLEAR_LOAD_USER_ERROR = 'users.clear-load-user-error';
export const SET_LOAD_USER_ERROR = 'users.set-load-user-error';

export const creators = {
  searchUsersStart: () => ({ type: SEARCH_USERS_START }),
  searchUsersSuccess: response => ({ type: SEARCH_USERS_SUCCESS, response }),
  searchUsersFailure: error => ({ type: SEARCH_USERS_FAILURE, error }),
  resetSearchUsers: () => ({ type: RESET_SEARCH_USERS }),
  clearLoadUserError: () => ({ type: CLEAR_LOAD_USER_ERROR }),
  setLoadUserError: error => ({ type: SET_LOAD_USER_ERROR, error }),
};

export default function createUserActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async searchUsers (query) {
      actions.searchUsersStart();
      const req = apiRequest({
        path: `/users/?q=${encodeURIComponent(query)}`
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.searchUsersSuccess(response.body);
      } catch (err) {
        actions.searchUsersFailure(err);
      }
    },

    async loadUser (id) {
      actions.clearLoadUserError();

      try {
        const response = await userModel.load(id);
        window.top.location.replace(response.location);
      } catch (error) {
        actions.setLoadUserError(error);
      }
    }
  };
}
