import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { RouterActions, ResourceActions } from '../../../actions';
import ResourceForm from './form';
import Loading from '../../common/loading';

export default createReactClass({
  displayName: 'EditForm',

  getInitialState () {
    return {
      isSubmitting:
        this.props.resource.updatePending || this.props.resource.findPending
    };
  },

  propTypes: {
    account: PropTypes.object.isRequired,
    learningObjectId: PropTypes.string.isRequired,
    licenses: PropTypes.object,
    resource: PropTypes.object,
    router: PropTypes.object,
    images: PropTypes.object,
    session: PropTypes.object,
    outcomes: PropTypes.object,
    groups: PropTypes.array,
    consortiums: PropTypes.object,
    resultPath: PropTypes.object
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      isSubmitting:
        nextProps.resource.updatePending || nextProps.resource.findPending
    });

    if (
      nextProps.resource.findPending !== this.props.resource.findPending &&
      nextProps.resource.findSuccessful
    ) {
      if (
        ['video', 'audio'].indexOf(nextProps.resource.learningObject.type) > -1
      ) {
        ResourceActions.updateLocalLearningObject({
          mediaObjectId:
            nextProps.resource.learningObject.versions[0].mediaObjectId
        });
      }
    }

    if (nextProps.resource.updateSuccessful) {
      const learningObject = nextProps.resource.learningObject;
      if (
        learningObject.scopeIds.length === 1 &&
        learningObject.scopeIds[0] === learningObject.createUserId &&
        learningObject.createUserId !== nextProps.session.user.id
      ) {
        return RouterActions.transitionTo(
          `${nextProps.resultPath.pathname}${nextProps.resultPath.search}`
        );
      }

      return RouterActions.transitionTo(
        `/resources/${nextProps.resource.learningObject.id}`,
        nextProps.router.query
      );
    }
  },

  handleSubmit (data) {
    var learningObject = Object.assign({}, this.props.resource.learningObject);
    if (learningObject.type === 'image') {
      delete learningObject.thumbnail;
    }
    const {
      showFederalStandards,
      showStateStandards
    } = this.props.account.settings;
    if (!showFederalStandards && !showStateStandards) {
      learningObject.outcomes = [];
    }

    ResourceActions.updateExisting(learningObject);
  },

  handleCancel (event) {
    ResourceActions.clearStore();
    RouterActions.goBack();
  },

  render () {
    if (this.props.resource.findPending) {
      return <Loading className="LearningObjectForm-loading" />;
    }
    return (
      <ResourceForm
        handleSubmit={this.handleSubmit}
        cancel={this.handleCancel}
        showUpdateOptions={false}
        formChanged={ResourceActions.updateLocalLearningObject}
        preventSubmission={this.state.isSubmitting}
        submitButtonText={formatMessage('Save Changes')}
        contentItemUrl={this.props.session.contentItemUrl}
        sessionAccount={this.props.session.account}
        user={this.props.session.user}
        images={this.props.images}
        session={this.props.session}
        licenses={this.props.licenses}
        consortiums={this.props.consortiums}
        account={this.props.account}
        groups={this.props.groups}
        outcomes={this.props.outcomes}
        resource={this.props.resource.learningObject}
        errors={this.props.resource.updateErrors}
      />
    );
  }
});
