import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Tooltip extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    location: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node
  }

  static defaultProps = {
    location: 'bottom'
  }

  render () {
    var classes = classnames(this.props.className, {
      Tooltip: true,
      'Tooltip-right': this.props.location === 'right',
      'Tooltip-left': this.props.location === 'left',
      'Tooltip-top': this.props.location === 'top',
      'Tooltip-bottom': this.props.location === 'bottom'
    });

    return (
      <div
        className={classes}
        aria-role="tooltip"
        aria-hidden="false"
        id={this.props.id || ''}
      >
        {this.props.children}
      </div>
    );
  }
}
