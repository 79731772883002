import React from 'react';
import formatMessage from 'format-message';
import PropTypes from 'prop-types';
import { confirmationDialog } from '../../confirmation-dialog';
import { FavoritesActions } from '../../../actions';
import ToggleFavoriteButton from './toggle-favorite-button';
import CommonsAlert from '../../common/alert';
import { addAlert } from '../../../actions/alert';

const FavoriteCell = ({ resource, isFavorite, session, isDesktop }) => {
  const getAlertId = (isFavorite, type, resourceId) => `${isFavorite ? 'add' : 'remove'}-favorite-${type}-${resourceId}`;

  const updateResourceFavorite = async (resource, isFavorite) => {
    if (!session.isAuthenticated) {
      showLoginRequiredDialog();
      return;
    }

    try {
      const response = isFavorite
        ? await FavoritesActions.addAsFavorite(resource)
        : await FavoritesActions.removeFromFavorites(resource);

      if (!response) {
        throw new Error('Failed to update favorite status');
      }

      addAlert({
        id: getAlertId(isFavorite, CommonsAlert.type.success, resource.id),
        type: CommonsAlert.type.success,
        message: isFavorite
          ? formatMessage('Resource added to favorites')
          : formatMessage('Resource removed from favorites')
      });
    } catch (error) {
      addAlert({
        id: getAlertId(isFavorite, CommonsAlert.type.error, resource.id),
        type: CommonsAlert.type.error,
        message: isFavorite
          ? formatMessage('Failed to add resource to favorites')
          : formatMessage('Failed to remove resource from favorites')
      });
    }
  };

  const showLoginRequiredDialog = async () => {
    await confirmationDialog({
      title: formatMessage('Login required'),
      message: formatMessage('You are currently not signed in. To use this function, please log in via your Canvas account.'),
      hideCancelButton: true,
      confirmText: formatMessage('Close')
    });
  };

  return (
    <ToggleFavoriteButton
      resource={resource}
      isFavorite={isFavorite}
      renderAs={isDesktop ? ToggleFavoriteButton.renderAs.icon : ToggleFavoriteButton.renderAs.link}
      onClick={updateResourceFavorite}
    />
  );
};

FavoriteCell.propTypes = {
  resource: PropTypes.object.isRequired,
  isFavorite: PropTypes.bool,
  session: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default FavoriteCell;
