import formatMessage from 'format-message';

import { ACTION_ADD_DIALOG, ACTION_REMOVE_DIALOG, ACTION_DIALOG_NOTHING } from '../constants/dialog';
import { dispatch } from '../store';

let dialogCounter = 1;

const getNextDialogId = () => {
  dialogCounter += 1;
  return dialogCounter;
};

const cancelDefaults = (withCancel, onCancel, cancelText) => {
  if (withCancel === undefined) {
    withCancel = true;
  }

  if (withCancel) {
    if (onCancel === undefined) {
      onCancel = { type: ACTION_DIALOG_NOTHING, payload: {} };
    }
    if (cancelText === undefined) {
      cancelText = formatMessage('Cancel');
    }
  } else {
    onCancel = null;
    cancelText = null;
  }
  return [withCancel, onCancel, cancelText];
};

export const addDialog = ({
  id,
  title,
  message,
  onConfirm, // must be an action object
  confirmText,
  withCancel,
  onCancel, // must be an action object or not defined if withCancel is false
  cancelText
}) => {
  if (id === undefined) {
    id = getNextDialogId();
  }

  [withCancel, onCancel, cancelText] = cancelDefaults(withCancel, onCancel, cancelText);

  const label = (withCancel)
    ? formatMessage('Confirmation required')
    : formatMessage('Please acknowledge');

  return dispatch({
    type: ACTION_ADD_DIALOG,
    payload: {
      id,
      title,
      message,
      onConfirm,
      confirmText,
      onCancel,
      cancelText,
      withCancel,
      label
    },
  });
};

export const removeDialog = id => dispatch({
  type: ACTION_REMOVE_DIALOG,
  payload: { id }
});

export const removeAllDialogs = () => dispatch({
  type: ACTION_REMOVE_DIALOG,
  payload: { id: null }
});

export const addLoginDialog = () => addDialog({
  title: formatMessage('Login required'),
  message: formatMessage('You must be logged in to perform this action.'),
  confirmText: formatMessage('Ok'),
  confirmAction: null,
  withCancel: false
});
