import compare from '../common/natural-compare';
import {
  RESET_CONSORTIUMS,
  LOAD_LIST_START,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_FAILURE,
  CHANGE_CREATE_NAME,
  CREATE_CONSORTIUM_START,
  CREATE_CONSORTIUM_SUCCESS,
  CREATE_CONSORTIUM_FAILURE,
  DELETE_CONSORTIUM_START,
  DELETE_CONSORTIUM_SUCCESS,
  DELETE_CONSORTIUM_FAILURE,
  LEAVE_CONSORTIUM_START,
  LEAVE_CONSORTIUM_SUCCESS,
  LEAVE_CONSORTIUM_FAILURE,
  UPDATE_STATUS_START,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE
} from '../actions/consortiums';

const initialState = {
  listIsLoading: false,
  listIsLoaded: false,
  listLoadError: null,
  list: [],
  createName: '',
  createConsortiumPending: false,
  createConsortiumSuccess: false,
  createConsortiumError: null,
  deleteConsortiumPending: false,
  deleteConsortiumSuccess: false,
  deleteConsortiumError: null,
  leaveConsortiumPending: false,
  leaveConsortiumSuccess: false,
  leaveConsortiumError: null,
  updateMembershipStatusPending: false,
  updateMembershipStatusSuccess: false,
  updateMembershipStatusError: null
};

export default function ConsortiumsReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_CONSORTIUMS:
      return initialState;

    case LOAD_LIST_START:
      return {
        ...state,
        listIsLoading: true,
        listIsLoaded: false,
        listLoadError: null,
        list: []
      };

    case LOAD_LIST_SUCCESS:
    {
      const statusOrder = { pending: 1, member: 2 };

      return {
        ...state,
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: null,
        list: action.list.slice()
          .sort((one, two) => {
            const status = statusOrder[one.membershipStatus] - statusOrder[two.membershipStatus];
            if (status) return status;
            return compare(one.name, two.name);
          })
      };
    }

    case LOAD_LIST_FAILURE:
      return {
        ...state,
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: action.error,
        list: []
      };

    case CHANGE_CREATE_NAME:
      return {
        ...state,
        createName: action.name,
        createConsortiumError: null
      };

    case CREATE_CONSORTIUM_START:
      return {
        ...state,
        createConsortiumPending: true,
        createConsortiumSuccess: false,
        createConsortiumError: null
      };

    case CREATE_CONSORTIUM_SUCCESS:
      return {
        ...state,
        createName: '',
        createConsortiumPending: false,
        createConsortiumSuccess: true,
        createConsortiumError: null,
        list: state.list
          .concat(action.consortium)
          .sort((a, b) => compare(a.name, b.name))
      };

    case CREATE_CONSORTIUM_FAILURE:
      return {
        ...state,
        createConsortiumPending: false,
        createConsortiumSuccess: false,
        createConsortiumError: action.error
      };

    case DELETE_CONSORTIUM_START:
      return {
        ...state,
        deleteConsortiumPending: true,
        deleteConsortiumSuccess: false,
        deleteConsortiumError: null
      };

    case DELETE_CONSORTIUM_SUCCESS:
      return {
        ...state,
        deleteConsortiumPending: false,
        deleteConsortiumSuccess: true,
        deleteConsortiumError: null,
        list: state.list.filter((consortium) => consortium !== action.consortium)
      };

    case DELETE_CONSORTIUM_FAILURE:
      return {
        ...state,
        deleteConsortiumPending: false,
        deleteConsortiumSuccess: false,
        deleteConsortiumError: action.error
      };

    case LEAVE_CONSORTIUM_START:
      return {
        ...state,
        leaveConsortiumPending: true,
        leaveConsortiumSuccess: false,
        leaveConsortiumError: null
      };

    case LEAVE_CONSORTIUM_SUCCESS:
      return {
        ...state,
        leaveConsortiumPending: false,
        leaveConsortiumSuccess: true,
        leaveConsortiumError: null,
        list: state.list
          .filter((consortium) => consortium !== action.consortium)
      };

    case LEAVE_CONSORTIUM_FAILURE:
      return {
        ...state,
        leaveConsortiumPending: false,
        leaveConsortiumSuccess: false,
        leaveConsortiumError: action.error
      };

    case UPDATE_STATUS_START:
      return {
        ...state,
        updateMembershipStatusPending: true,
        updateMembershipStatusSuccess: false,
        updateMembershipStatusError: null
      };

    case UPDATE_STATUS_SUCCESS:
    {
      const list = state.list.slice();
      const index = list.findIndex(({ membershipId }) => action.membership.id === membershipId);
      list[index] = {
        ...list[index],
        membershipStatus: action.membership.status
      };

      return {
        ...state,
        list,
        updateMembershipStatusPending: false,
        updateMembershipStatusSuccess: true,
        updateMembershipStatusError: null
      };
    }

    case UPDATE_STATUS_FAILURE:
      return {
        ...state,
        updateMembershipStatusPending: false,
        updateMembershipStatusSuccess: false,
        updateMembershipStatusError: action.error
      };

    default:
      return state;
  }
}
