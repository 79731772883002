import React, { Component } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import CroppedImg from '../common/cropped-img';

export default class Thumbnail extends Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    withAttribution: PropTypes.bool
  }

  static defaultProps = {
    withAttribution: true
  }

  onClick = () => {
    const { image, onClick } = this.props;
    if (onClick) {
      onClick(image);
    }
  }

  onLoad = () => {
    const { image, onLoad } = this.props;
    if (onLoad) {
      onLoad(image.url);
    }
  }

  render () {
    const { image, withAttribution } = this.props;

    return (
      <div
        role="img"
        className="lor-thumbnail"
        aria-label={image.description}
        style={{ backgroundColor: image.color || 'lightgray' }}
      >
        <CroppedImg
          className="lor-thumbnail-image"
          src={image.url}
          alt={image.description}
          onLoad={this.onLoad}
          onClick={this.onClick}
        />
        {withAttribution &&
          image.attributionName && (
          <div className="lor-thumbnail-attribution" tabIndex="-1">
            <div className="lor-thumbnail-attribution-name">
              <span className="lor-thumbnail-attribution-label">
                {formatMessage('By: ')}
              </span>
              <a
                key="attributionName"
                className="lor-thumbnail-attribution-link"
                href={image.attributionUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {image.attributionName}
              </a>
            </div>
            <div className="lor-thumbnail-attribution-license">
              <a
                className="lor-thumbnail-attribution-link"
                href={image.licenseUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {image.licenseName}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
