import React from 'react';
import PropTypes from 'prop-types';
import DeleteButton from './delete-button';
import EditButton from './edit-button';
import RouterStore from '../../stores/router';

class LearningObjectToolbar extends React.Component {
  static propTypes = {
    learningObjectId: PropTypes.string.isRequired,
    learningObjectTitle: PropTypes.string.isRequired,
    query: PropTypes.object
  }

  render () {
    return (
      <div className="Toolbar">
        <EditButton
          learningObjectId={this.props.learningObjectId}
          query={RouterStore.state.query}
        />
        <DeleteButton
          learningObjectId={this.props.learningObjectId}
          learningObjectTitle={this.props.learningObjectTitle}
          query={RouterStore.state.query}
        />
      </div>
    );
  }
}

export default LearningObjectToolbar;
