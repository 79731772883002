import React from 'react';
import formatMessage from 'format-message';
import sadLaptop from '../../../../assets/svg/sad-laptop.svg';

export default function SadLaptop ({ children }) {
  return (
    <div className="lor-error-layout-sad-laptop">
      <img
        alt={formatMessage('A sad laptop.')}
        src={sadLaptop}
      />
      {children}
    </div>
  );
}
