import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import IconBack from '../../icons/back';
import Link from '../../common/link';
import Loading from '../../common/loading';
import Error from '../../common/error';
import MemberList from './member-list';
import NameForm from './name-form';

export default createReactClass({
  displayName: 'Group',

  propTypes: {
    actions: PropTypes.shape({
      changeManagementStatus: PropTypes.func.isRequired,
      searchUsers: PropTypes.func.isRequired,
      resetSearchUsers: PropTypes.func.isRequired,
      createMember: PropTypes.func.isRequired,
      deleteMember: PropTypes.func.isRequired,
      changeName: PropTypes.func.isRequired,
      updateGroup: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.shape({
      group: PropTypes.shape({
        group: PropTypes.object,
        currentName: PropTypes.string,
        groupIsLoading: PropTypes.bool,
        groupIsLoaded: PropTypes.bool,
        groupLoadError: PropTypes.object
      }),
      users: PropTypes.shape({
        users: PropTypes.array,
        usersAreLoading: PropTypes.bool,
        usersAreLoaded: PropTypes.bool,
        usersLoadError: PropTypes.object
      })
    }).isRequired,
    session: PropTypes.object.isRequired
  },

  shouldComponentUpdate (nextProps) {
    return nextProps.state !== this.props.state;
  },

  render () {
    const { actions, state, session } = this.props;
    const {
      group,
      currentName,
      groupIsLoading,
      groupIsLoaded,
      groupLoadError,
      members,
      membersAreLoading,
      membersAreLoaded,
      membersLoadError
    } = state.group;
    const users = this.props.state.users;

    return (
      <div className="AccountGroup">
        <div className="AccountGroup-header">
          <Link className="AccountGroup-back-link" href="/account/groups">
            <IconBack className="AccountGroup-back-icon" />
            {formatMessage('Back to All Groups')}
          </Link>
        </div>

        {groupIsLoading && (
          <div className="AccountGroup-loading">
            <Loading />
          </div>
        )}

        {groupLoadError && (
          <Error className="AccountGroup-error" error={groupLoadError} />
        )}

        {groupIsLoaded && group && (
          <main className="AccountGroup-main">
            <NameForm
              group={group}
              currentName={currentName}
              changeName={actions.changeName}
              updateGroup={actions.updateGroup}
              updateGroupPending={state.group.updateGroupPending}
              updateGroupSuccess={state.group.updateGroupSuccess}
              updateGroupError={state.group.updateGroupError}
            />
            <MemberList
              changeManagementStatus={actions.changeManagementStatus}
              createMember={actions.createMember}
              deleteMember={actions.deleteMember}
              groupId={group.id}
              groupName={currentName}
              members={members}
              membersAreLoading={membersAreLoading}
              membersAreLoaded={membersAreLoaded}
              membersLoadError={membersLoadError}
              searchUsers={actions.searchUsers}
              resetSearchUsers={actions.resetSearchUsers}
              users={users}
              session={session}
            />
          </main>
        )}
      </div>
    );
  }
});
