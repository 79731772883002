import React, { useEffect } from 'react';
import { getState } from '../store';
import CommonsAlert from './common/alert';
import { removeAlertById, removeAllAlerts } from '../actions/alert';
import { MAX_VISIBLE_ALERTS_COUNT } from '../constants/alert';

export default function AlertHandler () {
  const alert = getState().alert;

  useEffect(() => {
    return () => removeAllAlerts();
  }, []);

  if (!alert.currentAlerts || alert.currentAlerts.length === 0) {
    return null;
  }

  return (
    <div className="alert-container">
      {alert.currentAlerts.slice(0, MAX_VISIBLE_ALERTS_COUNT).map((alert, index) => (
        <CommonsAlert
          key={alert.id}
          type={alert.type}
          onDismiss={() => removeAlertById(alert.id)}
        >
          {alert.message}
        </CommonsAlert>
      ))}
    </div>
  );
}
