import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import Member from './member';
import CreateMember from './member-create';
import Loading from '../../common/loading';
import Error from '../../common/error';

export default createReactClass({
  displayName: 'GroupMemberList',

  propTypes: {
    changeManagementStatus: PropTypes.func.isRequired,
    createMember: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    resetSearchUsers: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    groupId: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
    members: PropTypes.array,
    membersAreLoading: PropTypes.bool,
    membersAreLoaded: PropTypes.bool,
    membersLoadError: PropTypes.object,
    users: PropTypes.object,
    session: PropTypes.object.isRequired
  },

  handleCreateMember (user) {
    this.props.createMember({
      groupId: this.props.groupId,
      user
    });
  },

  renderRow (member) {
    const { deleteMember, changeManagementStatus, session, groupName } = this.props;
    const disabled = !session.isAdmin && member.userId === session.userId;
    return (
      <Member
        key={member.userId}
        member={member}
        deleteMember={deleteMember}
        changeManagementStatus={changeManagementStatus}
        disabled={disabled}
        groupName={groupName}
      />
    );
  },

  render () {
    const {
      members,
      membersAreLoading,
      membersAreLoaded,
      membersLoadError,
      searchUsers,
      resetSearchUsers,
      users
    } = this.props;

    return (
      <div className="GroupMemberList">
        <CreateMember
          members={members}
          onCreateMember={this.handleCreateMember}
          onSearchUsers={searchUsers}
          resetSearchUsers={resetSearchUsers}
          users={users}
        />

        {membersAreLoading && (
          <div className="GroupMemberList-loading">
            <Loading />
          </div>
        )}

        {membersLoadError && <Error error={membersLoadError} />}

        {membersAreLoaded && members && members.length > 0 && (
          <table className="GroupMemberList-table">
            <thead>
              <tr className="GroupMemberList-header-row">
                <th className="GroupMemberList-header GroupMemberList-manager-column">
                  {formatMessage('Manager')}
                </th>
                <th className="GroupMemberList-header GroupMemberList-name-column">
                  {formatMessage('User')}
                </th>
                <th className="GroupMemberList-header GroupMemberList-email-column">
                  {formatMessage('Email')}
                </th>
                <th className="GroupMemberList-header GroupMemberList-delete-column" />
              </tr>
            </thead>
            <tbody className="GroupMemberList-tbody">
              {members.map(this.renderRow)}
            </tbody>
          </table>
        )}
      </div>
    );
  }
});
