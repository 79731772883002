import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconEditLine } from '@instructure/ui-icons';

import Link from '../common/link';

class LearningObjectEditButton extends React.Component {
  render () {
    return (
      <Link
        className="lor-edit-button"
        href={`/resources/${this.props.learningObjectId}/edit`}
        renderIcon={IconEditLine}
      >
        {formatMessage('Edit Resource')}
      </Link>
    );
  }
}

LearningObjectEditButton.propTypes = {
  className: PropTypes.string,
  learningObjectId: PropTypes.string.isRequired,
  query: PropTypes.any
};

export default LearningObjectEditButton;
