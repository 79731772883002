import React from 'react';
import AlertFooter from './alert-footer';
import formatMessage from 'format-message';

const isDismissNeeded = status => status.error;
const getAlertVariant = status => {
  if (status.isImporting) {
    return 'info';
  }
  if (status.importResult) {
    return 'success';
  }
  return 'error';
};
export const getAlertMessage = status => {
  if (status.isImporting) {
    return formatMessage('Importing: {title}', { title: status.resource.title });
  }
  if (status.importResult) {
    return formatMessage('Imported: {title}', { title: status.resource.title });
  }
  return formatMessage('Failed to import: {title}', {
    title: status.resource.title
  });
};

const convertImportStatusToAlerts = (importStatus, onDismiss) => importStatus.map(status => ({
  variant: getAlertVariant(status),
  message: getAlertMessage(status),
  onDismiss: ev => onDismiss(status.resource),
  isDismissNeeded: isDismissNeeded(status),
  id: status.resource.id,
}));

export default function AlertFooterImportStatus (props) {
  const { importStatus, onDismiss } = props;
  return <AlertFooter alerts={convertImportStatusToAlerts(importStatus, onDismiss)} />;
}
