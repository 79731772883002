import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Alert } from '@instructure/ui-alerts';

const CommonsAlert = (props) => {
  const { type, children, ...rest } = props;

  return (
    <div className="lor-alert" data-testid="lor-alert">
      <Alert
        variant={type}
        renderCloseButtonLabel={formatMessage('Close')}
        liveRegionPoliteness="assertive"
        liveRegion={() => document.getElementById('screenreader-announcements-assertive')}
        {...rest}
      >
        {children}
      </Alert>
    </div>
  );
};

CommonsAlert.type = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning',
};

CommonsAlert.defaultProps = {
  timeout: 5000,
};

CommonsAlert.propTypes = {
  type: PropTypes.oneOf(Object.values(CommonsAlert.type)).isRequired,
  children: PropTypes.node.isRequired,
};

export default CommonsAlert;
