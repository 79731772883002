import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import formatMessage from 'format-message';
import * as announce from '../../../common/announce';
import Loading from '../../common/loading';
import router from '../../../router';

const CHARACTER_LIMIT = 50;

export default createReactClass({
  displayName: 'AccountGroupsCreate',

  propTypes: {
    name: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isCreating: PropTypes.bool
  },

  handleChange (event) {
    if (this.props.isCreating) {
      return;
    }
    this.props.onChange(event.target.value.slice(0, CHARACTER_LIMIT));
  },

  async handleKeyPress (event) {
    if (event.key !== 'Enter') {
      return;
    }

    const name = this.props.name.slice(0, CHARACTER_LIMIT).trim();
    if (name === '') {
      announce.assertively(formatMessage('Please name your group'));
      return;
    }
    announce.assertively(formatMessage('Creating {name}', { name }));

    const newGroup = await this.props.onCreate({ name });

    newGroup &&
      this._isMounted &&
      router.navigate(`/account/groups/${encodeURIComponent(newGroup.id)}`);
  },

  componentDidMount () {
    this._isMounted = true;
  },

  componentWillUnmount () {
    this._isMounted = false;
    announce.delayedReset();
  },

  render () {
    const { name, isCreating } = this.props;
    const inputClassName = classnames('AccountGroupsCreate-input', {
      'AccountGroupsCreate-input--readonly': isCreating
    });
    return (
      <div className="AccountGroupsCreate" data-heap-redact-text>
        <input
          className={inputClassName}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          maxLength={CHARACTER_LIMIT}
          placeholder={formatMessage('Create a new group')}
          type="text"
          value={name}
          aria-label={formatMessage(
            'Type the name of the new Group and press enter to create. This will take you to the group detail page'
          )}
          readOnly={isCreating}
        />
        {isCreating && <Loading className="AccountGroupsCreate-loading" />}
      </div>
    );
  }
});
