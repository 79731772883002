import formatMessage from 'format-message';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Head from 'react-helmet';
import classnames from 'classnames';
import tracker from '../common/analytics/analytics';
import Footer from './front/footer';
import { LoadingSpinner } from './common/loading-spinner';
import { AppHeader } from './app-header';
import AlertHandler from './alert-handler';
import DialogHandler from './dialog-handler';

const AppHandler = ({
  children,
  consortiums,
  groups,
  session,
  activeHeader,
  updates,
  logout,
  isManager
}) => {
  useEffect(
    () => {
      tracker.logPageView(location.pathname + location.hash);
    },
    [location.pathname, location.hash]
  );

  const refContainer = useRef(null);

  const isLoading =
    session.retrievalPending ||
    (consortiums && consortiums.listIsLoading) ||
    (groups && groups.listIsLoading);

  const appClassnames = classnames(
    'App',
    { ie11: !window.ActiveXObject && 'ActiveXObject' in window }
  );

  const skipToContent = (event) => {
    event.preventDefault();

    const container = refContainer.current;
    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <div className={appClassnames}>
      <Head titleTemplate={`%s | ${formatMessage('Canvas Commons')}`} />
      <a href="#" onClick={skipToContent} className="skip_navigation_link">{formatMessage('Skip to Commons Content')}</a>
      <AppHeader
        session={session}
        activeHeader={activeHeader}
        isManager={isManager}
        updates={updates}
        logout={logout}
      />
      <section className="App-main" ref={refContainer}>
        <div className="App-main-inner">
          <AlertHandler />
          <div className="App-main-inner-main">
            {isLoading ? (
              <div className="AppHandler--loading">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="App-main-inner-main-content">{children}</div>
            )}
            <Footer />
          </div>
          <DialogHandler />
        </div>
      </section>
    </div>
  );
};

AppHandler.propTypes = {
  children: PropTypes.any,
  consortiums: PropTypes.object,
  groups: PropTypes.object,
  session: PropTypes.object.isRequired,
  activeHeader: PropTypes.oneOf([
    'search',
    'share',
    'shared',
    'imported',
    'updates',
    'account',
    'favorites'
  ]),
  updates: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
};

export default AppHandler;
