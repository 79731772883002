import React from 'react';
import Message from './message';

export default {
  getInitialState () {
    return {
      errors: []
    };
  },

  addErrors (errors) {
    if (!Array.isArray(errors)) errors = [errors];

    this.setErrors(this.state.errors.concat(errors));
  },

  replaceErrors (errors) {
    if (!Array.isArray(errors)) errors = [errors];

    var newErrorIds = errors.map(err => {
      return err.param;
    });
    var oldErrorsWithoutNewErrors = this.state.errors.filter(err => {
      return err.param && newErrorIds.indexOf(err.param) === -1;
    });

    this.setErrors(oldErrorsWithoutNewErrors.concat(errors));
  },

  componentDidMount () {
    this._isMounted = true;
  },

  componentWillUnmount () {
    this._isMounted = false;
  },

  setErrors (errors) {
    if (!this._isMounted) {
      return;
    }
    if (!Array.isArray(errors)) errors = [errors];

    this.setState({
      errors: errors
    });
  },

  setErrorsExcluding (errors, ids) {
    if (!Array.isArray(ids)) {
      throw new Error('ids must be an array of ids to exclude');
    }

    if (!Array.isArray(errors)) errors = [errors];

    var oldErrorsMatchingIdsToRemember = this.state.errors.filter(err => {
      return err.param && ids.indexOf(err.param) > -1;
    });
    this.setErrors(oldErrorsMatchingIdsToRemember.concat(errors));
  },

  removeErrorsWithId (id) {
    var errorsWithoutId = this.state.errors.filter(err => {
      return err.param && err.param !== id;
    });
    this.setErrors(errorsWithoutId);
  },

  clearErrors () {
    if (!this._isMounted) return;
    this.setState({ errors: [] });
  },

  hasErrors () {
    return this.state.errors.length > 0;
  },

  hasErrorsWithId (id) {
    return this.getErrorsWithId(id).length > 0;
  },

  getErrors () {
    return this.state.errors;
  },

  getErrorsWithId (id) {
    // TODO: update when serializer changes
    return this.state.errors.filter(
      err => err.param === id || err.dataPath === id
    );
  },

  getFirstErrorMsgWithId (id) {
    var errors = this.getErrorsWithId(id);
    return errors[0] ? errors[0].msg : null;
  },

  renderError (err) {
    return (
      <Message key={err.message || err.msg} type="error2 message-with-icon">
        {err.message || err.msg}
      </Message>
    );
  },

  renderErrors () {
    if (this.hasErrors()) {
      return this.getErrors().map(this.renderError);
    }
  },

  renderErrorsWithId (id) {
    if (this.hasErrorsWithId(id)) {
      return this.getErrorsWithId(id).map(this.renderError);
    }
  }
};
