import React from 'react';
import formatMessage from 'format-message';
import SadPanda from '../../common/sad-panda';
import * as announce from '../../../common/announce';

export default class AccountGroupsNoResults extends React.Component {
  static displayName = 'AccountGroupsNoResults';

  componentDidMount () {
    // polite to try and have the message read after voice over reads the last
    // word that was typed
    announce.politely(formatMessage('Sorry, there are no matching groups'));
  }

  componentWillUnmount () {
    // without this it will not announce after the first time
    announce.delayedReset();
  }

  render () {
    return (
      <div className="AccountGroupsNoResults">
        <p className="AccountGroupsNoResults-message">{formatMessage('Sorry, there are no matching groups')}</p>
        <SadPanda />
      </div>
    );
  }
}
