import compare from '../common/natural-compare';
import {
  RESET_CONSORTIUM,
  LOAD_CONSORTIUM_START,
  LOAD_CONSORTIUM_SUCCESS,
  LOAD_CONSORTIUM_FAILURE,
  CHANGE_NAME,
  UPDATE_CONSORTIUM_START,
  UPDATE_CONSORTIUM_SUCCESS,
  UPDATE_CONSORTIUM_FAILURE,
  CREATE_MEMBER_START,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  DELETE_MEMBER_START,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  CHANGE_MANAGEMENT_STATUS_START,
  CHANGE_MANAGEMENT_STATUS_SUCCESS,
  CHANGE_MANAGEMENT_STATUS_FAILURE
} from '../actions/consortium';

import Error from './error-record';

export const initialState = {
  consortium: null,
  currentName: '',
  members: [],

  consortiumIsLoading: false,
  consortiumIsLoaded: false,
  consortiumLoadError: null,

  createMemberPending: false,
  createMemberSuccess: false,
  createMemberError: null,

  deleteMemberPending: false,
  deleteMemberSuccess: false,
  deleteMemberError: null,

  updateConsortiumPending: false,
  updateConsortiumSuccess: false,
  updateConsortiumError: null,

  changeContributorStatusPending: false,
  changeContributorStatusSuccess: false,
  changeContributorStatusError: null
};

export default function consortiumReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_CONSORTIUM:
      return initialState;

    case LOAD_CONSORTIUM_START:
      return {
        ...state,
        consortiumIsLoading: true,
        consortiumIsLoaded: false,
        consortiumLoadError: null,
        consortium: null
      };

    case LOAD_CONSORTIUM_SUCCESS:
      return {
        ...state,
        consortiumIsLoading: false,
        consortiumIsLoaded: true,
        consortiumLoadError: null,
        consortium: action.consortium,
        currentName: action.consortium.name,
        members: action.members
      };

    case LOAD_CONSORTIUM_FAILURE:
      return {
        ...state,
        consortiumIsLoading: false,
        consortiumIsLoaded: false,
        consortiumLoadError: new Error(action.error),
        consortium: null
      };

    case CHANGE_NAME:
      return {
        ...state,
        updateConsortiumSuccess: false,
        updateConsortiumError: null,
        currentName: action.name
      };

    case UPDATE_CONSORTIUM_START:
      return {
        ...state,
        updateConsortiumPending: true,
        updateConsortiumSuccess: false,
        updateConsortiumError: null
      };

    case UPDATE_CONSORTIUM_SUCCESS:
      return {
        ...state,
        consortium: action.consortium,
        updateConsortiumPending: false,
        updateConsortiumSuccess: true,
        updateConsortiumError: null
      };

    case UPDATE_CONSORTIUM_FAILURE:
      return {
        ...state,
        updateConsortiumPending: false,
        updateConsortiumSuccess: false,
        updateConsortiumError: new Error(action.error)
      };

    case CREATE_MEMBER_START:
      return {
        ...state,
        createMemberPending: true,
        createMemberSuccess: false,
        createMemberError: null
      };

    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        createMemberPending: false,
        createMemberSuccess: true,
        createMemberError: null,
        members: state.members
          .concat(action.member)
          .sort((a, b) => compare(a.accountName, b.accountName))
      };

    case CREATE_MEMBER_FAILURE:
      return {
        ...state,
        createMemberPending: false,
        createMemberSuccess: false,
        createMemberError: new Error(action.error)
      };

    case DELETE_MEMBER_START:
      return {
        ...state,
        deleteMemberPending: true,
        deleteMemberSuccess: false,
        deleteMemberError: null
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMemberPending: false,
        deleteMemberSuccess: true,
        deleteMemberError: null,
        members: state.members.filter((member) => member.id !== action.member.id)
      };

    case DELETE_MEMBER_FAILURE:
      return {
        ...state,
        deleteMemberPending: false,
        deleteMemberSuccess: false,
        deleteMemberError: new Error(action.error)
      };

    case CHANGE_MANAGEMENT_STATUS_START:
      return {
        ...state,
        changeContributorStatusPending: true,
        changeContributorStatusSuccess: false,
        changeContributorStatusError: null
      };

    case CHANGE_MANAGEMENT_STATUS_SUCCESS:
      return {
        ...state,
        changeContributorStatusPending: false,
        changeContributorStatusSuccess: true,
        changeContributorStatusError: null,
        members: state.members.map((member) => {
          if (member.id === action.membership.id) {
            return action.membership;
          }
          return member;
        })
      };

    case CHANGE_MANAGEMENT_STATUS_FAILURE:
      return {
        ...state,
        changeContributorStatusPending: false,
        changeContributorStatusSuccess: false,
        changeContributorStatusError: new Error(action.error)
      };

    default:
      return state;
  }
}
