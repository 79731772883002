import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { RadioInput, RadioInputGroup } from '@instructure/ui-radio-input';
import { Checkbox } from '@instructure/ui-checkbox';
import { Text } from '@instructure/ui-text';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import compare from '../../common/natural-compare';
import ResourceTypePicker from './resource-type-picker';
import usGrades from '../resources/forms/grade-schemes/us';
import ContentCategoryTypeSelector from '../resources/forms/content-category-type-selector';

const GRADE_COLUMNS = 2;
const GRADE_COLUMN_ITEMS = Math.round(usGrades.length / GRADE_COLUMNS);

export default class SearchFilters extends React.Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    types: PropTypes.arrayOf(PropTypes.string),
    gradeIds: PropTypes.arrayOf(PropTypes.string),
    scopeIds: PropTypes.arrayOf(PropTypes.string),
    showPublic: PropTypes.bool,
    canShowPublic: PropTypes.bool,
    canShowApprovedContent: PropTypes.bool,
    canShowAllManagedContent: PropTypes.bool,
    canShowAccountContent: PropTypes.bool,
    canShowConsortiumContent: PropTypes.bool,
    consortiums: PropTypes.object,
    groups: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    types: [],
    gradeIds: [],
    scopeIds: [],
  };

  handleGradeLevelChange = (event) => {
    const gradeIds = Array.from(this.props.gradeIds);

    if (event.target.checked === true) {
      gradeIds.push(event.target.value);
    } else {
      gradeIds.splice(gradeIds.indexOf(event.target.value), 1);
    }

    this.props.onChange({
      gradeIds
    });
  };

  handleScopeChange = (event, value) => {
    const curatedScope = `curated-${this.props.account.id}`;
    let searchScope;
    let scopeIds = [...this.props.scopeIds] || [];

    if (event.target.value === 'onlyApprovedContent') {
      // Toggle
      if (event.target.checked && scopeIds.indexOf(curatedScope) === -1) {
        scopeIds.push(curatedScope);
      } else if (!event.target.checked && scopeIds.indexOf(curatedScope) !== -1) {
        scopeIds.splice(scopeIds.indexOf(curatedScope), 1);
      }

      searchScope = { scopeIds };
    } else {
      scopeIds = scopeIds.filter((scope) => scope === curatedScope); // keep curated if set, remove everything else

      if (value === 'PUBLIC') {
        searchScope = { scopeIds, showPublic: true };
      } else if (value === 'ALLMANAGED') {
        searchScope = { scopeIds };
      } else {
        scopeIds.push(value);
        searchScope = { scopeIds };
      }
    }

    this.props.onChange(searchScope);
  };

  renderGradesColumns = () => {
    const { gradeIds = [] } = this.props;

    return Array(GRADE_COLUMNS)
      .fill()
      .map((_, start) => {
        start *= GRADE_COLUMN_ITEMS;

        return (
          <div
            key={`gradeColumn-${start}`}
            className="gradeColumn"
            style={{ width: `${100 / GRADE_COLUMNS}%` }}
          >
            {usGrades.slice(start, start + GRADE_COLUMN_ITEMS).map((grade) => (
              <div key={`gradeLevel-${grade.value}`} className="checkbox-item">
                <Checkbox
                  label={grade.label}
                  value={grade.value}
                  name={`gradeLevel-${grade.value}`}
                  onChange={this.handleGradeLevelChange}
                  checked={gradeIds.indexOf(grade.value) >= 0}
                  inline
                />
              </div>
            ))}
          </div>
        );
      });
  };

  render () {
    const {
      types = [],
      showPublic = 'true',
      account: { id: accountId = false, name: accountName = false },
      groups,
      consortiums: { list: consortiumsList = [] },
      scopeIds = [],
      canShowPublic = false,
      canShowApprovedContent = false,
      canShowAllManagedContent = false,
      canShowAccountContent = true,
      canShowConsortiumContent = true,
      showTypes = true,
      checkboxSize = 'medium',
      contentCategoryTypes = []
    } = this.props;

    const curatedScope = `curated-${this.props.account.id}`;
    const filteredScopes = scopeIds.filter((scope) => scope !== curatedScope);

    const selectedScope =
      filteredScopes[0] ||
      (canShowAllManagedContent
        ? 'ALLMANAGED'
        : canShowPublic || showPublic === 'true'
          ? 'PUBLIC'
          : accountId || 'PUBLIC');

    return (
      <div className="SearchFilters">
        {canShowApprovedContent && (
          <div className="SearchFilters-toggleApprovedResources">
            <Checkbox
              checked={scopeIds && scopeIds.indexOf(curatedScope) !== -1}
              label={formatMessage('Only {institution} Approved Resources', {
                institution: accountName,
              })}
              onChange={this.handleScopeChange}
              size={checkboxSize}
              value="onlyApprovedContent"
              variant="toggle"
              inline
            />
          </div>
        )}

        {showTypes && (
          <h4 className="SearchFilters-subtitle">{formatMessage('View only these types')}</h4>
        )}

        {showTypes && (
          <ResourceTypePicker onChange={(types) => this.props.onChange({ types })} value={types} />
        )}

        <div className="ContentCategoryTypeSelector">
          <ContentCategoryTypeSelector
            pluralLables
            label={
              <span>
                <Text weight="bold">{formatMessage('Content Types')}</Text>
              </span>
            }
            value={contentCategoryTypes}
            onChange={(contentCategoryTypes) => this.props.onChange({ contentCategoryTypes })}
          />
        </div>

        <fieldset>
          <ScreenReaderContent as="legend">
            {formatMessage('Grade/Levels')}
          </ScreenReaderContent>
          <h4 className="SearchFilters-subtitle">
            <Text>
              {formatMessage('Grade/Levels')}
            </Text>
          </h4>
          <div className="GradeColumns">
            {this.renderGradesColumns()}
          </div>
        </fieldset>

        <div className="GroupsFilters">
          <RadioInputGroup
            name="shared-with"
            onChange={this.handleScopeChange}
            value={selectedScope}
            description={formatMessage('Shared With')}
          >
            {canShowPublic && (
              <RadioInput
                label={formatMessage('All (Includes Public Resources)')}
                key="showPublic"
                value="PUBLIC"
                inline
              />
            )}

            {canShowAllManagedContent && (
              <RadioInput
                label={formatMessage('All Managed')}
                key="showAllManaged"
                value="ALLMANAGED"
                inline
              />
            )}

            {canShowAccountContent && accountId && (
              <RadioInput data-heap-redact-text label={accountName} key={accountId} value={accountId} inline />
            )}

            {groups &&
              groups.map((group) => {
                return (
                  <RadioInput
                    data-heap-redact-text
                    label={group.name}
                    key={`group-${group.id}`}
                    value={`group-${group.id}`}
                    inline
                  />
                );
              })}

            {canShowConsortiumContent &&
              consortiumsList
                .filter((consortium) => consortium.membershipStatus === 'member')
                .sort((a, b) => compare(a.name, b.name))
                .map((consortium) => {
                  return (
                    <RadioInput
                      data-heap-redact-text
                      label={consortium.name}
                      key={`consortium-${consortium.id}`}
                      value={`consortium-${consortium.id}`}
                      inline
                    />
                  );
                })}
          </RadioInputGroup>
        </div>
      </div>
    );
  }
}
