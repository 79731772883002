import formatMessage from 'format-message';

export default [
  /* {
    label: 'Preschool',
    abbreviation: 'Pre',
    value: 'Pre',
    flex: 1,
  }, */
  {
    label: formatMessage({ default: 'Kindergarten', id: 'grades_us_k', description: 'Kindergarten option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: 'K', id: 'grades_us_k_abbr', description: 'Abbreviation for kindergarten option for selecting a U.S. grade level' }),
    value: 'K',
    flex: 1
  },
  {
    label: formatMessage({ default: '1st grade', id: 'grades_us_1', description: '1st grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '1', id: 'grades_us_1_abbr', description: 'Abbreviation for 1st grade option for selecting a U.S. grade level' }),
    value: '1',
    flex: 1
  },
  {
    label: formatMessage({ default: '2nd grade', id: 'grades_us_2', description: '2nd grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '2', id: 'grades_us_2_abbr', description: 'Abbreviation for 2nd grade option for selecting a U.S. grade level' }),
    value: '2',
    flex: 1
  },
  {
    label: formatMessage({ default: '3rd grade', id: 'grades_us_3', description: '3rd grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '3', id: 'grades_us_3_abbr', description: 'Abbreviation for 3rd grade option for selecting a U.S. grade level' }),
    value: '3',
    flex: 1
  },
  {
    label: formatMessage({ default: '4th grade', id: 'grades_us_4', description: '4th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '4', id: 'grades_us_4_abbr', description: 'Abbreviation for 4th grade option for selecting a U.S. grade level' }),
    value: '4',
    flex: 1
  },
  {
    label: formatMessage({ default: '5th grade', id: 'grades_us_5', description: '5th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '5', id: 'grades_us_5_abbr', description: 'Abbreviation for 5th grade option for selecting a U.S. grade level' }),
    value: '5',
    flex: 1
  },
  {
    label: formatMessage({ default: '6th grade', id: 'grades_us_6', description: '6th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '6', id: 'grades_us_6_abbr', description: 'Abbreviation for 6th grade option for selecting a U.S. grade level' }),
    value: '6',
    flex: 1
  },
  {
    label: formatMessage({ default: '7th grade', id: 'grades_us_7', description: '7th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '7', id: 'grades_us_7_abbr', description: 'Abbreviation for 7th grade option for selecting a U.S. grade level' }),
    value: '7',
    flex: 1
  },
  {
    label: formatMessage({ default: '8th grade', id: 'grades_us_8', description: '8th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '8', id: 'grades_us_8_abbr', description: 'Abbreviation for 8th grade option for selecting a U.S. grade level' }),
    value: '8',
    flex: 1
  },
  {
    label: formatMessage({ default: '9th grade', id: 'grades_us_9', description: '9th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '9', id: 'grades_us_9_abbr', description: 'Abbreviation for 9th grade option for selecting a U.S. grade level' }),
    value: '9',
    flex: 1
  },
  {
    label: formatMessage({ default: '10th grade', id: 'grades_us_10', description: '10th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '10', id: 'grades_us_10_abbr', description: 'Abbreviation for 10th grade option for selecting a U.S. grade level' }),
    value: '10',
    flex: 1
  },
  {
    label: formatMessage({ default: '11th grade', id: 'grades_us_11', description: '11th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '11', id: 'grades_us_11_abbr', description: 'Abbreviation for 11th grade option for selecting a U.S. grade level' }),
    value: '11',
    flex: 1
  },
  {
    label: formatMessage({ default: '12th grade', id: 'grades_us_12', description: '12th grade option for selecting a U.S. grade level' }),
    abbreviation: formatMessage({ default: '12', id: 'grades_us_12_abbr', description: 'Abbreviation for 12th grade option for selecting a U.S. grade level' }),
    value: '12',
    flex: 1
  },
  {
    label: formatMessage({ default: 'Undergraduate', description: 'Undergraduate option for selecting a grade level' }),
    value: 'HE',
    flex: 3
  },
  {
    label: formatMessage({ default: 'Graduate', description: 'Graduate option for selecting a grade level' }),
    value: 'G',
    flex: 3
  }
];
