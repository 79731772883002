import React from 'react';
import { Pagination } from '@instructure/ui-pagination';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage from 'format-message';

const shouldShowPagination = ({ totalPages, currentPage }) => {
  return totalPages > 0 && currentPage > 0 && currentPage <= totalPages;
};

const ResultPagination = ({ totalPages, currentPage, onPageChange, hasTooManyResults, ...rest }) => {
  if (!shouldShowPagination({ totalPages, currentPage })) {
    return null;
  }

  const onResultPaginationPageChange = (currentPage, previousPage) => {
    if (currentPage === previousPage) {
      return;
    }
    const isPreviousPageNavigation = currentPage === previousPage - 1;
    onPageChange(currentPage, { isPreviousPageNavigation });
  };

  return (
    <div className={classnames({ 'result-pagination--too-many-results': hasTooManyResults })}>
      <Pagination
        as="nav"
        margin="mediumSmall 0"
        variant="input"
        labelNumberInput={
          hasTooManyResults
            ? (pages) => formatMessage('of over {limit}', { limit: pages - 1 })
            : undefined
        }
        currentPage={currentPage}
        totalPageNumber={totalPages}
        data-testid="ResultPagination"
        onPageChange={onResultPaginationPageChange}
        {...rest}
      />
    </div>
  );
};

ResultPagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  hasTooManyResults: PropTypes.bool,
};

export default ResultPagination;
