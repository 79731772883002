import debounce from 'lodash/function/debounce';
import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconFilterLine } from '@instructure/ui-icons';

import SearchFilters from './search-filters';
import SearchInput from './input';
import Tray from '../common/tray';
import AnalyticsActions from '../../actions/analytics';
import {
  SORT_RECENT,
  SORT_MATCHING,
  SORT_APPROVED,
  SORT_FAVORITE,
  SORT_DOWNLOADS,
  SORT_TITLE,
  SORT_AUTHOR,
  SORT_EMAIL,
  SORT_REPORTS,
  SORTING_ORDER_ASCENDING,
  SORTING_ORDER_DESCENDING
} from '../../constants/search';
import Button from '../common/button';
import SearchSortingSelect from './sorting/search-sorting-select';
import { hideFilterTray, showFilterTray } from '../../actions/filter';

export default class SearchOptions extends React.Component {
  static displayName = 'SearchOptions'

  static propTypes = {
    account: PropTypes.object.isRequired,
    searchPrivateObjects: PropTypes.bool,
    searchFavoriteObjects: PropTypes.bool,
    queryPlaceholder: PropTypes.string,
    queryArialLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    gradeIds: PropTypes.arrayOf(PropTypes.string),
    scopeIds: PropTypes.arrayOf(PropTypes.string),
    subjectIds: PropTypes.arrayOf(PropTypes.string),
    contentCategoryTypes: PropTypes.arrayOf(PropTypes.string),
    sortBy: PropTypes.oneOf([
      SORT_RECENT,
      SORT_MATCHING,
      SORT_APPROVED,
      SORT_FAVORITE,
      SORT_DOWNLOADS,
      SORT_REPORTS,
      SORT_TITLE,
      SORT_AUTHOR,
      SORT_EMAIL
    ]),
    sortOrder: PropTypes.oneOf([SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING]),
    sortingOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    showPublic: PropTypes.bool,
    canShowPublic: PropTypes.bool,
    canShowFeatured: PropTypes.bool,
    canShowApprovedContent: PropTypes.bool,
    consortiums: PropTypes.object,
    groups: PropTypes.arrayOf(PropTypes.object),
    extraSortOptions: PropTypes.bool,
    disableSorting: PropTypes.bool,
  }

  static defaultProps = {
    query: '',
    disableSorting: false,
  }

  static FilterButton ({
    className,
    expanded,
    label,
    hideLabel,
    showLabel,
    ...rest
  }) {
    return (
      <div className="SearchOptions-filterButton">
        <Button
          {...rest}
          className="SearchOptions-filter"
          display={Button.display.block}
          renderIcon={IconFilterLine}
          screenReaderLabel={expanded ? hideLabel : showLabel}
        >
          {label}
        </Button>
      </div>
    );
  }

  constructor (props) {
    super(props);
    this.throttleUpdateQuery = debounce(this.updateQuery.bind(this), 400);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.toggleFiltersPanel = this.toggleFiltersPanel.bind(this);
  }

  handleQueryChange (event) {
    this.throttleUpdateQuery(event.target.value);
  }

  updateQuery (query) {
    this.props.onChange({ query });
    AnalyticsActions.newSearchedWord(query);
  }

  componentWillUnmount () {
    this.throttleUpdateQuery.cancel();
    hideFilterTray();
  }

  toggleFiltersPanel () {
    if (this.props.showFilters) {
      hideFilterTray();
    } else {
      showFilterTray();
    }
  }

  render () {
    const { queryPlaceholder, queryArialLabel, query } = this.props;

    return (
      <React.Fragment>
        <div className="SearchOptions App-main-inner-main-content-width" role="banner" aria-live="polite" aria-atomic="true">
          <SearchInput
            onChange={this.handleQueryChange}
            defaultValue={query}
            placeholder={queryPlaceholder}
            aria-label={queryArialLabel}
          />

          <div className="SearchOptions-sortBy">
            <SearchSortingSelect
              onSortByChange={this.props.onChange}
              sortBy={this.props.sortBy}
              sortOrder={this.props.sortOrder}
              sortingOptions={this.props.sortingOptions}
              disabled={this.props.disableSorting}
            />
          </div>

          <SearchOptions.FilterButton
            expanded={this.props.showFilters}
            showLabel={formatMessage('Show filters')}
            hideLabel={formatMessage('Hide filters')}
            aria-controls="SearchOptions-filtersOptions--tray"
            label={formatMessage({
              default: 'Filter',
              description:
                'Filter the search results, like course, module, video and grade levels like, 1st grade, undergraduate, etc.'
            })}
            onClick={this.toggleFiltersPanel}
          />
        </div>
        <Tray
          id="SearchOptions-filtersOptions"
          label={formatMessage('Filter Results')}
          className="SearchOptions-panel"
          show={this.props.showFilters}
          onClose={() => hideFilterTray()}
        >
          <SearchFilters {...this.props} />
        </Tray>
      </React.Fragment>
    );
  }
}
