import formatMessage from 'format-message';

const TITLES = {
  copyright_reserve: formatMessage('Copyrighted'),
  public_domain: formatMessage('Public Domain'),
  attribution: formatMessage('CC - Attribution'),
  attribution_sharealike: formatMessage('CC - Attribution ShareAlike'),
  attribution_noderivs: formatMessage('CC - Attribution NoDerivs'),
  attribution_noncommercial: formatMessage('CC - Attribution NonCommercial'),
  attribution_noncommercial_sharealike: formatMessage('CC - Attribution NonCommercial ShareAlike'),
  attribution_noncommercial_noderivs: formatMessage('CC - Attribution NonCommercial NoDerivs')
};

export default function getTitle (id) {
  return TITLES[id];
}
