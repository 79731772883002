import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage from 'format-message';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import Close from '../icons/close';
import Link from './link';

export default class NotifyMessage extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  static propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    kind: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func
  }

  static defaultProps = {
    kind: 'primary'
  }

  onKeyUp (evt) {
    var esc = 27;

    if (evt.which === esc) {
      this.props.onClose();
    }
  }

  render () {
    var classes = classnames(
      'NotifyMessage',
      this.props.className,
      `NotifyMessage--${this.props.kind}`
    );

    return (
      <div
        ref="message"
        tabIndex="0"
        onKeyUp={this.onKeyUp}
        className={classes}
      >
        {this.props.onClose && (
          <Link
            className="NotifyMessage-close"
            onClick={this.props.onClose}
          >
            <ScreenReaderContent>
              {formatMessage('Close Alert')}
            </ScreenReaderContent>
            <Close width="14" height="14" />
          </Link>
        )}

        {this.props.title && (
          <span className="NotifyMessage-title">{this.props.title}</span>
        )}

        {this.props.icon && (
          <div className="NotifyMessage-icon">{this.props.icon}</div>
        )}

        <div className="NotifyMessage-message">{this.props.children}</div>
      </div>
    );
  }
}
