import formatMessage from 'format-message';
import thumbnailProcessing from '../../assets/img/thumbnail-processing.png';

export const showOutcomes = false;

export const grades = {
  higherEdId: 'HE'
};
export const introBannerDisplayCount = 3;
export const scope = {
  public: 'PUBLIC'
};
export const exportStatus = {
  incomplete: 'incomplete',
  failed: 'failed',
  complete: 'complete'
};
export const lti = {
  badConfigMsgs: {
    ACCOUNT: formatMessage('This account is not setup to connect to Commons.'),
    INVALID_LAUNCH: formatMessage(
      'The Canvas launch of Commons does not contain the required information.'
    ),
    SIGNING: formatMessage(
      'The account/tool security configuration is incorrect.'
    )
  }
};
export const resourceTypes = {
  course: {
    alt: formatMessage('Course'),
    singularName: formatMessage('Course'),
    pluralName: formatMessage('Courses'),
    heading: formatMessage('Course Information'),
    pluralSelected: formatMessage('Courses selected')
  },
  quiz: {
    alt: formatMessage('Classic Quiz'),
    singularName: formatMessage('Classic Quiz'),
    pluralName: formatMessage('Classic Quizzes'),
    heading: formatMessage('Classic Quiz Information'),
    pluralSelected: formatMessage('Classic Quizzes selected')
  },
  quizzesnext: {
    alt: formatMessage('New Quiz'),
    singularName: formatMessage('New Quiz'),
    pluralName: formatMessage('New Quizzes'),
    heading: formatMessage('New Quiz Information'),
    pluralSelected: formatMessage('New Quizzes selected')
  },
  module: {
    alt: formatMessage('Module'),
    singularName: formatMessage('Module'),
    pluralName: formatMessage('Modules'),
    heading: formatMessage('Module Information'),
    pluralSelected: formatMessage('Modules selected')
  },
  assignment: {
    alt: formatMessage('Assignment'),
    singularName: formatMessage('Assignment'),
    pluralName: formatMessage('Assignments'),
    heading: formatMessage('Assignment Information'),
    pluralSelected: formatMessage('Assignments selected')
  },
  discussion_topic: {
    alt: formatMessage('Discussion'),
    singularName: formatMessage('Discussion'),
    pluralName: formatMessage('Discussions'),
    heading: formatMessage('Discussion Information'),
    pluralSelected: formatMessage('Discussions selected')
  },
  page: {
    alt: formatMessage('Page'),
    singularName: formatMessage('Page'),
    pluralName: formatMessage('Pages'),
    heading: formatMessage('Page Information'),
    pluralSelected: formatMessage('Pages selected')
  },
  document: {
    alt: formatMessage('Document'),
    singularName: formatMessage('Document'),
    pluralName: formatMessage('Documents'),
    heading: formatMessage('Document Information'),
    pluralSelected: formatMessage('Documents selected')
  },
  video: {
    alt: formatMessage('Video'),
    singularName: formatMessage('Video'),
    pluralName: formatMessage('Videos'),
    heading: formatMessage('Video Information'),
    pluralSelected: formatMessage('Videos selected')
  },
  image: {
    alt: formatMessage('Image'),
    singularName: formatMessage('Image'),
    pluralName: formatMessage('Images'),
    heading: formatMessage('Image Information'),
    pluralSelected: formatMessage('Images selected')
  },
  audio: {
    alt: formatMessage('Audio'),
    singularName: formatMessage('Audio'),
    pluralName: formatMessage('Audio'),
    heading: formatMessage('Audio Information'),
    pluralSelected: formatMessage('Audio selected')
  },

  /* for module preview */
  context_external_tool: {
    alt: formatMessage('LTI Tool')
  },
  discussion: {
    alt: formatMessage('Discussion')
  },
  file: {
    alt: formatMessage('File')
  },
  link: {
    alt: formatMessage('Link')
  },
  weblink: {
    alt: formatMessage('Link')
  }
};
export const learningObjectTypes = resourceTypes;

export const tempThumbnails = {
  image: thumbnailProcessing
};

export default {
  grades,
  introBannerDisplayCount,
  scope,
  exportStatus,
  lti,
  learningObjectTypes,
  resourceTypes,
  tempThumbnails
};
