import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import Link from '../../common/link';
import Crop from '../../common/cropper';
import Button from '../../common/button';

export default class ImageCropper extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.getCroppedImage = this.getCroppedImage.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);

    this.state = {
      cropping: false
    };
  }

  static propTypes = {
    image: PropTypes.any.isRequired,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onImageLoaded: PropTypes.func
  }

  static defaultProps = {
    minWidth: 262,
    minHeight: 147
  }

  componentDidMount () {
    this.refs.cropText.focus();
    document.addEventListener('keyup', this.handleKey);
  }

  getCroppedImage () {
    if (!this.state.cropping) {
      this.setState({ cropping: true }, async function () {
        const image = await this.refs.crop.cropImage();
        this.props.onDone(image);
      });
    }
  }

  imageLoaded (image) {
    this.props.onImageLoaded(image);
  }

  handleKey (event) {
    if (event.key === 'Escape') {
      this.setState({
        cropping: false
      });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keyup', this.handleKey);
  }

  render () {
    return (
      <div className="ImageCropper">
        <span className="ImageCropper-wrapper">
          <ScreenReaderContent>
            <span tabIndex="0" ref="cropText">
              {formatMessage('Crop the image')}
            </span>
          </ScreenReaderContent>
          <Crop
            image={this.props.image}
            width={this.props.minWidth}
            height={this.props.minHeight}
            ref="crop"
            onImageLoaded={this.imageLoaded}
          />
        </span>
        <div className="ImageCropper-message-wrapper">
          <p className="ImageCropper-message">
            {formatMessage(
              'To crop image, either drag the selection or use your arrow keys. Hold shift to resize.'
            )}
          </p>
          <div className="ImageCropper-button-group">
            <Link onClick={this.props.onCancel}>
              {formatMessage('Cancel')}
            </Link>
            <Button color={Button.color.primary} onClick={this.getCroppedImage}>
              {formatMessage('Save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
