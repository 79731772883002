import { UPDATE_SHOW_FILTER_TRAY_ACTION } from '../constants/filter';
import { dispatch } from '../store';

export const showFilterTray = () => {
  return dispatch({
    type: UPDATE_SHOW_FILTER_TRAY_ACTION,
    payload: true
  });
};

export const hideFilterTray = () => {
  return dispatch({
    type: UPDATE_SHOW_FILTER_TRAY_ACTION,
    payload: false
  });
};
