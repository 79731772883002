import consoleTracker from './console-tracker';
import noTracker from './no-tracker';

const env = process.env.ENV;

export default (env === 'production'
  ? noTracker
  : env === 'test'
    ? noTracker
    : consoleTracker);
