const getSiblingPreviewCard = (element) => {
  const parent = (element.closest('.ConfirmOptions') || {}).parentElement;
  return parent && (parent.previousSibling || parent.nextSibling);
};

export const focusSiblingPreviewCard = (element) => {
  const sibling = getSiblingPreviewCard(element);
  if (sibling) {
    sibling.querySelector('a').focus();
  }
};
