export const SORT_RECENT = 'date';
export const SORT_RATING = 'rating';
export const SORT_MATCHING = 'relevance';
export const SORT_FAVORITE = 'favorite';
export const SORT_DOWNLOADS = 'downloads';
export const SORT_REPORTS = 'reports';
export const SORT_APPROVED = 'approved';
export const SORT_TITLE = 'title';
export const SORT_AUTHOR = 'authorName';
export const SORT_EMAIL = 'authorEmail';

export const SORTING_ORDER_ASCENDING = 'asc';
export const SORTING_ORDER_DESCENDING = 'desc';
