import formatMessage from 'format-message';
import { replaceErrors } from './replace';

export const resourceErrors = {
  'learning-object-required': formatMessage('Root resources array is required'),
  'title-minLength': formatMessage('Title is required'),
  'title-maxLength': formatMessage(
    'Title length must be a maximum of 250 characters in length'
  ),
  'description-minLength': formatMessage('Description is required'),
  'description-maxLength': formatMessage(
    'Description length must be a maximum of 2000 characters in length'
  ),
  'author-id-required': formatMessage('Author id required'),
  'author-name-required': formatMessage('Author name required'),
  'author-required': formatMessage('At least one author is required'),
  'account-required': formatMessage('Account is required'),
  'account-id-required': formatMessage('Account id is required'),
  'account-name-required': formatMessage('Account name is required'),
  'account-invalid': formatMessage('Invalid account option'),
  'tags-maxItems': formatMessage('A maximum of 10 tags are allowed'),
  'tags-unique': formatMessage('Tags must be unique'),
  'tag-malformed': formatMessage('Tag malformed'),
  'tag-length': formatMessage(
    'Each tag must be between 3 and 100 characters in length'
  ),
  'scope-ids-invalid': formatMessage('Invalid sharing option(s)'),
  'scope-ids-required': formatMessage(
    'Please select whom you would like to share this with'
  ),
  'scopeIds-maxItems': formatMessage('Maximum of 10'),
  'private-to-owner': formatMessage(
    'Resource can only be private to the creator'
  ),
  'grades-invalid': formatMessage('Invalid grade value(s)'),
  'thumbnail-required': formatMessage('Thumbnail image is required'),
  'thumbnail-invalid': formatMessage('Invalid thumbnail image'),
  'thumbnail-attribution-url-invalid': formatMessage('Invalid attribution url'),
  'thumbnail-license-url-invalid': formatMessage('Invalid license url'),
  'exportstatus-invalid': formatMessage('Invalid exportStatus value'),
  'type-invalid': formatMessage('Invalid type value'),
  'license-invalid': formatMessage('Invalid license value(s)'),
  'license-required': formatMessage('A license is required'),
  'license-specs-public-domain': formatMessage(
    'Cannot have license specifications on Public Domain license'
  ),
  'license-specs-invalid': formatMessage('Invalid license specifications'),
  'licenseSpecs-maxLength': formatMessage(
    'License specifications can only be 400 characters'
  ),
  'media-id-required': formatMessage(
    'Media id required for audio and video resources'
  ),
  'media-id-invalid': formatMessage('Invalid media id'),
  'versionNotes-required': formatMessage(
    'Version Notes is required and must be 350 characters or less'
  ),
  'versionNotes-minLength': formatMessage('Version Notes cannot be empty'),
  'versionNotes-maxLength': formatMessage(
    'Version Notes must be 350 characters or less'
  ),
  'outcome-invalid': formatMessage(
    'Outcomes must come from a recognized standard'
  ),
  'q-invalid': formatMessage('Invalid value for q'),
  'imports-not-authorized': formatMessage(
    'Not authorized to view imports for that user'
  ),
  'thumbnail-wrong-media-type': formatMessage(
    'Thumbnail must be an image type'
  ),
  'thumbnail-too-big': formatMessage('Thumbnail must be less than 1MB')
};

export default function (err) {
  return replaceErrors(err, resourceErrors);
}
