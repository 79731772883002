import React from 'react';
import formatMessage from 'format-message';
import SadLaptop from './layouts/sad-laptop';
import * as announce from '../../common/announce';

export default function SessionExpired () {
  const status = formatMessage(
    'Oops, your session has expired due to inactivity'
  );
  const action = formatMessage(
    'Please refresh to continue working with Commons.'
  );
  announce.assertively(status + action);
  return (
    <SadLaptop>
      <h1>{status}</h1>
      <h2>{action}</h2>
    </SadLaptop>
  );
}
