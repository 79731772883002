import Qs from 'qs';
import Url from 'url';

export default function normalizeUri (url, query = {}) {
  const parsedUrl = Url.parse(url);

  if (parsedUrl.host) {
    return url;
  }

  let path = ensureBeginningSlash(parsedUrl.pathname);
  const newQuery = Qs.parse(parsedUrl.query);
  Object.assign(newQuery, query);

  if (Object.keys(newQuery).length > 0) {
    path += '?' + Qs.stringify(newQuery);
  }

  return path;
}

/** @type {(str: string) => string} */
function ensureBeginningSlash (str) {
  if (str[0] === '/') {
    return str;
  } else {
    return `/${str}`;
  }
}
