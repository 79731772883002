import Error from './error-record';
import {
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  RESET_SEARCH_USERS,
  CLEAR_LOAD_USER_ERROR,
  SET_LOAD_USER_ERROR
} from '../actions/users';

export const initialState = {
  items: [],
  meta: { count: 0 },
  usersAreLoading: false,
  usersAreLoaded: false,
  usersLoadError: null,
  loadUserError: null
};

export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case SEARCH_USERS_START:
      return {
        ...state,
        items: [],
        meta: { count: 0 },
        usersAreLoading: true,
        usersAreLoaded: false,
        usersLoadError: null
      };

    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        ...action.response,
        usersAreLoading: false,
        usersAreLoaded: true,
        usersLoadError: null
      };

    case SEARCH_USERS_FAILURE:
      return {
        ...state,
        items: [],
        meta: { count: 0 },
        usersAreLoading: false,
        usersAreLoaded: false,
        usersLoadError: new Error(action.error)
      };

    case RESET_SEARCH_USERS:
      return initialState;

    case CLEAR_LOAD_USER_ERROR:
      return {
        ...state,
        loadUserError: null
      };

    case SET_LOAD_USER_ERROR:
      return {
        ...state,
        loadUserError: action.error
      };

    default:
      return state;
  }
}
