import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmationDialog from './confirmation-dialog';

const alertDialog = (props) => {
  const confirmRoot = document.createElement('div');
  const body = document.getElementsByTagName('body')[0];

  body.appendChild(confirmRoot);

  const onClose = () => {
    ReactDOM.unmountComponentAtNode(confirmRoot);
    body.removeChild(confirmRoot);
  };

  ReactDOM.render(
    <ConfirmationDialog onClose={onClose} {...props} hideCancelButton label="Alert" />,
    confirmRoot
  );
};

export default alertDialog;
