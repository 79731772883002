import { SORT_MATCHING } from '../../../constants/search';

const SEPARATOR = '#';

const valuesWithoutSortOrder = [
  SORT_MATCHING,
];

export const hasSortOrder = (value) => !valuesWithoutSortOrder.includes(value);

export const joinSortingValues = (sortBy, sortOrder) => `${sortBy}${SEPARATOR}${sortOrder}`;

export const splitSortingValues = (value) => {
  const [sortBy, sortOrder] = value.split(SEPARATOR);
  return { sortBy, sortOrder };
};

export const createSearchSortingOptions = (options) => {
  return options.map(({ id, label }) => ({ id, label, value: id }));
};
