import React from 'react';

import { subscribe } from '../store';
import formatMessage from 'format-message';

import FavoriteResourcesFirstTimeUser from '../components/search/favorite-resources-first-time-user';
import Head from 'react-helmet';
import NotFound from '../components/errors/404-not-found';

const View = props => {
  if (props.originalRoute === 'favorite-resources') {
    return <FavoriteResourcesFirstTimeUser />;
  } else {
    return <div>
      <Head title={formatMessage('Not Found')} />
      <NotFound />
    </div>;
  }
};

export default function ({ resolve, location, render, exiting }) {
  const unsubscribe = subscribe(() => render(<View originalRoute={location.query.originalRoute} />));
  exiting.then(unsubscribe);
  render(<View originalRoute={location.query.originalRoute} />);
  resolve();
}
