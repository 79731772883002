import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import formatMessage from 'format-message';
import Loading from '../../common/loading';
import Announce from '../../common/announce';
import ErrorMessage from '../../common/error-message';

const CHARACTER_LIMIT = 50;

export default createReactClass({
  displayName: 'ConsortiumsCreate',

  propTypes: {
    name: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isCreating: PropTypes.bool,
    createError: PropTypes.object
  },

  getInitialState () {
    return {
      isCreating: false
    };
  },

  handleChange (event) {
    if (this.state.isCreating) {
      return;
    }
    this.props.onChange(event.target.value.slice(0, CHARACTER_LIMIT));
  },

  handleKeyPress (event) {
    if (event.key !== 'Enter') {
      return;
    }

    const name = this.props.name.slice(0, CHARACTER_LIMIT).trim();
    this.props.onCreate({ name });
  },

  render () {
    const { name, isCreating, createError } = this.props;
    const inputClassName = classnames('ConsortiumsCreate-input', {
      'ConsortiumsCreate-input--readonly': isCreating
    });
    return (
      <div className="ConsortiumsCreate" data-heap-redact-text>
        <label className="ConsortiumsCreate-label" htmlFor="consortium.name">
          {formatMessage('Create a new consortium')}
        </label>

        {createError && (
          <span>
            <Announce assertively message={createError.message} />
            <ErrorMessage
              className="ConsortiumsCreate-error"
              inline
              message={createError.message}
            />
          </span>
        )}

        <input
          aria-label={formatMessage(
            'Type the name of the new consortium and press enter to create. This will take you to the consortium detail page'
          )}
          className={inputClassName}
          maxLength={CHARACTER_LIMIT}
          name="consortium.name"
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          placeholder={formatMessage('Name your new consortium')}
          readOnly={isCreating}
          ref="input"
          type="text"
          value={name}
        />

        {isCreating && (
          <div>
            <Announce
              assertively
              message={formatMessage('Creating {name}', { name })}
            />
            <Loading className="ConsortiumsCreate-loading" />
          </div>
        )}
      </div>
    );
  }
});
