import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';

import Loading from './loading';
import Link from './link';

const isNotFiller = child => child.type !== 'li' && child.props.search != null;

export default createReactClass({
  displayName: 'LoadMore',

  propTypes: {
    hasMore: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    children: PropTypes.any
  },

  componentDidUpdate (oldProps) {
    const oldCount = oldProps.children
      ? React.Children.count(
        React.Children.map(oldProps.children, child => child).filter(
          isNotFiller
        )
      )
      : 0;
    const newCount = this.props.children
      ? React.Children.count(
        React.Children.map(this.props.children, child => child).filter(
          isNotFiller
        )
      )
      : 0;
    // not first results and not on delete
    if (oldCount > 0 && newCount > oldCount) {
      const element = this.refs.parent.querySelector(
        `*:nth-child(${oldCount + 1}) .lor-result a`
      );
      if (element) {
        element.focus();
      }
    }
  },

  render () {
    const hasChildren = React.Children.count(this.props.children) > 0;
    const opacity = this.props.isLoading ? 1 : 0;

    return (
      <ol className="LoadMore" ref="parent">
        {this.props.children}

        {this.props.hasMore && !this.props.isLoading && (
          <li style={{ listStyle: 'none' }} className="LoadMore-button">
            <Link
              onClick={this.props.loadMore}
              className="LoadMore-button-btn"
            >
              {formatMessage('Load more results')}
            </Link>
          </li>
        )}

        {hasChildren && this.props.hasMore && (
          <li
            aria-hidden={!this.props.isLoading}
            className="LoadMore-loader"
            style={{
              opacity,
              display: this.props.isLoading ? 'block' : 'none'
            }}
          >
            <Loading />
          </li>
        )}
      </ol>
    );
  }
});
