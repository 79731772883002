import React from 'react';
import PropTypes from 'prop-types';
import env from '../../../config/env';
import apiRequest from '../../../common/api-request';

const DOCVIEWER = 'docviewer';
const NOTORIOUS = 'notorious';

class Ccv extends React.Component {
  componentDidMount () {
    window.addEventListener('message', this.handlePostMessage);
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.handlePostMessage);
  }

  handlePostMessage = event => {
    const isFromPreviewerIframe = event.origin.includes(
      env.PREVIEWER_URL
    );
    if (isFromPreviewerIframe === false) {
      return;
    }
    if (event.data) {
      const data = JSON.parse(event.data);
      if (data.type !== 'externalViewerRequest') {
        return;
      }
      const { id, service } = data.body;

      if (service === DOCVIEWER) {
        this.requestDocviewerSession(id);
      } else if (service === NOTORIOUS) {
        this.requestNotoriousSession(id);
      }
    }
  }

  submitPostMessage = ({ id, service, url }) => {
    if (this.ccv && this.ccv.contentWindow) {
      this.ccv.contentWindow.postMessage(
        JSON.stringify({
          type: 'externalViewerResponse',
          body: {
            id,
            service,
            url
          }
        }),
        env.PREVIEWER_URL
      );
    }
  }

  requestDocviewerSession = documentId => {
    apiRequest({
      path: `/resources/${this.props.resourceId}/document-preview/${documentId}`
    })
      .promise()
      .then(response => {
        const { previewUrl } = response.body;
        this.submitPostMessage({
          id: documentId,
          service: DOCVIEWER,
          url: previewUrl
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  requestNotoriousSession = mediaObjectId => {
    return apiRequest({
      path: `/resources/${this.props.resourceId}/media-preview-sources`,
      query: {
        mediaObjectId
      }
    })
      .promise()
      .then(response => {
        const mediaItem = response.body && response.body.items[0];
        if (mediaItem && mediaItem.status === 'succeeded') {
          this.submitPostMessage({
            id: mediaObjectId,
            service: NOTORIOUS,
            url: mediaItem.url
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  setCcvRef = node => {
    this.ccv = node;
  }

  setSrcWithParams = (sourceUrl, queryArray) => {
    return queryArray.reduce((accu, current, index) => {
      if (!current) return accu;

      const queryStart = index === 0 ? '?' : '&';
      return `${accu}${queryStart}${current}`;
    }, sourceUrl);
  }

  render () {
    const iframeQueryParams = [
      'compact',
      `manifest=${this.props.manifestUrl}`,
      `cartridge=${this.props.cartridgeUrl}`,
      !this.props.showQuizzesAnswers ? 'hide-responses' : '',
      this.props.prefersHighContrast ? 'high-contrast=true' : '',
      `locale=${this.props.locale}`
    ];

    return (
      <div className="cartridge-preview">
        <iframe
          allowFullScreen
          title="Content Preview"
          ref={this.setCcvRef}
          className="cartridge-preview-iframe"
          src={this.setSrcWithParams(
            env.PREVIEWER_URL,
            iframeQueryParams
          )}
          frameBorder="0"
        />
      </div>
    );
  }
}

Ccv.propTypes = {
  manifestUrl: PropTypes.string.isRequired,
  cartridgeUrl: PropTypes.string.isRequired,
  locale: PropTypes.string,
  prefersHighContrast: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  showQuizzesAnswers: PropTypes.bool
};

export default Ccv;
