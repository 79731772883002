import Uniflow from 'uniflow';
import apiRequest from '../common/api-request';

export default Uniflow.createActions({
  async getCourses () {
    this.emit('get-courses-pending');
    try {
      const request = apiRequest({
        path: '/courses'
      });
      const response = await request.promise();
      this.emit('get-courses-successful', response.body);
    } catch (e) {
      this.emit('get-courses-error', e);
    }
  }
});
