import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SimpleSelect } from '@instructure/ui-simple-select';

import {
  addAttributeByVariableToElement,
  addBaseAttributes,
} from '../../common/modify-elements';

const addAttributes = (select, props) => {
  addBaseAttributes(select, props, ['Select']);
  addAttributeByVariableToElement(select, props.tabIndex, 'tabIndex');

  if (props.name) {
    select.setAttribute('name', props.name);
  }
};

const getSelectedOption = (options, id) => options.find((option) => option.id === id);

export const Select = (props) => {
  const { renderLabel, options, onChange, ...restOfProps } = props;
  const addAttributesToElement = (select) => addAttributes(select, props);
  const [firstOption] = options || [];
  const [inputIcon, setInputIcon] = React.useState(firstOption && firstOption.renderBeforeLabel);
  const onChangeHandler = useCallback((event, data) => {
    const selectedOption = getSelectedOption(options, data.id);

    if (selectedOption.renderBeforeLabel) {
      setInputIcon(selectedOption.renderBeforeLabel);
    }

    if (onChange) {
      onChange(event, data);
    }
  }, [options, onChange]);

  return (
    <SimpleSelect
      {...restOfProps}
      onChange={onChangeHandler}
      renderBeforeInput={inputIcon}
      renderLabel={renderLabel || ''}
      inputRef={(input) => input && addAttributesToElement(input)}
      children={options.map((option) => (
        <SimpleSelect.Option key={option.id} {...option} children={option.label} />
      ))}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string,
  assistiveText: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  renderLabel: PropTypes.node || PropTypes.string || PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    renderBeforeLabel: PropTypes.node || PropTypes.string,
    value: PropTypes.string.isRequired
  })).isRequired,
};
