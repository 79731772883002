import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Checkbox } from '@instructure/ui-checkbox';
import IconBack from '../../icons/back';
import Link from '../../common/link';
import Loading from '../../common/loading';
import Error from '../../common/error';
import MemberList from './member-list';
import NameForm from './name-form';

export default createReactClass({
  displayName: 'AccountConsortium',

  propTypes: {
    actions: PropTypes.shape({
      changeContributorStatus: PropTypes.func.isRequired,
      createMember: PropTypes.func.isRequired,
      deleteMember: PropTypes.func.isRequired,
      changeName: PropTypes.func.isRequired,
      updateConsortium: PropTypes.func.isRequired,
      searchAccounts: PropTypes.func.isRequired,
      resetAccounts: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.shape({
      accounts: PropTypes.array,
      count: PropTypes.number,
      searchAccountsError: PropTypes.object,
      consortium: PropTypes.object,
      members: PropTypes.array,
      currentName: PropTypes.string,
      consortiumIsLoading: PropTypes.bool,
      consortiumIsLoaded: PropTypes.bool,
      consortiumLoadError: PropTypes.object,
      createMemberError: PropTypes.object
    }).isRequired,
    session: PropTypes.object.isRequired
  },

  shouldComponentUpdate (nextProps) {
    return nextProps.state !== this.props.state;
  },

  handleContributorChange (event) {
    const {
      state: { consortium, members },
      actions
    } = this.props;
    const creatorMember = members.find(
      member => member.accountId === consortium.creatorAccountId
    );
    actions.changeContributorStatus({
      id: creatorMember.id,
      consortiumId: creatorMember.consortiumId,
      isContributor: event.target.checked
    });
  },

  createMember (accountId) {
    this.props.actions.createMember({
      accountId,
      consortiumId: this.props.state.consortium.id
    });
  },

  render () {
    const { actions, state, session } = this.props;
    const {
      consortium,
      members,
      currentName,
      consortiumIsLoading,
      consortiumIsLoaded,
      consortiumLoadError,
      accounts,
      searchAccountsError,
      count,
      createMemberError
    } = state;

    const isCreatorContributor = members.some(
      member =>
        member.accountId === consortium.creatorAccountId && member.isContributor
    );
    return (
      <div className="AccountConsortium">
        <div className="AccountConsortium-header">
          <Link
            className="AccountConsortium-back-link"
            href="/account/consortiums"
          >
            <IconBack className="AccountConsortium-back-icon" />
            {formatMessage('Back to All Consortiums')}
          </Link>
        </div>

        {consortiumIsLoading && (
          <div className="AccountConsortium-loading">
            <Loading />
          </div>
        )}

        {consortiumLoadError && (
          <Error
            className="AccountConsortium-error"
            error={consortiumLoadError}
          />
        )}

        {consortiumIsLoaded && consortium && (
          <main className="AccountConsortium-main">
            <NameForm
              consortium={consortium}
              currentName={currentName}
              changeName={actions.changeName}
              updateConsortium={actions.updateConsortium}
              updateConsortiumPending={state.updateConsortiumPending}
              updateConsortiumSuccess={state.updateConsortiumSuccess}
              updateConsortiumError={state.updateConsortiumError}
            />
            <div className="AccountConsortium-contributor">
              <Checkbox
                checked={isCreatorContributor}
                label={formatMessage(
                  'Allow my institution to contribute to consortium'
                )}
                onChange={this.handleContributorChange}
                size="small"
                variant="toggle"
              />
            </div>
            <MemberList
              changeContributorStatus={actions.changeContributorStatus}
              createMember={this.createMember}
              createMemberError={createMemberError}
              deleteMember={actions.deleteMember}
              creatorAccountId={consortium.creatorAccountId}
              members={members}
              currentAccountId={session.accountId}
              accounts={accounts}
              accountsCount={count}
              searchAccountsError={searchAccountsError}
              searchAccounts={actions.searchAccounts}
              resetAccounts={actions.resetAccounts}
            />
          </main>
        )}
      </div>
    );
  }
});
