import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Tray } from '@instructure/ui-tray';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { CloseButton } from '@instructure/ui-buttons';

import Link from '../../common/link';
import ResourceTypeIcon from '../../icons/resource-type';
import { resourceTypes } from '../../../common/app-constants';

const ReportDetailsTray = ({ isOpen, onClose, resource }) => {
  const missingResource = !resource || !Object.keys(resource).length;
  return (
    <Tray
      label={formatMessage('Details')}
      open={isOpen}
      onDismiss={onClose}
      placement="end"
      className="ReportDetailsTray"
      data-testid="ReportDetailsTray"
      themeOverride={{
        zIndex: 10000,
      }}
      shouldCloseOnDocumentClick
    >
      <View as="div" padding="medium">
        <View as="div" position="relative">
          <Text size="large" weight="bold">
            {formatMessage('Reports')}
          </Text>
          <CloseButton
            size="small"
            placement="end"
            onClick={onClose}
            data-testid="DetailsTrayCloseButton"
            offset="none"
            screenReaderLabel={formatMessage('Close')}
          />
        </View>
        <div className="ReportDetailsTray--body">
          {missingResource ? (
            <Text>{formatMessage('The resource could not be found')}</Text>
          ) : (
            <React.Fragment>
              <div className="ReportDetailsTray--top">
                <div className={`font-color type-${resource.type}`}>
                  <ResourceTypeIcon
                    className="ReportDetailsTray--resourceIcon"
                    type={resource.type}
                    iconSize="x-small"
                    colored
                    ghost
                  />
                  <Text size="x-small" weight="bold" transform="uppercase">{resourceTypes[resource.type].singularName}</Text>
                </div>
                <Link
                  href={`/resources/${resource.id}`}
                  title={resource.title}
                >
                  {resource.title}
                </Link>
              </div>
              <View as="div" borderRadius="medium" background="secondary" borderWidth="small" padding="0 small">
                <div className="ReportDetailsTray--count">
                  <span>{formatMessage('Spam:')}</span>{resource.reports.spam}
                </div>
                <div className="ReportDetailsTray--count">
                  <span>{formatMessage('Infringing:')}</span>{resource.reports.infringing}
                </div>
                <div className="ReportDetailsTray--count">
                  <span>{formatMessage('Inappropriate:')}</span>{resource.reports.inappropriate}
                </div>
              </View>
            </React.Fragment>
          )}
        </div>
      </View>
    </Tray>
  );
};

ReportDetailsTray.propTypes = {
  onClose: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    reports: PropTypes.shape({
      spam: PropTypes.number,
      infringing: PropTypes.number,
      inappropriate: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ReportDetailsTray;
