import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage from 'format-message';

import Link from '../../common/link';
import FormGroup from '../../common/form-group';
import CharactersLeft from '../../common/characters-left';

export default class LicenseSpecifications extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.openLicenseSpecifications = this.openLicenseSpecifications.bind(this);
    this.updateLicenseSpecsState = this.updateLicenseSpecsState.bind(this);

    this.state = {
      showLicenseSpecs: props.licenseSpecs,
      licenseSpecs: ''
    };
  }

  static propTypes = {
    licenseId: PropTypes.string,
    licenseSpecs: PropTypes.string,
    shouldHideLicenseSpecs: PropTypes.bool,
    onChange: PropTypes.func,
    hasErrors: PropTypes.bool,
    errorMessage: PropTypes.element
  }

  UNSAFE_componentWillReceiveProps (props) {
    // if the license was public domain and they are switching to a new
    // one and there is already specs then show them
    if (props.licenseSpecs) {
      this.setState({ showLicenseSpecs: true });
    }

    if (props.shouldHideLicenseSpecs) {
      this.setState({
        showLicenseSpecs: false
      });
    }
  }

  openLicenseSpecifications (evt) {
    this.setState({ showLicenseSpecs: true }, () => {
      this.refs.specs.focus();
    });
  }

  updateLicenseSpecsState (evt) {
    this.setState({
      licenseSpecs: evt.target.value
    });
    this.props.onChange(evt);
  }

  render () {
    var charCountClass = classnames({
      'LicenseSpecifications-warning': this.state.licenseSpecs.length > 400
    });
    const additionalInformationMessage = formatMessage(
      '(Include any additional licensing, copyright, or use specifications)'
    );

    if (!this.state.showLicenseSpecs) {
      return (
        <div className="form-group LicenseSpecifications">
          <Link
            className="LicenseSpecifications-open"
            onClick={this.openLicenseSpecifications}
          >
            {formatMessage('Add Additional Information')}
          </Link>
        </div>
      );
    }

    return (
      <FormGroup
        className="LicenseSpecifications"
        label={formatMessage('Add Additional Information')}
        htmlFor="licenseSpecs"
        hasErrors={this.props.hasErrors}
        errorMessage={this.props.errorMessage}
      >
        <span className="LicenseSpecifications-help">
          {additionalInformationMessage}
        </span>
        <textarea
          className="form-control LicenseSpecifications-entry"
          id="licenseSpecs"
          name="licenseSpecs"
          onChange={this.updateLicenseSpecsState}
          value={this.props.licenseSpecs}
          ref="specs"
        />
        <p className="LicenseSpecifications-notice">
          <CharactersLeft
            countClassName={charCountClass}
            count={400 - this.state.licenseSpecs.length}
          />
        </p>
      </FormGroup>
    );
  }
}
