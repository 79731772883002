import React from 'react';
import formatMessage from 'format-message';
import { IconAddLine } from '@instructure/ui-icons';

import { Select } from '../common/select';

export default class CanvasResourceSelector extends React.Component {
  render () {
    const { canvasResourceType } = this.props;
    if (canvasResourceType !== 'module' &&
        canvasResourceType !== 'assignment') {
      return null;
    }
    const title = (canvasResourceType === 'module') ? formatMessage('Place in Module') : formatMessage('Place in Group');
    const options = this.props.availableCanvasResources.map(resource => ({
      id: resource.id,
      label: resource.name,
      value: resource.id,
      renderBeforeLabel: (resource.id === '_new') ? <IconAddLine /> : null
    }));

    return (<div data-testid="canvas-resource-selector" className="Canvas-resource-select-component" role="banner">
      <Select
        name="canvasResourceSelect"
        className="Canvas-resource-select"
        data-automation="CanvasResourceSelect"
        aria-label={title}
        assistiveText={title}
        onChange={this.props.onChangeHandler}
        defaultValue={this.props.currentSelected}
        renderLabel={false}
        visibleOptionsCount={Math.min(8, this.props.availableCanvasResources.length + 1)}
        options={options}
      />
    </div>);
  }
}
