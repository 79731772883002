import Uniflow from 'uniflow';
import NotificationCenterActions from '../actions/notification-center';

let bootstrapped = false;

const NotificationCenterStore = Uniflow.createStore({
  state: {
    currentNotifications: []
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    NotificationCenterActions.on('show-notification', NotificationCenterStore.addNotification);
    NotificationCenterActions.on('close-notification', NotificationCenterStore.closeNotification);
    NotificationCenterActions.on('close-notification-type', NotificationCenterStore.closeNotificationType);

    bootstrapped = true;
  },

  addNotification (notification) {
    const currentNotifications = this.state.currentNotifications.slice();

    if (this.state.currentNotifications.map((m) => m.id).indexOf(notification.id) === -1) {
      currentNotifications.push(notification);
    }

    this.setState({
      currentNotifications
    });
  },

  closeNotification (id) {
    const index = this.state.currentNotifications.map((m) => m.id).indexOf(id.toString());

    if (index !== -1) {
      const currentNotifications = this.state.currentNotifications.slice();
      currentNotifications.splice(index, 1);
      window.localStorage.lastUpdateId = id;
      this.setState({
        currentNotifications
      });
    }
  },

  closeNotificationType (type) {
    this.setState({
      currentNotifications:
        this.state.currentNotifications.filter((notification) => notification.type !== type)
    });
  }
});

export default NotificationCenterStore;
