import React from 'react';
import formatMessage from 'format-message';
import robotsSrc from '../../../../assets/svg/repairs.svg';

export default function Robots500 ({ children }) {
  return (
    <div className="lor-error-layout robots">
      <img
        alt={formatMessage('Robots in space building a starship, signifying maintenance works in the background.')}
        src={robotsSrc}
      />
      {children}
    </div>
  );
}
