import React from 'react';
import { Responsive } from '@instructure/ui-responsive';

import Results from './results';

const ResultsResponsive = props =>
  <Responsive
    query={{
      small: { maxWidth: '800px' },
      medium: { minWidth: '800px' },
      large: { minWidth: '1300px' },
    }}
  >
    {(responsiveProps, matches) => {
      const trailingColumnsWidthFixed = !matches.includes('large');
      const isLayoutStacked = matches.includes('small');
      const resultsResponsiveProps = {
        isLayoutStacked,
        trailingColumnsWidthFixed
      };
      return <Results {...props} {...resultsResponsiveProps} />;
    }}
  </Responsive>;

export default ResultsResponsive;
