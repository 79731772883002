import React from 'react';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

// Components
import NoResultsQuote from '../common/no-results-quote';
import starUrl from '../../../assets/svg/star-flag.svg';

export default class FavoriteResourcesFirstTimeUser extends React.Component {
  render () {
    const message = formatMessage('No Favorites Added');
    const quote = formatMessage('Add favorites from Commons by clicking the "Add to Favorites" link in the Resource details page.');

    announce.assertively(`${message}. ${quote}`);

    const noFavoritesAddedProps = {
      className: 'small',
      photoUrl: starUrl,
      message,
      quote,
      photoAlt: formatMessage('Icon')
    };

    return (
      <div className="FavoriteResources responsive-results-width first-time-user">
        <NoResultsQuote {...noFavoritesAddedProps} />
      </div>
    );
  }
}
