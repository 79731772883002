import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconLinkLine } from '@instructure/ui-icons';
import { Alert } from '@instructure/ui-alerts';

import Button from '../common/button';

export default class CopyResourceLinkButton extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      showAlert: false
    };
  }

  onClick = event => {
    this.resourceUrlTextArea.select();
    document.execCommand('copy');

    this.setState({
      showAlert: true
    });
  }

  getResourceUrl = () => {
    const currentUrl = window.location;
    const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
    const resourcePath = `resources/${this.props.learningObject.id}`;
    const marker = 'shared';

    return `${baseUrl}/${resourcePath}?${marker}`;
  }

  render () {
    const resourceUrl = this.getResourceUrl();

    return (
      <div className="lor-copy-resource-link-btn">
        <Button
          renderIcon={IconLinkLine}
          onClick={this.onClick}
          display={Button.display.block}
        >
          {formatMessage('Copy Resource Link')}
        </Button>
        <textarea
          className="lor-copy-resource-link-btn-link"
          ref={(textarea) => { this.resourceUrlTextArea = textarea; }}
          value={resourceUrl}
          readOnly
          aria-hidden="true"
          tabIndex="-1"
        />
        {this.state.showAlert && (
          <div className="lor-copy-resource-link-btn-alert">
            <Alert
              variant="success"
              timeout={5000}
              liveRegion={() => document.getElementById('screenreader-announcements-assertive')}
              onDismiss={() => this.setState({ showAlert: false })}
            >
              {formatMessage('Resource link copied to clipboard.')}
            </Alert>
          </div>
        )}
      </div>
    );
  }
}
