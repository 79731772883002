/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import classnames from 'classnames';
import ComplexMessage from './complex-message';
import UploadIcon from '../icons/upload';

export default class FilePicker extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.stopEvent = this.stopEvent.bind(this);

    this.state = {
      isDragging: false,
      isFocused: false
    };
  }

  static propTypes = {
    extensions: PropTypes.arrayOf(PropTypes.string), // [ '.jpg', '.png' ]
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    label: PropTypes.string, // must have a 'browse' ComplexMessage placeholder
    browseLabel: PropTypes.string, // text inside the 'browse' element
    typesLabel: PropTypes.string // text describing the accepted extensions
  }

  static defaultProps = {
    label: formatMessage(
      'Drag and drop your file here or {browse} your computer.',
      {
        browse: ComplexMessage.placeholder('browse')
      }
    ),
    browseLabel: formatMessage('browse')
  }

  componentDidMount () {
    document.addEventListener('dragenter', this.handleDragEnter, false);
  }

  componentWillUnmount () {
    document.removeEventListener('dragenter', this.handleDragEnter, false);
  }

  handleDragEnter (event) {
    this.setState({ isDragging: true });
    this.stopEvent(event);
  }

  handleDragLeave (event) {
    this.setState({ isDragging: false });
    this.stopEvent(event);
  }

  handleDrop (event) {
    this.props.onChange(event.dataTransfer.files[0]);
    this.setState({ isDragging: false });
    this.stopEvent(event);
  }

  handleInputChange (event) {
    this.props.onChange(event.target.files[0]);
  }

  stopEvent (event) {
    event.stopPropagation();
    event.preventDefault();
  }

  handleFocus (event) {
    this.setState({ isFocused: true });
  }

  handleBlur (event) {
    this.setState({ isFocused: false });
  }

  render () {
    return (
      <div
        className={classnames('FilePicker', this.props.className, {
          'FilePicker--focused': this.state.isFocused
        })}
      >
        <UploadIcon className="FilePicker-icon" />
        <label className="FilePicker-label">
          <ComplexMessage>
            {this.props.label}
            <span key="browse" className="Link FilePicker-browse">
              {this.props.browseLabel}
              <input
                className="FilePicker-input"
                name="FilePicker-input"
                type="file"
                accept={
                  this.props.extensions && this.props.extensions.join(',')
                }
                onChange={this.handleInputChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
            </span>
          </ComplexMessage>
        </label>
        {this.props.typesLabel && (
          <div className="FilePicker-types">{this.props.typesLabel}</div>
        )}
        {this.state.isDragging && (
          <div
            className="FilePicker-dropzone"
            onDragOver={this.stopEvent}
            onDragEnter={this.stopEvent}
            onDragLeave={this.handleDragLeave}
            onDragEnd={this.stopEvent}
            onDrop={this.handleDrop}
          >
            <UploadIcon className="FilePicker-dropzone-icon" />
          </div>
        )}
      </div>
    );
  }
}
