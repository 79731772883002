export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Shorten thousands, millions, billions, etc, to metric prefixs.
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @param {string} [suffix=''] Suffix to add after metric unit.
 * @returns {string|number}
 *
 * @example
 * // returns '13k+'
 * shortenLargeNumber(13543, 0, '+')
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000, 0)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651, 0)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345, 0)
 */
export function shortenLargeNumber (num, digits, suffix = '') {
  if (!num || !isFinite(num)) {
    return '0';
  }
  if (num >= 0 && num <= 999) {
    return num;
  }
  if (digits === undefined) {
    digits = 0;
  }

  digits = parseInt(digits);
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const regexp = new RegExp('^-?\\d+(?:\\.\\d{0,' + digits + '})?');

  for (let i = units.length - 1; i >= 0; i--) {
    const decimal = Math.pow(1000, i + 1);
    if (num <= -decimal || num >= decimal) {
      let prefix = (num / decimal).toString().match(regexp)[0];
      if (digits === 0) {
        prefix = prefix.replace('.', '');
      }
      return `${prefix}${units[i]}${suffix}`;
    }
  }
  return num;
}
