import apiRequest from '../common/api-request';
import { getDescription } from './thumbnail-get-description';
import localizeErrors from './error-messages/thumbnail';
import isErroneous from '../common/is-erroneous';

export function find (learningObjectId, done) {
  const path = `/resources/${learningObjectId ? learningObjectId + '/' : ''}thumbnails`;
  apiRequest({
    path
  })
    .end((err, res) => {
      if (isErroneous(err, res)) {
        localizeErrors(err);
        return done(err, res ? res.body : null);
      }

      // localize all of the descriptions
      res.body.items.forEach((img) => {
        img.description = getDescription(img.url, img.description);
      });

      done(null, res.body);
    });
}
