import { inIframe } from '../common/in-iframe';
import env from '../config/env';

export const redirectToLogin = () => {
  if (!inIframe()) {
    window.location.replace(env.loginUrl || 'https://canvas.instructure.com/login/canvas');
    return true;
  }

  return false;
};
