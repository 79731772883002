import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { View } from '@instructure/ui-view';
import { IconArrowOpenStartLine } from '@instructure/ui-icons';
import fishBow from '../../../../../assets/svg/fish-bow.svg';
import CommonsLink from '../../../common/link';

export const CursorLimitReachedError = ({ onBackButtonClick, onFilterClick }) => {
  return (
    <View as="div">
      <View as="div" margin="medium 0">
        <CommonsLink onClick={onBackButtonClick} renderIcon={IconArrowOpenStartLine}>
          {formatMessage('Back to previous search results')}
        </CommonsLink>
      </View>
      <div className="message-with-image">
        <img src={fishBow} alt={formatMessage('Fish bow')} />
        <section aria-live="assertive" role="alert">
          <h2>{formatMessage('The number of results is too large to process')}</h2>
          <p>
            {formatMessage('Try narrowing down the results by ')}
            <CommonsLink onClick={onFilterClick}>
              {formatMessage('filtering')}
            </CommonsLink>
          </p>
        </section>
      </div>
    </View>
  );
};

CursorLimitReachedError.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
};
