import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import _ from 'lodash';
import { Alert } from '@instructure/ui-alerts';

import { SORT_RECENT, SORT_MATCHING } from '../../../constants/search';
import apiRequest from '../../../common/api-request';
import { csvColumns } from './const';
import { convertToCSVString } from '../../../common/convert-to-csv';
import Button from '../../common/button';
import { getState } from '../../../store';

export default class Download extends React.Component {
  static displayName = 'Download'
  loadingDotInterval = null

  constructor (props) {
    super(props);
    this.state = {
      disabled: false,
      dots: '',
      query: {}
    };
  }

  static propTypes = {
    results: PropTypes.object.isRequired,
    onEnd: PropTypes.func
  }

  handleDownloadButton = _ => {
    if (this.state.disabled) return;
    this.setState(
      {
        query: { ...this.props.baseParams, ...this.props.router.query },
        dots: '',
        disabled: true
      },
      _ => {
        this.disableButton();
        this.startFetch().catch((error) => {
          this.setState({ error: error });
        });
      }
    );
  }

  disableButton = _ => {
    this.loadingDotInterval = setInterval(_ => {
      let dots = this.state.dots + '.';
      if (dots.length > 3) dots = '';
      this.setState({ dots, disabled: true });
    }, 500);
  }

  enableButton = _ => {
    this.loadingDotInterval && clearInterval(this.loadingDotInterval);
    if (!this.unmounted) {
      this.setState({ disabled: false, error: null });
    }
  }

  componentDidMount () {
    this.unmounted = false;
  }

  componentWillUnmount () {
    this.unmounted = true;
    this.loadingDotInterval && clearInterval(this.loadingDotInterval);
  }

  startFetch = async _ => {
    if (!navigator.onLine) {
      return this.setState({ error: 'Navigator offline' });
    }

    let sortBy = this.state.query.sortBy;
    if (!this.state.query.sortBy) {
      sortBy = this.state.query.q ? SORT_MATCHING : SORT_RECENT;
    }
    this.state.query.showPublic = true;

    const { items, meta } = await this.loadSearch({
      ...this.state.query,
      scroll: true,
      sortBy
    });

    this.fetchNext(items, meta.scrollId, meta.count);
  }

  fetchNext = async (previous = [], scrollId, count) => {
    if (!navigator.onLine) {
      return this.setState({ error: 'Navigator offline' });
    }

    try {
      const { items } = await this.loadSearch({
        scrollId: scrollId
      });
      const csvState = [...previous, ...items];
      if (csvState.length < count && csvState.length !== previous.length) {
        this.fetchNext(csvState, scrollId, count);
      } else {
        if (this.props.onEnd) {
          this.props.onEnd(csvState);
        } else {
          this.serveCSVFile(csvState);
        }

        this.enableButton();
      }
    } catch (error) {
      this.enableButton();
    }
  }

  serveCSVFile = (csvState) => {
    const shouldShowReports = _.get(getState(), 'featureFlags.flags.report_content');
    const filteredCsvColumns = csvColumns.filter(({ id }) => id !== 'reportCount' || shouldShowReports);
    const csv = convertToCSVString(filteredCsvColumns, csvState);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const type = isSafari ? 'application/csv' : 'text/csv';
    const blob = new Blob([csv], { type });
    const dataURI = `data:${type};charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;

    const data =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    const encodedUri = encodeURI(data);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'commons-stats.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  loadSearch = query => {
    return new Promise((resolve, reject) => {
      const req = apiRequest({
        path: '/resources/stats',
        query
      });

      req.end((err, res) => {
        if (err) {
          return reject(err);
        }
        if (res.status >= 400) {
          reject(new Error(JSON.stringify(res)));
        }
        resolve(res.body);
      });
    });
  }

  render () {
    return (
      <div className="CSVDownload">
        {this.state.error && (
          <span className="Alert--floating Csv-error-alert">
            <Alert
              variant="error"
              margin="small"
              renderCloseButtonLabel={formatMessage('Close')}
              liveRegion={() => document.getElementById('screenreader-announcements-assertive')}
              timeout={10000}
              onDismiss={() => this.enableButton()}
            >
              {formatMessage('Failed to export the CSV file')}
            </Alert>
          </span>
        )}
        <Button
          key={`csv-${this.props.results.count}`}
          size={Button.size.small}
          onClick={this.handleDownloadButton}
          interaction={this.state.disabled ? Button.interaction.disabled : Button.interaction.enabled}
        >
          {formatMessage('Download CSV')}
          {this.state.disabled && this.state.dots}
        </Button>
      </div>
    );
  }
}
