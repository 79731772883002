import React from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {
  render () {
    const className = ((this.props.className || '') + ' Checkbox').trim();
    return (
      <span className={className}>
        <input {...this.props} type="checkbox" className="Checkbox-input" />
        <span className="Checkbox-facade" />
      </span>
    );
  }
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  className: PropTypes.string
};
