import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { RESOURCES_STATS as searchPath } from '../../../constants/search-paths';
import { STATS_PAGE_SIZE as resultsPerPage } from '../../../constants/paginated-page-sizes';
import { createFetchResults } from '../../../common/util/create-fetch-results';

// Components
import NoResult from '../../common/no-result';
import Loading from '../../common/loading';
import TableObjects from './table-objects';

const fetchResults = createFetchResults(searchPath, resultsPerPage);

export default class AccountStats extends React.Component {
  static displayName = 'AccountStats'

  static propTypes = {
    session: PropTypes.object.isRequired,
    groups: PropTypes.object
  }

  render () {
    const {
      isAdmin,
      account,
      isAccountCurator = false
    } = this.props.session;
    const { groups } = this.props;
    if (groups.listIsLoading) {
      return (
        <div className="AppHandler--loading">
          <Loading />
        </div>
      );
    }

    const isAllowed = isAdmin || isAccountCurator;

    const baseParams = isAllowed
      ? { accountId: account.id, showPrivate: true }
      : {
        scopeIds: groups.list
          .filter(({ isManager }) => isManager)
          .map(({ id }) => 'group-' + id)
      };

    const noResultsMessage = this.props.results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Stats to Show');

    return (
      <TableObjects
        baseParams={baseParams}
        fetchResults={fetchResults}
        searchPrivateObjects={false}
        resultsPerPage={resultsPerPage}
        emptyMessage={
          <NoResult title={noResultsMessage} />
        }
        {...this.props}
      />
    );
  }
}
