import { isResourcePaginated } from './is-resource-paginated';
import SearchActions from '../../actions/search';

export function createFetchResults (path, resultsPerPage) {
  return (params, cursor = null) => {
    if (isResourcePaginated()) {
      SearchActions.fetchResultsWithPath(params, path, { isPaginated: true, resultsPerPage });
    } else {
      SearchActions.fetchResultsWithPath(params, path, { cursor });
    }
  };
}
