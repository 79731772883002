import React from 'react';
import Robots500 from './layouts/robots-500';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';

export default function InternalServerError500 () {
  const message = formatMessage('An unexpected error has occurred in Commons. Please go back and try again.');
  announce.assertively(message);
  return (
    <Robots500>
      <h1>{formatMessage('Internal Server Error')}</h1>
      <h2>{formatMessage('Our code just experienced the unexpected. We are notified.')}</h2>
    </Robots500>
  );
}
