import Uniflow from 'uniflow';
import LicensesActions from '../actions/licenses';

let bootstrapped = false;

const LicensesStore = Uniflow.createStore({
  state: {
    licenses: [],
    retrievalPending: false,
    retrievalError: null,
    retrievalSuccessful: false
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    // pending
    LicensesActions.on('licenses-retrieval-pending', () => {
      LicensesStore.setState({
        retrievalPending: true,
        retrievalSuccessful: false,
        retrievalError: null
      });
    });

    // errors
    LicensesActions.on('licenses-retrieval-error', (error) => {
      LicensesStore.setState({
        retrievalError: error,
        retrievalPending: false,
        retrievalSuccessful: false
      });
    });

    // successes
    LicensesActions.on('licenses-retrieval-success', ({ items }) => {
      LicensesStore.setState({
        licenses: items,
        retrievalPending: false,
        retrievalSuccessful: true,
        retrievalError: null
      });
    });

    bootstrapped = true;
  },

  getLicenseWithId (id) {
    return this.state.licenses.find((license) => license.id === id);
  }
});

export default LicensesStore;
