import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import debounce from 'lodash/function/debounce';
import CheckIcon from '../../icons/check-medium';
import Announce from '../../common/announce';
import ErrorMessage from '../../common/error-message';

const CHARACTER_LIMIT = 50;
const DEBOUNCE_WAIT = 1000; // 1 second

export default class GroupNameForm extends React.Component {
  static displayName = 'GroupNameForm'

  static propTypes = {
    group: PropTypes.object.isRequired,
    currentName: PropTypes.string.isRequired,
    changeName: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired,
    updateGroupPending: PropTypes.bool,
    updateGroupSuccess: PropTypes.bool,
    updateGroupError: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.saveDebounced = debounce(this.save.bind(this), DEBOUNCE_WAIT, {
      leading: false,
      trailing: true
    });
  }

  handleChange (event) {
    const name = event.target.value.slice(0, CHARACTER_LIMIT);
    this.props.changeName(name);
    this.saveDebounced(name);
  }

  save (name) {
    this.props.updateGroup({
      id: this.props.group.id,
      name
    });
  }

  shouldComponentUpdate (nextProps) {
    return (
      nextProps.group !== this.props.group ||
      nextProps.currentName !== this.props.currentName ||
      nextProps.updateGroupPending !== this.props.updateGroupPending ||
      nextProps.updateGroupSuccess !== this.props.updateGroupSuccess ||
      nextProps.updateGroupError !== this.props.updateGroupError
    );
  }

  render () {
    const {
      currentName,
      updateGroupPending,
      updateGroupSuccess,
      updateGroupError
    } = this.props;

    return (
      <div className="GroupNameForm">
        <label className="GroupNameForm-label" htmlFor="group.name">
          {formatMessage('Edit Group')}
        </label>

        {(updateGroupPending && <Announce message="" />) || // clear, so next saving will be announced
          (updateGroupError && (
            <GroupNameForm.Error error={updateGroupError} />
          )) ||
          (updateGroupSuccess && <GroupNameForm.Saved />)}

        <input
          className="GroupNameForm-input"
          id="group.name"
          maxLength={CHARACTER_LIMIT}
          name="name"
          onChange={this.handleChange}
          type="text"
          value={currentName}
        />
      </div>
    );
  }

  static Error ({ error }) {
    let message = error.errors[0].message;
    if (message === 'name-minLength') {
      message = formatMessage('Group name cannot be empty');
    }
    return (
      <div className="GroupNameForm-error">
        <ErrorMessage message={message} inline />
        <Announce message={message} />
      </div>
    );
  }

  static Saved () {
    const message = formatMessage('Changes saved');
    return (
      <div className="GroupNameForm-saved">
        {message}
        <CheckIcon className="GroupNameForm-saved-icon" />
        <Announce message={message} />
      </div>
    );
  }
}
