import { UPDATE_SHOW_FILTER_TRAY_ACTION } from '../constants/filter';

const initialState = {
  showFilterTray: false,
};

export default function filterReducer (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SHOW_FILTER_TRAY_ACTION:
      return {
        ...state,
        showFilterTray: action.payload,
      };
    default:
      return state;
  }
}
