import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import reject from 'lodash/collection/reject';
import formatMessage from 'format-message';

import HandlesErrors from '../../common/handles-errors';
import * as LearningObjectReview from '../../../models/review';
import Review from './review';
import ReviewsSummary from './reviews-summary';
import MyReview from './my-review';
import Link from '../../common/link';

export default createReactClass({
  displayName: 'Reviews',

  mixins: [HandlesErrors],

  propTypes: {
    editingUser: PropTypes.object,
    learningObject: PropTypes.object,
    reviews: PropTypes.array,
    isAuthenticated: PropTypes.bool
  },

  getDefaultProps () {
    return {
      learningObject: {},
      editingUser: {}
    };
  },

  getInitialState () {
    return {
      reviews: [],
      myVotedReviewerUserIds: []
    };
  },

  componentDidMount () {
    LearningObjectReview.list(
      this.props.learningObject.id,
      this.setReviewsStateFromApi
    );
  },

  getList () {
    LearningObjectReview.list(
      this.props.learningObject.id,
      this.setReviewsStateFromApi
    );
  },

  setReviewsStateFromApi (err, body) {
    if (err || (body && body.errors)) {
      var msg = (
        <div>
          <strong>
            {formatMessage(
              "Sorry we couldn't load the reviews for this resource."
            )}
          </strong>
          &nbsp; {formatMessage('Please retry by')} &nbsp;
          <Link onClick={this.getList}>
            {formatMessage('clicking here')}
          </Link>
          .
        </div>
      );
      return this.setErrors({ param: 'reviews', msg: msg });
    } else {
      this.clearErrors();
    }

    this.setState({
      reviews: body && body.items ? body.items : []
    });
  },

  getMyReview () {
    for (const review of this.props.reviews) {
      if (review.userId === this.props.editingUser.id) {
        return review;
      } else if (this.props.learningObject.myRating) {
        return this.props.learningObject.myRating;
      }
    }
  },

  getAllReviewsExceptMine () {
    if (this.getMyReview() && this.props.editingUser.id) {
      return reject(this.state.reviews, review => {
        return (
          this.props.learningObject.id === review.resourceId &&
          this.props.editingUser.id === review.userId
        );
      });
    } else {
      return this.state.reviews;
    }
  },

  handleVote (review) {
    return event => {
      var reviews = this.state.reviews;
      var reviewIndex = reviews.indexOf(review);
      if (reviews[reviewIndex]) {
        reviews[reviewIndex].voteCount += 1;
      }
      var myVotedReviewerUserIds = this.state.myVotedReviewerUserIds;
      myVotedReviewerUserIds.push(review.userId);
      this.setState({ reviews, myVotedReviewerUserIds });
      // LearningObjectReview.createVote(review.resourceId, review.userId);
    };
  },

  renderMyReview () {
    if (!this.props.learningObject.isCreator && this.props.isAuthenticated) {
      return (
        <div>
          <MyReview
            ref="myReview"
            learningObjectId={this.props.learningObject.id}
            review={this.getMyReview()}
            editingUser={this.props.editingUser}
          />
          <div className="lor-review-divider" />
        </div>
      );
    }
  },

  renderReviews () {
    return this.getAllReviewsExceptMine().map(review => {
      var key = review.resourceId + review.userId;
      var hasVoted =
        this.state.myVotedReviewerUserIds.indexOf(review.userId) !== -1;
      return (
        <Review
          ref={key}
          key={key}
          review={review}
          isVotable={!!review.body}
          hasVoted={hasVoted}
          onVote={this.handleVote(review)}
        />
      );
    });
  },

  render () {
    const showTitle =
      this.state.reviews.length > 0 || !this.props.learningObject.isCreator;
    return (
      <div className="lor-reviews">
        <ReviewsSummary
          ref="reviewsSummary"
          learningObject={this.props.learningObject}
          showTitle={showTitle}
        />
        {this.renderMyReview()}
        {this.renderReviews()}
        {this.renderErrors()}
      </div>
    );
  }
});
