import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';

export function getMediaSources (learningObjectId, done) {
  const path = `/resources/${learningObjectId}/media-preview-sources`;
  const request = apiRequest({
    path
  });

  request.end((err, res) => {
    if (res && res.body && res.body.retry_after) {
      setTimeout(
        () => getMediaSources(learningObjectId, done),
        res.body.retry_after * 1000
      );
      return null;
    }

    if (isErroneous(err, res)) {
      return done(err, res ? res.body : null);
    }

    done(null, res.body);
  });
}
