import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import SetupComplete from '../components/setup-complete';

export default function ({ resolve, render }) {
  render(
    <div>
      <Head title={formatMessage('Setup Complete')} />
      <SetupComplete />
    </div>
  );
  resolve();
}
