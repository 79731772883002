import formatMessage from 'format-message';
import gradeSchemes from '../components/resources/forms/grade-schemes/us';
import { contentCategoryTypePluralLabels } from '../components/resources/forms/content-category-type-selector';

const TYPES_PARAM_ALIASES = {
  discussion_topic: formatMessage('discussions'),
  course: formatMessage('courses'),
  module: formatMessage('modules'),
  assignment: formatMessage('assignments'),
  quiz: formatMessage('classic quizzes'),
  quizzesnext: formatMessage('new quizzes'),
  page: formatMessage('pages'),
  document: formatMessage('documents'),
  video: formatMessage('videos'),
  image: formatMessage('images'),
  audio: formatMessage('audio')
};

export function tokenizeTypesQuery ({ types }) {
  return types != null
    ? types.split(',').map(t => {
      return {
        param: 'types',
        value: t,
        alias: TYPES_PARAM_ALIASES[t]
      };
    })
    : null;
}

export function tokenizeContentCategoryTypesQuery ({ contentCategoryTypes }) {
  return contentCategoryTypes != null
    ? contentCategoryTypes.split(',').map(t => {
      return {
        param: 'contentCategoryTypes',
        value: t,
        alias: contentCategoryTypePluralLabels[t]
      };
    })
    : null;
}

export function tokenizeGradesQuery ({ gradeIds }) {
  if (!gradeIds) return null;

  return gradeIds
    .split(',')
    .map(grade => {
      const gradeSchema = gradeSchemes.find(g => g.value === grade);

      return {
        param: 'gradeIds',
        value: gradeSchema.abbreviation || grade,
        alias: gradeSchema.abbreviation || gradeSchema.label
      };
    });
}

// looks for authorIds in the query param. uses the authorName param
// as the alias for each tokenized query. Note: the authorName is not
// a recognized param in the Resources Search API
export function tokenizeAuthorInfoQuery ({ authorIds, authorName }) {
  return authorIds != null
    ? authorIds.split(',').map(a => {
      return {
        param: 'authorIds',
        value: a,
        alias: authorName
      };
    })
    : null;
}

export function typesQueryRemove (query, key, param) {
  if (!query.types || key !== 'types') return {};

  const typesArray = query.types.split(',');
  return {
    types: typesArray.filter(type => type !== param).join(',')
  };
}

export function contentCategoryTypeQueryRemove (query, key, param) {
  if (!query.contentCategoryTypes || key !== 'contentCategoryTypes') return {};

  const contentCategoryTypesArray = query.contentCategoryTypes.split(',');
  return {
    contentCategoryTypes: contentCategoryTypesArray.filter(contentCategoryType => contentCategoryType !== param).join(',')
  };
}

export function gradeIdsRemove (query, key, param) {
  if (!query.gradeIds || key !== 'gradeIds') return {};

  const gradeIds = query.gradeIds.split(',');

  return {
    gradeIds: gradeIds.filter(gradeId => gradeId !== param).join(',')
  };
}

// clears out the author name as long as there is an authorId to remove
export function authorInfoQueryRemove (query, key, param) {
  if (!query.authorIds || key !== 'authorIds') return {};

  return { authorIds: null, authorName: null };
}

export const removeFunctions = () => {
  return [
    typesQueryRemove,
    gradeIdsRemove,
    authorInfoQueryRemove,
    contentCategoryTypeQueryRemove
  ];
};

export const tokenizeFunctions = () => {
  return [
    tokenizeTypesQuery,
    tokenizeGradesQuery,
    tokenizeAuthorInfoQuery,
    tokenizeContentCategoryTypesQuery
  ];
};
