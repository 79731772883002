import formatMessage from 'format-message';
import { replaceErrors } from './replace';

export const thumbnailErrors = {
  'media-id-invalid': formatMessage('Invalid value for media id'),
  'height-invalid': formatMessage('Invalid value for height'),
  'width-invalid': formatMessage('Invalid value for width')
};

export default function (err) {
  return replaceErrors(err, thumbnailErrors);
}
