import apiRequest from '../common/api-request';

const langParam = window.location.search.match(/[&?]lang=([^&]*)/);
const sidParam = window.location.search.match(/[&?]session-id=([^&]*)/);

const sid = window.localStorage.SESSION_ID;
const storedSessionId = sid === 'null' ? null : (sid === 'undefined' ? undefined : sid);

const env = {
  SESSION_ID: (sidParam && sidParam[1]) || storedSessionId,
  HIGH_CONTRAST: window.localStorage.HIGH_CONTRAST === '1',
  LANG: (langParam && langParam[1]) || window.localStorage.LANG || 'en',
  // PREVIEWER_URL: coming from the api on startup
};

// set localStorage to most recently used tab's session
// this makes sure opening in a new tab and reloading get the right session
window.localStorage.SESSION_ID = env.SESSION_ID;
window.localStorage.HIGH_CONTRAST = env.HIGH_CONTRAST ? 1 : 0;
window.localStorage.LANG = env.LANG;
window.addEventListener(
  'focus',
  () => {
    window.localStorage.SESSION_ID = env.SESSION_ID;
    window.localStorage.HIGH_CONTRAST = env.HIGH_CONTRAST ? 1 : 0;
    window.localStorage.LANG = env.LANG;
  },
  false
);

export async function fetchConfigFromApi () {
  const configResp = await apiRequest({ path: '/client-config' }).promise();
  Object.assign(env, configResp.body);
}

export default env;
