import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loading from './loading';

export default class CroppedImg extends React.Component {
  state = {
    isLoading: true
  }

  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    onLoad: PropTypes.func
  }

  static defaultProps = {
    className: ''
  }

  handleImgOnLoad = () => {
    var box = this.refs.box;
    var canvas = this.refs.canvas;
    var width = (canvas.width = box.clientWidth);
    var height = (canvas.height = box.clientHeight);
    var boxRatio = width / height;

    var img = this.refs.img;
    var { naturalWidth, naturalHeight } = img;
    var imgRatio = naturalWidth / naturalHeight;

    var w = naturalWidth;
    var h = naturalHeight;

    if (imgRatio > boxRatio) {
      // cap height
      h = height;
      w = (naturalWidth / naturalHeight) * height;
    } else {
      // cap width
      w = width;
      h = (naturalHeight / naturalWidth) * width;
    }

    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    var ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(img, x, y, w, h);

    this.setState(
      {
        isLoading: false
      },
      () => {
        if (this.props.onLoad) {
          this.props.onLoad();
        }
      }
    );
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (
      this.props.src !== props.src ||
      this.props.className !== props.className
    ) {
      this.setState({ isLoading: true });
    }
  }

  shouldComponentUpdate (props, state) {
    return !(
      this.props.className === props.className &&
      this.props.src === props.src &&
      this.props.alt === props.alt &&
      this.state.isLoading === state.isLoading
    );
  }

  render () {
    let { className, noBorderRadius, ...rest } = this.props;
    className = classNames('CroppedImg',
      className,
      {
        'CroppedImg-borderRadius': !noBorderRadius
      }
    );

    return (
      <span {...rest} ref="box" className={className}>
        {this.props.src && (
          <img
            // React does not detect changes when source remains the same
            // In order to call `handleImgOnLoad` and resize the image we change the key on render
            key={Date.now()}
            ref="img"
            className="CroppedImg-img"
            src={this.props.src}
            alt=""
            onLoad={this.handleImgOnLoad}
          />
        )}
        <canvas
          ref="canvas"
          className="CroppedImg-canvas"
          style={{
            opacity: this.state.isLoading ? 0 : null,
          }}
        >
          {this.props.alt}
        </canvas>
        {this.props.src &&
          this.state.isLoading && <Loading className="CroppedImg-loading" />}
      </span>
    );
  }
}
