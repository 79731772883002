import React from 'react';
import { getAlertMessage } from '../alert-footer/alert-footer-import-status';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

const FavoriteResourceImportStatusScreenReaderContent = ({ importStatus }) => {
  const screenReaderStatus = Object.keys(importStatus)
    .map(key => importStatus[key])
    .filter(
      ({ isImporting, error, importResult }) =>
        isImporting || error || importResult
    )
    .map(importStatus => (
      <div key={importStatus.resource.id}>{getAlertMessage(importStatus)}</div>
    ));

  return (
    <div
      tabIndex="-1"
      aria-live="polite"
      aria-relevant="additions text"
    >
      <ScreenReaderContent>{screenReaderStatus}</ScreenReaderContent>
    </div>
  );
};

export default FavoriteResourceImportStatusScreenReaderContent;
