import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Popover } from '@instructure/ui-popover';
import { View } from '@instructure/ui-view';
import { IconNoLine } from '@instructure/ui-icons';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import Button from '../../common/button';

export default class CuratedPopover extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      displayPopover: false
    };
  }

  static defaultProps = {
    accountName: PropTypes.string
  }

  togglePopover = () => {
    this.setState({
      displayPopover: !this.state.displayPopover
    });
  }

  render () {
    return (
      <Popover
        on="click"
        isShowingContent={this.state.displayPopover}
        onHideContent={this.togglePopover}
        placement="top start"
        shouldContainFocus
        shouldReturnFocus
        shouldCloseOnDocumentClick
        renderTrigger={
          <Button
            onClick={this.togglePopover}
            renderIcon={<IconNoLine color="error" size="x-small" />}
          >
            <ScreenReaderContent>
              {formatMessage('Resource updating not available')}
            </ScreenReaderContent>
          </Button>
        }
      >
        <View width={705} display="flex">
          <div
            className="Popover-warningWrapper"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <View
              width={45}
              display="inline-block"
              as="div"
              textAlign="center"
            >
              <IconNoLine color="primary-inverse" size="x-small" />
            </View>
          </div>
          <View
            maxWidth={640}
            display="inline-block"
            as="div"
            padding="small"
          >
            <p>
              <strong>
                {formatMessage('Contact Canvas Admin to Update.')}
              </strong>
              {formatMessage(
                'This resource has been designated {institution} approved by your Canvas Admin. New Versions must be viewed and approved by them. Please contact your Canvas Admin to proceed',
                {
                  institution: this.props.accountName
                }
              )}
            </p>
            <Button
              color={Button.color.primary}
              onClick={this.togglePopover}
              margin="x-small 0 0 0"
            >
              {formatMessage('Ok, Got it')}
            </Button>
          </View>
        </View>
      </Popover>
    );
  }
}
