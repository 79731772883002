import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../../components/app-handler';
import { AccountActions, SessionActions } from '../../actions';
import AccountSettings from '../../components/account/settings';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import AccountHeader from './account-header';

const view = () => {
  const state = getState();

  if (state.session.retrievalSuccessful && !state.session.isAdmin) {
    return <NotAuthenticated />;
  }

  return (
    <AppHandler
      activeHeader="account"
      session={state.session}
      updates={state.updates}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Account Settings')} />
      <div className="AccountSettings App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs
          activeTab="settings"
          session={state.session}
          isManager={!!state.groups.isManager}
        />
        <h2 className="AccountSettings-title">{formatMessage('Account Settings')}</h2>
        <AccountSettings
          accountState={state.account}
          userState={state.users}
          session={state.session}
          consortiums={state.consortiums}
          groups={state.groups}
        />
      </div>
    </AppHandler>
  );
};

export default function ({ resolve, render, exiting }) {
  AccountActions.loadAccountCurators();

  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
  render(view());
  resolve();
}
