import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

export default class VersionNotes extends React.Component {
  static propTypes = {
    resource: PropTypes.object.isRequired
  }

  render () {
    const versions = this.props.resource.versions
      .filter(version => version.versionNotes && version.versionNotes.length)
      .map(version => (
        <li className="VersionNotes-note" key={version.id}>
          <div className="VersionNotes-note-title">
            {formatMessage(
              'Updated { updatedDate, date, medium } - { updatedDate, time, short }',
              {
                updatedDate: new Date(version.createDate)
              }
            )}
          </div>
          <div className="VersionNotes-note-body">{version.versionNotes}</div>
        </li>
      ));

    return <ul className="VersionNotes-list">{versions}</ul>;
  }
}
