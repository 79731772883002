import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Autocomplete from '../../common/react-autocomplete';
import classnames from 'classnames';
import formatMessage from 'format-message';
import * as announce from '../../../common/announce';

export default createReactClass({
  displayName: 'ConsortiumMemberCreate',

  propTypes: {
    createMember: PropTypes.func.isRequired,
    accounts: PropTypes.array,
    accountsCount: PropTypes.number,
    searchAccounts: PropTypes.func,
    resetAccounts: PropTypes.func
  },

  getInitialState () {
    return {
      value: ''
    };
  },

  onChange (event, value) {
    this.setState({ value }, () => {
      if (value === '') this.props.resetAccounts();
      if (value.length >= 3) {
        this.props.searchAccounts({
          query: value,
          includePublicOnly: false
        });
      } else if (value.length > 0) {
        announce.politely(
          formatMessage('Sorry, there is no match for {institution}', {
            institution: this.state.value
          })
        );
      }
    });
  },

  componentWillUnmount () {
    announce.reset();
  },

  highlightSearch (name) {
    // http://stackoverflow.com/a/9310752
    const regexEscapedInputValue = this.state.value.replace(
      /[-[\]{}()*+?.,\\^$|#\s]/g,
      '\\$&'
    );
    const searchExp = new RegExp(`(${regexEscapedInputValue})`, 'gi');
    const splitName = name.split(searchExp);

    return splitName.map((text, i) => {
      // the split that ends up being used does not remove matches
      const className = classnames({
        'AccountResult-highlighted': text.search(searchExp) !== -1
      });
      return (
        <span className={className} key={i}>
          {text}
        </span>
      );
    });
  },

  renderResult (item, isHighlighted) {
    if (isHighlighted) {
      const index = this.props.accounts.indexOf(item);
      announce.assertively(`${index + 1} ${item.name}`);
    }
    const className = classnames('AccountResult', {
      'AccountResult-selected': isHighlighted,
      'AccountResult-disabled': item.isMember
    });

    return (
      <div
        aria-disabled={item.isMember}
        className={className}
        id={item.id}
        key={item.id}
      >
        {item.isMember && (
          <div className="ConsortiumMemberInAccount">
            {item.name}{' '}
            <span className="InConsortium">
              {formatMessage('In Consortium')}
            </span>
          </div>
        )}
        {!item.isMember && this.highlightSearch(item.name)}
      </div>
    );
  },

  getItemValue () {
    return ''; // disabling autocomplete feature for now because of SR annoyances
  },

  renderMenu (items, value, style) {
    if (items.length === 0) {
      return <div />;
    }
    return <div className="ConsortiumMemberCreate-search-menu">{items}</div>;
  },

  onSelect (value, item) {
    if (item.isMember) {
      return;
    }
    this.setState({ value: '' }, () => {
      this.props.resetAccounts();
    });
    this.props.createMember(item.id);
    announce.assertively(
      formatMessage('{name} has been invited', {
        name: item.name
      })
    );
  },

  render () {
    return (
      <div className="ConsortiumMemberCreate">
        <Autocomplete
          inputProps={{
            className: 'ConsortiumMemberCreate-input',
            placeholder: formatMessage('Search institutions to add'),
            type: 'text',
            autoComplete: 'off',
            autoCorrect: 'off'
          }}
          ariaLabelText={formatMessage('Search institutions to add')}
          getItemValue={this.getItemValue}
          value={this.state.value}
          onChange={this.onChange}
          items={this.props.accounts}
          renderItem={this.renderResult}
          renderMenu={this.renderMenu}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
});
