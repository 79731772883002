import formatMessage from 'format-message';
import {
  SORT_AUTHOR,
  SORT_DOWNLOADS,
  SORT_FAVORITE,
  SORT_RECENT,
  SORT_TITLE
} from '../../../constants/search';

export const getVisibleHeaders = (isDesktopView) => {
  const textAlign = getTextAlignByView(isDesktopView);

  const headers = [
    {
      id: SORT_TITLE,
      label: formatMessage('Title'),
      width: '40%',
      sortable: true
    },
    {
      id: 'grade',
      label: formatMessage('Grade level'),
      sortable: false
    },
    {
      id: SORT_AUTHOR,
      label: formatMessage('Author'),
      sortable: true
    },
    {
      id: SORT_RECENT,
      label: formatMessage('Last updated'),
      sortable: true,
      textAlign: textAlign
    },
    {
      id: SORT_FAVORITE,
      label: formatMessage('Favorites'),
      sortable: true,
      textAlign: textAlign,
    },
    {
      id: SORT_DOWNLOADS,
      label: formatMessage('Downloads'),
      sortable: true,
      textAlign: textAlign
    },
    {
      id: 'favorite-action',
      label: formatMessage('Favorite'),
      sortable: false,
      hiddenInMobile: true,
      textAlign: textAlign
    }
  ];

  return headers.filter((header) => isDesktopView || !header.hiddenInMobile);
};

export const getTextAlignByView = (isDesktopView) => {
  return isDesktopView ? 'center' : 'start';
};
