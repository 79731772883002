import React from 'react';
import PropTypes from 'prop-types';
import { Select } from './select';
import { usStatesOptions } from '../../constants/united-states-states';

export default class OutcomeAuthoritySelect extends React.Component {
  static displayName = 'OutcomeAuthoritySelect'

  static propTypes = {
    noneLabel: PropTypes.string
  }

  static noneValue = 'NONE'

  render () {
    const noneOption = {
      id: OutcomeAuthoritySelect.noneValue,
      label: this.props.noneLabel || '',
      value: OutcomeAuthoritySelect.noneValue
    };
    const options = [
      noneOption,
      ...usStatesOptions,
    ];
    const { noneLabel, ...props } = this.props;

    return (
      <Select {...props} options={options} />
    );
  }
}
