import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconFlagLine } from '@instructure/ui-icons';
import { Alert } from '@instructure/ui-alerts';

import Button from '../../common/button';
import { ReportsActions } from '../../../actions';
import ReportContentModal from './report-content-modal';

const ALERT_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  NONE: 'none'
};

const ReportAlert = ({ alertType, onDismiss }) => {
  if (alertType === ALERT_STATUS.NONE) return null;
  const variant = alertType === ALERT_STATUS.SUCCESS ? 'success' : 'error';
  const message =
    alertType === ALERT_STATUS.SUCCESS
      ? formatMessage('We have received your report. Thank you!')
      : formatMessage('There was a problem sending the report. Please try again.');
  return (
    <div className="lor-report-content-btn-alert">
      <Alert
        variant={variant}
        timeout={5000}
        liveRegionPoliteness="assertive"
        liveRegion={() => document.getElementById('screenreader-announcements-assertive')}
        onDismiss={onDismiss}
      >
        {message}
      </Alert>
    </div>
  );
};

const ReportContent = ({ learningObject }) => {
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [alertType, setAlertType] = React.useState(ALERT_STATUS.NONE);
  const [isReporting, setIsReporting] = React.useState(false);

  const sendReport = async (reportType) => {
    setIsReporting((prevState) => {
      if (prevState) return prevState;
      ReportsActions.reportContent(learningObject.id, reportType).then((reportSuccess) => {
        setIsReporting(false);
        if (reportSuccess) {
          setAlertType(ALERT_STATUS.SUCCESS);
          setIsShowModal(false);
        } else {
          setAlertType(ALERT_STATUS.ERROR);
        }
      });
      return true;
    });
  };

  return (
    <React.Fragment>
      <div className="lor-report-content-btn">
        <Button
          renderIcon={IconFlagLine}
          onClick={() => setIsShowModal(true)}
          display={Button.display.block}
          disabled={isShowModal}
        >
          {formatMessage('Report content')}
        </Button>
        <ReportAlert alertType={alertType} onDismiss={() => setAlertType(ALERT_STATUS.NONE)} />
      </div>
      {isShowModal ? (
        <ReportContentModal
          sendReport={sendReport}
          isReporting={isReporting}
          closeModal={() => setIsShowModal(false)}
        />
      ) : null}
    </React.Fragment>
  );
};

ReportContent.propTypes = {
  learningObject: PropTypes.object.isRequired
};

export default ReportContent;
