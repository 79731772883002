import Uniflow from 'uniflow';
import apiRequest from '../common/api-request';
import { isResourcePaginated } from '../common/util/is-resource-paginated';
import { MANAGED_RESOURCES_PAGE_SIZE, SHARED_PAGE_SIZE, FAVORITES_PAGE_SIZE } from '../constants/paginated-page-sizes';
import { RESOURCES_MANAGED, RESOURCES } from '../constants/search-paths';
import localizeErrors from '../models/error-messages/resources';
import RouterActions from '../actions/router';
import ResultsActions from '../actions/results';
import LearningObjectActions from '../actions/resource';
import RouterStore from '../stores/router';
import ResultsStore from '../stores/results';
import SessionStore from '../stores/session';
import buildSearchQuery from './util/build-search-query';

// models
import * as LearningObjectModel from '../models/resource';
import { localizeDescription } from '../models/thumbnail-get-description';
import { calculatePaginationPages } from '../common/util/calculate-pagination-pages';

let bootstrapped = false;

const getParamsByPageType = () => {
  const baseParams = {
    options: {
      isPaginated: true,
    },
    prevParams: {
      ...RouterStore.state.query
    },
    path: RESOURCES
  };
  switch (RouterStore.state.pathname) {
    case '/account/resources':
      if (SessionStore.state.isAdmin || SessionStore.state.isAccountCurator) {
        baseParams.prevParams.accountId = SessionStore.state.account.id;
        baseParams.prevParams.showPublic = true;
      }
      baseParams.options.resultsPerPage = MANAGED_RESOURCES_PAGE_SIZE;
      baseParams.path = RESOURCES_MANAGED;
      return baseParams;
    case '/shared':
      if (SessionStore.state.isAdmin || SessionStore.state.isAccountCurator) {
        baseParams.prevParams.accountId = SessionStore.state.account.id;
        baseParams.prevParams.showPublic = true;
      }
      baseParams.options.resultsPerPage = SHARED_PAGE_SIZE;
      return baseParams;
    case '/favorites':
      baseParams.options.resultsPerPage = FAVORITES_PAGE_SIZE;
      baseParams.prevParams.onlyFavorites = true;
      return baseParams;
    default:
      return false;
  }
};

const SearchActions = Uniflow.createActions({
  bootstrap () {
    if (bootstrapped) {
      return;
    }

    LearningObjectActions.on('learning-object-destroyed', SearchActions.refetchPage);
    ResultsActions.on('remove-from-favorites', () => SearchActions.refetchPage());

    bootstrapped = true;
  },

  search (query) {
    query.where.cursor = query.where.cursor || 'initial';
    this.emit('search-pending', query);
    LearningObjectModel.findAll(query, this.handleSearchResponse);
  },

  update (change) {
    const params = Object.assign({}, RouterStore.state.query, change);

    for (let key of Object.keys(params)) {
      if (key === 'query') {
        key = 'q';
        params.q = params.query;
        delete params.query;
      }
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
      if (params[key] == null || params[key] === '') {
        delete params[key];
      }
    }

    // reset page count if the filters or the ordering have changed
    if (params.page && !change.page) {
      delete params.page;
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  clear () {
    const params = {
      ...RouterStore.state.query,
    };

    for (const key of Object.keys(params)) {
      if (key === 'q' || key === 'sortBy') {
        continue;
      }

      delete params[key];
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  handleSearchResponse (err, results) {
    if (err) {
      this.emit('search-error', err);
    } else {
      this.emit('search-successful', results);
    }
  },

  loadNextPage (cursor, lastQuery) {
    if (cursor) {
      var query = Object.assign({}, lastQuery);
      query.where.cursor = cursor;

      this.search(query);
    }
  },

  clearResults () {
    this.emit('results-reset');
  },

  redirectToLastPage (count, size) {
    const paginationPages = calculatePaginationPages(1, count, size);

    return this.update({
      page: paginationPages.lastAvailablePage,
    });
  },

  fetchResultsWithPath (params, path, options = {}) {
    const query = buildSearchQuery(params, options);

    if (query.cursor === undefined) {
      this.emit('results-reset');
    }
    this.emit('results-reset-placeholder');

    const deletePreviousItems = !!options.isPaginated;

    const req = apiRequest({
      path,
      query
    });
    this.emit('results-requested', req, deletePreviousItems);

    req.end((err, res) => {
      if (err) {
        localizeErrors(err);
        return this.emit('results-error', err);
      }

      const isPageInvalid = res.body.meta &&
        res.body.meta.count &&
        res.body.items &&
        !res.body.items.length;

      if (options.isPaginated && isPageInvalid) {
        return this.redirectToLastPage(res.body.meta.count, query.size);
      }

      (res.body.items || []).forEach(localizeDescription);
      this.emit('results-response', req, res, deletePreviousItems);
    });
  },

  refetchPage () {
    const params = getParamsByPageType();
    const isPageEmptyAfterRemoval = ResultsStore.get().length === 1;
    if (!params || !isResourcePaginated() || (!ResultsStore.hasMore() && !isPageEmptyAfterRemoval)) {
      return;
    }
    const { prevParams, path, options } = params;
    this.emit('results-increase-placeholder');
    const prevQuery = buildSearchQuery(prevParams, options);
    const req = apiRequest({
      path,
      query: {
        ...prevQuery,
        size: prevQuery.size + 1 // increase the size to prevent too fast refetching bug (we got the deleted item too)
      }
    });

    req.end((err, res) => {
      if (ResultsStore.getPlaceholdersCount() === 0) {
        return;
      }
      this.emit('results-decrease-placeholder');
      if (err) {
        localizeErrors(err);
      }
      (res.body.items || []).forEach(localizeDescription);
      this.emit('results-sync-response', res);
      const isPageEmpty = ResultsStore.get().length === 0;
      const pageFromQuery = parseInt(RouterStore.state.query.page, 10) || 1;
      const isFirstPage = pageFromQuery === 1;
      if (isPageEmptyAfterRemoval && isPageEmpty && !isFirstPage) {
        this.update({ page: RouterStore.state.query.page - 1 });
      }
    });
  },

  dontConfirmOnDelete () {
    this.emit('dont-confirm-on-delete');
  },
});

export default SearchActions;
