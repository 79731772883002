import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noResultSrc from '../../../assets/svg/no_result.svg';

export default function NoResult ({
  title,
  message,
  imageUrl,
  imageAlt,
  imageSize,
  className,
  ...rest
}) {
  className = classNames(className, 'No-result');

  return (
    <div {...rest} className={className}>
      <img
        className="No-result-image"
        src={imageUrl}
        alt={imageAlt || title}
        style={{ maxHeight: imageSize }}
      />
      <h2 className="No-result-title" role="status" aria-live="polite">{title}</h2>
      {message && <p className="No-result-message" role="status" aria-live="polite">{message}</p>}
    </div>
  );
}

NoResult.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  imageHeight: PropTypes.number
};

NoResult.defaultProps = {
  imageUrl: noResultSrc,
  imageSize: 200
};
