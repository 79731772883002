import LearningObjectActions from '../../actions/resource';
import { throwOnNullishParams } from '../validation';

export const importIntoCourse = async ({
  courseId,
  courseName,
  learningObject,
  target
}) => {
  return new Promise((resolve, reject) => {
    LearningObjectActions.importIntoCourse(
      {
        learningObjectId: learningObject.id,
        courseId,
        courseName,
        target
      },
      response => {
        if (response.error) {
          reject(response.error);
          return;
        }
        resolve(response);
      });
  });
};

export const getFileUrl = ({ canvasUrl, courseId, contentId } = {}) => {
  throwOnNullishParams({
    canvasUrl,
    courseId,
    contentId
  });
  return `${canvasUrl}/courses/${courseId}/files/${contentId}/download?wrap=1`;
};
