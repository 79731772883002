import React from 'react';
import TosEnglishText from './tos-text-en';
import PageTitle from './common/page-title';
import formatMessage from 'format-message';

export default function () {
  return (
    <div className="lor-tos-body">
      <PageTitle title={formatMessage('Terms of Use')} />
      <TosEnglishText />
    </div>
  );
}
