import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Checkbox } from '@instructure/ui-checkbox';
import { Text } from '@instructure/ui-text';

import FormGroup from '../../../common/form-group';

const CONSORTIUM_PREFIX = 'consortium-';
const GROUP_PREFIX = 'group-';

export default class SharedWith extends React.Component {
  static displayName = 'SharedWith'

  static propTypes = {
    account: PropTypes.object,
    consortiums: PropTypes.object,
    errorMessage: PropTypes.node,
    groups: PropTypes.array,
    hasErrors: PropTypes.bool,
    isUpdate: PropTypes.bool,
    onScopeIdChange: PropTypes.func,
    onFeaturedChange: PropTypes.func,
    resource: PropTypes.object,
    session: PropTypes.object,
    sessionAccount: PropTypes.object,
    user: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.state = {
      expandConsortiums: this.hasConsortiumScopes(),
      expandGroups: this.hasGroupScopes(),
      isAccountCurator: props.session.isAdmin || props.session.isAccountCurator
    };
  }

  componentDidMount () {
    this.removeAccountIdScopeId();
  }

  componentDidUpdate () {
    this.removeAccountIdScopeId();
  }

  removeAccountIdScopeId = () => {
    const { denyShareWithInstance } = this.props.account.settings;
    const { scopeIds } = this.props.resource;
    const accountId = this.props.sessionAccount.id;

    if (denyShareWithInstance && scopeIds.includes(accountId)) {
      this.props.onScopeIdChange(scopeIds.filter(scopeId => scopeId !== accountId));
    }
  }

  expandGroups = evt => {
    const expandCategory = {
      consortiums: 'expandConsortiums',
      groups: 'expandGroups'
    };
    const expandArgument = evt.target.value;
    const categoryPrefix =
      expandArgument === 'groups' ? GROUP_PREFIX : CONSORTIUM_PREFIX;

    this.setState({ [expandCategory[expandArgument]]: evt.target.checked });

    if (!evt.target.checked) {
      const { scopeIds } = this.props.resource;
      const filteredScope = scopeIds.filter(
        resource => !resource.startsWith(categoryPrefix)
      );
      this.props.onScopeIdChange(filteredScope);
    }
  }

  hasResourceScope = resourcePrefix => {
    const { scopeIds } = this.props.resource;
    return scopeIds.some(id => id.startsWith(resourcePrefix));
  }

  hasConsortiumScopes = () => {
    const { scopeIds } = this.props.resource;
    return scopeIds.some(id => id.startsWith(CONSORTIUM_PREFIX));
  }

  hasGroupScopes = () => {
    const { scopeIds } = this.props.resource;
    return scopeIds.some(id => id.startsWith(GROUP_PREFIX));
  }

  getSelectedGroupIds = resource => {
    return resource.scopeIds
      .filter(scopeId => scopeId.startsWith(GROUP_PREFIX))
      .map(scopeId => scopeId.slice(GROUP_PREFIX.length));
  }

  handleScopeIdChange = (event, prefix) => {
    const privateId = this.props.resource.createUserId || this.props.user.id;
    const scopeId = event.target.value;
    const scopeIds = new Set(this.props.resource.scopeIds);
    const curatedScope = `curated-${this.props.sessionAccount.id}`;

    const isChecked = event.target.checked;
    const isAccountCurator = this.state.isAccountCurator;

    if (isChecked) {
      switch (scopeId) {
        case curatedScope:
          scopeIds.add(scopeId);
          break;
        case privateId:
          scopeIds.clear();

          if (
            !isAccountCurator &&
            this.props.resource.scopeIds.includes(curatedScope)
          ) {
            scopeIds.add(curatedScope);
          }

          scopeIds.add(privateId);

          this.setState({
            expandConsortiums: false,
            expandGroups: false
          });

          if (this.props.resource.featured) {
            this.props.onFeaturedChange(false);
          }
          break;
        default:
          scopeIds.delete(privateId);
          scopeIds.add(scopeId);
      }
    } else {
      scopeIds.delete(scopeId);
    }

    this.props.onScopeIdChange([...scopeIds]);
  }

  handleFeaturedChange = (event, prefix) => {
    const isChecked = event.target.checked;
    this.props.onFeaturedChange(isChecked);
  }

  render = () => {
    const scopeIds = this.props.resource.scopeIds || [];
    const consortiumOptions = this.props.consortiums.list.filter(
      ({ membershipStatus, membershipIsContributor }) =>
        membershipStatus === 'member' && membershipIsContributor
    );
    const areConsortiumsAvailable = consortiumOptions.length > 0;
    const areGroupsAvailable = this.props.groups.length > 0;

    const consortiumScopesAvailable = this.hasResourceScope(CONSORTIUM_PREFIX);
    const groupScopesAvailable = this.hasResourceScope(GROUP_PREFIX);

    const curatedScope = `curated-${this.props.sessionAccount.id}`;
    const isResourceOwner =
      this.props.resource.createUserId === this.props.user.id;
    const isPrivateResource = scopeIds.includes(
      this.props.resource.createUserId
    );
    const allowCuratedSharing =
      this.props.account.settings.allowApprovedContent &&
      this.state.isAccountCurator;

    const isCuratedContent = scopeIds.some(
      scopeId =>
        scopeId.includes('curated-') &&
        !allowCuratedSharing &&
        !this.props.isUpdate
    );

    const isAccountAdmin = this.props.session.isAdmin;
    const featured = this.props.resource.featured || false;
    const accountFeaturedContentEnabled = isAccountAdmin;

    return (
      <div>
        <FormGroup
          legend={formatMessage('Who can use this resource?')}
          htmlFor="scopeIds"
          required
          hasErrors={this.props.hasErrors}
          helpText={formatMessage('Maximum of 10')}
          errorMessage={this.props.errorMessage}
        >
          {isResourceOwner && (
            <div className="SharedWith-topCheckOption">
              <Checkbox
                checked={isPrivateResource}
                disabled={isCuratedContent}
                label={formatMessage('Only Me')}
                onChange={this.handleScopeIdChange}
                value={this.props.resource.createUserId}
                inline
              />
            </div>
          )}
          {!this.props.account.settings.denyShareWithInstance && (
            <div className="SharedWith-topCheckOption" data-testid="all-of-selector">
              <Checkbox
                data-heap-redact-attributes="label"
                checked={scopeIds.includes(this.props.sessionAccount.id)}
                disabled={isPrivateResource}
                label={formatMessage('All of { name }', {
                  name: this.props.sessionAccount.name
                })}
                onChange={this.handleScopeIdChange}
                value={this.props.sessionAccount.id}
                inline
              />
            </div>
          )}
          {areGroupsAvailable && (
            <div className="SharedWith-topCheckOption Group-parent" data-testid="resource-group-selector">
              <Checkbox
                checked={this.state.expandGroups || groupScopesAvailable}
                disabled={isPrivateResource}
                label={formatMessage('Select Group(s)')}
                onChange={this.expandGroups}
                value="groups"
                inline
              />
            </div>
          )}
          {areGroupsAvailable &&
          (this.state.expandGroups || groupScopesAvailable) &&
          this.props.groups.map(group => {
            const groupValue = `group-${group.id}`;
            return (
              <div
                className="SharedWith-childCheckOption"
                key={group.id}>
                <Checkbox
                  data-heap-redact-attributes="label"
                  checked={scopeIds.includes(groupValue)}
                  label={group.name}
                  onChange={this.handleScopeIdChange}
                  value={groupValue}
                  inline
                />
              </div>
            );
          })}
          {areConsortiumsAvailable && (
            <div className="SharedWith-topCheckOption Consortium-parent">
              <Checkbox
                checked={
                  this.state.expandConsortiums || consortiumScopesAvailable
                }
                disabled={isPrivateResource}
                label={formatMessage('Select Consortium(s)')}
                onChange={this.expandGroups}
                value="consortiums"
                inline
              />
            </div>
          )}
          {areConsortiumsAvailable &&
          (this.state.expandConsortiums || consortiumScopesAvailable) &&
          consortiumOptions.map(consortium => {
            const consortiumValue = `consortium-${consortium.id}`;
            return (
              <div
                className="SharedWith-childCheckOption"
                key={consortium.id}>
                <Checkbox
                  data-heap-redact-attributes="label"
                  checked={scopeIds.includes(consortiumValue)}
                  label={consortium.name}
                  onChange={this.handleScopeIdChange}
                  value={consortiumValue}
                  inline
                />
              </div>
            );
          })}
          {this.props.account.settings.canSharePublic && (
            <div className="SharedWith-topCheckOption">
              <Checkbox
                checked={scopeIds.includes('PUBLIC')}
                disabled={isPrivateResource}
                label={formatMessage('Public (any Canvas Commons user)')}
                onChange={this.handleScopeIdChange}
                value="PUBLIC"
                inline
              />
            </div>
          )}
        </FormGroup>

        {(allowCuratedSharing || accountFeaturedContentEnabled) && (
          <FormGroup
            label={formatMessage('Shared as...')}
            htmlFor="additionalSharingOption"
          >
            {allowCuratedSharing && (
              <div className="SharedAs-Curated">
                <Checkbox
                  checked={scopeIds.includes(curatedScope)}
                  disabled={isPrivateResource}
                  label={<Text size="medium">{formatMessage('Institution Approved Content')}</Text>}
                  onChange={this.handleScopeIdChange}
                  size="small"
                  value={curatedScope}
                  variant="toggle"
                  inline
                />
              </div>
            )}
            {accountFeaturedContentEnabled && (
              <div className="SharedAs-Featured">
                <Checkbox
                  checked={featured}
                  disabled={isPrivateResource}
                  label={<Text size="medium">{formatMessage('Featured Content')}</Text>}
                  onChange={this.handleFeaturedChange}
                  size="small"
                  value="featured"
                  variant="toggle"
                  inline
                />
              </div>
            )}
          </FormGroup>
        )}
      </div>
    );
  }
}
