import React from 'react';
import PropTypes from 'prop-types';
import CroppedImg from '../../common/cropped-img';
import MediaHoverPreview from './media-hover-preview';
import ResourceTypeIcon from '../../icons/resource-type';
import { resourceTypes } from '../../../common/app-constants';
import iconApprovedSrc from '../../../../assets/svg/approved-check.svg';

export default class SearchResultThumbnail extends React.Component {
  static propTypes = {
    displayApprovedBanner: PropTypes.bool,
    learningObject: PropTypes.object.isRequired,
    isFocused: PropTypes.bool,
    isHovered: PropTypes.bool,
    showPreview: PropTypes.bool,
    curatedBanner: PropTypes.object
  }

  static defaultProps = {
    isFocused: false,
    isHovered: false,
    showPreview: true
  }

  isMedia () {
    var type = this.props.learningObject.type;
    return type === 'video' || type === 'audio';
  }

  render () {
    var croppedImage;
    const hasThumbnail =
      this.props.learningObject &&
      this.props.learningObject.thumbnail &&
      this.props.learningObject.thumbnail.url;

    if (hasThumbnail) {
      croppedImage = (
        <CroppedImg
          className="SearchResultThumbnail-image"
          src={this.props.learningObject.thumbnail.url}
          alt={this.props.learningObject.thumbnail.description}
        />
      );
    }

    var mediaPreview;
    const hasMediaPreview = this.isMedia() && this.props.showPreview;
    if (hasMediaPreview) {
      mediaPreview = (
        <MediaHoverPreview
          className="SearchResultThumbnail-preview"
          learningObject={this.props.learningObject}
          isHovered={this.props.isHovered}
          isFocused={this.props.isFocused}
        />
      );
    }

    const type = this.props.learningObject.type;
    const resourceType = resourceTypes[type] || {};
    const { color: approvedColor = null, image: approvedIcon = null } =
      this.props.curatedBanner || {};

    return (
      <div className="SearchResultThumbnail" data-automation="ResourceThumbnail">
        {croppedImage}
        {mediaPreview}
        {this.props.displayApprovedBanner && (
          <div className="SearchResultThumbnail-approved-container">
            <div
              className="SearchResultThumbnail-approved-banner"
              style={{
                borderTopColor: approvedColor
              }}
            />
            <img
              aria-hidden
              className="SearchResultThumbnail-approved-icon"
              data-automation="ResourceThumbnailApprovedIcon"
              src={approvedIcon || iconApprovedSrc}
            />
          </div>
        )}

        <div className="SearchResultThumbnail-icon-bg" data-automation="ResourceTypeIcon">
          <ResourceTypeIcon
            aria-label={resourceType.singularName}
            className="SearchResultThumbnail-icon"
            type={type || 'course'}
            colored
          />
        </div>

      </div>
    );
  }
}
