import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Tokeninput from 'react-tokeninput';
import formatMessage from 'format-message';

import * as announce from '../../common/announce';
import ComplexMessage from '../common/complex-message';
import AnalyticsActions from '../../actions/analytics';
import Link from './link';

const noop = () => {};

const COMMUNITY_REVIEW_TAG_LABEL = formatMessage('Community Review');

export default createReactClass({
  displayName: 'Tags',

  propTypes: {
    name: PropTypes.string,
    id: PropTypes.string,
    isPublic: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func
  },

  getDefaultProps () {
    return {
      value: []
    };
  },

  handleTagsSelect (tagOrText) {
    const text = tagOrText ? tagOrText.name || tagOrText : undefined;

    if (text) {
      const toRemove = text.toLowerCase();
      const selected = this.props.value
        .filter(tag => tag.toLowerCase() !== toRemove)
        .concat(text);
      this.props.onChange(selected);

      announce.assertively(formatMessage('Tag added'));
      AnalyticsActions.tagAdded(text);
    }
  },

  handleTagsRemove (tag) {
    if (!tag) return;
    const toRemove = tag.name.toLowerCase();
    const selected = this.props.value.filter(
      tag => tag.toLowerCase() !== toRemove
    );
    this.props.onChange(selected);
    announce.assertively(formatMessage('Tag removed'));
    AnalyticsActions.tagRemoved(tag.name);
  },

  handleCommunityTagButton (event) {
    event.preventDefault();
    this.handleTagsSelect(COMMUNITY_REVIEW_TAG_LABEL);
  },

  transformLearningObjectTags () {
    return this.props.value.map(tag => {
      return { name: tag, id: tag };
    });
  },

  componentWillUnmount () {
    announce.reset();
  },

  render () {
    return (
      <div className="Tags">
        {this.props.isPublic && (
          <div className="Tags-help-text">
            <div className="Tags-community-review-text">
              <span>
                {formatMessage(
                  'Want this resource reviewed by the Commons community?'
                )}{' '}
              </span>
              <ComplexMessage className="Tags-help-text-nowrap">
                {formatMessage('Tag it with "{button}".', {
                  button: ComplexMessage.placeholder('button')
                })}
                <Link
                  className="Tags-community-button"
                  key="button"
                  onClick={this.handleCommunityTagButton}
                >
                  {COMMUNITY_REVIEW_TAG_LABEL}
                </Link>
              </ComplexMessage>
            </div>
          </div>
        )}
        <Tokeninput
          name={this.props.name}
          id={this.props.id}
          onInput={noop}
          onSelect={this.handleTagsSelect}
          onRemove={this.handleTagsRemove}
          selected={this.transformLearningObjectTags()}
          menuContent={[]}
        />
      </div>
    );
  }
});
