import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Table } from '@instructure/ui-table';
import { Text } from '@instructure/ui-text';
import { Responsive } from '@instructure/ui-responsive';
import { SearchActions } from '../../../actions';
import { SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING } from '../../../constants/search';
import ResultsStore from '../../../stores/results';

const toggleSortOrder = (sortOrder) => sortOrder === SORTING_ORDER_ASCENDING ? SORTING_ORDER_DESCENDING : SORTING_ORDER_ASCENDING;

const PlaceholderRow = (props) => {
  return (
    <Table.Row
      data-placeholder-type="row"
      {...props.responsiveProps}
      isStacked={props.responsiveProps.layout === 'stacked'}
    >
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="circle" />
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="rect" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="placeholder-list-cell">
          <div data-placeholder-type="circle" />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const getPlaceholderRows = (numberOfPlaceholders, responsiveProps) => {
  return Array.from({ length: numberOfPlaceholders }, (_, index) => <PlaceholderRow key={index} responsiveProps={responsiveProps} />);
};

const ResultsList = ({
  results,
  resultsPerPage,
  sortBy,
  sortOrder,
  onListItemRender,
  onGetListHeaders,
}) => {
  const onChangeSort = (event, { id }) => {
    const nextOrder = sortBy === id
      ? toggleSortOrder(sortOrder)
      : SORTING_ORDER_DESCENDING;
    SearchActions.update({ sortBy: id, sortOrder: nextOrder });
  };
  const placeholdersCount = ResultsStore.getPlaceholdersCount();

  const getInstUISortDirection = (sortByKey) => {
    const sortDirection = sortOrder === SORTING_ORDER_ASCENDING ? 'ascending' : 'descending';
    return sortBy === sortByKey ? sortDirection : 'none';
  };

  const renderColHeader = (header, isDesktopView) => {
    return (
      <Table.ColHeader
        key={header.id}
        id={header.id}
        width={header.width}
        textAlign={header.textAlign}
        {...(header.sortable && { onRequestSort: onChangeSort, sortDirection: getInstUISortDirection(header.id) })}
      >
        <Text weight="bold">{header.label}</Text>
      </Table.ColHeader>
    );
  };

  const renderHead = (responsiveProps, isDesktop) => (
    <Table.Head renderSortLabel={formatMessage('Sort by')}>
      <Table.Row {...responsiveProps}>
        {onGetListHeaders(isDesktop).map((header) => renderColHeader(header, isDesktop))}
      </Table.Row>
    </Table.Head>
  );

  const renderBody = (responsiveProps, isDesktop) => (
    <Table.Body>
      <React.Fragment>
        {
          results.store.showLoading()
            ? getPlaceholderRows(resultsPerPage, responsiveProps)
            : results.store.get().map((result) => onListItemRender(result, responsiveProps, isDesktop))
        }
        {
          !!placeholdersCount && getPlaceholderRows(placeholdersCount, responsiveProps)
        }
      </React.Fragment>
    </Table.Body>
  );

  return (
    <div className="results-list">
      <Responsive
        match="media"
        query={{
          small: { maxWidth: 1024 },
          large: { minWidth: 1025 }
        }}
        props={{
          small: { layout: 'stacked' },
          large: { layout: 'fixed' }
        }}
      >
        {(responsiveProps, matches) => (
          <Table caption={formatMessage('Search results table')} {...responsiveProps}>
            {renderHead(responsiveProps, matches.includes('large'))}
            {renderBody(responsiveProps, matches.includes('large'))}
          </Table>
        )}
      </Responsive>
    </div>
  );
};

ResultsList.propTypes = {
  results: PropTypes.object.isRequired,
  resultsPerPage: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf([SORTING_ORDER_ASCENDING, SORTING_ORDER_DESCENDING]),
  onListItemRender: PropTypes.func,
  onGetListHeaders: PropTypes.func,
};

export default ResultsList;
