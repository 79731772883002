import formatMessage from 'format-message';
import React from 'react';
import Head from 'react-helmet';
import AppHandler from '../components/app-handler';
import ShareForm from '../components/resources/forms/share';
import { ResourceActions, ConsortiumsActions, SessionActions } from '../actions';
import { getState, subscribe } from '../store';
import PageTitle from '../components/common/page-title';

const view = () => {
  const state = getState();

  return (
    <AppHandler
      activeHeader="share"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Share Resource')} />
      <PageTitle title={formatMessage('Share Resource')} />
      <ShareForm {...state} groups={state.groups.list} />
    </AppHandler>
  );
};

const handleUpdate = (updateResourceId) => {
  ResourceActions.find(updateResourceId);
};

const handleNewShare = (state) => {
  if (!['image', 'video'].includes(state.session.contextType)) {
    ResourceActions.getPlaceholderThumbnail();
  }
};

export default function ({ resolve, params, render, exiting }) {
  ResourceActions.clearStore();

  const state = getState();
  const updateResourceId = state.session.session.updateResourceId;
  if (updateResourceId) {
    handleUpdate(updateResourceId);
  } else {
    handleNewShare(state);
  }
  ConsortiumsActions.loadList();

  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
  render(view());
  resolve();
}
