var React = require('react');
var PropTypes = require('prop-types');
var createReactClass = require('create-react-class');
var classnames = require('classnames');
var flexStyles = require('./lib/flex-styles');

module.exports = createReactClass({
  displayName: 'Grade',

  propTypes: {
    grade: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
  },

  onClick: function () {
    this.props.onClick(this.props.index);
  },

  render: function () {
    const grade = this.props.grade;
    const label = grade.abbreviation || grade.label;
    const flex = grade.flex || 1;
    const styles = flexStyles(flex);

    var labelClassNames = classnames('gri-grade-label', grade.labelClassName);

    return (
      <div
        className="gri-grade"
        title={grade.label}
        onClick={this.onClick}
        style={styles}
      >
        <div className="gri-grade-division" />
        <div className={labelClassNames}>{label}</div>
      </div>
    );
  }
});
