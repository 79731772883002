import apiRequest from '../common/api-request';

export default function createReportsActions (dispatch) {
  return {
    async reportContent (learningObjectId, reportType) {
      const request = apiRequest({
        path: `/resources/${learningObjectId}/report`,
        method: 'post',
        body: { reportType }
      });

      try {
        const response = await request.promise();
        if (response.status !== 201) {
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    },
  };
}
