import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import Link from '../common/link';
import Course from './course';
import CroppedImg from '../common/cropped-img';
import formatDuration from '../../common/format-duration';
import UpdateButton from '../resources/update-button';
import ResourceTypeIcon from '../icons/resource-type';
import { DismissButton } from './dismiss-button';

export default class UpdatesListItem extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleCourseChange = this.handleCourseChange.bind(this);

    this.state = {
      selectedCourses: this.getAllCourses()
    };
  }

  static propTypes = {
    onAccept: PropTypes.func,
    update: PropTypes.object,
    updateErrors: PropTypes.array
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.update !== nextProps.update) {
      this.setState({ selectedCourses: this.getAllCourses(nextProps) });
    }
  }

  getAllCourses (nextProps) {
    const props = nextProps || this.props;
    return props.update.courses.map(course => ({
      id: course.courseId,
      name: course.courseName
    }));
  }

  handleCourseChange ({ courseId: id, courseName: name }, event) {
    if (event.target.checked) {
      this.setState({
        selectedCourses: this.state.selectedCourses.concat({ id, name })
      });
    } else {
      this.setState({
        selectedCourses: this.state.selectedCourses.filter(
          course => course.id !== id
        )
      });
    }
  }

  render () {
    const learningObject = this.props.update.resource;
    const version = (learningObject.versions || [])[0];
    const update = this.props.update;
    const createDate = (version && version.createDate) || update.createDate;

    const authors = learningObject.authors.map(author => author.name).join(', ');
    const duration = +update.mediaObjectDuration;

    const isSingleCourse = update.courses.length === 1;
    const selectedCourses = this.state.selectedCourses;
    const courses = update.courses.map(course => {
      const selected = selectedCourses.some(({ id }) => course.courseId === id);
      return (
        <Course
          key={course.courseId}
          course={course}
          isSingle={isSingleCourse}
          selected={selected}
          onChange={this.handleCourseChange}
          error={this.props.updateErrors.find(
            error => error.id === course.courseId
          )}
        />
      );
    });

    let img;
    if (learningObject.thumbnail) {
      img = (
        <div className="Updates-list-item-column1-thumb">
          <CroppedImg
            className="Updates-list-item-column1-img"
            src={learningObject.thumbnail.url}
            alt={learningObject.thumbnail.description}
          />
          <span className="Updates-list-item-column1-label">
            {formatMessage('Update')}
          </span>
          {!isNaN(duration) && (
            <span className="Updates-list-item-column1-duration">
              {formatDuration(duration)}
            </span>
          )}
        </div>
      );
    }

    return (
      <li className="Updates-list-item" key={learningObject.id} tabIndex="-1">
        <div className="Updates-list-item-column1">{img}</div>

        <div className="Updates-list-item-column2">
          <div className="Updates-list-item-header">
            <div className="Updates-list-item-header-column1">
              <ResourceTypeIcon
                className="Updates-list-item-header-icon"
                type={learningObject.type || 'course'}
                colored
              />
            </div>

            <div className="Updates-list-item-header-column2">
              <Link
                className="Updates-list-item-title"
                href={`/resources/${learningObject.id}`}
              >
                {learningObject.title}
              </Link>
              <div className="Updates-list-item-header-authors">
                <span className="Updates-list-item-header-authors-label" data-heap-redact-text>
                  {formatMessage('By:') + ' '}
                </span>
                <span className="Updates-list-item-header-authors-names" data-heap-redact-text>
                  {authors}
                </span>
              </div>
            </div>
          </div>

          <div className="Updates-list-item-updated-timestamp">
            {createDate &&
              formatMessage(
                '{ datetime, date, medium } - { datetime, time, short }',
                {
                  datetime: new Date(createDate)
                }
              )}
          </div>

          <div className="Updates-list-item-notes">{update.versionNotes}</div>

          <ul className="Updates-list-item-course-list">{courses}</ul>

          <div className="Updates-list-item-buttons">
            <DismissButton
              learningObjectId={learningObject.id}
              courses={selectedCourses}
              onDismissal={this.props.onAccept}
            >
              {isSingleCourse
                ? formatMessage('Dismiss')
                : formatMessage('Dismiss selected')}
            </DismissButton>
            <UpdateButton
              learningObjectId={learningObject.id}
              courses={selectedCourses}
              onUpdate={this.props.onAccept}
            >
              {isSingleCourse
                ? formatMessage('Update')
                : formatMessage('Update selected')}
            </UpdateButton>
          </div>
        </div>
      </li>
    );
  }
}
