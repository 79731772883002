import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage from 'format-message';

const MESSAGES = {
  pending: formatMessage('Pending'),
  declined: formatMessage('Declined Invite')
};

export default function ConsortiumMember ({ status }) {
  const className = classnames('ConsortiumMember-status', {
    'ConsortiumMember-status-declined': status === 'declined',
    'ConsortiumMember-status-pending': status === 'pending'
  });
  return <span className={className}>{MESSAGES[status]}</span>;
}

ConsortiumMember.propTypes = {
  status: PropTypes.oneOf(['pending', 'declined', 'member'])
};
