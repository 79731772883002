import React from 'react';
import PropTypes from 'prop-types';
import PreviewCard from '../resources/preview-card';
import { withCuratedBanner } from '../common/with-curated-banner';

const PreviewCardWrapper = ({
  available,
  resource,
  curatedBanner,
  displayApprovedBanner,
}) => {
  return (
    <PreviewCard
      resource={resource}
      available={available}
      displayApprovedBanner={displayApprovedBanner}
      curatedBanner={curatedBanner}
    />
  );
};

PreviewCardWrapper.propTypes = {
  accountSettings: PropTypes.object,
  allowApprovedContent: PropTypes.bool,
  accountId: PropTypes.string,
  available: PropTypes.bool,
  resource: PropTypes.object.isRequired
};

export default withCuratedBanner(PreviewCardWrapper);
