import React from 'react';
import formatMessage from 'format-message';
import Link from '../common/link';
import { getLocalizedUrl } from '../../common/localized-urls';

export default function () {
  return (
    <footer className="App-footer" data-testid="AppFooter">
      <Link href="/tos" target="_blank">{formatMessage('Terms of Use')}</Link>
      <Link href={getLocalizedUrl('https://www.canvaslms.com/policies/privacy')} target="_blank">
        {formatMessage('Privacy Policy')}
      </Link>
      <Link href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons/tkb-p/commons')} target="_blank">
        {formatMessage('Help')}
      </Link>
    </footer>
  );
}
