import formatMessage from 'format-message';

const formatUpdatesTitle = (count) => {
  if (!Number.isInteger(count)) {
    return formatMessage('Updates');
  }

  return count < 10 ? formatMessage('Updates ({ count, number })', { count }) : formatMessage('Updates (10+)');
};

const mapTitleToHeader = {
  search: () => formatMessage('Search'),
  shared: () => formatMessage('Shared'),
  imported: () => formatMessage('Imported'),
  updates: (count) => formatUpdatesTitle(count),
  favorites: () => formatMessage('Favorites'),
  account: () => formatMessage('Admin'),
  login: () => formatMessage('Login'),
  tos: () => formatMessage('Terms of Use'),
  share: () => formatMessage('Share'),
  adminSettings: () => formatMessage('Account Settings'),
  adminGroups: () => formatMessage('Groups'),
  adminConsortiums: () => formatMessage('Consortiums'),
  adminResources: () => formatMessage('Managed Resources'),
  adminStats: () => formatMessage('Stats'),
};

const getNavItems = ({
  isAdmin,
  isManager,
  allowApprovedContent,
  isAccountCurator,
  isSessionLimited,
  updates,
  publicOnly,
}) => {
  let items = [];
  if (!isSessionLimited) {
    items = [
      {
        id: 'search',
        path: '/search',
        label: mapTitleToHeader.search(),
      },
      {
        id: 'shared',
        path: '/shared',
        label: mapTitleToHeader.shared(),
      },
      {
        id: 'imported',
        path: '/imported',
        label: mapTitleToHeader.imported(),
      },
      {
        id: 'updates',
        path: '/updates',
        label: mapTitleToHeader.updates(updates.getUpdatesSuccessful && updates.count),
      },
      {
        id: 'favorites',
        path: '/favorites',
        label: mapTitleToHeader.favorites(),
      }
    ];
  }

  if (isAdmin || isManager || (allowApprovedContent && isAccountCurator)) {
    const showAccountSettings = isAdmin;
    const showGroups = !publicOnly && (isManager || isAdmin);
    const showConsortiums = !publicOnly && isAdmin;
    const showStats = isAdmin;

    const adminItems = [];

    if (showAccountSettings) {
      adminItems.push({ id: 'settings', path: '/account/settings', label: mapTitleToHeader.adminSettings() });
    }

    if (showGroups) {
      adminItems.push({ id: 'groups', path: '/account/groups', label: mapTitleToHeader.adminGroups() });
    }

    if (showConsortiums) {
      adminItems.push({ id: 'consortiums', path: '/account/consortiums', label: mapTitleToHeader.adminConsortiums() });
    }

    adminItems.push({ id: 'resources', path: '/account/resources', label: mapTitleToHeader.adminResources() });

    if (showStats) {
      adminItems.push({ id: 'stats', path: '/account/stats', label: mapTitleToHeader.adminStats() });
    }

    items.push({
      id: 'account',
      path: isAdmin ? '/account/settings' : isManager ? '/account/groups' : '/account/resources',
      label: mapTitleToHeader.account(),
      subItems: adminItems,
    });
  }

  return items;
};

export {
  mapTitleToHeader,
  getNavItems
};
