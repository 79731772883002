import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Checkbox } from '@instructure/ui-checkbox';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import Link from '../../common/link';
import Trash from '../../common/trash';
import MemberStatus from './member-status';
import { confirmationDialog } from '../../confirmation-dialog';

export default createReactClass({
  displayName: 'ConsortiumMember',

  propTypes: {
    changeContributorStatus: PropTypes.func.isRequired,
    deleteMember: PropTypes.func.isRequired,
    member: PropTypes.object.isRequired,
    disabled: PropTypes.bool
  },

  handleContributorChange (event) {
    const { member, changeContributorStatus } = this.props;
    changeContributorStatus({
      id: member.id,
      consortiumId: member.consortiumId,
      isContributor: event.target.checked
    });
  },

  async confirmRemove () {
    const { member, deleteMember } = this.props;
    const message = formatMessage('Resources shared by this institution will no longer be available.');
    const title = formatMessage('Remove {accountName} from this consortium?', {
      accountName: member.accountName
    });
    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Remove')
    });

    if (confirmationAnswer) {
      deleteMember(member);
      this.shiftFocus();
    }
  },

  hideMember () {
    this.props.deleteMember(this.props.member);
    this.shiftFocus();
  },

  shiftFocus () {
    const memberElement = this.refs.tr;
    let elementToFocus = null;
    if (memberElement.previousSibling) {
      elementToFocus = memberElement.previousSibling.querySelector(
        'input[type="checkbox"],button'
      );
    }

    if (!elementToFocus) {
      elementToFocus = document.querySelector('.ConsortiumMemberCreate-input');
    }

    if (elementToFocus) {
      elementToFocus.focus();
    }
  },

  render () {
    const { member, disabled } = this.props;

    return (
      <tr className="ConsortiumMember" ref="tr">
        <td className="ConsortiumMember-cell ConsortiumMemberList-contributor-column">
          {member.status === 'declined' ? (
            <Link
              className="ConsortiumMember-hide-button"
              onClick={this.hideMember}
            >
              <span aria-hidden>{formatMessage('Hide')}</span>
              <ScreenReaderContent>
                {formatMessage('Hide {name}', { name: member.accountName })}
              </ScreenReaderContent>
            </Link>
          ) : (
            <Checkbox
              checked={member.isContributor}
              disabled={disabled}
              label={
                <ScreenReaderContent>
                  {formatMessage('Enable {institution} to contribute', {
                    institution: member.accountName
                  })}
                </ScreenReaderContent>
              }
              onChange={disabled ? null : this.handleContributorChange}
              size="small"
              value="toggleMembershipStatus"
              variant="toggle"
            />
          )}
        </td>
        <td className="ConsortiumMember-cell ConsortiumMemberList-institution-column">
          <span className="ConsortiumMemberList-institution-label">
            {member.accountName}
          </span>
          <MemberStatus status={member.status} />
        </td>
        <td className="ConsortiumMember-cell ConsortiumMemberList-delete-column">
          {member.status !== 'declined' && (
            <Link
              className="ConsortiumMember-delete-button"
              interaction={disabled ? Link.interaction.disabled : Link.interaction.enabled}
              onClick={disabled ? null : this.confirmRemove}
            >
              <ScreenReaderContent>
                {formatMessage('Remove from consortium')}
              </ScreenReaderContent>
              <Trash />
            </Link>
          )}
        </td>
      </tr>
    );
  }
});
