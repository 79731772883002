import Uniflow from 'uniflow';

export default Uniflow.createActions({
  showNotification (notification) {
    this.emit('show-notification', notification);
  },
  closeNotification (id) {
    this.emit('close-notification', id);
  },
  closeAllNotificationsOfType (type) {
    this.emit('close-notification-type', type);
  }
});
