import apiRequest from '../api-request';
import { throwOnNullishParams } from '../validation';

export const createNewAssignmentGroup = async (courseId, name) => {
  throwOnNullishParams({
    courseId,
    name
  });

  const result = await apiRequest({
    method: 'post',
    path: '/resources/assignment-group/new',
    body: { course: { id: courseId }, assignmentGroup: { name } }
  }).promise();

  if (result.status !== 201) {
    throw new Error(result.status);
  }

  return result.body;
};
