import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import { Checkbox, CheckboxGroup } from '@instructure/ui-checkbox';
import { IconGradebookImportLine } from '@instructure/ui-icons';
import { TextInput } from '@instructure/ui-text-input';
import without from 'lodash/array/without';

import { exportStatus } from '../../common/app-constants';
import Message from '../common/message';
import ComplexMessage from '../common/complex-message';
import AnalyticsActions from '../../actions/analytics';
import CoursesActions from '../../actions/courses';
import RouterActions from '../../actions/router';
import LearningObjectActions from '../../actions/resource';
import RouterStore from '../../stores/router';
import LearningObjectStore from '../../stores/resource';
import * as announce from '../../common/announce';
import Loading from '../common/loading';
import Button from '../common/button';
import Link from '../common/link';

const filterCourse = rawFilter => course => {
  if (!rawFilter) {
    return true;
  }
  const filter = rawFilter.toLowerCase().trim();
  return course.name
    .toLowerCase()
    .trim()
    .includes(filter);
};

export default createReactClass({
  displayName: 'ImportButton',

  propTypes: {
    learningObject: PropTypes.object.isRequired,
    courses: PropTypes.array.isRequired,
    coursesLoadError: PropTypes.object,
    coursesLoadPending: PropTypes.bool,
    onImport: PropTypes.func
  },

  getInitialState () {
    return {
      selected: [],
      filter: ''
    };
  },

  onChange (selected) {
    if (selected.includes('all')) {
      if (!this.state.selected.includes('all')) {
        selected = this.props.courses.map(({ id }) => id).concat('all');
      } else if (this.state.selected.length > selected.length) {
        selected = without(selected, 'all');
      }
    } else if (this.state.selected.includes('all')) {
      selected = [];
    } else if (selected.length === this.props.courses.length) {
      selected.push('all');
    }

    this.setState({
      selected
    });
  },

  updateFilter (evt) {
    const newFilter = evt.target.value;
    const courseCount = this.props.courses.filter(filterCourse(newFilter))
      .length;
    announce.reset();
    if (courseCount > 0) {
      announce.politely(
        formatMessage(
          `{count, plural,
        one {# course found}
        other {# courses found}
      }`,
          {
            count: courseCount
          }
        )
      );
    } else {
      announce.politely(formatMessage('No courses found'));
    }
    this.setState({
      filter: newFilter
    });
  },

  importIntoCourse () {
    announce.reset();
    const coursesToImport = this.props.courses.filter(({ id }) =>
      this.state.selected.includes(id)
    );
    AnalyticsActions.selectedCourseToImport();
    AnalyticsActions.timeToImport();
    var learningObjectId = this.props.learningObject.id;
    LearningObjectActions.importIntoMultiCourses(
      learningObjectId,
      coursesToImport
    );
    RouterActions.transitionTo(`/resources/${learningObjectId}`, {
      importing: true,
      q: RouterStore.state.query.q
    });
    LearningObjectStore.incrementDownloadCount(coursesToImport.length);
    this.props.onImport();
  },

  getExportStatus () {
    var version = (this.props.learningObject.versions || [])[0];
    var status = version ? version.exportStatus : exportStatus.failed;
    return status;
  },

  render () {
    var status = this.getExportStatus();
    var isEnabled =
      status === exportStatus.complete && this.state.selected.length > 0;
    var text;
    switch (status) {
      case exportStatus.incomplete:
        text = formatMessage('Resource Pending');
        break;
      case exportStatus.failed:
        text = formatMessage('Resource Unavailable');
        break;
      case exportStatus.complete:
      default:
        text = formatMessage('Import into Course');
        break;
    }

    let checkGroupChildren = null;
    if (this.props.coursesLoadError) {
      checkGroupChildren = (
        <Message type="error2 message-with-icon">
          <ComplexMessage>
            {formatMessage(
              '{error_message} Please try again by {clicking_here}.',
              {
                error_message: ComplexMessage.placeholder('strong'),
                clicking_here: ComplexMessage.placeholder('link')
              }
            )}
            <strong key="strong">
              {formatMessage(
                'Sorry, we couldn’t get your course list at this time.'
              )}
            </strong>
            <Link key="link" onClick={CoursesActions.getCourses}>
              {formatMessage('clicking here')}
            </Link>
          </ComplexMessage>
        </Message>
      );
    } else if (this.props.coursesLoadPending) {
      checkGroupChildren = (
        <div className="ImportButton-loading">
          <Loading />
        </div>
      );
    } else {
      checkGroupChildren = this.props.courses
        .filter(filterCourse(this.state.filter))
        .map(course => (
          <Checkbox key={course.id} label={course.name} value={course.id} />
        ));
    }

    return (
      <div className="ImportButton">
        <h2 className="ImportButton-title heading-type-2">
          {formatMessage('Import into Canvas')}
        </h2>
        {this.props.courses && this.props.courses.length > 0 && (
          <TextInput
            renderLabel={false}
            value={this.state.filter}
            inputRef={input =>
              input && input.classList.add('ImportButton-search')}
            placeholder={formatMessage('Search for a course')}
            onChange={this.updateFilter}
          />
        )}
        {!Array.isArray(checkGroupChildren) && checkGroupChildren}
        {checkGroupChildren.length > 0 && (
          <div className="ImportButton-course-list">
            <CheckboxGroup
              name="courses"
              description="courses"
              onChange={this.onChange}
              value={this.state.selected}
            >
              {!this.state.filter && checkGroupChildren.length > 0 && (
                <Checkbox key="all" label={formatMessage('All')} value="all" />
              )}
              {checkGroupChildren}
            </CheckboxGroup>
          </div>
        )}

        {checkGroupChildren.length === 0 && (
          <div className="ImportButton-no-courses">
            {this.state.filter
              ? formatMessage('No courses found')
              : formatMessage(
                'You have no courses to import into. Become a teacher, teacher’s aide, or course designer in the courses you wish to import into first.'
              )}
          </div>
        )}

        <Button
          display={Button.display.block}
          textAlign={Button.textAlign.start}
          color={Button.color.primary}
          className="ImportButton-button"
          interaction={isEnabled ? Button.interaction.enabled : Button.interaction.disabled}
          onClick={this.importIntoCourse}
          renderIcon={IconGradebookImportLine}
        >
          {text}
        </Button>
      </div>
    );
  }
});
