import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import SearchActions from '../../actions/search';
import { tokenizeFunctions } from '../../common/tokenize-query';
import Link from '../common/link';

const isFilterExist = (query) => {
  return query && (
    query.scopeIds ||
    query.accountId ||
    query.accountName ||
    tokenizeFunctions().map(f => f(query)).filter(value => value).length > 0
  );
};

const ClearFilterButton = ({ query }) => {
  if (!isFilterExist(query)) {
    return null;
  }

  return (
    <Link onClick={SearchActions.clear}>
      {formatMessage('Clear filters')}
    </Link>
  );
};

export default function SearchControls ({ query }) {
  return (
    <div className="SearchControls">
      <div className="SearchControl">
        <ClearFilterButton
          query={query}
        />
      </div>
    </div>
  );
}

SearchControls.propTypes = {
  query: PropTypes.object,
};
