import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Modal } from '@instructure/ui-modal/lib/Modal';
import { Heading } from '@instructure/ui-heading';
import { Text } from '@instructure/ui-text';
import { RadioInput, RadioInputGroup } from '@instructure/ui-radio-input';

import Button from '../../common/button';

const radioInputs = [
  { value: 'spam', key: 'spam', label: formatMessage('Spam') },
  { value: 'infringing', key: 'infringing', label: formatMessage('Infringing copyrights') },
  { value: 'inappropriate', key: 'inappropriate', label: formatMessage('Inappropriate or harmful') }
];

const ReportContentModal = ({ sendReport, isReporting, closeModal }) => {
  const [reportType, setReportType] = React.useState(radioInputs[0].value);

  const onReportTypeChanged = (event) => {
    setReportType(event.target.value);
  };

  const onClose = () => {
    setReportType(radioInputs[0].value);
    closeModal();
  };

  const heading = formatMessage('Report this content');

  return (
    <Modal
      open
      label={heading}
      size="small"
      data-automation={heading}
    >
      <Modal.Header>
        <Heading>{heading}</Heading>
      </Modal.Header>
      <Modal.Body>
        <Text lineHeight="default">
          <RadioInputGroup
            onChange={onReportTypeChanged}
            name="reportType"
            defaultValue={radioInputs[0].value}
            description={formatMessage('This content is:')}
          >
            {radioInputs.map(({ key, ...input }) => (
              <RadioInput {...input} key={key} />
            ))}
          </RadioInputGroup>
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-automation="CancelButton"
          margin="0 x-small 0 0"
          onClick={onClose}
          disabled={isReporting}
        >
          {formatMessage('Cancel')}
        </Button>
        <Button
          data-automation="ConfirmButton"
          color={Button.color.primary}
          onClick={() => sendReport(reportType)}
          disabled={isReporting}
        >
          {formatMessage('Report')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ReportContentModal.propTypes = {
  sendReport: PropTypes.func.isRequired,
  isReporting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ReportContentModal;
