export function displayAsApprovedContent ({
  allowApprovedContent,
  scopeIds,
  accountId,
  learningObjectAccountId
}) {
  return (
    allowApprovedContent &&
    scopeIds.some(scopeId => scopeId.includes('curated-')) &&
    accountId === learningObjectAccountId
  );
}

export function getCuratedBanner (resource, allowApprovedContent, accountId, accountSettings) {
  const canDisplayApprovedContent = displayAsApprovedContent({
    allowApprovedContent: allowApprovedContent,
    scopeIds: resource.scopeIds,
    accountId: accountId,
    learningObjectAccountId: resource.account
      ? resource.account.id
      : ''
  });

  return canDisplayApprovedContent
    ? accountSettings.curatedBanner
    : null;
}
