import React from 'react';
import PropTypes from 'prop-types';

export default class OutcomesResult extends React.Component {
  static propTypes = {
    option: PropTypes.object
  }

  render () {
    const option = this.props.option;

    return (
      <div>
        <div className="outcome-result-info">
          <div className="outcome-result-title">
            <div className="outcome-result-source">{option.title}</div>
            <div className="outcome-result-title-divider"> / </div>
            <div className="outcome-result-topic">
              {option.subjectDescription}
            </div>
          </div>
          <div className="outcome-result-code">{option.number}</div>
          <div className="outcome-result-description">{option.description}</div>
        </div>
      </div>
    );
  }
}
