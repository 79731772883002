import Uniflow from 'uniflow';
import normalizeUri from '../common/normalize-uri';

let inMaintenanceMode = false;

export default Uniflow.createActions({
  match (args) {
    this.emit('match', args);
  },

  matchResult (args) {
    this.emit('matchResult', args);
  },

  transitionTo (uri = '/', query = {}) {
    const router = require('../router').default;
    router.navigate(normalizeUri(uri, query) + window.location.hash);
  },

  backTo (name, uri) {
    this.emit('back-to-route', { name, uri });
  },

  replaceWith (uri = '/', query = {}) {
    const router = require('../router').default;
    const url = normalizeUri(uri, query) + window.location.hash;
    router.replace(url);
    router.route(url);
  },

  goBack () {
    const router = require('../router').default;
    router.back();
  },

  setMaintenanceMode (newVal) {
    inMaintenanceMode = newVal === true;
  },

  isMaintenanceMode () {
    return inMaintenanceMode;
  }
});
