import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import formatMessage from 'format-message';
import NoResult from '../common/no-result';

// Actions
import { UpdatesActions } from '../../actions';

// Stores
import UpdatesStore from '../../stores/updates';

// Components
import LoadMore from './../common/load-more';
import Loading from './../common/loading';
import Item from './item';

export default createReactClass({
  displayName: 'Updates',

  propTypes: {
    learningObjects: PropTypes.array,
    updates: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired
  },

  getDefaultProps () {
    return {
      learningObjects: [],
      session: {}
    };
  },

  handleAccept (learningObjectId) {
    const updated = this.refs[learningObjectId];
    const item = updated && ReactDOM.findDOMNode(updated);
    if (item && item.nextSibling) {
      item.nextSibling.focus();
    } else if (item && item.previousSibling) {
      item.previousSibling.focus();
    } else {
      this.refs.list.focus();
    }
  },

  render () {
    var updatedLearningObjects = [];
    if (UpdatesStore.hasUpdates()) {
      updatedLearningObjects = this.props.updates.updates.map(update => {
        return (
          <Item
            ref={update.resource.id}
            key={update.resource.id}
            update={update}
            updateErrors={
              this.props.updates.updateForLearningObjectCourseErrors[
                update.resource.id
              ] || []
            }
            onAccept={this.handleAccept}
          />
        );
      });
    }

    let emptyMessage;
    if (
      this.props.session.user &&
      this.props.updates.getUpdatesSuccessful &&
      !this.props.updates.updates.length
    ) {
      emptyMessage = <NoResult title={formatMessage('Nothing To Update')} />;
    }

    const isInitialLoadPending =
      !this.props.updates.getUpdatesSuccessful &&
      !this.props.updates.updates.length;
    let loading;
    if (isInitialLoadPending) {
      loading = (
        <div className="Updates--loading">
          <Loading />
        </div>
      );
    }

    return (
      <div className="Updates-list App-main-inner-main-content-width">
        {loading}
        {emptyMessage}
        <div ref="list" tabIndex="-1">
          <LoadMore
            hasMore={UpdatesStore.hasMore()}
            isLoading={this.props.updates.getUpdatesPending}
            loadMore={UpdatesActions.loadNextPage.partial(
              this.props.session.user.id,
              this.props.updates.cursor
            )}
          >
            {updatedLearningObjects}
          </LoadMore>
        </div>
      </div>
    );
  }
});
