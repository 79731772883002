import React from 'react';
import PropTypes from 'prop-types';
import NotificationCenterActions from '../../../actions/notification-center';
import Link from '../link';
import formatMessage from 'format-message';
import ResourceTypeIcon from '../../icons/resource-type';

export function updateNotificationId (updates) {
  // the id will be the most recent updateDate
  return +new Date(
    updates.sort(
      (a, b) => +new Date(b.updateDate) - +new Date(a.updateDate)
    )[0].updateDate
  );
}

export default class UpdatesNotification extends React.Component {
  static propTypes = {
    updates: PropTypes.array,
    count: PropTypes.number
  }

  render () {
    return (
      <div className="UpdatesNotification">
        {this.props.updates.slice(0, 3).map(update => {
          return (
            <div key={update.resource.id} className="UpdatesNotification-item">
              <ResourceTypeIcon
                className="UpdatesNotification-item-icon"
                type={update.resource.type || 'course'}
                colored
              />
              <div className="UpdatesNotification-item-content">
                {formatMessage(
                  'The {type} "{title}" was updated on {date,date,medium}',
                  {
                    type: update.resource.type,
                    title: update.resource.title,
                    date: new Date(update.updateDate)
                  }
                )}
              </div>
            </div>
          );
        })}
        <Link
          className="UpdatesNotification-update-notes"
          onClick={NotificationCenterActions.closeNotification.partial(
            updateNotificationId(this.props.updates)
          )}
          href="/updates"
        >
          {formatMessage('View Update Notes')}
        </Link>
        {this.props.updates.length > 3 && (
          <span className="UpdatesNotification-additional">
            {this.props.updates.length > 13
              ? formatMessage('And 10+ More Additional Updates')
              : formatMessage('And {count, number} More Additional Updates', {
                count: this.props.updates.length - 3
              })}
          </span>
        )}
      </div>
    );
  }
}
