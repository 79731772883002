import React from 'react';
import iconErrorSrc from '../../../../assets/svg/icon-error.svg';

export default function RedText ({ children }) {
  return (
    <div className="lor-error-layout">
      <div className="lor-error">
        <div className="lor-error-icon-wrapper">
          <img
            aria-hidden
            className="lor-error-icon"
            src={iconErrorSrc}
          />
        </div>

        <div className="lor-error-message">
          {children}
        </div>
      </div>
    </div>
  );
}
