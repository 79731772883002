import React from 'react';
import PropTypes from 'prop-types';
import outcomesAuthorityFilter from '../../../common/outcomes-authority-filter';
import Link from '../../common/link';
import TooltipTrigger from '../../common/tooltip-trigger';
import Tooltip from '../../common/tooltip';

export default class CsvOutcomes extends React.Component {
  static displayName = 'CsvOutcomes'

  static propTypes = {
    header: PropTypes.string,
    values: PropTypes.array.isRequired,
    session: PropTypes.object.isRequired
  }

  static defaultProps = {
    session: {
      session: {}
    }
  }

  renderValue (outcome) {
    return (
      <TooltipTrigger
        className="lor-csv-value"
        key={outcome.id}
        tooltip={
          <Tooltip className="CsvOutcomes-tooltip" location="bottom">
            {outcome.description}
          </Tooltip>
        }
      >
        <Link href={`/search?q=${outcome.title}`}>{outcome.title}</Link>
      </TooltipTrigger>
    );
  }

  renderValues (values) {
    return (
      <span className="lor-csv-values">{values.map(this.renderValue)}</span>
    );
  }

  render () {
    const outcomes = outcomesAuthorityFilter(
      this.props.values,
      this.props.session.session.stateStandard
    );
    return (
      <div className="CsvOutcomes lor-csv">{this.renderValues(outcomes)}</div>
    );
  }
}
