import React from 'react';
import formatMessage from 'format-message';
import { IconBulletListLine, IconTableCellSelectAllLine } from '@instructure/ui-icons';
import Link from '../common/link';
import { getState } from '../../store';
import { CARD_VIEW, LIST_VIEW } from '../../constants/results-view';
import { updateResultsView } from '../../actions/local-storage';

const BUTTON_INFO = {
  [CARD_VIEW]: {
    icon: IconBulletListLine,
    message: formatMessage('Switch to list view'),
  },
  [LIST_VIEW]: {
    icon: IconTableCellSelectAllLine,
    message: formatMessage('Switch to card view'),
  },
};

const getOppositeResultsView = (resultsView) => {
  return resultsView === CARD_VIEW ? LIST_VIEW : CARD_VIEW;
};

const ToggleResultsViewButton = () => {
  const currentResultsView = getState().localStorage.searchPage.resultsView;

  return (
    <Link
      renderIcon={BUTTON_INFO[currentResultsView].icon}
      onClick={() => updateResultsView(getOppositeResultsView(currentResultsView))}
      className="toggle-results-view-button"
    >
      {BUTTON_INFO[currentResultsView].message}
    </Link>
  );
};

export default ToggleResultsViewButton;
