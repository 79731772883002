import { useEffect, useRef } from 'react';

export const usePaginationScrolling = (isPaginationEnabled, props, getPaginationPages) => {
  const previousPageRef = useRef(null);
  const bottomScrollTargetRef = useRef(null);

  const { results, resultsPerPage, router } = props;

  const scrollToTop = () => window.scrollTo(0, 0);

  const scrollToBottom = () => bottomScrollTargetRef.current.scrollIntoView();

  const shouldScrollToTop = (currentPage, previousPage) => currentPage !== previousPage - 1;

  const shouldScrollToBottom = (previousPage) => {
    const { totalPages } = getPaginationPages();
    const isLastPageFull = results.store.getFilteredResultsCount() % resultsPerPage === 0;
    return previousPage === totalPages && !isLastPageFull;
  };

  useEffect(() => {
    if (!isPaginationEnabled) {
      return;
    }

    const previousPage = previousPageRef.current || 1;
    const currentPage = parseInt(router.query.page, 10);
    const isNewQuery = !currentPage;

    if (isNewQuery) {
      previousPageRef.current = null;
      return;
    }

    if (previousPage === currentPage) {
      return;
    }

    if (results.store.showLoading()) {
      if (shouldScrollToTop(currentPage, previousPage)) {
        scrollToTop();
      } else if (shouldScrollToBottom(previousPage)) {
        scrollToBottom();
      }
    }

    previousPageRef.current = currentPage;
  }, [results.store.showLoading()]);

  return bottomScrollTargetRef;
};
