import Uniflow from 'uniflow';
import CoursesActions from '../actions/courses';

let bootstrapped = false;

const CoursesStore = Uniflow.createStore({
  state: {
    courses: [],
    meta: {},
    getCoursesPending: true,
    getCoursesErrors: null,
    getCoursesSuccessful: null
  },

  bootstrap () {
    if (bootstrapped) {
      return;
    }

    CoursesActions.on('get-courses-pending', CoursesStore.getCoursesPending);
    CoursesActions.on('get-courses-error', CoursesStore.getCoursesError);
    CoursesActions.on('get-courses-successful', CoursesStore.getCoursesSuccessful);

    bootstrapped = true;
  },

  getCoursesPending () {
    this.setState({
      course: [],
      getCoursesPending: true,
      getCoursesErrors: null,
      getCoursesSuccessful: true
    });
  },
  getCoursesError (errors) {
    this.setState({
      getCoursesPending: false,
      getCoursesErrors: errors
    });
  },
  getCoursesSuccessful ({ items, meta }) {
    this.setState({
      courses: items,
      meta,
      getCoursesPending: false,
      getCoursesSuccessful: true
    });
  }
});

export default CoursesStore;
