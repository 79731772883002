import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import ConsortiumsResult from './result';
import ConsortiumsCreate from './create';
import NoResults from './no-results';
import Loading from '../../common/loading';
import Error from '../../common/error';

export default createReactClass({
  displayName: 'Consortiums',

  propTypes: {
    createName: PropTypes.string,
    changeCreateName: PropTypes.func.isRequired,
    createConsortium: PropTypes.func.isRequired,
    createConsortiumPending: PropTypes.bool,
    createConsortiumError: PropTypes.object,
    deleteConsortium: PropTypes.func.isRequired,
    leaveConsortium: PropTypes.func.isRequired,
    list: PropTypes.array,
    listIsLoading: PropTypes.bool,
    listIsLoaded: PropTypes.bool,
    listLoadError: PropTypes.object,
    session: PropTypes.object.isRequired,
    updateMembershipStatus: PropTypes.func
  },

  moveFocusUp (consortium) {
    const consortiumElement = ReactDOM.findDOMNode(this.refs[consortium.id]);
    let elementToFocus = null;
    if (consortiumElement.previousSibling) {
      elementToFocus = consortiumElement.previousSibling.querySelector(
        '.ConsortiumsResult-link'
      );
    }
    if (!elementToFocus) {
      elementToFocus = ReactDOM.findDOMNode(this.refs.create).querySelector(
        '.ConsortiumsCreate-input'
      );
    }
    elementToFocus.focus();
  },

  deleteConsortiumHandler (consortium) {
    this.moveFocusUp(consortium);
    this.props.deleteConsortium(consortium);
  },

  leaveConsortiumHandler (consortium) {
    this.moveFocusUp(consortium);
    this.props.leaveConsortium(consortium);
  },

  updateMembershipStatusHandler (membershipId, consortiumId, status) {
    if (status === 'declined') {
      const element = ReactDOM.findDOMNode(this.refs[consortiumId]);
      const elementToFocus =
        (element &&
          element.previousSibling &&
          element.previousSibling.querySelector('a, button')) ||
        ReactDOM.findDOMNode(this.refs.create).querySelector('input');
      elementToFocus.focus();
    }
    this.props.updateMembershipStatus(membershipId, consortiumId, status);
  },

  render () {
    const {
      list,
      listIsLoading,
      listIsLoaded,
      listLoadError,
      session
    } = this.props;
    const hasNoResults = listIsLoaded && list.length === 0;

    const consortiumComponents = list
      .filter(consortium => consortium.membershipStatus !== 'declined')
      .map(consortium => {
        return (
          <ConsortiumsResult
            accountId={session.account.id}
            key={consortium.id}
            ref={consortium.id}
            consortium={consortium}
            onDelete={this.deleteConsortiumHandler}
            onLeave={this.leaveConsortiumHandler}
            updateMembershipStatus={this.updateMembershipStatusHandler}
          />
        );
      });

    return (
      <div refs="div">
        <ConsortiumsCreate
          ref="create"
          isCreating={this.props.createConsortiumPending}
          createError={this.props.createConsortiumError}
          name={this.props.createName}
          onChange={this.props.changeCreateName}
          onCreate={this.props.createConsortium}
          refs="create"
        />

        <div className="Consortiums-table" ref="table" tabIndex="-1">
          {listIsLoading && (
            <div className="Consortiums-loading">
              <Loading />
            </div>
          )}

          {listLoadError && <Error error={listLoadError} />}

          {hasNoResults ? (
            <NoResults />
          ) : (
            <div className="Consortiums-results">{consortiumComponents}</div>
          )}
        </div>
      </div>
    );
  }
});
