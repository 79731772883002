import React from 'react';

export default class Warning extends React.Component {
  static defaultProps = {
    width: '100px',
    height: '100px'
  };

  render () {
    return (
      <svg {...this.props} viewBox="0 0 100 100">
        <g transform="translate(0 240)">
          <path
            className="warning" fill="currentColor" d="M97-162.4l-38.4-69.8c-5-9-13.1-9-18,0L2.1-162.4c-5,9-0.6,16.3,9.7,16.3h75.5C97.6-146,101.9-153.4,97-162.4z
                 M49.5-158.4c-3.5,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2c3.5,0,6.2,2.8,6.2,6.2S53-158.4,49.5-158.4z M55.8-183.2
                c0,3.5-2.8,6.2-6.2,6.2c-3.5,0-6.2-2.8-6.2-6.2v-21.7c0-3.5,2.8-6.2,6.2-6.2c3.5,0,6.2,2.8,6.2,6.2V-183.2z" />
        </g>
      </svg>

    );
  }
}
