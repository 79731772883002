import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

export default class TooltipTrigger extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      isHovered: false,
      isFocused: false
    };
  }

  static propTypes = {
    focusable: PropTypes.bool,
    id: PropTypes.string,
    tooltip: PropTypes.element,
    className: PropTypes.string,
    children: PropTypes.node
  }

  isShowTooltip () {
    return this.state.isHovered || this.state.isFocused;
  }

  handleMouseEnter () {
    this.setState({ isHovered: true });
  }

  handleMouseLeave () {
    this.setState({ isHovered: false });
  }

  handleFocus () {
    this.setState({ isFocused: true });
  }

  handleBlur () {
    this.setState({ isFocused: false });
  }

  handleKeypress (evt) {
    var escKey = 27;
    if (evt.which === escKey) {
      this.setState(this.getInitialState());
    }
  }

  render () {
    return (
      <span
        ref="trigger"
        className={classnames('TooltipTrigger', this.props.className)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyUp={this.handleKeypress}
        tabIndex={this.props.focusable ? '0' : ''}
        aria-describedby={this.props.id || ''}
      >
        {this.props.children}
        <CSSTransitionGroup
          transitionName="TooltipTrigger-animation"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {this.isShowTooltip() && this.props.tooltip}
        </CSSTransitionGroup>
      </span>
    );
  }
}
