import React from 'react';
import formatMessage from 'format-message';
import SadPanda from './sad-panda';
import * as announce from '../../common/announce';

export default class OutcomesNoResults extends React.Component {
  componentDidMount () {
    // polite to try and have the message read after voice over reads the last
    // word that was typed
    announce.politely(formatMessage('Sorry, there are no outcomes matching your query'));
  }

  componentWillUnmount () {
    // without this it will not announce after the first time
    announce.reset();
  }

  render () {
    return (
      <div
        className="OutcomesNoResults"
      >
        <p className="OutcomesNoResults-message">{formatMessage('Sorry, there are no outcomes matching your query')}</p>
        <SadPanda />
      </div>
    );
  }
}
