import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

const thresholds = {
  seconds: 45, // seconds to minute
  minutes: 45, // minutes to hour
  hours: 22, // hours to day
  days: 26, // days to month
  months: 11 // months to year
};

function getValueUnits (ms) {
  const seconds = Math.round(ms / 1000);
  if (seconds < thresholds.seconds) return [seconds, 'seconds'];

  const minutes = Math.round(ms / 1000 / 60);
  if (minutes < thresholds.minutes) return [minutes, 'minutes'];

  const hours = Math.round(ms / 1000 / 60 / 60);
  if (hours < thresholds.hours) return [hours, 'hours'];

  const days = Math.round(ms / 1000 / 60 / 60 / 24);
  if (days < thresholds.days) return [days, 'days'];

  const months = Math.round(ms / 1000 / 60 / 60 / 24 / 30); // aprox
  if (months < thresholds.months) return [months, 'months'];

  const years = Math.round(ms / 1000 / 60 / 60 / 24 / 365);
  return [years, 'years'];
}

export default class TimeSince extends React.Component {
  static propTypes = {
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }

  render () {
    const timeAgo = Date.now() - new Date(this.props.time);
    const [value, units] = getValueUnits(timeAgo);
    return (
      <span>
        {formatMessage(
          `{ units, select,
          seconds {{ value, plural, one {a second ago} other {# seconds ago} }}
          minutes {{ value, plural, one {a minute ago} other {# minutes ago} }}
          hours   {{ value, plural, one {an hour ago}  other {# hours ago} }}
          days    {{ value, plural, one {a day ago}    other {# days ago} }}
          months  {{ value, plural, one {a month ago}  other {# months ago} }}
          other   {{ value, plural, one {a year ago}   other {# years ago} }}
        }`,
          { value, units }
        )}
      </span>
    );
  }
}
