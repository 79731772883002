import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import Avatar from '../../common/avatar';
import TimeSince from '../../common/time-since';
import Link from '../../common/link';

export default class Review extends React.Component {
  static propTypes = {
    isEditable: PropTypes.bool,
    isMyReview: PropTypes.bool,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickRating: PropTypes.func,
    review: PropTypes.object
  }

  static defaultProps = {
    isMyReview: false,
    review: {
      updateDate: Date.now()
    }
  }

  renderEditControls () {
    if (this.props.isEditable) {
      return [
        <Link
          className="Review-footer-btn lor-review-delete-btn"
          key="delete-button"
          onClick={this.props.onClickDelete}
        >
          {formatMessage('Delete')}
        </Link>,
        <Link
          className="Review-footer-btn lor-review-edit-btn"
          key="edit-button"
          onClick={this.props.onClickEdit}
        >
          {formatMessage('Edit')}
        </Link>
      ];
    }
  }

  renderBody () {
    if (this.props.review.body) {
      return (
        <p ref="reviewBody" className="Review-body">
          {this.props.review.body}
        </p>
      );
    }
  }

  renderFooter () {
    if (this.props.isEditable) {
      return (
        <footer className="Review-footer">
          <div className="Review-footer-btn-row">
            {this.renderEditControls()}
            <span className="Review-date">
              <TimeSince
                ref="reviewUpdateDate"
                time={this.props.review.updateDate}
              />
            </span>
          </div>
        </footer>
      );
    }
  }

  render () {
    return (
      <article className="Review">
        <header className="Review-header">
          <div className="Review-title">
            <Avatar
              data-heap-redact-attributes="url"
              ref="reviewAvatar"
              className="Review-avatar"
              url={this.props.review.userAvatarUrl}
            />
            <span ref="reviewTitle" data-heap-redact-text>{this.props.review.userFullName}</span>
          </div>
        </header>
        {this.renderBody()}
        {this.renderFooter()}
      </article>
    );
  }
}
