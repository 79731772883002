export const throwErrorIfNullable = (key, value) => {
  if (value === null || value === undefined) {
    throw new Error(`${key} cannot be null or undefined!`);
  }
};
export const throwOnNullishParams = params => {
  Object.keys(params).forEach(key => {
    throwErrorIfNullable(key, params[key]);
  });
};
