import _ from 'lodash';
import {
  RESET_FAVORITES,
  LOAD_LIST_START,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_FAILURE,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAILURE,
  TOGGLING_START,
  REMOVE_SUCCESS,
  REMOVE_FAILURE
} from '../actions/favorites';

const initialState = {
  listIsLoading: false,
  listIsLoaded: false,
  listLoadError: null,
  isToggling: false,
  list: [],
  filter: '',
  resourcesByResourceId: {}
};

export default function favoritesReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_FAVORITES:
      return initialState;

    case LOAD_LIST_START:
      return {
        ...state,
        listIsLoading: true,
        listIsLoaded: false,
        listLoadError: null,
        isToggling: false,
        list: [],
        resourcesByResourceId: {}
      };

    case LOAD_LIST_SUCCESS:
      return {
        ...state,
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: null,
        list: action.list,
        resourcesByResourceId: action.list.reduce((map, learningObject) => {
          map[learningObject.id] = learningObject;
          return map;
        }, {})
      };

    case LOAD_LIST_FAILURE:
      return {
        ...state,
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: action.error,
        list: [],
        resourcesByResourceId: {}
      };

    case TOGGLING_START:
      return {
        ...state,
        isToggling: true
      };

    case ADD_FAVORITE_SUCCESS:
      return {
        ...state,
        filter: '',
        isToggling: false,
        list: state.list
          .concat(action.learningObject),
        resourcesByResourceId: {
          ...state.resourcesByResourceId,
          [action.learningObject.id]: action.learningObject
        }
      };

    case ADD_FAVORITE_FAILURE:
      return {
        ...state,
        isToggling: false
      };

    case REMOVE_SUCCESS:
      return {
        ...state,
        filter: '',
        isToggling: false,
        list: state.list.filter(({ id }) => id !== action.learningObject.id),
        resourcesByResourceId: _.omit(state.resourcesByResourceId, action.learningObject.id),
      };

    case REMOVE_FAILURE:
      return {
        ...state,
        isToggling: false
      };

    default:
      return state;
  }
}
