import React from 'react';
import oopsSrc from '../../../../assets/svg/oops.svg';

export default function YellowOops ({ children }) {
  return (
    <div className="lor-error-layout">
      <img
        aria-hidden
        src={oopsSrc}
      />
      <div className="lor-warning">
        {children}
      </div>
    </div>
  );
}
