import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import { setFeatureFlag } from '../common/analytics/heap';

export const GET_FEATURE_FLAG_START = 'ff.feature-flag-start';
export const GET_FEATURE_FLAG_SUCCESS = 'ff.feature-flag-success';
export const GET_FEATURE_FLAG_FAILURE = 'ff.feature-flag-failure';

export const creators = {
  getFeatureFlagStart: () => ({ type: GET_FEATURE_FLAG_START }),
  getFeatureFlagSuccess: (featureFlags) => ({ type: GET_FEATURE_FLAG_SUCCESS, featureFlags }),
  getFeatureFlagFailure: (error) => ({ type: GET_FEATURE_FLAG_FAILURE, error })
};

export default function createFeaturedContentActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async getFeatureFlags () {
      try {
        actions.getFeatureFlagStart();
        const response = await apiRequest({
          path: '/feature-flags'
        }).promise();

        if (Object.keys(response.body).indexOf('enable_heap_analytics') > -1) {
          setFeatureFlag(response.body.enable_heap_analytics);
        }

        actions.getFeatureFlagSuccess(response.body);
      } catch (err) {
        actions.getFeatureFlagFailure(err);
      }
    }
  };
}
