import React from 'react';
import formatMessage from 'format-message';
import SadPanda from './sad-panda';
import * as announce from '../../common/announce';

const message = formatMessage('No results found. It\'s possible the user you are searching for can not be found because they have not accessed Commons previously. Ask the user to launch Commons from the Canvas navigation and you will be able to add them to the group.');

export default class UsersNoResults extends React.Component {
  componentDidMount () {
    // polite to try and have the message read after voice over reads the last
    // word that was typed
    announce.politely(message);
  }

  componentWillUnmount () {
    // without this it will not announce after the first time
    announce.reset();
  }

  render () {
    return (
      <div
        className="UsersNoResults"
      >
        <p className="UsersNoResults-message">{message}</p>
        <SadPanda />
      </div>
    );
  }
}
