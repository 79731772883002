import Uniflow from 'uniflow';
import LearningObjectActions from '../actions/resource';
import SearchActions from '../actions/search';
import ResultsActions from '../actions/results';

let bootstrapped = false;
const emptyList = [];

// Initial State
export function setInitialState () {
  ResultsStore.replaceState({
    items: emptyList,
    loading: false,
    pendingReq: null,
    denylist: emptyList,
    nextCursor: null,
    count: null,
    total: null,
    error: null,
    hasMore: true,
    placeholdersCount: 0,
    searchSuccessful: false
  });
}

const ResultsStore = Uniflow.createStore({
  bootstrap () {
    if (bootstrapped) {
      return;
    }

    setInitialState();

    // Subscribe to actions
    ResultsActions.on('increase-download-count', resourceId => {
      ResultsStore.incrementDownloadCount(1, resourceId);
    });
    ResultsActions.on(
      'remove-from-favorites',
      ResultsStore.handleRemoveFromFavorites
    );
    LearningObjectActions.on(
      'learning-object-destroyed',
      ResultsStore.handleDestroy
    );
    SearchActions.on('results-reset', ResultsStore.handleReset);
    SearchActions.on('results-requested', ResultsStore.handleRequest);
    SearchActions.on('results-response', ResultsStore.handleResponse);
    SearchActions.on('results-error', ResultsStore.handleRequestError);
    SearchActions.on('results-increase-placeholder', ResultsStore.increasePlaceholdersCount);
    SearchActions.on('results-decrease-placeholder', ResultsStore.decreasePlaceholdersCount);
    SearchActions.on('results-reset-placeholder', ResultsStore.resetPlaceholdersCount);
    SearchActions.on('results-sync-response', ResultsStore.syncResponse);

    bootstrapped = true;
  },

  get () {
    return this.state.items.filter(this.isNotDenylisted);
  },

  getFilteredResultsCount () {
    return this.state.count;
  },

  getPlaceholdersCount () {
    return this.state.placeholdersCount;
  },

  hasMore () {
    return this.state.hasMore;
  },

  isNotDenylisted ({ id }) {
    return !~this.state.denylist.indexOf(id);
  },

  showLoading () {
    return this.state.loading && !this.get().length;
  },

  showEmpty () {
    return (
      !this.state.loading &&
      !this.state.hasMore &&
      this.get().length === 0 &&
      !this.state.error
    );
  },

  handleDestroy (learningObjectId) {
    this.setState({
      denylist: [...this.state.denylist, learningObjectId],
      count: (this.state.count - 1) || 0,
      total: (this.state.total - 1) || 0
    });
  },

  handleRemoveFromFavorites (learningObjectId) {
    this.setState({
      items: this.state.items.filter(({ id }) => id !== learningObjectId),
      count: (this.state.count - 1) || 0,
      total: (this.state.total - 1) || 0
    });
  },

  increasePlaceholdersCount () {
    this.setState({
      placeholdersCount: this.state.placeholdersCount + 1
    });
  },

  decreasePlaceholdersCount () {
    this.setState({
      placeholdersCount: this.state.placeholdersCount === 0 ? 0 : this.state.placeholdersCount - 1
    });
  },

  resetPlaceholdersCount () {
    this.setState({
      placeholdersCount: 0
    });
  },

  handleReset () {
    if (this.state.pendingReq && this.state.pendingReq.xhr) {
      this.state.pendingReq.abort();
    }
    this.setState({
      items: emptyList,
      pendingReq: null,
      hasMore: true,
      nextCursor: null,
      count: null,
      total: null,
      searchSuccessful: false,
      loading: false
    });
  },

  handleRequest (req, deletePreviousItems) {
    const newItems = deletePreviousItems ? emptyList : this.state.items;

    this.setState({
      pendingReq: req,
      error: null,
      searchSuccessful: false,
      loading: true,
      items: newItems
    });
    // avoid triggering loading animation for fast responses (i.e. cached)
    setTimeout(() => {
      if (this.state.pendingReq === req) {
        this.setState({ loading: true });
      }
    }, 15);
  },

  handleResponse (req, res, deletePreviousItems) {
    if (this.state.pendingReq !== req) {
      return;
    }
    const body = { meta: {}, items: [], ...res.body };
    const newItems = deletePreviousItems ? [...body.items] : [...this.state.items, ...body.items];

    this.setState({
      items: newItems,
      nextCursor: body.meta.cursor ? JSON.stringify(body.meta.cursor) : null,
      count: body.meta.count || 0,
      total: body.meta.total || 0,
      pendingReq: null,
      loading: false,
      hasMore: !!body.meta.cursor,
      searchSuccessful: true
    });
  },

  syncResponse (res) {
    const body = { meta: {}, items: [], ...res.body };
    const firstNewItem = body.items.find(item => !this.state.items.some(currentItem => currentItem.id === item.id));
    this.setState({
      items: firstNewItem ? [...this.state.items, firstNewItem] : this.state.items,
      hasMore: !!body.meta.cursor,
    });
  },

  handleRequestError (err) {
    this.setState({
      error: err,
      loading: false,
      hasMore: false
    });
  }
});

export default ResultsStore;
