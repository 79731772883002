import formatMessage from 'format-message';

const subjects = [
  { value: 'artmusic', label: formatMessage('Art & Music') },
  { value: 'englishlanguagearts', label: formatMessage('English Language Arts') },
  { value: 'foreignlanguage', label: formatMessage('Foreign Language') },
  { value: 'math', label: formatMessage('Math') },
  { value: 'science', label: formatMessage('Science') },
  { value: 'socialstudieshistorygeography', label: formatMessage('Social Studies, History, Geography') },
  { value: 'specialty', label: formatMessage('Specialty') }
];

export default {
  find () {
    return subjects.slice();
  },

  getLabels (values) {
    return subjects
      .filter((subject) => values.indexOf(subject.value) > -1)
      .map((subject) => subject.label);
  }
};
