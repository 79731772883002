import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

import {
  IconCoursesSolid,
  IconCoursesLine,
  IconModuleSolid,
  IconModuleLine,
  IconAssignmentSolid,
  IconAssignmentLine,
  IconQuizSolid,
  IconQuizLine,
  IconDiscussionSolid,
  IconDiscussionLine,
  IconDocumentSolid,
  IconDocumentLine,
  IconVideoSolid,
  IconVideoLine,
  IconAudioSolid,
  IconAudioLine,
  IconImageSolid,
  IconImageLine,
  IconCollectionSolid,
  IconCollectionLine,
} from '@instructure/ui-icons';

const iconPaths = {
  course: { solid: IconCoursesSolid, line: IconCoursesLine },
  module: { solid: IconModuleSolid, line: IconModuleLine },
  assignment: { solid: IconAssignmentSolid, line: IconAssignmentLine },
  quiz: { solid: IconQuizLine, line: IconQuizLine },
  quizzesnext: { solid: IconQuizSolid, line: IconQuizSolid },
  discussion_topic: { solid: IconDiscussionSolid, line: IconDiscussionLine },
  page: { solid: IconDocumentSolid, line: IconDocumentLine },
  document: { solid: IconCollectionSolid, line: IconCollectionLine },
  video: { solid: IconVideoSolid, line: IconVideoLine },
  audio: { solid: IconAudioSolid, line: IconAudioLine },
  image: { solid: IconImageSolid, line: IconImageLine }
};

export default createReactClass({
  displayName: 'ResourceTypeIcon',

  propTypes: {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(iconPaths)).isRequired,
    solid: PropTypes.bool,
    colored: PropTypes.bool,
    raw: PropTypes.bool,
    ghost: PropTypes.bool,
  },

  render () {
    const { className, type, colored, solid, raw, ghost, iconSize, style } = this.props;
    const Icon = iconPaths[type] || iconPaths.course;
    return (
      <span
        aria-hidden="true"
        focusable="false"
        className={classnames(
          `type-${type}`,
          'ResourceTypeIcon',
          `ResourceTypeIcon--${type}`,
          className,
          ghost ? 'font-color' : (colored ? 'background-color' : ''),
          {
            'ResourceTypeIcon--raw': raw
          }
        )}
        style={{
          ...style
        }}
        data-automation="FeaturedResourceTypeIcon"
      >
        {solid ? <Icon.solid size={iconSize} /> : <Icon.line size={iconSize} />}
      </span>
    );
  }
});
