import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import Avatar from '../../common/avatar';
import CharactersLeft from '../../common/characters-left';
import * as announce from '../../../common/announce';
import Button from '../../common/button';
import Link from '../../common/link';

export default class ReviewEdit extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.onBodyChange = this.onBodyChange.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSave = this.onClickSave.bind(this);

    this.state = {
      bodyText: props.review.body || ''
    };
  }

  static propTypes = {
    editingUser: PropTypes.object,
    isSubmitting: PropTypes.bool,
    onClickSave: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func,
    onClickRating: PropTypes.func,
    onChange: PropTypes.func,
    showCancel: PropTypes.bool,
    review: PropTypes.object
  }

  static defaultProps = {
    editingUser: {},
    review: {},
    showCancel: false
  }

  getBodyLeft () {
    return 1000 - (this.state.bodyText || '').length;
  }

  onBodyChange (evt) {
    this.setState({ bodyText: evt.target.value });
    if (this.props.onChange) this.props.onChange(evt);
  }

  onClickCancel (evt) {
    this.setState({ bodyText: '' });
    this.props.onClickCancel(evt);
  }

  onClickSave (evt) {
    const bodyLeft = this.getBodyLeft();
    if (this.state.bodyText.length === 0) {
      announce.assertively(formatMessage('Review must contain text'));
    } else if (bodyLeft < 0) {
      announce.assertively(
        formatMessage('Review must be less than 1000 characters')
      );
    } else {
      this.props.onClickSave(evt, this.state.bodyText);
    }
  }

  componentWillUnmount () {
    announce.reset();
  }

  render () {
    const bodyLeft = this.getBodyLeft();
    return (
      <article className="Review">
        <header className="Review-header">
          <div className="Review-title">
            <Avatar
              data-heap-redact-attributes="url"
              ref="reviewAvatar"
              className="Review-avatar"
              url={this.props.editingUser.avatarUrl}
            />
            <span ref="reviewTitle" data-heap-redact-text>
              {this.props.editingUser.fullName ||
                this.props.review.userFullName}
            </span>
          </div>
        </header>
        <textarea
          ref="reviewTextarea"
          name="lor-review-textarea"
          className="Review-textarea"
          aria-describedby="Review-charsLeft"
          value={this.state.bodyText}
          placeholder={formatMessage('Leave your review')}
          aria-label={formatMessage('Leave your review')}
          onChange={this.onBodyChange}
        />
        <div className="Review-btns-row">
          <CharactersLeft
            id="Review-charsLeft"
            className="Review-charsLeft"
            countClassName={
              bodyLeft < 0
                ? 'Review-charsLeft-negative'
                : 'Review-charsLeft-positive'
            }
            count={bodyLeft}
          />
          {this.props.showCancel && this.state.bodyText && (
            <Link
              className="Review-cancel-btn"
              interaction={this.props.isSubmitting ? Link.interaction.disabled : Link.interaction.enabled}
              onClick={this.onClickCancel}
            >
              {formatMessage('Cancel')}
            </Link>
          )}
          <Button
            interaction={(this.props.isSubmitting || !this.state.bodyText || bodyLeft < 0) ? Button.interaction.disabled : Button.interaction.enabled}
            margin="xx-small"
            onClick={this.onClickSave}
          >
            {formatMessage('Submit')}
          </Button>
        </div>
      </article>
    );
  }
}
