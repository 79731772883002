import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const DELIMITER = '\x00|\x1F|\x00';

/**
 * Allows you to compose a localization message with React component arguments
 *
 * <ComplexMessage>
 *   { // the message is formatMessage('literal') so it can be extracted and/or inlined
 *     formatMessage('{error_message} {name} Please try again by {clicking_here}.', {
 *       error_message: ComplexMessage.placeholder('strong'), // identify the sibling component to place by key
 *       name: 'Bob', // normal arguments don't need intermediate placeholders
 *       clicking_here: ComplexMessage.placeholder('link')
 *     })
 *   }
 *   <strong key="strong">
 *     { formatMessage('Sorry, we couldn’t do that.') }
 *   </strong>
 *   <Button onClick={ this.doSomething }>
 *     { formatMessage('clicking here') }
 *   </Button>
 * </ComplexMessage>
 **/
export default createReactClass({
  displayName: 'ComplexMessage',

  propTypes: {
    children: PropTypes.node
  },

  statics: {
    placeholder (id) {
      return DELIMITER + id + DELIMITER;
    }
  },

  render () {
    var components = {};
    var strings = [];

    // create map of component to place, and a list strings to place them in
    React.Children.forEach(this.props.children, child => {
      if (typeof child === 'string') {
        strings.push(child);
      } else if (child.key) {
        components[child.key] = child;
      } else {
        throw new Error(
          'ComplexMessage children must have a key,' +
            ' or be a formatted message string with placeholders.'
        );
      }
    });

    // create a flat array of strings and components
    var children = strings.reduce((children, child) => {
      return children.concat(
        child.split(DELIMITER).map((part, i) => {
          // every odd section of the string is a placeholder
          var isPlaceholder = i % 2 === 1;
          return isPlaceholder ? components[part] : part;
        })
      );
    }, []);

    // render a span with all of the message parts as children
    return React.createElement('span', this.props, ...children);
  }
});
