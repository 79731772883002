import formatMessage from 'format-message';
import _ from 'lodash';
import { getState } from '../../../store';
import {
  SORT_EMAIL,
  SORT_APPROVED,
  SORT_REPORTS,
  SORTING_ORDER_DESCENDING,
  SORTING_ORDER_ASCENDING
} from '../../../constants/search';
import { getDefaultSearchSortingOptions } from './default-search-sorting-options';
import { createSearchSortingOptions, joinSortingValues } from './util';

const EMAIL_DESC = joinSortingValues(SORT_EMAIL, SORTING_ORDER_DESCENDING);
const EMAIL_ASC = joinSortingValues(SORT_EMAIL, SORTING_ORDER_ASCENDING);
const APPROVED_DESC = joinSortingValues(SORT_APPROVED, SORTING_ORDER_DESCENDING);
const APPROVED_ASC = joinSortingValues(SORT_APPROVED, SORTING_ORDER_ASCENDING);
const REPORTS_DESC = joinSortingValues(SORT_REPORTS, SORTING_ORDER_DESCENDING);
const REPORTS_ASC = joinSortingValues(SORT_REPORTS, SORTING_ORDER_ASCENDING);

const options = [
  {
    id: EMAIL_ASC,
    label: formatMessage({
      default: 'Email, A → Z',
      description: 'Label for option to sort alphabetically by author email'
    })
  },
  {
    id: EMAIL_DESC,
    label: formatMessage({
      default: 'Email, Z → A',
      description: 'Label for option to sort reverse alphabetically by author email'
    })
  },
  {
    id: APPROVED_DESC,
    label: formatMessage({
      default: 'Approved first',
      description: 'Label for option to sort by approved resources first'
    })
  },
  {
    id: APPROVED_ASC,
    label: formatMessage({
      default: 'Approved last',
      description: 'Label for option to sort by approved resources last'
    })
  }
];

const reportOptions = [
  {
    id: REPORTS_DESC,
    label: formatMessage({
      default: 'Most reported',
      description: 'Label for option to sort most reported resources first'
    })
  },
  {
    id: REPORTS_ASC,
    label: formatMessage({
      default: 'Least reported',
      description: 'Label for option to sort least reported resources first'
    })
  }
];

export const getStatsSearchSortingOptions = () => {
  const result = getDefaultSearchSortingOptions();
  result.push(...createSearchSortingOptions(options));

  const isReportContentEnabled = _.get(getState(), 'featureFlags.flags.report_content');
  if (isReportContentEnabled) {
    result.push(...createSearchSortingOptions(reportOptions));
  }

  return result;
};
