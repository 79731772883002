import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@instructure/ui-alerts';
import formatMessage from 'format-message';

import { UsersActions } from '../../../actions';
import AnalyticsActions from '../../../actions/analytics';
import SearchInfo from '../../search/info';
import ResultsResponsive from './results-responsive';
import Download from './download';
import SearchInfoStats from '../../search/results/search-info-stats';
import { isCursorLimitReachedError } from '../../../common/is-cursor-limit-reached-error';
import { CursorLimitReachedError } from '../../search/results/errors/cursor-limit-reached';
import { ResultsErrorWithImage } from '../../search/results/error';
import { showFilterTray } from '../../../actions/filter';
import { RESOURCE_CURSOR_LIMIT } from '../../../constants/resource-cursor-limit';
import SearchActions from '../../../actions/search';

const ERROR_MESSAGES = {
  canvas_user_404: formatMessage(
    'This user account no longer exists in Canvas.'
  ),
  default: formatMessage('There was an error.')
};

export default class TableObjects extends React.Component {
  static propTypes = {
    baseParams: PropTypes.object,
    fetchResults: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    statsMessage: PropTypes.node,
    searchPrivateObjects: PropTypes.bool,
    query: PropTypes.string,
    queryPlaceholder: PropTypes.string,
    queryArialLabel: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.state = {
      isNewSearch: true
    };

    this.props.fetchResults({
      showPublic: true,
      ...props.baseParams,
      ...props.router.query
    });

    const params = Object.assign(
      {},
      this.props.baseParams,
      this.props.router.query
    );
    this.fetchResults = (cursor) => this.props.fetchResults(params, cursor);
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.state.isNewSearch && newProps.results.searchSuccessful) {
      this.setState({ isNewSearch: false });
      AnalyticsActions.viewedAccountStatsResults(newProps.results.count);
    }

    if (this.props.router !== newProps.router) {
      const newParams = Object.assign(
        { showPublic: true },
        newProps.baseParams,
        newProps.router.query
      );
      this.props.fetchResults(newParams);
      this.setState({ isNewSearch: !newProps.router.query.page });
    }
  }

  renderResults () {
    return (
      <React.Fragment>
        <SearchInfoStats
          results={this.props.results}
          statsMessage={this.props.statsMessage}
          includeToggleResultsViewButton={false}
        />
        <Download {...this.props} />
        <ResultsResponsive {...this.props} fetchResults={this.fetchResults} />
      </React.Fragment>
    );
  }

  renderEmptyMessage () {
    return this.props.emptyMessage;
  }

  redirectToLastValidPage () {
    SearchActions.redirectToLastPage(
      Math.min(this.props.results.store.getFilteredResultsCount(), RESOURCE_CURSOR_LIMIT),
      this.props.resultsPerPage
    );
  }

  renderError () {
    return isCursorLimitReachedError(this.props.results.error)
      ? <CursorLimitReachedError onBackButtonClick={() => this.redirectToLastValidPage()} onFilterClick={() => showFilterTray()} />
      : <ResultsErrorWithImage onRetryClick={this.fetchResults} />;
  }

  render () {
    const shouldShowEmptyMessage = this.props.results.store.showEmpty();
    const shouldShowError = !!this.props.results.error;

    return (
      <div>
        {this.props.users.loadUserError && (
          <span className="Alert--floating">
            <Alert
              variant="error"
              renderCloseButtonLabel={formatMessage('Close')}
              margin="small"
              timeout={10000}
              onDismiss={() => UsersActions.clearLoadUserError()}
            >
              {ERROR_MESSAGES[this.props.users.loadUserError.message] ||
                ERROR_MESSAGES.default}
            </Alert>
          </span>
        )}
        <SearchInfo {...this.props} />
        {shouldShowError
          ? this.renderError()
          : shouldShowEmptyMessage
            ? this.renderEmptyMessage()
            : this.renderResults()}
      </div>
    );
  }
}
