var classnames = require('classnames');
var pauseEvent = require('./lib/pause-event');

var React = require('react');
var PropTypes = require('prop-types');
var createReactClass = require('create-react-class');

module.exports = createReactClass({
  displayName: 'Knob',

  propTypes: {
    options: PropTypes.array,
    onMove: PropTypes.func,
    onDragEnd: PropTypes.func,
    onMoveIndex: PropTypes.func,
    onMoveIndexBackward: PropTypes.func,
    onMoveIndexForward: PropTypes.func,
    onPointerMove: PropTypes.func,
    index: PropTypes.number,
    lowerBoundIndex: PropTypes.number,
    upperBoundIndex: PropTypes.number,
    left: PropTypes.string,
    label: PropTypes.string,
    isUpperBound: PropTypes.bool
  },

  getInitialState: function () {
    return {
      dragging: false,
      focus: false
    };
  },

  shouldComponentUpdate: function (nextProps, nextState) {
    return (
      nextProps.index !== this.props.index ||
      nextState.dragging !== this.state.dragging ||
      nextState.focus !== this.state.focus
    );
  },

  componentDidUpdate: function (prevProps, prevState) {
    // Force repaint in Safari
    // http://martinwolf.org/2014/06/10/force-repaint-of-an-element-with-javascript/
    var element = this.refs.div;
    element.style.display = 'none';
    // eslint-disable-next-line no-unused-expressions
    element.offsetHeight;
    element.style.display = '';
  },

  getMouseEventMap: function () {
    return {
      mousemove: this.handleMouseMove,
      mouseup: this.handleMouseUp
    };
  },

  handleMouseDown: function (event) {
    this.setState({
      dragging: true,
      focus: true
    });
    pauseEvent(event);
    this.addHandlers(this.getMouseEventMap());
    this.refs.select.focus();
  },

  handleMouseUp: function () {
    this.handleDragEnd(this.getMouseEventMap());
  },

  handleDragEnd: function (eventMap) {
    this.removeHandlers(eventMap);
    this.setState({
      dragging: false
    });
    this.props.onDragEnd();
  },

  handleMouseMove: function (event) {
    pauseEvent(event);
    var position = this.getMousePosition(event);
    this.props.onPointerMove(this.props.index, position[0], event);
  },

  getMousePosition: function (event) {
    return [event.pageX, event.pageY];
  },

  addHandlers: function (eventMap) {
    for (var key in eventMap) {
      document.addEventListener(key, eventMap[key], false);
    }
  },

  removeHandlers: function (eventMap) {
    for (var key in eventMap) {
      document.removeEventListener(key, eventMap[key], false);
    }
  },

  handleKeyDown: function (event) {
    var index = this.props.index;
    var isLeftArrow = event.which === 37;
    var isRightArrow = event.which === 39;

    if (isLeftArrow && this.props.onMoveIndexBackward) {
      this.props.onMoveIndexBackward(index);
    }

    if (isRightArrow && this.props.onMoveIndexForward) {
      this.props.onMoveIndexForward(index);
    }
  },

  handleSelectChange: function (event) {
    var isUpperBound = this.props.isUpperBound;
    var index = this.props.index;
    this.props.onMoveIndex(
      index,
      Number(event.target.value) + Number(isUpperBound || 0)
    );
  },

  handleSelectFocus: function (event) {
    this.setState({ focus: true });
  },

  handleSelectBlur: function (event) {
    this.setState({ focus: false });
  },

  render: function () {
    var options = this.props.options;
    var isUpperBound = this.props.isUpperBound;
    var lowerBoundIndex = this.props.lowerBoundIndex;
    var upperBoundIndex = this.props.upperBoundIndex;

    var knobClasses = classnames('gri-knob', {
      'gri-knob-dragging': this.state.dragging,
      'gri-knob-focus': this.state.focus
    });

    var knobSelectClasses = classnames(
      'gri-knob-select',
      'gri-screenreader-only',
      {
        'gri-knob-lower-bound-select': !isUpperBound,
        'gri-knob-upper-bound-select': isUpperBound
      }
    );

    var optionComponents = options.map(function (option, index) {
      var disabled = !(isUpperBound
        ? index >= lowerBoundIndex && index < options.length
        : index >= 0 && index < upperBoundIndex);
      return (
        <option key={option.value} value={index} disabled={disabled}>
          {option.label || option.abbreviation}
        </option>
      );
    });

    return (
      <div
        title={this.props.label}
        ref="div"
        onMouseDown={this.handleMouseDown}
        className={knobClasses}
        style={{ left: this.props.left }}
      >
        <select
          aria-label={this.props.label}
          ref="select"
          value={this.props.index - Number(isUpperBound || 0)}
          className={knobSelectClasses}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleSelectChange}
          onFocus={this.handleSelectFocus}
          onBlur={this.handleSelectBlur}
          tabIndex={0}
        >
          {optionComponents}
        </select>
      </div>
    );
  }
});
