import React from 'react';
import classnames from 'classnames';
import formatMessage from 'format-message';

export default function UploadIcon ({ className }) {
  return (
    <svg
      focusable="false"
      className={classnames('UploadIcon', className)}
      width="40" height="25" viewBox="0 0 40 25"
    >
      <title>{formatMessage('Upload')}</title>
      <path
        fill="currentColor"
        d="M32.27 10.52c0-.103.017-.208.017-.312C32.287 4.568 27.722 0 22.087
          0 18.03 0 14.543 2.373 12.9 5.807c-.003.005-.008.006-.015.005-.712
          -.354-1.51-.56-2.352-.56-2.598 0-4.765 1.898-5.18 4.378-.003.003
          -.005.007-.008.007C2.227 10.697 0 13.64 0 17.1 0 21.458 3.535 25
          7.888 25l10.445-.003V17.5h-5l7.5-7.5 7.5 7.5h-5v7.495l8.752-.003
          c3.943 0 7.287-3.085 7.4-7.027.118-4.082-3.162-7.435-7.215-7.445"
      />
    </svg>
  );
}
