import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import { Overlay } from '@instructure/ui-overlays';

import HandlesErrors from '../../common/handles-errors';
import * as Thumbnail from '../../../models/thumbnail';
import tracker from '../../../common/analytics/analytics';
import * as announce from '../../../common/announce';
import Button from '../../common/button';
import Message from '../../common/message';
import formatMessage from 'format-message';
import ComplexMessage from '../../common/complex-message';
import FilePicker from '../../common/file-picker';
import ImageCropper from './image-cropper';
import CroppedImg from '../../common/cropped-img';
import Link from '../../common/link';

const MIN_WIDTH = 262;
const MIN_HEIGHT = 147;
const VALID_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif'];
const ESCAPE_KEY = 27;

function hasOnlyOneImage (thumbnails) {
  return thumbnails.length === 1;
}

export default createReactClass({
  displayName: 'ImagePicker',

  mixins: [HandlesErrors],

  propTypes: {
    imageState: PropTypes.object.isRequired,
    value: PropTypes.string,
    description: PropTypes.string,
    alt: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    learningObjectId: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.node,
    initialSearchQuery: PropTypes.string
  },

  getInitialState () {
    return {
      uploadError: null,
      modalIsOpen: false,
      isLoading: true,
      thumbnails: {},
      image: null,
      imageQuery: this.props.initialSearchQuery,
      searchInputFocus: false
    };
  },

  onImageLoaded (image) {
    const width = image.naturalWidth || image.width;
    const height = image.naturalHeight || image.height;

    const isBigEnough =
      width && width >= MIN_WIDTH && height && height >= MIN_HEIGHT;

    if (!isBigEnough) {
      const message = formatMessage(
        'Image is too small. Must be at least { width } x { height }.',
        {
          width: MIN_WIDTH,
          height: MIN_HEIGHT
        }
      );
      this.setState({
        uploadError: message,
        image: null
      });
      var modalPage = ReactDOM.findDOMNode(this).querySelector(
        '.lor-fullscreen-modal-page'
      );
      if (modalPage) modalPage.focus();
      announce.assertively(message);
      return false;
    }

    return true;
  },

  componentDidMount () {
    this.findThumbnails();
    this._isMounted = true;
  },

  componentWillUnmount () {
    this._isMounted = false;
    announce.reset();
  },

  onUpload (image) {
    const ext = `.${image.name
      .split('.')
      .pop()
      .toLowerCase()}`;
    const isValidExtension = VALID_EXTENSIONS.includes(ext);

    if (!isValidExtension) {
      const message = formatMessage('Please upload jpg, png, or gif files.');
      this.setState({ uploadError: message });
      var modalPage = ReactDOM.findDOMNode(this).querySelector(
        '.lor-fullscreen-modal-page'
      );
      if (modalPage) modalPage.focus();
      announce.assertively(message);
    } else {
      this.setState({
        uploadError: null,
        image
      });
    }
  },

  UNSAFE_componentWillReceiveProps (nextProps) {
    // only make the request if the prop has changed
    if (this.props.learningObjectId !== nextProps.learningObjectId) {
      this.findThumbnails(nextProps.learningObjectId);
    }

    const suggestedQueryChanged =
      this.props.initialSearchQuery !== nextProps.initialSearchQuery;
    if (suggestedQueryChanged) {
      this.setState({
        imageQuery: nextProps.initialSearchQuery
      });
    }
  },

  findThumbnails (learningObjectId) {
    // the error message calls this function and passes in it's event
    // so this is to prevent it using the event
    learningObjectId =
      typeof learningObjectId === 'string'
        ? learningObjectId
        : this.props.learningObjectId;

    Thumbnail.find(learningObjectId, (err, body) => {
      if (err || body.errors) {
        var msg = (
          <ComplexMessage>
            {formatMessage(
              '{error_message} Please try again by {clicking_here}.',
              {
                error_message: ComplexMessage.placeholder('error'),
                clicking_here: ComplexMessage.placeholder('here')
              }
            )}
            <strong key="error">
              {formatMessage('Sorry, we couldn’t load thumbnails.')}
            </strong>
            <Link key="here" onClick={this.findThumbnails}>
              {formatMessage('clicking here')}
            </Link>
          </ComplexMessage>
        );
        return this.props.replaceErrors({ param: 'thumbnails', msg: msg });
      } else {
        this.props.removeErrorsWithId('thumbnails');
      }

      if (this._isMounted) {
        this.setState({
          isLoading: false,
          thumbnails: body.items
        });
      }

      if (hasOnlyOneImage(body.items) && !this.props.value) {
        this.handleImageSelect({
          url: body.items[0].url,
          description: body.items[0].description
        });
      }
    });
  },

  triggerSelectionProcess () {
    tracker.logAction('Share', 'StartThumbnailSelection');
    this.openModal();
  },

  handleImageSelect (image) {
    const data = {
      target: {
        name: this.props.name,
        value: image
      }
    };

    this.props.onChange(data);
    this.closeModal();
    return false;
  },

  openModal () {
    if (this.state.modalIsOpen) return false;

    this.setState(
      {
        modalIsOpen: true
      },
      () => {
        const uploadInput = ReactDOM.findDOMNode(this).querySelector(
          '.Images-search-query-input'
        );
        if (uploadInput) {
          uploadInput.focus();
          uploadInput.select();
        }
      }
    );
    return false;
  },

  closeModal () {
    if (!this.state.modalIsOpen) {
      return false;
    }

    this.setState(
      {
        modalIsOpen: false
      },
      () => {
        if (this.refs.selectButton) {
          ReactDOM.findDOMNode(this.refs.selectButton).focus();
        }
      }
    );
    return false;
  },

  renderSelectButton () {
    if (hasOnlyOneImage(this.state.thumbnails)) {
      return <div />;
    }

    return (
      <div className="ImagePicker-button-wrapper">
        <div>
          <Button
            size={Button.size.small}
            ref="selectButton"
            onClick={this.triggerSelectionProcess}
            className="ImagePicker-button"
          >
            {formatMessage('Click to change')}
          </Button>
        </div>
      </div>
    );
  },

  onCropCancel () {
    this.setState({ image: null }, () => {
      var uploadInput = ReactDOM.findDOMNode(this).querySelector(
        '.FilePicker-input'
      );
      if (uploadInput) uploadInput.focus();
    });
  },

  onCropDone (image) {
    image.name = this.state.image.name; // copy filename over to cropped blob
    this.props.onChange({
      target: {
        name: this.props.name + 'File',
        value: image
      }
    });
    this.handleImageSelect({
      url: window.URL.createObjectURL(image),
      description: formatMessage('Uploaded Thumbnail')
    });
    this.setState({ image: null });
  },

  cancel () {
    tracker.logAction('Share', 'CancelThumbnailSelection');
    this.closeModal();
  },

  onKeyUp (event) {
    // only close on escape
    if (event.which === ESCAPE_KEY) {
      this.cancel();
    }
  },

  render () {
    if (this.props.hasErrorsWithId('thumbnails')) {
      return <div>{this.props.renderErrorsWithId('thumbnails')}</div>;
    }

    let selectedImage;
    const useSelectedImage = this.props.value;
    if (useSelectedImage) {
      selectedImage = (
        <CroppedImg
          className="SearchResultThumbnail-image"
          src={this.props.value}
          alt={this.props.description}
        />
      );
    }

    const modal = (
      <div className="lor-fullscreen-modal" onKeyUp={this.onKeyUp}>
        <article className="lor-fullscreen-modal-page" tabIndex="-1">
          {this.state.image ? (
            <ImageCropper
              minHeight={MIN_HEIGHT}
              minWidth={MIN_WIDTH}
              image={this.state.image}
              onCancel={this.onCropCancel}
              onDone={this.onCropDone}
              onImageLoaded={this.onImageLoaded}
            />
          ) : (
            <div>
              {this.state.uploadError && (
                <Message type="error">
                  <strong>{this.state.uploadError}</strong>
                </Message>
              )}
              <FilePicker
                label={formatMessage(
                  'Drag and drop your image here or {browse} your computer.',
                  {
                    browse: ComplexMessage.placeholder('browse')
                  }
                )}
                typesLabel={formatMessage('jpg, png, or gif files')}
                extensions={VALID_EXTENSIONS}
                onChange={this.onUpload}
              />

              <nav className="ImagePicker-nav lor-modal-nav">
                <Button
                  className="lor-modal-close-button"
                  onClick={this.cancel}
                >
                  {formatMessage('Cancel')}
                </Button>
              </nav>

            </div>
          )}
        </article>
      </div>
    );

    return (
      <div className="ImagePicker LearningObjectForm-thumbnail">
        <div className="ImagePicker-image-container">{selectedImage}</div>
        {this.renderSelectButton()}
        <Overlay
          open={this.state.modalIsOpen}
          label={formatMessage('Image Picker')}
          shouldReturnFocus
          shouldContainFocus
        >
          {modal}
        </Overlay>
      </div>
    );
  }
});
