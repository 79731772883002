import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import tracker from '../../common/analytics/analytics';
import { TextInput } from '@instructure/ui-text-input';
import { IconSearchLine } from '@instructure/ui-icons';

export default class SearchInput extends React.Component {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { value: props.defaultValue };
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    'aria-label': PropTypes.string
  }

  onFocus = () => {
    tracker.logAction('Import_LOR_Object', 'Click', 'Start_LOR_Search', '1', {
      nonInteraction: 1
    });
  }

  onChange = evt => {
    this.setState({ value: evt.target.value });
    this.props.onChange(evt);
  }

  render () {
    // note: default props use `in` and would allow null
    const placeholder =
      this.props.placeholder ||
      formatMessage('Search by title, name, institution or tag');
    const label =
      this.props['aria-label'] ||
      formatMessage(
        'Search Commons for course resources by tag, name, institution, or outcome'
      );
    return (
      <div className={`SearchInput ${this.props.classNames || ''}`} data-heap-redact-text>
        <TextInput
          renderAfterInput={IconSearchLine}
          onChange={this.onChange}
          onFocus={this.onFocus}
          value={this.state.value}
          placeholder={placeholder}
          aria-label={label}
          id="SearchInput"
          display="inline-block"
          renderLabel={false}
          name="q"
        />
      </div>
    );
  }
}
