import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import consortiumErrors from '../models/error-messages/consortium';
import ResourceActions from './resource';

export const RESET_CONSORTIUM = 'consortium.reset';
export const LOAD_CONSORTIUM_START = 'consortium.load-consortium-start';
export const LOAD_CONSORTIUM_SUCCESS = 'consortium.load-consortium-success';
export const LOAD_CONSORTIUM_FAILURE = 'consortium.load-consortium-failure';
export const CHANGE_NAME = 'consortium.change-name';
export const LOAD_MEMBERS_START = 'consortium.load-members-start';
export const LOAD_MEMBERS_SUCCESS = 'consortium.load-members-success';
export const LOAD_MEMBERS_FAILURE = 'consortium.load-members-failure';
export const CREATE_MEMBER_START = 'consortium.create-member-start';
export const CREATE_MEMBER_SUCCESS = 'consortium.create-member-success';
export const CREATE_MEMBER_FAILURE = 'consortium.create-member-failure';
export const DELETE_MEMBER_START = 'consortium.delete-member-start';
export const DELETE_MEMBER_SUCCESS = 'consortium.delete-member-success';
export const DELETE_MEMBER_FAILURE = 'consortium.delete-member-failure';
export const UPDATE_CONSORTIUM_START = 'consortium.update-start';
export const UPDATE_CONSORTIUM_SUCCESS = 'consortium.update-success';
export const UPDATE_CONSORTIUM_FAILURE = 'consortium.update-failure';
export const CHANGE_MANAGEMENT_STATUS_START = 'consortium.change-management-status-start';
export const CHANGE_MANAGEMENT_STATUS_SUCCESS = 'consortium.change-management-status-success';
export const CHANGE_MANAGEMENT_STATUS_FAILURE = 'consortium.change-management-status-failure';

export const creators = {
  reset: () => ({ type: RESET_CONSORTIUM }),
  loadConsortiumStart: () => ({ type: LOAD_CONSORTIUM_START }),
  loadConsortiumSuccess: ({ consortium, members }) => ({ type: LOAD_CONSORTIUM_SUCCESS, consortium, members }),
  loadConsortiumFailure: (error) => ({ type: LOAD_CONSORTIUM_FAILURE, error }),
  changeName: (name) => ({ type: CHANGE_NAME, name }),
  updateConsortiumStart: () => ({ type: UPDATE_CONSORTIUM_START }),
  updateConsortiumSuccess: (consortium) => ({ type: UPDATE_CONSORTIUM_SUCCESS, consortium }),
  updateConsortiumFailure: (error) => ({ type: UPDATE_CONSORTIUM_FAILURE, error }),
  createMemberStart: () => ({ type: CREATE_MEMBER_START }),
  createMemberSuccess: (member) => ({ type: CREATE_MEMBER_SUCCESS, member }),
  createMemberFailure: (error) => ({ type: CREATE_MEMBER_FAILURE, error }),
  deleteMemberStart: () => ({ type: DELETE_MEMBER_START }),
  deleteMemberSuccess: (member) => ({ type: DELETE_MEMBER_SUCCESS, member }),
  deleteMemberFailure: (error) => ({ type: DELETE_MEMBER_FAILURE, error }),
  changeContributorStatusStart: () => ({ type: CHANGE_MANAGEMENT_STATUS_START }),
  changeContributorStatusSuccess: (membership) => ({ type: CHANGE_MANAGEMENT_STATUS_SUCCESS, membership }),
  changeContributorStatusFailure: (error) => ({ type: CHANGE_MANAGEMENT_STATUS_FAILURE, error })
};

export default function createConsortiumActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadConsortium (consortiumId) {
      actions.loadConsortiumStart();
      const req = apiRequest({
        path: `/consortiums/${consortiumId}`
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw consortiumErrors(response.body || response.error);
        }
        actions.loadConsortiumSuccess(response.body);
      } catch (err) {
        actions.loadConsortiumFailure(err);
      }
    },

    async createMember ({ consortiumId, accountId }) {
      actions.createMemberStart();
      const req = apiRequest({
        path: `/consortiums/${consortiumId}/members`,
        method: 'post',
        body: { accountId }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw consortiumErrors(response.body || response.error);
        }
        actions.createMemberSuccess(response.body);
      } catch (err) {
        actions.createMemberFailure(err);
      }
    },

    async deleteMember (member) {
      actions.deleteMemberStart();
      const req = apiRequest({
        path: `/consortiums/${member.consortiumId}/members/${member.id}`,
        method: 'delete'
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw consortiumErrors(response.body || response.error);
        }
        actions.deleteMemberSuccess(member);
        ResourceActions.clearStore();
      } catch (err) {
        actions.deleteMemberFailure(err);
      }
    },

    async updateConsortium (consortium) {
      actions.updateConsortiumStart();
      const req = apiRequest({
        path: `/consortiums/${consortium.id}`,
        method: 'put',
        body: { name: consortium.name }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw consortiumErrors(response.body || response.error);
        }
        actions.updateConsortiumSuccess(response.body);
      } catch (err) {
        actions.updateConsortiumFailure(err);
      }
    },

    async changeContributorStatus (member) {
      actions.changeContributorStatusStart();
      const req = apiRequest({
        path: `/consortiums/${member.consortiumId}/members/${member.id}`,
        method: 'put',
        body: {
          isContributor: member.isContributor
        }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw consortiumErrors(response.body || response.error);
        }
        actions.changeContributorStatusSuccess(response.body);
      } catch (err) {
        actions.changeContributorStatusFailure(err);
      }
    }
  };
}
