import { get } from 'lodash';
import axios from 'axios';
import env from '../config/env';

const load = async (id) => {
  const request = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'x-session-id': env.SESSION_ID
    }
  });

  try {
    const response = await request.get(`/api/users/${id}/profile`);
    return response.data;
  } catch (error) {
    if (get(error, 'response.data.message') === 'canvas_user' && error.response.status === 404) {
      throw Error('canvas_user_404');
    }

    throw error;
  }
};

export default {
  load
};
