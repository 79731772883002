import {
  GET_FEATURE_FLAG_START,
  GET_FEATURE_FLAG_SUCCESS,
  GET_FEATURE_FLAG_FAILURE
} from '../actions/feature-flags';

export const initialState = {
  error: null,
  isLoaded: false,
  isLoading: false,
  flags: {
    // leaving this line here, because can be useful for future feature flag implementation
    // featured_content: false
    stats: false
  }
};

export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case GET_FEATURE_FLAG_START:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null
      };

    case GET_FEATURE_FLAG_SUCCESS:
      return {
        ...state,
        flags: action.featureFlags,
        isLoading: false,
        isLoaded: true,
        error: null
      };

    case GET_FEATURE_FLAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: new Error(action.error)
      };

    default:
      return state;
  }
}
