import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import Setup from '../components/setup';
import { getState, subscribe } from '../store';

const view = () => (
  <div>
    <Head title={formatMessage('Setup')} />
    <Setup session={getState().session} />
  </div>
);

export default function ({ resolve, render, exiting }) {
  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
  render(view());
  resolve();
}
