import React from 'react';
import { Responsive } from '@instructure/ui-responsive';
import { Flex } from '@instructure/ui-flex';

const CommonsResponsiveFlex = (props) => {
  return (
    <Responsive
      query={{
        small: { maxWidth: '768px' },
        large: { minWidth: '768px' },
      }}
      props={{
        small: { direction: 'column', gap: 'medium' },
        large: { justifyItems: 'space-between', direction: 'row' },
      }}
      {...props}
    >
      {(responsiveProps) => {
        return (
          <Flex {...responsiveProps}>
            {props.children}
          </Flex>
        );
      }}
    </Responsive>
  );
};

export default CommonsResponsiveFlex;
