import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import ResourceActions from './resource';

export const RESET_GROUP = 'group.reset';
export const LOAD_GROUP_START = 'group.load-group-start';
export const LOAD_GROUP_SUCCESS = 'group.load-group-success';
export const LOAD_GROUP_FAILURE = 'group.load-group-failure';
export const CHANGE_NAME = 'group.change-name';
export const LOAD_MEMBERS_START = 'group.load-members-start';
export const LOAD_MEMBERS_SUCCESS = 'group.load-members-success';
export const LOAD_MEMBERS_FAILURE = 'group.load-members-failure';
export const CREATE_MEMBER_START = 'group.create-member-start';
export const CREATE_MEMBER_SUCCESS = 'group.create-member-success';
export const CREATE_MEMBER_FAILURE = 'group.create-member-failure';
export const DELETE_MEMBER_START = 'group.delete-member-start';
export const DELETE_MEMBER_SUCCESS = 'group.delete-member-success';
export const DELETE_MEMBER_FAILURE = 'group.delete-member-failure';
export const UPDATE_GROUP_START = 'group.update-start';
export const UPDATE_GROUP_SUCCESS = 'group.update-success';
export const UPDATE_GROUP_FAILURE = 'group.update-failure';
export const CHANGE_MANAGEMENT_STATUS_START = 'group.change-management-status-start';
export const CHANGE_MANAGEMENT_STATUS_SUCCESS = 'group.change-management-status-success';
export const CHANGE_MANAGEMENT_STATUS_FAILURE = 'group.change-management-status-failure';

export const creators = {
  reset: () => ({ type: RESET_GROUP }),
  loadGroupStart: () => ({ type: LOAD_GROUP_START }),
  loadGroupSuccess: (group) => ({ type: LOAD_GROUP_SUCCESS, group }),
  loadGroupFailure: (error) => ({ type: LOAD_GROUP_FAILURE, error }),
  changeName: (name) => ({ type: CHANGE_NAME, name }),
  updateGroupStart: () => ({ type: UPDATE_GROUP_START }),
  updateGroupSuccess: (group) => ({ type: UPDATE_GROUP_SUCCESS, group }),
  updateGroupFailure: (error) => ({ type: UPDATE_GROUP_FAILURE, error }),
  loadMembersStart: () => ({ type: LOAD_MEMBERS_START }),
  loadMembersSuccess: (members) => ({ type: LOAD_MEMBERS_SUCCESS, members }),
  loadMembersFailure: (error) => ({ type: LOAD_MEMBERS_FAILURE, error }),
  createMemberStart: () => ({ type: CREATE_MEMBER_START }),
  createMemberSuccess: (member) => ({ type: CREATE_MEMBER_SUCCESS, member }),
  createMemberFailure: (error) => ({ type: CREATE_MEMBER_FAILURE, error }),
  deleteMemberStart: () => ({ type: DELETE_MEMBER_START }),
  deleteMemberSuccess: (member) => ({ type: DELETE_MEMBER_SUCCESS, member }),
  deleteMemberFailure: (error) => ({ type: DELETE_MEMBER_FAILURE, error }),
  changeManagementStatusStart: () => ({ type: CHANGE_MANAGEMENT_STATUS_START }),
  changeManagementStatusSuccess: (membership) => ({ type: CHANGE_MANAGEMENT_STATUS_SUCCESS, membership }),
  changeManagementStatusFailure: (error) => ({ type: CHANGE_MANAGEMENT_STATUS_FAILURE, error })
};

export default function createGroupActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async loadGroup (groupId) {
      actions.loadGroupStart();
      const req = apiRequest({
        path: `/groups/${groupId}`
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.loadGroupSuccess(response.body);
      } catch (err) {
        actions.loadGroupFailure(err);
      }
    },

    async loadMembers (groupId) {
      actions.loadMembersStart();
      const req = apiRequest({
        path: `/groups/${groupId}/members`
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.loadMembersSuccess(response.body.items);
      } catch (err) {
        actions.loadMembersFailure(err);
      }
    },

    async createMember ({ groupId, user }) {
      actions.createMemberStart();
      const req = apiRequest({
        path: `/groups/${groupId}/members`,
        method: 'post',
        body: {
          userId: user.uuid,
          isManager: false // by default
        }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.createMemberSuccess(response.body);
      } catch (err) {
        actions.createMemberFailure(err);
      }
    },

    async deleteMember (member) {
      actions.deleteMemberStart();
      const req = apiRequest({
        path: `/groups/${member.groupId}/members/${member.id}`,
        method: 'delete'
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.deleteMemberSuccess(member);
      } catch (err) {
        actions.deleteMemberFailure(err);
      }
    },

    async updateGroup (group) {
      actions.updateGroupStart();
      const req = apiRequest({
        path: `/groups/${group.id}`,
        method: 'put',
        body: { name: group.name }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.updateGroupSuccess(response.body);
        ResourceActions.clearStore();
      } catch (err) {
        actions.updateGroupFailure(err);
      }
    },

    async changeManagementStatus (member) {
      actions.changeManagementStatusStart();
      const req = apiRequest({
        path: `/groups/${member.groupId}/members/${member.id}`,
        method: 'put',
        body: {
          isManager: member.isManager
        }
      });

      try {
        const response = await req.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        actions.changeManagementStatusSuccess(response.body);
      } catch (err) {
        actions.changeManagementStatusFailure(err);
      }
    }
  };
}
