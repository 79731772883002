import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import HandlesErrors from '../../common/handles-errors';
import * as LearningObjectReview from '../../../models/review';
import { confirmationDialog } from '../../confirmation-dialog';
import Review from './review';
import ReviewEdit from './review-edit';
import formatMessage from 'format-message';

export default createReactClass({
  displayName: 'MyReview',

  mixins: [HandlesErrors],

  propTypes: {
    editingUser: PropTypes.object,
    learningObjectId: PropTypes.string.isRequired,
    review: PropTypes.object
  },

  getDefaultProps () {
    return {
      editingUser: {}
    };
  },

  getInitialState () {
    return {
      review: {},
      isInEditMode: false,
      isSubmitting: false
    };
  },

  UNSAFE_componentWillMount () {
    this.setState({
      originalReview: this.props.review || {},
      review: this.props.review || {}
    });
  },

  UNSAFE_componentWillReceiveProps (updatedReview) {
    this.setState({
      review: updatedReview.review || {}
    });
  },

  _genReviewWithRating (ratingValue) {
    return Object.assign({}, this.state.review, { rating: ratingValue });
  },

  _genReviewWithBody (body) {
    return Object.assign({}, this.state.review, { body: body });
  },

  componentDidMount () {
    this._isMounted = true;
  },

  componentWillUnmount () {
    this._isMounted = false;
  },

  onClickEdit () {
    this.setState(
      {
        isInEditMode: true
      },
      () => {
        if (!this._isMounted) return;
        var input = ReactDOM.findDOMNode(this).querySelector('input,textarea');
        if (input) {
          input.focus();
        }
      }
    );
  },

  async onClickDelete () {
    const confirmationMessage = formatMessage('You will permanently delete your review.');
    const confirmationTitle = formatMessage('Delete review?');
    const confirmationAnswer = await confirmationDialog({
      message: confirmationMessage,
      title: confirmationTitle,
      confirmText: 'Delete'
    });

    if (confirmationAnswer) {
      LearningObjectReview.destroy(this.props.learningObjectId, (err, res) => {
        if (err || res.status !== 204) {
          var msg = (
            <div>
              <strong>
                {formatMessage('Sorry, we couldn’t delete your review.')}
              </strong>
              &nbsp;
              {formatMessage('Please try again.')}
            </div>
          );
          return this.replaceErrors({ param: 'my-review-destroy', msg });
        }

        this.removeErrorsWithId('my-review-destroy');
        this.setState({
          originalReview: {},
          review: {}
        });
      });
    }
  },

  onClickCancel () {
    this.setState(
      {
        review: this.state.originalReview,
        isInEditMode: false,
        hasEdits: false
      },
      () => this.returnFocus()
    );
  },

  onClickSave (evt, reviewBody) {
    var newReview = this._genReviewWithBody(reviewBody);
    this.setState({ isSubmitting: true }, () => this.returnFocus());
    LearningObjectReview.update(
      this.props.learningObjectId,
      newReview,
      (err, body) => {
        this.setState({ isSubmitting: false });
        if (err || body.errors) {
          var msg = (
            <div>
              <strong>
                {formatMessage('Sorry, we couldn’t save your review.')}
              </strong>
              &nbsp;
              {formatMessage('Please try again.')}
            </div>
          );
          return this.replaceErrors({ param: 'my-review-body', msg: msg });
        }

        this.removeErrorsWithId('my-review-body');
        this.setState(
          {
            originalReview: body,
            review: body,
            isInEditMode: false,
            hasEdits: false
          },
          () => this.returnFocus()
        );
      }
    );
  },

  onClickRating (ratingValue) {
    this.setState({
      review: this._genReviewWithRating(ratingValue),
      hasEdits: true
    });
  },

  onReviewBodyChange () {
    this.setState({
      hasEdits: true
    });
  },

  returnFocus () {
    const element = ReactDOM.findDOMNode(this).querySelector(
      this.isInEditMode() ? 'input,textarea' : '.lor-review-edit-btn'
    );
    if (element) {
      element.focus();
    }
  },

  isInEditMode () {
    return this.state.isInEditMode || !this.state.review.body;
  },

  render () {
    if (this.isInEditMode()) {
      return (
        <div data-testid="myReviewEdit">
          <ReviewEdit
            isSubmitting={this.state.isSubmitting}
            editingUser={this.props.editingUser}
            onChange={this.onReviewBodyChange}
            onClickCancel={this.onClickCancel}
            onClickSave={this.onClickSave}
            onClickRating={this.onClickRating}
            ref="reviewEdit"
            review={this.state.review}
            showCancel={this.state.hasEdits}
          />
          {this.renderErrorsWithId('my-review-body')}
          {this.renderErrorsWithId('my-review-destroy')}
        </div>
      );
    } else {
      return (
        <div data-testid="myReview">
          <Review
            ref="review"
            review={this.state.review}
            isEditable
            isMyReview
            onClickEdit={this.onClickEdit}
            onClickDelete={this.onClickDelete}
          />
        </div>
      );
    }
  }
});
