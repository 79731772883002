import React from 'react';
import PropTypes from 'prop-types';
import RouterStore from '../../stores/router';

import BadConfig from './400-bad-config';
import BadMedia from './bad-media';
import NoEmail from './no-email';
import NotAuthenticated from './401-not-authenticated';
import NotFound from './404-not-found';
import NoAccess from './403-no-access';
import ServerError from './500-server-error';
import ServiceNotAvailableError from './503-service-not-available';
import SessionExpired from './session-expired';
import LoggedOut from './logged-out';

const statuses = {
  400: React.createFactory(BadConfig),
  403: React.createFactory(NotAuthenticated),
  404: React.createFactory(NotFound),
  500: React.createFactory(ServerError),
  503: React.createFactory(ServiceNotAvailableError)
};

const messages = {
  'bad-config': React.createFactory(BadConfig),
  'bad-media': React.createFactory(BadMedia),
  'no-email': React.createFactory(NoEmail),
  'not-authenticated': React.createFactory(NotAuthenticated),
  'not-found': React.createFactory(NotFound),
  'no-access': React.createFactory(NoAccess),
  'server-error': React.createFactory(ServerError),
  'service-unavailable': React.createFactory(ServiceNotAvailableError),
  'logged-out': React.createFactory(LoggedOut),
  'session-expired': React.createFactory(SessionExpired)
};

export default class ErrorsIndex extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  }

  render () {
    const componentByStatus = statuses[this.props.type];
    const componentsByMessage = messages[this.props.type];
    const componentDefault = statuses['500'];

    return (componentByStatus || componentsByMessage || componentDefault)({
      code: RouterStore.state.query.code
    });
  }
}
