import formatMessage from 'format-message';
import { replaceErrors } from './replace';

export const updateErrors = {
  'user-id-required': formatMessage('userId is required'),
  'not-authorized': formatMessage('Not authorized to view imports for that user'),
  'course-ids-required': formatMessage('Course IDs are required')
};

export default function (err) {
  return replaceErrors(err, updateErrors);
}
