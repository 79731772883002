const THUMBNAIL_WIDTH = 262;
const THUMBNAIL_HEIGHT = 147;

export async function resizeToThumbnail (blob, width = THUMBNAIL_WIDTH, height = THUMBNAIL_HEIGHT) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onload = function (e) {
      const img = new window.Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        const cols = img.width;
        const rows = img.height;

        // Calculate scale to maintain aspect ratio
        const scale = Math.max(width / cols, height / rows);
        const newCols = Math.round(scale * cols);
        const newRows = Math.round(scale * rows);

        // Draw the resized image centered on the canvas
        ctx.drawImage(img, (width - newCols) / 2, (height - newRows) / 2, newCols, newRows);

        canvas.toBlob(function (newBlob) {
          if (newBlob) {
            resolve(newBlob);
          } else {
            reject(new Error('Canvas toBlob conversion failed'));
          }
        }, 'image/jpeg');
      };
      img.src = e.target.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
}
