import usGrades from '../resources/forms/grade-schemes/us';
import formatMessage from 'format-message';

const sortedGradeCodes = [
  'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'HE', 'G'
];

const gradeIndexByGradeCode = sortedGradeCodes.reduce((p, c, index) => {
  p[c] = index;
  return p;
}, {});

const getGradeText = (gradeCode) => usGrades[gradeIndexByGradeCode[gradeCode.toUpperCase()]].label;

const lowText = (lowest) => {
  const gradeCode = sortedGradeCodes[lowest];
  return gradeCode === 'HE' || gradeCode === 'G' ? getGradeText(gradeCode) : gradeCode;
};

const highText = (highest) => {
  const gradeCode = sortedGradeCodes[highest];
  if (gradeCode === 'HE' || gradeCode === 'G') return getGradeText(gradeCode);

  const isPlural = highest < gradeIndexByGradeCode.HE && highest > gradeIndexByGradeCode['1'];
  return isPlural ? formatMessage('{0} Grades', [gradeCode]) : formatMessage('{0} Grade', [gradeCode]);
};

export const sortGrades = (a, b) => gradeIndexByGradeCode[a.toUpperCase()] - gradeIndexByGradeCode[b.toUpperCase()];

export const mapToGradeAbbreviation = (grade) => {
  const gradeInfo = usGrades[gradeIndexByGradeCode[grade.toUpperCase()]];
  return gradeInfo
    ? (gradeInfo.abbreviation || gradeInfo.label)
    : grade;
};

export default function groupResourceGrades (gradesIn) {
  if (!Array.isArray(gradesIn)) return formatMessage('No grades set');

  const grades = gradesIn.filter(x => gradeIndexByGradeCode[x] !== undefined);

  if (grades.length === 0) return formatMessage('No grades set');

  if (grades.length === 1) {
    const gradeCode = grades[0];
    return gradeCode === 'K' || gradeCode === 'HE' || gradeCode === 'G'
      ? getGradeText(gradeCode) : formatMessage('{0} Grade', [gradeCode]);
  }

  const gradeIndexes = grades.map(x => gradeIndexByGradeCode[x]);
  const lowest = Math.min(...gradeIndexes);
  const highest = Math.max(...gradeIndexes);

  if (lowest === 0 && highest === sortedGradeCodes.length - 1) {
    return formatMessage('All grades');
  }

  return `${lowText(lowest)} - ${highText(highest)}`;
}
