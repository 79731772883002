import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import Errors from '../components/errors';
import env from '../config/env';
import router from '../router';
import { getState } from '../store';
import { redirectToLogin } from './login-redirect';

function NotAuthenticated () {
  return (
    <div>
      <Head title={formatMessage('Not Authenticated')} />
      <Errors type="not-authenticated" />
    </div>
  );
}

export default function ({ resolve, render }) {
  if (!env.SESSION_ID) {
    if (!redirectToLogin()) {
      router.navigate('/search');
    }
    return resolve();
  } else if (getState().session.limited) {
    render(<NotAuthenticated />);
    return resolve();
  }
}
