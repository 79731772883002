import React from 'react';
import * as announce from '../../common/announce';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import ComplexMessage from '../common/complex-message';
import Message from '../common/message';
import Link from '../common/link';
import { exportStatus } from '../../common/app-constants';

export default class MessageBanner extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired,
    isRecentlyCreated: PropTypes.bool.isRequired,
    isRecentlyImported: PropTypes.bool.isRequired,
    handleImportRetry: PropTypes.func,
    message: PropTypes.object
  }

  componentDidMount () {
    this.announce();
  }

  getExportStatus () {
    var version = (this.props.learningObject.versions || [])[0];
    var status = version ? version.exportStatus : exportStatus.failed;
    return status;
  }

  shouldComponentUpdate (nextProps) {
    const somethingChanged =
      this.props.handleImportRetry !== nextProps.handleImportRetry ||
      this.props.isRecentlyCreated !== nextProps.isRecentlyCreated ||
      this.props.isRecentlyImported !== nextProps.isRecentlyImported ||
      this.props.learningObject.isCreator !==
        nextProps.learningObject.isCreator ||
      this.props.learningObject.versions !==
        nextProps.learningObject.versions ||
      this.props.learningObject.downloadCount !==
        nextProps.learningObject.downloadCount;

    return somethingChanged;
  }

  componentDidUpdate () {
    this.announce();
  }

  announce () {
    const messageContainer = document.querySelector('.message-container');
    const message =
      messageContainer && messageContainer.querySelector('.message');
    if (message) {
      // This timeout is needed since the tray will close as soon as we do the import
      // casuing the focus to go back to the Import / Download button and the announce gets missed
      // by voice over
      setTimeout(() => {
        announce.assertively(message.textContent);
      }, 400);
    }
  }

  render () {
    const messages = [];
    const status = this.getExportStatus();
    const {
      isRecentlyCreated,
      isRecentlyImported,
      handleImportRetry
    } = this.props;

    if (this.props.message) {
      messages.push(
        <Message type={this.props.message.type}>
          {this.props.message.message}
        </Message>
      );
    } else {
      // exporting message
      if (status === exportStatus.complete && isRecentlyCreated) {
        messages.push(
          <Message type="success">
            <strong>{formatMessage('Your share was successful!')}</strong>
          </Message>
        );
      }

      // importing messages
      if (isRecentlyImported && !handleImportRetry) {
        messages.push(
          <Message type="success">
            <ComplexMessage>
              {formatMessage(
                `{success_message}
                  It may take a little while to see changes in your course.`,
                {
                  success_message: ComplexMessage.placeholder('success')
                }
              )}
              <strong key="success">
                {formatMessage('You have successfully started the import!')}
              </strong>
            </ComplexMessage>
          </Message>
        );
      } else if (isRecentlyImported && handleImportRetry) {
        messages.push(
          <Message type="error">
            <ComplexMessage>
              {formatMessage('{error_message} Please {try_again}.', {
                error_message: ComplexMessage.placeholder('error'),
                try_again: ComplexMessage.placeholder('retry')
              })}
              <strong key="error">
                {formatMessage(
                  'Something went wrong with the import into Canvas.'
                )}
              </strong>
              <Link key="retry" onClick={handleImportRetry}>
                {formatMessage('try again')}
              </Link>
            </ComplexMessage>
          </Message>
        );
      }
    }

    messages.length && messages.splice(0, 0, <br />);

    return React.createElement(
      'div',
      { className: 'message-container' },
      ...messages
    );
  }
}
