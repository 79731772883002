export function replaceErrors (err, errorMessages) {
  const errors = err.errors;
  const localizedErrors = Array.isArray(errors) && errors
    .map((error) => {
      return {
        ...error,
        msg: errorMessages[error.msg] || errorMessages[`${error.dataPath}-${error.keyword}`] || error.msg
      };
    });
  err.errors = localizedErrors;
  return err;
}
