export const getLocalStorageItem = (key, fallbackValue) => {
  try {
    return window.localStorage.getItem(key) || fallbackValue;
  } catch (e) {
    return fallbackValue;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    return false;
  }

  return true;
};
