import React from 'react';

export default class TosTextEn extends React.Component {
  render () {
    return (
      <div>
        <p>Instructure Learning Object Repository Terms of Use</p>
        <p>Last Updated Date: June 16th, 2016</p>

        <p>
          PLEASE READ THIS TERMS OF USE AGREEMENT (THE “TERMS”) CAREFULLY. BY
          ACCESSING OR USING THIS WEBSITE (THE “WEBSITE”) OF INSTRUCTURE, INC.
          (“INSTRUCTURE”), INCLUDING USING THE SERVICES ENABLED VIA THE WEBSITE
          (THE “SERVICES”), CLICKING THE “I ACCEPT” CHECK BOX, OR MERELY BROWSING
          THE WEBSITE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTAND, AND AGREE
          TO BE BOUND BY THE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS,
          YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE SERVICES.
        </p>
        <p>
          PLEASE NOTE THAT THE TERMS ARE SUBJECT TO CHANGE BY INSTRUCTURE IN ITS
          SOLE DISCRETION AT ANY TIME. When changes are made, Instructure will
          make a new copy of the Terms available at the Website. We will also
          update the “Last Updated” date at the top of the Terms. If we make any
          material changes, and you have registered to use the Services, we will
          also send an e-mail to you at the last e-mail address you provided to
          us pursuant to the Terms. Any changes to the Terms will be effective
          immediately for new Users of the Website or Services and will be
          effective thirty (30) days after posting of notice of such changes on
          the Website for existing User. If you do not agree to any change(s)
          after receiving a notice of such change(s), you shall stop using the
          Website and/or the Services. Otherwise, your continued use of the
          Website and/or Services constitutes your acceptance of such change(s).
        </p>
        <p>
          USE OF THE SERVICES AND INSTRUCTURE PROPERTIES. The Services consists
          of a platform for users of the Services (each, a “User”) to share
          content (whether information, text, images, audio, tools, resources,
          or any other form of content) that other Users may use for educational
          and classroom purposes.  The Website, the Services, and the information
          and content made available by Instructure on the Website and in the
          Services (collectively, the “Instructure Properties”) are protected
          by copyright laws throughout the world. Subject to the Terms,
          Instructure grants you a limited license to reproduce portions of
          the Instructure Properties for the sole purpose of using the
          Services for your personal or internal business purposes.
        </p>
        <p>
          CERTAIN RESTRICTIONS. The rights granted to you in the Terms are subject
          to the following restrictions: (a) you shall not license, sell, rent,
          lease, transfer, assign, reproduce, distribute, host or otherwise
          commercially exploit the Instructure, (b) you shall not use framing
          techniques to enclose any trademark or logo on the Instructure Properties;
          (c) you shall not use any metatags or other “hidden text” using
          Instructure’s name or trademarks; (d) you shall not modify, translate,
          adapt, make derivative works of, disassemble, decompile, reverse compile
          or reverse engineer any part of the Instructure Properties Properties
          except to the extent the foregoing restrictions are expressly prohibited
          by applicable law; (e) you shall not use any automated software, devices,
          or other processes (including but not limited to spiders, robots,
          scrapers, crawlers, avatars, data mining tools, or the like) to
          “scrape” or download data from the Services; (f) except as expressly
          stated herein or as stated in the terms accompanying an item of Content
          uploaded by another User to the Services, no part of the Instructure
          Properties may be reproduced, distributed, republished, downloaded,
          displayed, posted or transmitted in any form or by any means; and (g)
          you shall not remove or destroy any copyright notices or other proprietary
          markings contained on or in the Services. Instructure and its suppliers
          reserve all rights not granted in the Terms. Any unauthorized use of
          the Instructure Properties terminates the licenses granted by
          Instructure pursuant to the Terms.
        </p>
        <p>
          REGISTRATION. In order to access certain features of the Instructure
          Properties you must have an account (“Account”). To create an Account,
          you must provide us with any registration data requested in the
          registration form and accept the Terms (“Registration Data”). In
          registering for the Services, you agree to (1) provide true, current,
          and complete Registration Data; and (2) maintain and promptly update
          the Registration Data to keep it true, current, and complete. You are
          responsible for all activities that occur under your Account. You may
          not share your Account or password with anyone, and you agree to notify
          Instructure immediately of any unauthorized use of your password or
          any other breach of security.
        </p>
        <p>
          CONTENT. You acknowledge that all information and content (“Content”),
          including the Instructure Properties, is the sole responsibility of
          the party from whom such Content originated. This means that you, and
          not Instructure, are entirely responsible for all Content that you
          upload, post, e-mail, transmit or otherwise make available
          (“Make Available”) through the Instructure Properties (“Your Content”),
          and other Users of the Instructure Properties, and not Instructure,
          are similarly responsible for all Content they Make Available through
          the Instructure Properties (“User Content”). You acknowledge that
          Instructure has no obligation to pre-screen any Content, although
          Instructure reserves the right in its sole discretion to pre-screen,
          refuse, or remove any Content that (1) violates any law or regulation,
          (2) violates these Terms, and/or (3) otherwise creates liability for
          Instructure. Unless expressly agreed to by Instructure in writing
          elsewhere, Instructure has no obligation to store any of Your Content.
          Instructure has no responsibility or liability for the deletion or
          accuracy of any Content or the failure to store or transmit Content.
          Without limiting the foregoing, Instructure shall have the right, in
          its sole discretion, to remove any of Your Content for any reason,
          including if such Content violates the Terms or any applicable law.
        </p>
        <p>
          OWNERSHIP. Except with respect to Your Content and User Content, you
          agree that Instructure and its suppliers own all rights, title and
          interest in the Instructure Properties. Instructure’s name and other
          related graphics, logos, service marks and trade names used on or in
          connection with the Instructure Properties are the trademarks of
          Instructure and may not be used without permission in connection with
          any third-party products or services. Other trademarks, service marks
          and trade names that may appear on or in the Instructure Properties
          are the property of their respective owners. Instructure does not claim
          ownership of Your Content. However, you grant the following rights to
          Your Content: (a) to Instructure a fully paid, royalty-free, perpetual,
          irrevocable, worldwide, royalty-free, non-exclusive and fully sublicensable
          right (including any moral rights) and license to use, license, distribute,
          reproduce, modify, adapt, publicly perform, and publicly display, Your
          Content (in whole or in part) for the purposes of operating and
          providing the Instructure Properties to you and to your other Users and
          (b) to other Users a limited license to copy, modify, and redistribute
          Your Content in accordance with the terms you select as a part of the
          process for uploading each item of Your Content. When you post or publish
          Your Content on or in the Instructure Properties, you represent that
          you have the authority to grant the aforementioned licenses. You
          warrant that the holder of any worldwide intellectual property right,
          including moral rights, in Your Content, has completely and effectively
          waived all such rights and validly and irrevocably granted to you the
          right to grant the license stated above.
        </p>
        <p>
          USER CODE OF CONDUCT. As a condition of use, you agree not to use the
          Instructure Properties for any purpose that is prohibited by the Terms
          or by applicable law. Do not post, or permit others to post, Content on
          the Instructure Properties that (i) encourages illegal activities, is
          fraudulent, or is unlawful; (ii) insults, defames, harasses, or threatens
          others; (iii) violates the copyright or intellectual property or privacy
          rights of others; (iv) contains obscene material (unless such material
          is made available for educational purposes); (v) harms or impersonates
          others; or (vi) advertises or sells a product or service. Do not use
          content made available on the Services unless allowed by the express
          copyright terms laid out by the particular User who submitted such
          content (e.g. Creative Commons). Do not attempt or engage in, any
          potentially harmful acts that are directed against the Instructure
          Properties, including but not limited to violating or attempting to
          violate any security features of the Instructure Properties, introducing
          viruses, worms, or similar harmful code into the Instructure Properties,
          or interfering or attempting to interfere with use of the Instructure
          Properties by any other user, host or network, including by means of
          overloading, “flooding,” “spamming,” “mail bombing”, or “crashing” the
          Instructure Properties.
        </p>
        <p>
          FEEDBACK. You agree that submission of any ideas, suggestions, documents,
          and/or proposals to Instructure through its suggestion, feedback, wiki,
          forum or similar pages (“Feedback”) is done on a non-confidential basis
          and that Instructure has no obligations with respect to such Feedback.
          You represent and warrant that you have all rights necessary to submit
          the Feedback. You hereby grant to Instructure a fully paid, royalty-free,
          perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable
          right and license to use, reproduce, perform, display, distribute,
          adapt, modify, re-format, create derivative works of, and otherwise
          commercially or non-commercially exploit in any manner, any and all
          Feedback, and to sublicense the foregoing rights.
        </p>
        <p>
          INTERACTIONS WITH OTHER USERS. You are solely responsible for your
          interactions with other Users of the Services and any other parties
          with whom you interact through the Services; provided, however, that
          Instructure reserves the right, but has no obligation, to intercede in
          such disputes. You agree that Instructure will not be responsible for
          any liabilities incurred as the result of such interaction. Instructure
          is not responsible for and does not control User Content. Instructure
          has no obligation to review or monitor, and does not approve, endorse
          or make any representations or warranties with respect to User Content.
          You use all User Content and interact with other Users at your own risk.
        </p>
        <p>
          THIRD-PARTY WEBSITES. The Instructure Properties may contain links to
          third-party websites (“Third Party Websites”). When you click on a
          link to a Third-Party Website, we will not warn you that you have left
          the Instructure Properties and are subject to separate terms and
          conditions or privacy policies. Instructure is not responsible for any
          Third-Party Websites and does not review, approve, monitor, endorse,
          warrant, or make any representations with respect to Third-Party
          Websites, or their products or services. You use all links in Third-Party
          Websites at your own risk.
        </p>
        <p>
          INDEMNIFICATION. You agree to indemnify and hold Instructure, its
          parents, subsidiaries, affiliates, officers, employees, agents,
          partners and licensors (collectively the “Instructure Parties”)
          harmless from any losses, costs, liabilities and expenses (including
          reasonable attorneys’ fees) relating to or arising out of: (a) Your
          Content; (b) your use of, or inability to use, the Instructure
          Properties; (c) your violation of the Terms; (d) your violation of any
          rights of another party, including any User; or (e) your violation of
          any applicable laws, rules or regulations. Instructure reserves the
          right, at its own cost, to assume the exclusive defense and control of
          any matter otherwise subject to indemnification by you, in which event
          you will fully cooperate with Instructure in asserting any available
          defenses. You agree that the provisions in this section will survive
          any termination of your Account, the Terms, or your access to the
          Instructure Properties.
        </p>
        <p>
          DISCLAIMER OF WARRANTIES. YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO
          THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE INSTRUCTURE
          PROPERTIES IS AT YOUR SOLE RISK, AND THE INSTRUCTURE PROPERTIES ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS.
          INSTRUCTURE PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS,
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. INSTRUCTURE
          PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT: (1) THE
          INSTRUCTURE PROPERTIES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF THE
          INSTRUCTURE PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
          ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
          INSTRUCTURE PROPERTIES WILL BE ACCURATE OR RELIABLE; OR (4) ANY ERRORS
          IN THE INSTRUCTURE PROPERTIES WILL BE CORRECTED. ANY CONTENT DOWNLOADED
          FROM OR OTHERWISE ACCESSED THROUGH THE INSTRUCTURE PROPERTIES IS
          ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY OR PERSON, INCLUDING, BUT NOT LIMITED TO, YOUR
          COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS THE INSTRUCTURE PROPERTIES,
          OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT. NO ADVICE
          OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM INSTRUCTURE OR
          THROUGH THE INSTRUCTURE PROPERTIES WILL CREATE ANY WARRANTY NOT
          EXPRESSLY MADE HEREIN.
        </p>
        <p>
          LIMITATION OF LIABILITY.YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL
          INSTRUCTURE PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
          THE INSTRUCTURE PROPERTIES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES
          RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT INSTRUCTURE
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ON ANY THEORY OF
          LIABILITY, RESULTING FROM: (1) THE USE OR INABILITY TO USE THE
          INSTRUCTURE PROPERTIES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS
          OR SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
          OBTAINED THROUGH THE INSTRUCTURE PROPERTIES; (3) UNAUTHORIZED ACCESS TO
          OR ALTERATION OF YOUR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY
          ON INSTRUCTURE PROPERTIES; OR (5) ANY OTHER MATTER RELATED TO THE INSTRUCTURE
          PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT
          (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.
          UNDER NO CIRCUMSTANCES WILL INSTRUCTURE PARTIES BE LIABLE TO YOU FOR
          MORE THAN FIFTY DOLLARS ($50). THE LIMITATIONS OF DAMAGES SET FORTH
          ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
          INSTRUCTURE AND YOU.
        </p>
        <p>
          PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT. It is
          Instructure’s policy to terminate membership privileges of any User who
          repeatedly infringes copyright upon prompt notification to Instructure
          by the copyright owner or the copyright owner’s legal agent. Without
          limiting the foregoing, if you believe that your work has been copied
          and posted on the Instructure Properties in a way that constitutes
          copyright infringement, please provide our Copyright Agent with the
          following information: (1) an electronic or physical signature of the
          person authorized to act on behalf of the owner of the copyright interest;
          (2) a description of the copyrighted work that you claim has been infringed;
          (3) a description of the location on the Instructure Properties of the
          material that you claim is infringing; (4) your address, telephone number
          and e-mail address; (5) a written statement by you that you have a good
          faith belief that the disputed use is not authorized by the copyright
          owner, its agent or the law; (6) a statement by you, made under penalty
          of perjury, that the above information in your notice is accurate and
          that you are the copyright owner or authorized to act on the copyright
          owner’s behalf. Contact information for Instructure’s Copyright Agent
          for notice of claims of copyright infringement is as follows: General
          Counsel; 6330 South 3000 East, Suite 700, Salt Lake City, UT 84121;
          legal@instructure.com.
        </p>
        <p>
          TERM AND TERMINATION. The Terms commence on the date when you accept
          them (as described in the preamble above) and remain in full force and
          effect while you use the Instructure Properties, unless terminated
          earlier in accordance with the Terms. If you have materially breached
          any provision of the Terms, or if Instructure is required to do so by
          law (e.g., where the provision of the Website or the Services is, or
          becomes, unlawful), Instructure has the right to immediately terminate
          any Services and licenses provided to you under these Terms. You agree
          that Instructure shall not be liable to you or any third-party for
          any termination of your Account made in accordance with the Terms.
          Termination of any Service includes removal of access to such Service
          and barring of further use of the Service. Termination of all Services
          also may include deletion of your password and all related information,
          files and Content associated with or inside your Account (or any part
          thereof), including Your Content. Upon termination of any Service,
          your right to use such Service will automatically terminate immediately.
          Instructure will not have any liability whatsoever to you for any termination,
          including for deletion of Your Content. All provisions of the Terms
          which by their nature should survive, shall survive termination of
          Services, including without limitation, ownership provisions,
          indemnification, warranty disclaimers, and limitation of liability.
        </p>
        <p>
          COMPLIANCE WITH EXPORT CONTROL AND SANCTIONS LAWS. You agree that you
          will use the Services in compliance with all export control laws,
          restrictions and regulations of the United States and all other relevant
          countries. You further agree not to export, or allow the export or
          re-export of Content, Your Content, or User Content (including any
          software, technical data, or any direct product thereof): (i) in violation
          of any applicable export control laws, restrictions, or regulations,
          including, but not limited to, the Export Administration Regulations
          (15 C.F.R. Part 730 et. seq.) and the International Traffic in Arms
          Regulations (22 C.F.R. Part 120 et. seq.) of the United States; (ii) to
          persons designated by the U.S. Treasury Department as either a
          Specially Designated National or a Foreign Sanctions Evader; (iii)
          persons designated by the U.S. Commerce Department on the Entities List,
          the Denied Persons List, or the Unverified List; (iv) any person or entity
          located in a country that is or becomes subject to U.S. embargoes
          (collectively, the “Embargoed Countries”); or (v) any person that is a
          national of an Embargoed Country, wherever located, unless and until all
          required licenses or authorizations are obtained from the relevant
          government authorities. If you are accessing or downloading Content,
          Your Content, or User Content (including Services, any software,
          technical data, or any direct product thereof), you represent that you
          are authorized to do so and that such accessing or downloading of content
          does not violate applicable law. You agree that you are solely responsible
          for compliance with U.S. and applicable export control laws, restrictions,
          and regulations. Your rights under the Terms are contingent upon your
          compliance with this and all other provisions.
        </p>
        <p>
          RELEASE. You hereby release Instructure Parties and their successors
          from claims, demands, any and all losses, damages, rights, and actions
          of any kind, including personal injuries, death, and property damage,
          that is either directly or indirectly related to or arises from any
          User Content and interactions with or conduct of other Services Users
          or third-party websites of any kind arising in connection with or as a
          result of the Terms or your use of the Instructure Properties. If you
          are a California resident, you hereby waive California Civil Code
          Section 1542, which states, “A general release does not extend to
          claims which the creditor does not know or suspect to exist in his
          favor at the time of executing the release, which, if known by him must
          have materially affected his settlement with the debtor.
        </p>
        <p>
          MISCELANEOUS. The Terms, and your rights and obligations hereunder,
          may not be assigned, subcontracted, delegated, or otherwise transferred
          by you without Instructure’s prior written consent, and any attempted
          assignment, subcontract, delegation, or transfer in violation of the
          foregoing will be null and void. Instructure shall not be liable for
          any delay or failure to perform resulting from causes outside its
          reasonable control, including, but not limited to, acts of God, war,
          terrorism, riots, embargos, acts of civil or military authorities,
          fire, floods, accidents, strikes or shortages of transportation
          facilities, fuel, energy, labor or materials. The Terms and any action
          related thereto will be governed and interpreted by and under the laws
          of the State of Utah, without giving effect to any principles that
          provide for the application of the law of another jurisdiction. You
          hereby expressly consent to the personal jurisdiction and venue in the
          state of Utah for any lawsuit filed there against you by Instructure
          arising from or related to the Terms. The communications between you
          and Instructure use electronic means, whether you visit the Instructure
          Properties or send Instructure e-mails, or whether Instructure posts
          notices on the Instructure Properties or communicates with you via e-mail.
          For contractual purposes, you (1) consent to receive communications from
          Instructure in an electronic form; and (2) agree that all terms and
          conditions, agreements, notices, disclosures, and other communications
          that Instructure provides to you electronically satisfy any legal
          requirement that such communications would satisfy if it were to be in
          writing. The foregoing does not affect your statutory rights. Where
          Instructure requires that you provide an e-mail address, you are
          responsible for providing Instructure with your most current e-mail
          address. In the event that the last e-mail address you provided to
          Instructure is not valid, or for any reason is not capable of delivering
          to you any notices required/ permitted by the Terms, Instructure’s
          dispatch of the e-mail containing such notice will nonetheless constitute
          effective notice. You may give notice to Instructure at the following
          address: 6330 South 3000 East, Suite 700, Salt Lake City, UT 84121. Such
          notice shall be deemed given when received by Instructure by letter
          delivered by nationally recognized overnight delivery service or first
          class postage prepaid mail at the above address. Any waiver or failure
          to enforce any provision of the Terms on one occasion will not be deemed
          a waiver of any other provision or of such provision on any other occasion.
          If any provision of the Terms is, for any reason, held to be invalid or
          unenforceable, the other provisions of the Terms will remain enforceable,
          and the invalid or unenforceable provision will be deemed modified so
          that it is valid and enforceable to the maximum extent permitted by law.
          The Terms are the final, complete and exclusive agreement of the parties
          with respect to the subject matter hereof and supersedes and merges all
          prior discussions between the parties with respect to such subject matter.
        </p>
      </div>
    );
  }
}
