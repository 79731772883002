import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import _ from 'lodash';
import { View } from '@instructure/ui-view';
import { TopNavBar } from '@instructure/ui-top-nav-bar';
import { IconCommonsLine, IconInfoLine } from '@instructure/ui-icons';
import { Drilldown } from '@instructure/ui-drilldown';

import { mapTitleToHeader } from '../app-header-utils';
import { getState } from '../../store';
import { getLocalizedUrl } from '../../common/localized-urls';
import { getActionsMenu, renderUser } from './menu-helpers';

const renderSubmenu = (item) => {
  return item.subItems
    ? (
      <Drilldown rootPageId={item.id}>
        <Drilldown.Page id={item.id}>
          {item.subItems.map((subItem) => (
            <Drilldown.Option
              id={subItem.id}
              key={subItem.id}
              href={subItem.path}
              data-testid={`submenu-${item.id}-${subItem.id}`}
            >
              {subItem.label}
            </Drilldown.Option>
          ))}
        </Drilldown.Page>
      </Drilldown>
    )
    : undefined;
};

const renderNavItem = (item) => {
  return (
    <TopNavBar.Item
      id={item.id}
      key={item.id}
      href={item.subItems ? undefined : item.path}
      renderSubmenu={renderSubmenu(item)}
    >
      {item.label}
    </TopNavBar.Item>
  );
};

const NewAppNav = ({
  activeHeader,
  mainHeader,
  navItems,
  openGuides,
  isInFrame,
  logout,
  session: {
    isAuthenticated,
    userFullName,
    userAvatarUrl,
  },
  retrievalSuccessful,
}) => {
  const help_tray = _.get(getState(), 'featureFlags.flags.help_tray');
  const onOpenGuides = () => {
    if (help_tray) {
      openGuides();
    }
  };

  return (
    <View as="div">
      <TopNavBar inverseColor breakpoint={684}>
        {({ currentLayout }) => (
          <TopNavBar.Layout
            navLabel={formatMessage('Commons navigation')}
            data-testid="NewAppNav"
            id="newAppNav"
            themeOverride={{
              desktopBackgroundInverse: '#F5F5F5',
              smallViewportBackgroundInverse: '#F5F5F5',
              desktopInlinePadding: '1.5rem !important', // start/end paddings override for inst-ui
            }}
            smallViewportConfig={{
              dropdownMenuToggleButtonLabel: formatMessage('Commons Toggle Menu'),
              dropdownMenuLabel: formatMessage('Commons Main Menu'),
              alternativeTitle: isAuthenticated && mainHeader ? mapTitleToHeader[mainHeader]() : undefined,
            }}
            renderMenuItems={(
              <TopNavBar.MenuItems
                renderHiddenItemsMenuTriggerLabel={(
                  hiddenChildrenCount
                ) => formatMessage('{count} More', { count: hiddenChildrenCount })}
                currentPageId={activeHeader}
                data-testid="NewAppNavMenuItems"
              >
                {navItems.map(renderNavItem)}
              </TopNavBar.MenuItems>
            )}
            renderBrand={(
              <TopNavBar.Brand
                screenReaderLabel="Commons"
                renderIcon={(
                  <IconCommonsLine
                    size="small"
                    color="primary"
                    height="2.5rem"
                    width="2.5rem"
                  />
                )}
                href="/search"
                data-testid="NewAppNavHeaderLogo"
              />
            )}
            renderActionItems={(
              <TopNavBar.ActionItems
                renderHiddenItemsMenuTriggerLabel={formatMessage('More...')}
                data-testid="NewAppNavActionItems"
              >
                {getActionsMenu({
                  isAuthenticated,
                  help_tray,
                  isSmallViewport: currentLayout === 'smallViewport'
                })}
                <TopNavBar.Item
                  renderIcon={<IconInfoLine />}
                  id="guides"
                  variant="button"
                  color="secondary"
                  href={!help_tray
                    ? getLocalizedUrl('https://community.canvaslms.com/t5/Commons/tkb-p/commons')
                    : undefined}
                  target="_blank"
                  data-automation="GuidesButton"
                  data-testid="GuidesButton"
                  onClick={onOpenGuides}
                >
                  {formatMessage('Guides')}
                </TopNavBar.Item>
              </TopNavBar.ActionItems>
            )}
            renderUser={renderUser({
              isInFrame,
              isAuthenticated,
              userFullName,
              userAvatarUrl,
              isSmallViewport: currentLayout === 'smallViewport',
              retrievalSuccessful,
              logout
            })}
          />
        )}
      </TopNavBar>
    </View>
  );
};

NewAppNav.propTypes = {
  activeHeader: PropTypes.oneOf(Object.keys(mapTitleToHeader)).isRequired,
  mainHeader: PropTypes.oneOf(Object.keys(mapTitleToHeader)).isRequired,
  navItems: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  isInFrame: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openGuides: PropTypes.func.isRequired,
  retrievalSuccessful: PropTypes.bool.isRequired,
};

export default NewAppNav;
