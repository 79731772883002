import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { Table } from '@instructure/ui-table';
import { IconLinkSolid, IconMiniArrowUpLine } from '@instructure/ui-icons';
import { Tooltip } from '@instructure/ui-tooltip';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';
import _ from 'lodash';

import Link from '../../common/link';
import LoadMore from '../../common/load-more';
import { LoadingSpinner } from '../../common/loading-spinner';
import ResourceTypeIcon from '../../icons/resource-type';
import { headers } from './const';
import { SearchActions, UsersActions } from '../../../actions/';
import RouterStore from '../../../stores/router';
import { getState } from '../../../store';
import ReportDetailsTray from '../reports-details-tray/reports-details-tray';
import { calculatePaginationPages } from '../../../common/util/calculate-pagination-pages';
import { isResourcePaginated } from '../../../common/util/is-resource-paginated';
import ResultPagination from '../../common/result-pagination';

const PRIVATE_RESOURCE = formatMessage(
  'This resource is private. You do not have permission to view it.'
);

const filterReports = (shouldShowReports) => ({ id }) => id !== 'reported-head' || shouldShowReports;

export default class Results extends React.Component {
  constructor (props) {
    super(props);
    const { sortBy, sortOrder } = RouterStore.state.query;
    const shouldShowReports = _.get(getState(), 'featureFlags.flags.report_content');
    const header = headers
      .filter(filterReports(shouldShowReports))
      .find(({ sort }) => sort === sortBy);
    this.paginationScrollToTop = false;
    this.paginationScrollToBottom = false;
    this.onPageChange = this.onPageChange.bind(this);
    this.endRef = React.createRef();

    this.state = {
      sortBy,
      ascending: sortOrder === 'asc',
      focusedHeaderTitle: null,
      tableCaptions: header && header.onSortedTableCaptions,
      selectedLearningObject: {},
      showReportDetailsTray: false
    };
  }

  renderIsApproved (learningObject) {
    return learningObject.scopeIds.some(scopeId => scopeId.includes('curated-'))
      ? formatMessage('Yes')
      : '-';
  }

  renderResourceTitle (learningObject) {
    // make sure only resources that are private and do not belong to the current user
    const isPrivate =
      learningObject.isPrivate &&
      learningObject.scopeIds[0] !== this.props.session.userId;

    return isPrivate
      ? (<Tooltip
        color="inverse"
        renderTip={PRIVATE_RESOURCE}
        on={['click', 'hover', 'focus']}
        placement="end"
      >
        <span className="focusable Table--resourceLink" tabIndex={0}>
          <ResourceTypeIcon
            className="Stats--resourceIcon"
            type={learningObject.type || 'course'}
          />
          <span className="Table--truncated">{learningObject.title}</span>
        </span>
      </Tooltip>)
      : (<Link
        href={`/resources/${learningObject.id}`}
        title={learningObject.title}
      >
        <span className="Table--resourceLink">
          <ResourceTypeIcon
            className="Stats--resourceIcon"
            type={learningObject.type || 'course'}
          />
          <span className="Table--link Table--truncated">{learningObject.title}</span>
        </span>
      </Link>);
  }

  renderSourceLink (learningObject) {
    if ('sourceId' in learningObject) {
      return (
        <Link
          target="_parent"
          href={`/api/resources/${learningObject.id}/source`}
          title={learningObject.title}
        >
          <IconLinkSolid />
        </Link>
      );
    }

    return null;
  }

  handleAuthorLinkClick (author) {
    return async (event) => {
      event.stopPropagation();
      event.preventDefault();
      await UsersActions.loadUser(author.id);
    };
  }

  renderAuthorLink (learningObject) {
    const author = learningObject.authors[0];

    if (author) {
      return (
        <Link
          data-heap-redact-attributes="href"
          target="_parent"
          href={`/api/users/${author.id}/source?session-id=${this.props.session.sid}`}
          onClick={this.handleAuthorLinkClick(author)}
          title={author.name}
        >
          <span title={author.name} className="Table--truncated Table--link" data-heap-redact-text>
            {author.name}
          </span>
        </Link>
      );
    }
  }

  renderAuthorEmail (learningObject) {
    const author = learningObject.authors[0];

    if (author) {
      const mailLink = `mailto:${author.email}`;

      return (
        <Link
          data-heap-redact-attributes="href"
          href={mailLink}
          title={author.email}
        >
          <span title={author.email} className="Table--truncated Table--link" data-heap-redact-text>
            {author.email}
          </span>
        </Link>
      );
    }
  }

  getPaginationProps () {
    if (!isResourcePaginated()) {
      return { isPaginated: false };
    }

    const count = this.props.results.store.getFilteredResultsCount();
    if (!count) {
      return { isPaginated: true };
    }

    return {
      ...calculatePaginationPages(RouterStore.state.query.page, count, this.props.resultsPerPage),
      isPaginated: true
    };
  }

  onPageChange (currentPage, { isPreviousPageNavigation }) {
    this.paginationScrollToBottom = isPreviousPageNavigation;
    this.paginationScrollToTop = !isPreviousPageNavigation;

    const isPageFull = this.props.results.store.get().length === this.props.resultsPerPage;
    this.paginationScrollBottomPosition = (this.paginationScrollToBottom && isPageFull) ? document.documentElement.scrollTop : null;

    SearchActions.update({ page: currentPage });
  }

  handlePaginationScrolling = () => {
    if (!isResourcePaginated()) {
      return;
    }

    if (this.paginationScrollToTop) {
      this.scrollToTop();
      this.paginationScrollToTop = false;
    } else if (this.paginationScrollToBottom) {
      this.scrollToBottom();
      this.paginationScrollToBottom = false;
    }
  }

  scrollToTop () {
    window.scrollTo(0, 0);
  }

  scrollToBottom () {
    if (this.paginationScrollBottomPosition) {
      document.documentElement.scrollTop = this.paginationScrollBottomPosition;
      this.paginationScrollBottomPosition = null;
    } else {
      this.endRef.current.scrollIntoView();
    }
  }

  renderPagination (paginationProps, isEmpty) {
    if (isEmpty) {
      return null;
    }

    return <div className="Pagination">
      <ResultPagination
        currentPage={paginationProps.currentPage}
        totalPages={paginationProps.totalPages}
        hasTooManyResults={paginationProps.hasTooManyResults}
        onPageChange={this.onPageChange}
      />
    </div>;
  }

  renderLoadMore (isLoading, fetchResults) {
    return <LoadMore
      hasMore={this.props.results.hasMore}
      isLoading={isLoading}
      loadMore={fetchResults}
    />;
  }

  renderReports (learningObject) {
    const openTray = () => {
      if (!this.state.showReportDetailsTray) {
        this.setState({ selectedLearningObject: learningObject, showReportDetailsTray: true });
      }
    };
    if (learningObject.reportCount) {
      return (
        <Link onClick={openTray}>
          <span title={learningObject.reportCount} className="Table--truncated Table--link" data-heap-redact-text>
            {learningObject.reportCount}
          </span>
        </Link>
      );
    } else {
      return learningObject.reportCount;
    }
  }

  handleSort = (event, { sort, onSortedTableCaptions }) => {
    const { ascending } = this.state;

    if (sort === RouterStore.state.query.sortBy) {
      const nextOrder = ascending ? 'desc' : 'asc';
      this.setState({
        ascending: !ascending
      });
      SearchActions.update({ sortOrder: nextOrder });
    } else {
      this.setState({
        sortBy: sort,
        tableCaptions: onSortedTableCaptions,
        ascending: true
      });
      SearchActions.update({ sortBy: sort, sortOrder: 'asc' });
    }
  }

  onFocus = (title) => () => {
    this.setState({
      focusedHeaderTitle: title,
    });
  }

  componentDidUpdate () {
    if (!this.props.results.store.showLoading()) {
      this.handlePaginationScrolling();
    }
  }

  render () {
    const {
      isLayoutStacked,
      trailingColumnsWidthFixed,
    } = this.props;
    const isLoading = this.props.results.store.showLoading();
    const results = this.props.results.store.get();
    const fetchResults = () => this.props.fetchResults(this.props.results.nextCursor);
    const { ascending, focusedHeaderTitle, tableCaptions } = this.state;
    const shouldShowReports = _.get(getState(), 'featureFlags.flags.report_content');
    const direction = ascending ? 'ascending' : 'descending';
    const layout = isLayoutStacked ? 'stacked' : 'fixed';

    const getSortDirection = sort => sort === RouterStore.state.query.sortBy ? direction : 'none';

    const headerSortingLabel = focusedHeaderTitle || '';
    const tableCaption = tableCaptions ? tableCaptions[direction] : '';
    const paginationProps = this.getPaginationProps();
    const isEmpty = !results.length;

    return (
      <div>
        <Table caption={tableCaption} layout={layout} data-automation="StatsTable">
          <Table.Head>
            <Table.Row>
              {headers.filter(filterReports(shouldShowReports)).map(({ id, sort, title, width, textAlign, justifyRight, onClickSortingLabel, onSortedTableCaptions, automation }) =>
                sort ? (
                  <Table.ColHeader
                    key={`header-${id}`}
                    id={title}
                    aria-sort={getSortDirection(sort)}
                    data-automation={automation}
                    width={width(trailingColumnsWidthFixed)}
                    textAlign={textAlign}
                    onRequestSort={e => this.handleSort(e, { sort, onSortedTableCaptions })}
                    sortDirection={getSortDirection(sort)}
                    onFocus={this.onFocus(onClickSortingLabel)}
                  >
                    {justifyRight
                      ? (<span className="Table--ColHeader--justifyRight">{title}</span>)
                      : title}
                  </Table.ColHeader>
                ) : (
                  <Table.ColHeader
                    key={`header-${id}`}
                    id={title}
                    width={width(trailingColumnsWidthFixed)}
                    textAlign={textAlign}
                  >
                    {title}
                  </Table.ColHeader>
                )
              )}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {!isEmpty
              ? results.map(learningObject => (
                <Table.Row
                  key={learningObject.id}
                  data-automation={learningObject.id}
                >
                  <Table.Cell data-automation="ResourceTitle">
                    {this.renderResourceTitle(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Author">
                    {this.renderAuthorLink(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Email">
                    {this.renderAuthorEmail(learningObject)}
                  </Table.Cell>
                  <Table.Cell data-automation="Approved">
                    {this.renderIsApproved(learningObject)}
                  </Table.Cell>
                  {!!shouldShowReports && (
                    <Table.Cell textAlign={isLayoutStacked ? 'start' : 'end'} data-automation="Reports">
                      {this.renderReports(learningObject)}
                    </Table.Cell>
                  )}
                  <Table.Cell textAlign={isLayoutStacked ? 'start' : 'end'} data-automation="Favorites">
                    {learningObject.favoritedCount}
                    <IconMiniArrowUpLine style={{ color: 'transparent' }} />
                  </Table.Cell>
                  <Table.Cell textAlign={isLayoutStacked ? 'start' : 'end'} data-automation="Downloads">
                    {learningObject.downloadCount}
                    <IconMiniArrowUpLine style={{ color: 'transparent' }} />
                  </Table.Cell>
                  <Table.Cell
                    textAlign={isLayoutStacked ? 'start' : 'center'}
                    data-automation="CanvasSource"
                  >
                    {this.renderSourceLink(learningObject)}
                  </Table.Cell>
                </Table.Row>
              ))
              : undefined}
          </Table.Body>
        </Table>
        {isLoading && (
          <div className="loadingSinnner--container">
            <LoadingSpinner />
          </div>
        )}
        {paginationProps.isPaginated
          ? this.renderPagination(paginationProps, isEmpty)
          : this.renderLoadMore(isLoading, fetchResults)}
        <div ref={this.endRef} />
        <div id="headerSortingLabel" aria-live="polite" aria-atomic="true" aria-relevant="additions text">
          <ScreenReaderContent>
            {headerSortingLabel}
          </ScreenReaderContent>
        </div>
        <div id="statsTableCaption" aria-live="polite" aria-atomic="true" aria-relevant="additions text">
          <ScreenReaderContent>
            {tableCaption}
          </ScreenReaderContent>
        </div>
        {!!shouldShowReports && (
          <ReportDetailsTray
            isOpen={this.state.showReportDetailsTray}
            resource={this.state.selectedLearningObject}
            onClose={() => this.setState({ showReportDetailsTray: false })}
          />
        )}
      </div>
    );
  }
}

Results.displayName = 'StatsResults';

Results.propTypes = {
  fetchResults: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  results: PropTypes.object.isRequired,
  isLayoutStacked: PropTypes.bool,
  trailingColumnsWidthFixed: PropTypes.bool
};

Results.defaultProps = {
  isLayoutStacked: false,
  trailingColumnsWidthFixed: false
};
