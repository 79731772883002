import React, { useState } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconStarLightSolid, IconStarSolid } from '@instructure/ui-icons';
import { IconButton } from '@instructure/ui-buttons';
import CommonsLink from '../../common/link';

const ToggleFavoriteButton = ({ resource, isFavorite, onClick, renderAs }) => {
  const [isLoading, setIsLoading] = useState(false);

  const removeFromFavoritesLabel = formatMessage('Remove from favorites');
  const addToFavoritesLabel = formatMessage('Add to favorites');

  const onButtonClick = async (event) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    await new Promise((resolve) => {
      window.setTimeout(() => {
        onClick(resource, !isFavorite)
          .then(() => {
            setIsLoading(false);
            resolve();
          });
      }, 0);
    });
  };

  const renderIconButton = () => (
    <IconButton
      color="primary"
      renderIcon={isFavorite ? IconStarSolid : IconStarLightSolid}
      screenReaderLabel={isFavorite ? removeFromFavoritesLabel : addToFavoritesLabel}
      interaction={isLoading ? 'disabled' : 'enabled'}
      onClick={onButtonClick}
      withBorder={false}
      withBackground={false}
    />
  );

  const renderLink = () => (
    <CommonsLink
      display={CommonsLink.display.flex}
      renderIcon={isFavorite ? IconStarSolid : IconStarLightSolid}
      onClick={onButtonClick}
      screenReaderLabel={isFavorite ? removeFromFavoritesLabel : addToFavoritesLabel}
      interaction={isLoading ? CommonsLink.interaction.disabled : CommonsLink.interaction.enabled}
    >
      {isFavorite
        ? removeFromFavoritesLabel
        : addToFavoritesLabel}
    </CommonsLink>
  );

  const renderButton = () => {
    switch (renderAs) {
      case ToggleFavoriteButton.renderAs.icon:
        return renderIconButton();
      case ToggleFavoriteButton.renderAs.link:
        return renderLink();
      default:
        throw new Error(`Unsupported renderAs value: ${renderAs}`);
    }
  };

  return (
    <React.Fragment>
      {renderAs && (
        <div className="toggle-favorite-btn">
          {renderButton()}
        </div>
      )}
    </React.Fragment>
  );
};

ToggleFavoriteButton.renderAs = {
  icon: 'icon',
  link: 'link',
};

ToggleFavoriteButton.propTypes = {
  resource: PropTypes.object.isRequired,
  isFavorite: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  renderAs: PropTypes.oneOf(Object.values(ToggleFavoriteButton.renderAs)).isRequired,
};

export default ToggleFavoriteButton;
