import usGrades from '../components/resources/forms/grade-schemes/us';

const grades = usGrades.map(({ value, label, abbreviation }) => ({
  value,
  label: abbreviation || label,
  screenReader: abbreviation ? label : null
}));

export default {
  // TODO: make find sync or eventually use api w/ callback
  find () {
    return grades.slice();
  },

  getLabels (values) {
    return grades
      .filter(grade => values.indexOf(grade.value) > -1)
      .map(grade => grade.label);
  }
};
