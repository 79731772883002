import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import { GroupsActions, SessionActions } from '../../actions';
import AppHandler from '../../components/app-handler';
import AccountGroups from '../../components/account/groups';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import AccountHeader from './account-header';

const view = () => {
  const state = getState();
  const isAllowed = state.session.isAdmin || state.groups.isManager;
  const areGroupsLoaded = state.groups.listIsLoaded;

  if (areGroupsLoaded) {
    if (!isAllowed) {
      return <NotAuthenticated />;
    }
  }

  return (
    <AppHandler
      activeHeader="account"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Groups')} />
      <div className="AccountGroups App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs
          activeTab="groups"
          session={state.session}
          isManager={!!state.groups.isManager}
        />
        <h2 className="AccountGroups-title" tabIndex="-1">
          {formatMessage('Groups')}
        </h2>
        {(areGroupsLoaded || isAllowed) && (
          <AccountGroups
            {...state.groups}
            session={state.session}
            {...GroupsActions}
          />
        )}
      </div>
    </AppHandler>
  );
};

export default function ({ resolve, render, exiting }) {
  GroupsActions.reset();
  GroupsActions.loadList();

  const unsubscribe = subscribe(() => render(view()));
  exiting.then(unsubscribe);
  render(view());
  resolve();
}
