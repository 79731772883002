import React from 'react';
import formatMessage from 'format-message';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';

import previewProcessingIcon from '../../../../assets/svg/preview/preview-processing.svg';
import previewFailedIcon from '../../../../assets/svg/preview/preview-failed.svg';
import DocumentPreview from './document';
import ImagePreview from './image';
import MediaPreview from './media';
import Ccv from './ccv';

export default function ResourcePreview ({ resource, session }) {
  let preview = <div />;

  // Shows an error if the preview is not available
  const version = resource.versions[0] || { exportStatus: 'failed' };
  if (version.exportStatus === 'incomplete') {
    preview = (
      <View
        as="div"
        margin="small"
        padding="large"
        textAlign="center"
        background="primary"
      >
        <View as="div">
          <img
            className="preview-error-icon processing"
            src={previewProcessingIcon}
            alt={formatMessage('Preview error')}
          />
        </View>
        <Text size="x-large">{formatMessage('Preview not Available')}</Text>
        <br />
        <br />
        <Text>
          {formatMessage(
            'The resource has not finished processing. Please try again later.'
          )}
        </Text>
      </View>
    );
  } else if (version.exportStatus === 'failed') {
    preview = (
      <View
        as="div"
        margin="small"
        padding="large"
        textAlign="center"
        background="primary"
      >
        <View as="div">
          <img
            className="preview-error-icon failed"
            src={previewFailedIcon}
            alt={formatMessage('Preview error')}
          />
        </View>
        <Text size="x-large">{formatMessage('Preview not Available')}</Text>
        <br />
        <br />
        <Text>
          {resource.isCreator
            ? formatMessage(
              'The was an error sharing your resource. Please try again.'
            )
            : formatMessage(
              'There is an error with this resource. Please try again later.'
            )}
        </Text>
      </View>
    );
  } else if (resource.type === 'document') {
    preview = <DocumentPreview learningObject={resource} />;
  } else if (resource.type === 'image') {
    preview = <ImagePreview learningObject={resource} />;
  } else if (resource.type === 'video' || resource.type === 'audio') {
    preview = <MediaPreview learningObject={resource} />;
  } else if (resource.cartridgeUrl) {
    const showQuizzesAnswers =
      'roles' in session &&
      !!session.roles.find(
        role => role.includes('Administrator') || role.includes('Instructor')
      );

    const resourceVersion =
      resource.versions &&
      resource.versions.sort(
        (a, b) => Date.parse(a.updateDate) < Date.parse(b.updateDate)
      );
    if (resourceVersion && resourceVersion.length > 0) {
      const latestVersion = resourceVersion[0];
      preview = (
        <Ccv
          manifestUrl={latestVersion.imsManifestUrl}
          cartridgeUrl={resource.cartridgeUrl}
          resourceId={resource.id}
          showQuizzesAnswers={showQuizzesAnswers}
          locale={session.locale}
          prefersHighContrast={session.prefersHighContrast}
        />
      );
    }
  }

  return preview;
}
