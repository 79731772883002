import React from 'react';
import { CheckboxGroup, Checkbox } from '@instructure/ui-checkbox';
import formatMessage from 'format-message';

const contentCategoryTypeLabels = {
  template: formatMessage('Template'),
  openTextbook: formatMessage('Open Textbook')
};

export const contentCategoryTypePluralLabels = {
  template: formatMessage('Templates'),
  openTextbook: formatMessage('Open Textbooks')
};

export default function ContentCategoryTypeSelector (props) {
  const { value, onChange, label, pluralLables } = props;
  const labels = pluralLables ? contentCategoryTypePluralLabels : contentCategoryTypeLabels;
  return (
    <CheckboxGroup
      name="contentCategoryType"
      onChange={onChange}
      value={value}
      description={label}
    >
      <Checkbox label={labels.template} value="template" />
      <Checkbox label={labels.openTextbook} value="openTextbook" />
    </CheckboxGroup>
  );
}
