import compare from '../common/natural-compare';
import {
  RESET_GROUPS,
  LOAD_LIST_START,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_FAILURE,
  CHANGE_CREATE_NAME,
  CREATE_GROUP_START,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  FILTER_LIST,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE
} from '../actions/groups';

const initialState = {
  listIsLoading: false,
  listIsLoaded: false,
  listLoadError: null,
  list: [],
  createName: '',
  createGroupPending: false,
  createGroupSuccess: false,
  createGroupError: null,
  filter: '',
  deleteGroupPending: false,
  deleteGroupSuccess: false,
  deleteGroupError: null,
  isManager: null
};

export default function groupsReducer (state = initialState, action) {
  switch (action.type) {
    case RESET_GROUPS:
      return initialState;

    case LOAD_LIST_START:
      return {
        ...state,
        listIsLoading: true,
        listIsLoaded: false,
        listLoadError: null,
        list: []
      };

    case LOAD_LIST_SUCCESS:
      return {
        ...state,
        isManager: action.list.some(({ isManager }) => isManager),
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: null,
        list: action.list.sort((a, b) => compare(a.name, b.name))
      };

    case LOAD_LIST_FAILURE:
      return {
        ...state,
        listIsLoading: false,
        listIsLoaded: true,
        listLoadError: action.error,
        list: []
      };

    case CHANGE_CREATE_NAME:
      return {
        ...state,
        createName: action.name
      };

    case CREATE_GROUP_START:
      return {
        ...state,
        createName: '',
        createGroupPending: true,
        createGroupSuccess: false,
        createGroupError: null
      };

    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createGroupPending: false,
        createGroupSuccess: true,
        createGroupError: null,
        filter: '',
        list: state.list
          .concat(action.group)
          .sort((a, b) => compare(a.name, b.name))
      };

    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        createGroupPending: false,
        createGroupSuccess: false,
        createGroupError: action.error
      };

    case FILTER_LIST:
      return {
        ...state,
        filter: action.filter
      };

    case DELETE_GROUP_START:
      return {
        ...state,
        deleteGroupPending: true,
        deleteGroupSuccess: false,
        deleteGroupError: null
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroupPending: false,
        deleteGroupSuccess: true,
        deleteGroupError: null,
        list: state.list.filter((group) => group !== action.group)
      };

    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        deleteGroupPending: false,
        deleteGroupSuccess: false,
        deleteGroupError: action.error
      };

    default:
      return state;
  }
}
