import env from '../config/env.js';
import Qs from 'qs';

/** @type {(path: string, query: Array | String) => string} */
export function url (path, query) {
  let search = Array.isArray(query) ? query.join('&') : Qs.stringify(query);
  if (search && !path.includes('?')) {
    search = '?' + search;
  }
  return `/api${path}${search}`;
}

export function makeKey ({ method, path, query }) {
  return `${method.toUpperCase()} ${url(path, query)}`;
}

const stubs = new Map();

export function stubApi ({ method = 'get', path, query }, response) {
  const key = makeKey({ method, path, query });
  stubs.set(key, response);
}

export function clearApiStubs () {
  stubs.clear();
}

export default function makeApiRequest ({
  method = 'get',
  path,
  query = null,
  body = null,
  bodyTransform = null,
  contentType = 'application/json'
}) {
  method = method.toUpperCase();

  const hasStubs = stubs.size > 0;
  if (['production', 'beta', 'bugcrowd'].indexOf(process.env.NODE_ENV) === -1 && hasStubs) {
    const key = makeKey({ method, path, query });
    let err;
    let response;
    if (!stubs.has(key)) {
      err = new Error(`Stub missing for ${key}`);
    } else {
      response = stubs.get(key);
      if (typeof response === 'function') {
        response = response(body);
      }
      if (response.status === 401) {
        err = response;
      }
    }

    return {
      promise () {
        return err ? Promise.reject(err) : Promise.resolve(response);
      },
      end (callback) {
        callback(err, response);
      },
      abort () {}
    };
  }

  let xhr = new XMLHttpRequest();
  let sent;
  return {
    promise () {
      return (
        sent ||
        (sent = new Promise((resolve, reject) => {
          xhr.open(method, url(path, query), true);
          if (contentType && method !== 'DELETE') {
            xhr.setRequestHeader('Content-Type', contentType);
          }
          xhr.setRequestHeader('x-session-id', env.SESSION_ID);
          xhr.onreadystatechange = () => {
            if (!xhr || xhr.readyState !== 4) return;
            try {
              resolve(parse(xhr, bodyTransform));
            } catch (err) {
              err.status = xhr.status;
              reject(err);
            }
          };
          xhr.send(body && serialize(body));
        }))
      );
    },
    end (callback) {
      this.promise()
        .then(res => callback(null, res))
        .catch(err => callback(err));
    },
    abort () {
      if (xhr) {
        const tmp = xhr;
        xhr = null;
        tmp.abort();
      }
    }
  };
}

/** @type {(obj: Object) => string} */
function serialize (obj) {
  const str = {}.toString.call(obj);
  switch (str) {
    case '[object File]':
    case '[object Blob]':
    case '[object FormData]':
      return obj;
    default:
      return JSON.stringify(obj);
  }
}

function parse (xhr, transform) {
  const status = xhr.status;
  if (status === 0) {
    throw new Error('Origin is not allowed by Access-Control-Allow-Origin');
  }

  let body = xhr.responseText ? JSON.parse(xhr.responseText) : null;
  if (body && body.errors) {
    throw body;
  }
  if (transform) {
    body = transform(body);
  }

  return { status, body };
}
