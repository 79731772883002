import React from 'react';
import formatMessage from 'format-message';

import CroppedImg from '../common/cropped-img';
import Loading from '../common/loading';
import ResourceTypeIcon from '../icons/resource-type';
import { resourceTypes } from '../../common/app-constants';
import Button from '../common/button';

export default function FavoriteResourceCard (props) {
  const { returnUrl, resource, handleClick, body, error, isImporting } = props;
  let croppedImage = null;
  const hasThumbnail = resource && resource.thumbnail && resource.thumbnail.url;
  if (hasThumbnail) {
    croppedImage = (
      <CroppedImg
        className="FavoriteResourceCard-image"
        src={resource.thumbnail.url}
        alt={resource.thumbnail.description}
      />
    );
  }
  const type = resource.type;
  return (
    <li className="FavoriteResourceSmall">
      <form
        action={returnUrl}
        id={`passback-${props.resource.id}`}
        method="post"
        name="favoritePassback"
      >
        <Button
          className="FavoriteResourceSmall-button"
          onClick={handleClick}
        >
          <input
            name="lti_message_type"
            type="hidden"
            value="ContentItemSelection"
          />
          <input type="hidden" name="lti_version" value="LTI-1p0" />
          <input
            name="content_items"
            type="hidden"
            value={JSON.stringify(body)}
          />
          <div className="FavoriteResourceSmall">
            <div className="FavoriteResourceSmall-image">
              {croppedImage}
              <div className="FavoriteResourceCard--overlay FavoriteResourceCard--type">
                <ResourceTypeIcon
                  aria-label={resourceTypes[type].singularName}
                  className="SearchResultThumbnail-icon"
                  type={type}
                  colored
                />
              </div>
            </div>
            <div className="FavoriteResourceCard-link">
              {resource.title}
            </div>
          </div>
        </Button>
      </form>
      {error ? (
        <div className="FavoriteResourceCard--overlay FavoriteResourceCard--error">
          <div className="FavoriteResourceCard--error-message">
            {formatMessage('Error when importing')}
          </div>
          <div>
            <Button onClick={handleClick}>Retry</Button>
          </div>
        </div>
      ) : (
        isImporting && (
          <div className="FavoriteResourceCard--overlay FavoriteResourceCard--loading">
            <Loading />
          </div>
        )
      )}
    </li>
  );
}
