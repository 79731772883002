import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function ErrorMessage ({ className, inline, message }) {
  return (
    <div
      className={classnames(className, 'error-message', {
        'error-message-inline': inline
      })}
    >
      {message}
    </div>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  message: PropTypes.string.isRequired
};
