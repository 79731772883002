import React from 'react';
import Head from 'react-helmet';
import { GroupActions, UsersActions, SessionActions } from '../../actions';
import AppHandler from '../../components/app-handler';
import Group from '../../components/account/group';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import AccountHeader from './account-header';

/* eslint-disable react/prop-types */
const view = () => {
  const state = getState();
  if (!state.group.groupIsLoading) {
    const isAllowed =
      !state.session.retrievalSuccessful ||
      state.session.isAdmin ||
      state.groups.isManager;
    if (!isAllowed) {
      return <NotAuthenticated />;
    }
  }

  return (
    <AppHandler
      activeHeader="account"
      session={state.session}
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head title={state.group.group && state.group.group.name} />
      <div className="AccountSettings App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs
          activeTab="groups"
          session={state.session}
          isManager={!!state.groups.isManager}
        />
        <Group
          actions={{
            ...GroupActions,
            ...UsersActions
          }}
          state={{
            group: state.group,
            users: state.users
          }}
          session={state.session}
        />
      </div>
    </AppHandler>
  );
};

export default function ({ params, resolve, render, exiting }) {
  const groupId = params.id;
  GroupActions.reset();
  GroupActions.loadGroup(groupId);
  GroupActions.loadMembers(groupId);

  const unsubscribe = subscribe(() => render(view(groupId)));
  exiting.then(unsubscribe);
  render(view());
  resolve();
}
