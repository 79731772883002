module.exports = function (value) {
  return {
    WebkitBoxFlex: value,
    MozBoxFlex: value,
    boxFlex: value,
    msflex: value,
    WebkitFlex: value,
    flex: value
  };
};
