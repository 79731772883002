import { bindActionCreators } from 'redux';
import apiRequest from '../common/api-request';
import isErroneous from '../common/is-erroneous';
import * as announce from '../common/announce';
import formatMessage from 'format-message';

export const SEARCH_ACCOUNTS_START = 'accounts.search-accounts-start';
export const SEARCH_ACCOUNTS_SUCCESSFUL = 'accounts.search-accounts-successful';
export const SEARCH_ACCOUNTS_FAILED = 'accounts.search-accounts-failed';
export const RESET_ACCOUNTS = 'accounts.reset';

export const creators = {
  startSearch: () => ({ type: SEARCH_ACCOUNTS_START }),
  searchFailed: (error) => ({ type: SEARCH_ACCOUNTS_FAILED, error }),
  searchSuccessful: ({ items, meta }) => ({ type: SEARCH_ACCOUNTS_SUCCESSFUL, items, meta }),
  resetAccounts: () => ({ type: RESET_ACCOUNTS })
};

export default function createAccountsActions (dispatch) {
  const actions = bindActionCreators(creators, dispatch);

  return {
    ...actions,

    async searchAccounts ({ query, cursor, includePublicOnly }) {
      actions.startSearch();

      const request = apiRequest({
        path: '/account',
        query: {
          q: query, cursor, includePublicOnly
        }
      });

      try {
        const response = await request.promise();
        if (isErroneous(null, response)) {
          throw response.body;
        }
        if (response.body && response.body.items && response.body.items.length > 0) {
          announce.politely(
            formatMessage(
              `{count, plural,
            one {# institution found}
            other {# institutions found}
          }`,
              {
                count: response.body.items.length
              }
            )
          );
        } else if (query.length > 0) {
          announce.politely(
            formatMessage('Sorry, there is no match for {institution}', {
              institution: query
            })
          );
        }
        actions.searchSuccessful(response.body);
      } catch (err) {
        actions.searchFailed(err);
      }
    }
  };
}
