import { ACTION_ADD_DIALOG, ACTION_REMOVE_DIALOG } from '../constants/dialog';

const initialState = {
  currentDialogs: []
};

export default function dialogReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_ADD_DIALOG:
      return {
        ...state,
        currentDialogs: [...state.currentDialogs, action.payload],
      };
    case ACTION_REMOVE_DIALOG:
      return {
        ...state,
        currentDialogs: state.currentDialogs.filter(dialog => dialog.id !== action.payload.id && action.payload.id !== null),
      };
    default:
      return state;
  }
}
