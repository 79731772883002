import React, { useEffect } from 'react';
import { getState } from '../../store';
import { GroupsActions, ConsortiumsActions } from '../../actions/';

export const withListLoaders = (Component) => {
  function WithListLoaders (props) {
    const { session } = getState();
    useEffect(() => {
      if (session.sid) {
        ConsortiumsActions.loadList(true);
        GroupsActions.loadList(true);
      }
    }, []);
    return <Component {...props} />;
  }
  WithListLoaders.displayName = 'WithDefaultSearchFilters';
  return WithListLoaders;
};
