import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../../components/app-handler';
import AccountStats from '../../components/account/stats';
import SearchOptions from '../../components/search/search-options';
import {
  SearchActions,
  SessionActions,
  RouterActions
} from '../../actions';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import { withListLoaders } from '../../components/common/with-list-loaders';
import AccountHeader from './account-header';
import { getStatsSearchSortingOptions } from '../../components/search/sorting/stats-search-sorting-options';
import { getSortingFromQuery } from '../util/get-sorting-from-query';
import { isCursorLimitReachedError } from '../../common/is-cursor-limit-reached-error';

/* eslint-disable react/prop-types */
const View = ({ queryParams }) => {
  const {
    q,
    types,
    scopeIds,
    gradeIds,
    subjectIds,
    contentCategoryTypes
  } = queryParams;
  const { sortBy, sortOrder } = getSortingFromQuery(queryParams);
  const state = getState();

  if (state.session.retrievalSuccessful && !state.session.isAdmin) {
    return <NotAuthenticated />;
  }

  return (
    <AppHandler
      activeHeader="account"
      consortiums={state.consortiums}
      groups={state.groups}
      session={state.session}
      router={state.router}
      showSidebar
      updates={state.updates}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
    >
      <Head title={formatMessage('Stats')} />
      <div className="AccountStats App-main-inner-main-content-width">
        <AccountHeader />
        <AdminTabs activeTab="stats" session={state.session} isManager={!!state.groups.isManager} />
        <h2 className="AccountResources-title">{formatMessage('Stats')}</h2>
        <main className="Main AccountStats-main">
          <SearchOptions
            featureFlags={state.featureFlags}
            searchPrivateObjects
            onChange={SearchActions.update}
            query={q}
            types={types ? types.split(',') : []}
            contentCategoryTypes={contentCategoryTypes ? contentCategoryTypes.split(',') : []}
            scopeIds={scopeIds ? scopeIds.split(',') : []}
            gradeIds={gradeIds ? gradeIds.split(',') : []}
            subjectIds={subjectIds}
            sortBy={sortBy}
            sortOrder={sortOrder}
            sortingOptions={getStatsSearchSortingOptions()}
            consortiums={state.consortiums}
            groups={state.groups.list}
            canShowApprovedContent={state.account.settings.allowApprovedContent}
            canShowPublic={state.session.isAdmin && !state.session.publicOnly}
            queryArialLabel={formatMessage('Search Managed Resources by tag, name, or outcome')}
            account={state.session.account}
            extraSortOptions
            showFilters={state.filter.showFilterTray}
            disableSorting={state.results && isCursorLimitReachedError(state.results.error)}
          />
          <AccountStats {...state} />
        </main>
      </div>
    </AppHandler>
  );
};

const StatsPage = withListLoaders(View);

export default function ({ resolve, render, exiting, location }) {
  RouterActions.backTo(formatMessage('Stats'), window.location.href);

  const unsubscribe = subscribe(() => render(<StatsPage queryParams={location.query} />));
  exiting.then(unsubscribe);
  render(<StatsPage queryParams={location.query} />);
  resolve();
}
