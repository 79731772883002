import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const BackIcon = ({ className, ...restProps }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    className={classnames('BackIcon', className)}
    viewBox="0 0 12 16"
    role="img"
    {...restProps}
  >
    <path
      fill="currentColor"
      d="M5.627 3.88V0L0 6l5.627 6V8.034C12.174 7.87 11.437 12.78 9.77 16c4.114
        -4.74 2.422-12.12-4.143-12.12"
    />
  </svg>
);

BackIcon.displayName = 'BackIcon';
BackIcon.propTypes = {
  className: PropTypes.string
};

export default BackIcon;
