export default function (error = {}) {
  let errors;
  if (error.errors) {
    errors = error.errors
      .map(({ param, msg }) => {
        return { param, message: msg };
      });
  } else {
    errors = [
      { message: error.message }
    ];
  }
  return { errors };
}
