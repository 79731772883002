import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import formatMessage from 'format-message';
import classnames from 'classnames';
import { ScreenReaderContent } from '@instructure/ui-a11y-content';

import Pill from '../../common/pill';
import Trash from '../../common/trash';
import Link from '../../common/link';
import { confirmationDialog } from '../../confirmation-dialog';
import * as announce from '../../../common/announce';

export default createReactClass({
  displayName: 'ConsortiumsResult',

  propTypes: {
    accountId: PropTypes.string,
    consortium: PropTypes.object,
    onDelete: PropTypes.func,
    onLeave: PropTypes.func,
    updateMembershipStatus: PropTypes.func
  },

  componentDidUpdate (oldProps) {
    if (
      oldProps.consortium.membershipStatus === 'pending' &&
      this.props.consortium.membershipStatus === 'member'
    ) {
      ReactDOM.findDOMNode(this).focus();
    }
  },

  async openDeleteConfirmation () {
    const message = formatMessage('Resources shared by other institutions will no longer be available. Resource shared by your institution will still be available to your users.');
    const title = formatMessage('Delete {name}?', { name: this.props.consortium.name });

    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Delete')
    });

    if (confirmationAnswer) {
      this.props.onDelete(this.props.consortium);
      announce.assertively(formatMessage('Consortium deleted'));
    }
  },

  async openLeaveConfirmation () {
    const message = formatMessage('Resources shared by other institutions will no longer be available.');
    const title = formatMessage(
      'Leave {name}?',
      { name: this.props.consortium.name }
    );
    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Leave')
    });

    if (confirmationAnswer) {
      this.props.onLeave(this.props.consortium);
    }
  },

  async openDeclineConfirmation () {
    const message = formatMessage(
      'You will not see resources shared with {consortium}.',
      {
        consortium: this.props.consortium.name,
      }
    );
    const title = formatMessage(
      'Decline the consortium {consortium} Created By: {institution}?',
      {
        consortium: this.props.consortium.name,
        institution: this.props.consortium.creatorAccountName
      }
    );
    const confirmationAnswer = await confirmationDialog({
      message,
      title,
      confirmText: formatMessage('Decline')
    });

    if (confirmationAnswer) {
      this.props.updateMembershipStatus(
        this.props.consortium.membershipId,
        this.props.consortium.id,
        'declined'
      );
    }
  },

  joinClicked () {
    announce.assertively(
      formatMessage('You have joined {consortium}', {
        consortium: this.props.consortium.name
      })
    );
    this.props.updateMembershipStatus(
      this.props.consortium.membershipId,
      this.props.consortium.id,
      'member'
    );
  },

  componentWillUnmount () {
    announce.reset();
  },

  render () {
    const { consortium, accountId } = this.props;
    const isOwner = accountId === consortium.creatorAccountId;
    const isInvitation = consortium.membershipStatus === 'pending';
    const classNames = classnames('ConsortiumsResult', {
      'ConsortiumsResult-invitation': isInvitation
    });

    return (
      <div className={classNames} tabIndex="-1" data-heap-redact-text>
        <div className="ConsortiumsResult-name-column">
          {isOwner ? (
            <Link
              className="ConsortiumsResult-link"
              href={`/account/consortiums/${consortium.id}`}
            >
              {consortium.name}
            </Link>
          ) : (
            <React.Fragment>
              <span className="ConsortiumsResult-non-link">
                {consortium.name}
                <span className="ConsortiumsResult-creator">
                  {formatMessage('Created by: {name}', {
                    name: consortium.creatorAccountName
                  })}
                </span>
              </span>
              {isInvitation && (
                <div className="ConsortiumsResult-invitation-actions">
                  <Pill
                    className="ConsortiumsResult-decline"
                    onClick={this.openDeclineConfirmation}
                  >
                    {formatMessage('Decline')}
                    <ScreenReaderContent>{consortium.name}</ScreenReaderContent>
                  </Pill>
                  <Pill
                    className="ConsortiumsResult-join"
                    onClick={this.joinClicked}
                  >
                    {formatMessage('Join')}
                    <ScreenReaderContent>{consortium.name}</ScreenReaderContent>
                  </Pill>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="ConsortiumsResult-delete-column">
          {isOwner ? (
            <Link
              className="ConsortiumsResult-delete-button"
              onClick={this.openDeleteConfirmation}
              title={formatMessage('Delete')}
            >
              <ScreenReaderContent>
                {formatMessage('Delete {name}', {
                  name: consortium.name
                })}
              </ScreenReaderContent>
              <Trash />
            </Link>
          ) : (
            !isInvitation && (
              <Link onClick={this.openLeaveConfirmation}>
                <ScreenReaderContent>
                  {formatMessage('Leave {name}', {
                    name: consortium.name
                  })}
                </ScreenReaderContent>
                <span aria-hidden>{formatMessage('Leave')}</span>
              </Link>
            )
          )}
        </div>
      </div>
    );
  }
});
