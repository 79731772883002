/*
Some patterns derived from github.com/mpowaga/react-slider (MIT)
*/

var React = require('react');
var PropTypes = require('prop-types');
var createReactClass = require('create-react-class');
var ReactDOM = require('react-dom');
var flattenCategories = require('./lib/flatten-categories');
var accumulateFlex = require('./lib/accumulate-flex');
var flexStyles = require('./lib/flex-styles');
var toArray = require('./lib/to-array');
var Knob = require('./knob');
var find = require('lodash/collection/find');
var Grade = require('./grade');
var formatMessage = require('format-message');

module.exports = createReactClass({
  displayName: 'GradeRangeInput',

  propTypes: {
    id: PropTypes.string,
    name: PropTypes.string,
    grades: PropTypes.array,
    values: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    rangeStartLabel: PropTypes.string,
    rangeEndLabel: PropTypes.string
  },

  getInitialState: function () {
    return {
      lowerBoundIndex: 0,
      upperBoundIndex: 1,
      left: null,
      pageX: null,
      right: null
    };
  },

  handlePointerMove: function (index, pageX, event) {
    var grades = this.props.grades;
    var left = this.refs.grades.firstChild.getBoundingClientRect().left;
    var right = this.refs.grades.lastChild.getBoundingClientRect().right;
    var pointerLeftOfComponent = pageX <= left;
    var pointerRightOfComponent = pageX >= right;

    this.setState({ left, right, pageX }); // for debug?

    var newIndex;

    if (pointerLeftOfComponent) {
      newIndex = 0;
    } else if (pointerRightOfComponent) {
      newIndex = grades.length;
    } else {
      var gradeNodes = this.refs.grades.childNodes;
      var gradeNodesArray = toArray(gradeNodes);
      var currentNode = find(gradeNodesArray, function (node) {
        var left = ReactDOM.findDOMNode(node).firstChild.getBoundingClientRect()
          .left;
        var right = ReactDOM.findDOMNode(node).lastChild.getBoundingClientRect()
          .right;
        return pageX >= left && pageX <= right;
      });
      var indexOfCurrentNode = gradeNodesArray.indexOf(currentNode);

      left = ReactDOM.findDOMNode(
        currentNode
      ).firstChild.getBoundingClientRect().left;
      right = ReactDOM.findDOMNode(
        currentNode
      ).lastChild.getBoundingClientRect().right;
      var middle = left + (right - left) / 2;
      if (pageX <= middle) {
        newIndex = indexOfCurrentNode;
      } else {
        newIndex = indexOfCurrentNode + 1;
      }
    }

    if (newIndex !== index) {
      this.handleMoveIndex(index, newIndex);
    }
  },

  triggerChange: function () {
    var lowerBoundIndex = this.state.lowerBoundIndex;
    var upperBoundIndex = this.state.upperBoundIndex;
    var grades = this.props.grades;

    var newGrades = grades.slice(lowerBoundIndex, upperBoundIndex);
    var newValues = newGrades.map(function (grade) {
      return grade.value;
    });
    this.props.onChange({
      target: {
        name: this.props.name,
        value: newValues
      }
    });
  },

  determineBounds: function (props) {
    var grades = props.grades;
    var values = props.values;

    if (!values || values.length < 1) {
      return this.setState({
        lowerBoundIndex: 0,
        upperBoundIndex: grades.length
      });
    }
    var lowerBoundIndex = grades.reduce(function (
      lowerBoundIndex,
      grade,
      index
    ) {
      var gradeIncludedInValues = values.indexOf(grade.value) > -1;
      if (gradeIncludedInValues && lowerBoundIndex === -1) {
        return index;
      }
      return lowerBoundIndex;
    },
    -1);

    var upperBoundIndex = grades.reduceRight(function (
      upperBoundIndex,
      grade,
      index
    ) {
      if (values.indexOf(grade.value) > -1 && !upperBoundIndex) {
        return index + 1;
      }
      return upperBoundIndex;
    },
    0);

    this.setState({
      lowerBoundIndex,
      upperBoundIndex: upperBoundIndex || grades.length
    });
  },

  UNSAFE_componentWillMount: function () {
    this.determineBounds(this.props);
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    this.determineBounds(nextProps);
  },

  handleMoveIndexBackward: function (index) {
    var lowerBoundIndex = this.state.lowerBoundIndex;
    var upperBoundIndex = this.state.upperBoundIndex;

    if (lowerBoundIndex === index && index > 0) {
      return this.setState(
        {
          lowerBoundIndex: lowerBoundIndex - 1
        },
        this.triggerChange
      );
    }

    if (upperBoundIndex === index && index > 1) {
      return this.setState(
        {
          lowerBoundIndex:
            lowerBoundIndex === upperBoundIndex - 1
              ? lowerBoundIndex - 1
              : lowerBoundIndex,
          upperBoundIndex: upperBoundIndex - 1
        },
        this.triggerChange
      );
    }
  },

  handleMoveIndexForward: function (index) {
    var lowerBoundIndex = this.state.lowerBoundIndex;
    var upperBoundIndex = this.state.upperBoundIndex;
    var grades = this.props.grades;

    if (lowerBoundIndex === index && upperBoundIndex !== grades.length) {
      return this.setState(
        {
          lowerBoundIndex: lowerBoundIndex + 1,
          upperBoundIndex:
            upperBoundIndex === lowerBoundIndex + 1
              ? upperBoundIndex + 1
              : upperBoundIndex
        },
        this.triggerChange
      );
    }

    if (upperBoundIndex === index && index < grades.length) {
      return this.setState(
        {
          upperBoundIndex: upperBoundIndex + 1
        },
        this.triggerChange
      );
    }
  },

  handleMoveIndex: function (oldIndex, newIndex) {
    var lowerBoundIndex = this.state.lowerBoundIndex;
    var upperBoundIndex = this.state.upperBoundIndex;
    var grades = this.props.grades;

    if (lowerBoundIndex === oldIndex && newIndex !== upperBoundIndex) {
      if (newIndex > upperBoundIndex) {
        this.setState(
          {
            lowerBoundIndex: upperBoundIndex,
            upperBoundIndex: newIndex
          },
          this.triggerChange
        );
      } else {
        this.setState(
          {
            lowerBoundIndex: newIndex
          },
          this.triggerChange
        );
      }
    } else if (upperBoundIndex === oldIndex && newIndex !== lowerBoundIndex) {
      if (newIndex < lowerBoundIndex) {
        this.setState(
          {
            lowerBoundIndex: newIndex,
            upperBoundIndex: lowerBoundIndex
          },
          this.triggerChange
        );
      } else {
        this.setState(
          {
            upperBoundIndex: newIndex
          },
          this.triggerChange
        );
      }
    } else if (
      upperBoundIndex === oldIndex &&
      newIndex === lowerBoundIndex &&
      lowerBoundIndex > 0
    ) {
      this.setState(
        {
          lowerBoundIndex: lowerBoundIndex - 1,
          upperBoundIndex: newIndex
        },
        this.triggerChange
      );
    } else if (
      lowerBoundIndex === oldIndex &&
      newIndex === upperBoundIndex &&
      upperBoundIndex < grades.length
    ) {
      this.setState(
        {
          lowerBoundIndex: newIndex,
          upperBoundIndex: upperBoundIndex + 1
        },
        this.triggerChange
      );
    }
  },

  handleDragEnd: function () {
    this.triggerChange();
  },

  handleGradeClick: function (index) {
    this.setState(
      {
        lowerBoundIndex: index,
        upperBoundIndex: index + 1
      },
      this.triggerChange
    );
  },

  handleFromSelectChange: function (event) {
    this.handleSelectChange(
      false,
      this.state.lowerBoundIndex,
      event.target.value
    );
  },

  handleToSelectChange: function (event) {
    this.handleSelectChange(
      true,
      this.state.upperBoundIndex,
      event.target.value
    );
  },

  handleSelectChange: function (isUpperBound, index, newIndex) {
    this.handleMoveIndex(
      index,
      parseInt(newIndex, 10) + Number(isUpperBound || 0)
    );
  },

  updateHeight: function () {
    var div = this.refs.container;
    if (div.clientHeight < div.scrollHeight) {
      div.style.height = div.scrollHeight + 'px';
    }
  },

  componentDidMount: function () {
    this.updateHeight();
  },

  componentDidUpdate: function () {
    this.updateHeight();
  },

  render: function () {
    var grades = this.props.grades;
    var lowerBoundIndex = this.state.lowerBoundIndex;
    var upperBoundIndex = this.state.upperBoundIndex;

    var flexBeforeFirstKnob = grades
      .slice(0, lowerBoundIndex)
      .reduce(accumulateFlex, 0);
    var flexBetweenKnobs = grades
      .slice(lowerBoundIndex, upperBoundIndex)
      .reduce(accumulateFlex, 0);
    var flexAfterSecondKnob = grades
      .slice(upperBoundIndex)
      .reduce(accumulateFlex, 0);

    var gradeComponents = grades.map((grade, index) => (
      <Grade
        grade={grade}
        index={index}
        onClick={this.handleGradeClick}
        key={grade.value + grade.label}
      />
    ));
    var gradeCategories = grades.reduce(flattenCategories, []);
    var gradeCategoryComponents = gradeCategories.map(
      createGradeCategoryComponent
    );

    const totalFlex =
      flexBeforeFirstKnob + flexBetweenKnobs + flexAfterSecondKnob;

    const fromOptions = this.props.grades.map(function (option, index) {
      var disabled = !(index >= 0 && index < upperBoundIndex);
      return (
        <option key={option.value} value={index} disabled={disabled}>
          {option.label || option.abbreviation}
        </option>
      );
    });

    const toOptions = this.props.grades.map(
      function (option, index) {
        var disabled = !(
          index >= lowerBoundIndex && index < this.props.grades.length
        );
        return (
          <option key={option.value} value={index} disabled={disabled}>
            {option.label || option.abbreviation}
          </option>
        );
      }.bind(this)
    );

    return (
      <div>
        <div
          style={{
            display: 'flex',
            marginBottom: '8px',
            marginTop: '8px',
            fontSize: '0.9em'
          }}
        >
          <div style={{ flex: '1' }}>
            <label>
              <span style={{ marginRight: '8px' }}>
                {formatMessage('From:')}
              </span>
              <select
                onChange={this.handleFromSelectChange}
                value={lowerBoundIndex}
              >
                {fromOptions}
              </select>
            </label>
          </div>
          <div style={{ flex: '1', textAlign: 'right' }}>
            <label>
              <span style={{ marginRight: '8px' }}>{formatMessage('To:')}</span>
              <select
                onChange={this.handleToSelectChange}
                value={upperBoundIndex - 1}
              >
                {toOptions}
              </select>
            </label>
          </div>
        </div>
        <div
          ref="container"
          className="gri-container"
          id={this.props.id}
          aria-hidden="true"
        >
          <div className="gri-axis" />
          <div className="gri-selection-container">
            <div
              className="gri-selection-before"
              style={flexStyles(flexBeforeFirstKnob)}
            />
            <div
              className="gri-selection"
              style={flexStyles(flexBetweenKnobs)}
            />
            <div
              className="gri-selection-after"
              style={flexStyles(flexAfterSecondKnob)}
            />
          </div>
          <div className="gri-grades" ref="grades">
            {gradeComponents}
          </div>
          <div className="gri-grade-categories">{gradeCategoryComponents}</div>
          <div className="gri-knobs">
            <div
              className="gri-knob-spacer"
              style={flexStyles(flexBeforeFirstKnob)}
            />
            <Knob
              label={this.props.rangeStartLabel}
              options={this.props.grades}
              onPointerMove={this.handlePointerMove}
              onDragEnd={this.handleDragEnd}
              onMoveIndex={this.handleMoveIndex}
              onMoveIndexBackward={this.handleMoveIndexBackward}
              onMoveIndexForward={this.handleMoveIndexForward}
              index={lowerBoundIndex}
              lowerBoundIndex={lowerBoundIndex}
              upperBoundIndex={upperBoundIndex}
              left={`${(flexBeforeFirstKnob * 100) / totalFlex}%`}
              isUpperBound={false}
            />
            <div
              className="gri-knob-spacer"
              style={flexStyles(flexBetweenKnobs)}
            />
            <Knob
              label={this.props.rangeEndLabel}
              options={this.props.grades}
              onPointerMove={this.handlePointerMove}
              onDragEnd={this.handleDragEnd}
              onMoveIndex={this.handleMoveIndex}
              onMoveIndexBackward={this.handleMoveIndexBackward}
              onMoveIndexForward={this.handleMoveIndexForward}
              index={upperBoundIndex}
              lowerBoundIndex={lowerBoundIndex}
              upperBoundIndex={upperBoundIndex}
              left={`${((flexBeforeFirstKnob + flexBetweenKnobs) * 100) /
                totalFlex}%`}
              isUpperBound
            />
            <div
              className="gri-knob-spacer"
              style={flexStyles(flexAfterSecondKnob)}
            />
          </div>
        </div>
      </div>
    );
  }
});

function createGradeCategoryComponent (category, index) {
  return (
    <div
      key={index}
      style={flexStyles(category.flex)}
      className="gri-grade-category"
    >
      {category.label}
    </div>
  );
}
