import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import NotificationCenterActions from '../../../actions/notification-center';
import Close from '../../icons/close';
import Link from '../link';

export default class Notification extends React.Component {
  static displayName = 'Notification'

  static propTypes = {
    notificationId: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func
  }

  constructor (props, context) {
    super(props, context);
    this.closeNotification = this.closeNotification.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  closeNotification () {
    NotificationCenterActions.closeNotification(this.props.notificationId);
    if (this.props.onClose) {
      this.props.onClose(this.props.notificationId);
    }
  }

  onKeyUp (evt) {
    var esc = 27;

    if (evt.which === esc) {
      this.closeNotification();
    }
  }

  render () {
    return (
      <div
        ref="notification"
        className="Notification"
        tabIndex="0"
        onKeyUp={this.onKeyUp}
      >
        <Link
          className="Notification-close"
          onClick={this.closeNotification}
        >
          <Close
            width="14"
            height="14"
            description={formatMessage('Close Notification')}
          />
        </Link>
        {this.props.children}
      </div>
    );
  }
}
