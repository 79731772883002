import React from 'react';
import formatMessage from 'format-message';

import ResultsActions from '../../actions/results';
import { FavoritesActions } from '../../actions';

import { IconStarSolid } from '@instructure/ui-icons';
import ConfirmAction from '../common/confirm-action';
import PreviewCardWrapper from './preview-card-wrapper';
import { focusSiblingPreviewCard } from './util/focus-sibling-preview-card';

export default function PreviewCardFavorites (props) {
  const { id, title } = props.resource;

  const onRemoveFavorite = async (event) => {
    const resource = props.resource;
    const result = await FavoritesActions.removeFromFavorites(resource);
    if (result) {
      focusSiblingPreviewCard(event.target);
      ResultsActions.removeFromFavorites(resource.id);
    }
    return !!result;
  };

  return (
    <ConfirmAction
      key={`delete-${id}`}
      onPerformAction={onRemoveFavorite}
      confirmMessage={formatMessage('Remove {title} from favorites', {
        title
      })}
      onConfirmMessage={formatMessage('{title} Removed from favorites', {
        title
      })}
      confirmationMessage={formatMessage(
        'You will remove {name} from favorites.',
        { name: title }
      )}
      confirmationTitle={
        formatMessage('Remove {name} from favorites?', {
          name: title
        })
      }
      confirmationTextButton={formatMessage('Remove')}
      actionIcon={<IconStarSolid color="brand" />}
    >
      <PreviewCardWrapper {...props} />
    </ConfirmAction>
  );
}
