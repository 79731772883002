import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import * as searchPaths from '../../constants/search-paths';
import * as paginatedPageSizes from '../../constants/paginated-page-sizes';
import { createFetchResults } from '../../common/util/create-fetch-results';
import SearchResultsWrapper from './results/wrapper';
import PreviewCardDeletable from '../resources/preview-card-deletable';

// Components
import NoResult from '../common/no-result';

// Stores
import ResultsStore from '../../stores/results';

export default class SharedHandler extends React.Component {
  static displayName = 'SharedHandler'
  static isResultView = true

  static propTypes = {
    session: PropTypes.object
  }

  onCardRender (resource) {
    return (
      <PreviewCardDeletable
        accountSettings={this.props.account && this.props.account.settings}
        allowApprovedContent={this.props.session.allowApprovedContent}
        accountId={this.props.session.accountId}
        available
        resource={resource}
      />
    );
  }

  render () {
    const { session, results } = this.props;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Shared Resources');

    const resultsPerPage = paginatedPageSizes.SHARED_PAGE_SIZE;
    const fetchResults = createFetchResults(searchPaths.RESOURCES, resultsPerPage);
    return (
      <SearchResultsWrapper
        baseParams={{ authorIds: [session.userId] }}
        fetchResults={fetchResults}
        resultsPerPage={resultsPerPage}
        queryArialLabel={formatMessage(
          'Search My Shared Resources for course resources by keyword, author, institution, or title'
        )}
        isLoading={ResultsStore.showLoading()}
        searchPrivateObjects
        emptyMessage={<NoResult title={noResultsMessage} />}
        canShowPublic={!session.publicOnly}
        canToggleLayout={false}
        onCardRender={resource => this.onCardRender(resource)}
        {...this.props}
      />
    );
  }
}
