import React from 'react';
import FavoriteResources from '../components/search/favorite-resources';
import { getState, subscribe } from '../store';

/* eslint-disable react/prop-types */
const view = ({ q }) => <FavoriteResources {...getState()} q={q} />;

export default function ({ resolve, location, render, exiting }) {
  const unsubscribe = subscribe(() => render(view(location.query)));
  exiting.then(unsubscribe);
  render(view(location.query));
  resolve();
}
