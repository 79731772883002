import formatMessage from 'format-message';
import env from './config/env';

function loadTranslations (locale) {
  return new Promise((resolve) => {
    const isPolyfill = typeof Intl === 'undefined';
    switch (locale) {
      case 'ar':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/ar', '../locales/ar'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/ar');
            resolve(require('../locales/ar'));
          }, 'ar.intl')
          : require.ensure(['../locales/ar'], (require) => {
            resolve(require('../locales/ar'));
          }, 'ar');
      case 'cy':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/cy', '../locales/cy'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/cy');
            resolve(require('../locales/cy'));
          }, 'cy.intl')
          : require.ensure(['../locales/cy'], (require) => {
            resolve(require('../locales/cy'));
          }, 'cy');
      case 'da':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/da', '../locales/da'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/da');
            resolve(require('../locales/da'));
          }, 'da.intl')
          : require.ensure(['../locales/da'], (require) => {
            resolve(require('../locales/da'));
          }, 'da');
      case 'de':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/de', '../locales/de'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/de');
            resolve(require('../locales/de'));
          }, 'de.intl')
          : require.ensure(['../locales/de'], (require) => {
            resolve(require('../locales/de'));
          }, 'de');
      case 'en-AU':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/en-AU', '../locales/en-AU'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/en-AU');
            resolve(require('../locales/en-AU'));
          }, 'en-au.intl')
          : require.ensure(['../locales/en-AU'], (require) => {
            resolve(require('../locales/en-AU'));
          }, 'en-au');
      case 'en-GB':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/en-GB', '../locales/en-GB'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/en-GB');
            resolve(require('../locales/en-GB'));
          }, 'en-gb.intl')
          : require.ensure(['../locales/en-GB'], (require) => {
            resolve(require('../locales/en-GB'));
          }, 'en-gb');
      case 'es':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/es', '../locales/es'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/es');
            resolve(require('../locales/es'));
          }, 'es.intl')
          : require.ensure(['../locales/es'], (require) => {
            resolve(require('../locales/es'));
          }, 'es');
      case 'fr-CA':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/fr-CA', '../locales/fr-CA'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/fr-CA');
            resolve(require('../locales/fr-CA'));
          }, 'fr-ca.intl')
          : require.ensure(['../locales/fr-CA'], (require) => {
            resolve(require('../locales/fr-CA'));
          }, 'fr-ca');
      case 'fr':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/fr', '../locales/fr'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/fr');
            resolve(require('../locales/fr'));
          }, 'fr.intl')
          : require.ensure(['../locales/fr'], (require) => {
            resolve(require('../locales/fr'));
          }, 'fr');
      case 'ht':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/fr', '../locales/ht'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/fr');
            resolve(require('../locales/ht'));
          }, 'ht.intl')
          : require.ensure(['../locales/ht'], (require) => {
            resolve(require('../locales/ht'));
          }, 'ht');
      case 'it':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/it', '../locales/it'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/it');
            resolve(require('../locales/it'));
          }, 'it.intl')
          : require.ensure(['../locales/it'], (require) => {
            resolve(require('../locales/it'));
          }, 'it');
      case 'ja':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/ja', '../locales/ja'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/ja');
            resolve(require('../locales/ja'));
          }, 'ja.intl')
          : require.ensure(['../locales/ja'], (require) => {
            resolve(require('../locales/ja'));
          }, 'ja');
      case 'mi':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/en', '../locales/mi'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/en');
            resolve(require('../locales/mi'));
          }, 'mi.intl')
          : require.ensure(['../locales/mi'], (require) => {
            resolve(require('../locales/mi'));
          }, 'mi');
      case 'nb':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/nb', '../locales/nb'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/nb');
            resolve(require('../locales/nb'));
          }, 'nb.intl')
          : require.ensure(['../locales/nb'], (require) => {
            resolve(require('../locales/nb'));
          }, 'nb');
      case 'nl':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/nl', '../locales/nl'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/nl');
            resolve(require('../locales/nl'));
          }, 'nl.intl')
          : require.ensure(['../locales/nl'], (require) => {
            resolve(require('../locales/nl'));
          }, 'nl');
      case 'pl':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/pl', '../locales/pl'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/pl');
            resolve(require('../locales/pl'));
          }, 'pl.intl')
          : require.ensure(['../locales/pl'], (require) => {
            resolve(require('../locales/pl'));
          }, 'pl');
      case 'pt-BR':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/pt-BR', '../locales/pt-BR'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/pt-BR');
            resolve(require('../locales/pt-BR'));
          }, 'pt-br.intl')
          : require.ensure(['../locales/pt-BR'], (require) => {
            resolve(require('../locales/pt-BR'));
          }, 'pt-br');
      case 'pt':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/pt', '../locales/pt'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/pt');
            resolve(require('../locales/pt'));
          }, 'pt.intl')
          : require.ensure(['../locales/pt'], (require) => {
            resolve(require('../locales/pt'));
          }, 'pt');
      case 'ru':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/ru', '../locales/ru'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/ru');
            resolve(require('../locales/ru'));
          }, 'ru.intl')
          : require.ensure(['../locales/ru'], (require) => {
            resolve(require('../locales/ru'));
          }, 'ru');
      case 'sl':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/sl', '../locales/sl'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/sl');
            resolve(require('../locales/sl'));
          }, 'sl.intl')
          : require.ensure(['../locales/sl'], (require) => {
            resolve(require('../locales/sl'));
          }, 'sl');
      case 'sv':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/sv', '../locales/sv'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/sv');
            resolve(require('../locales/sv'));
          }, 'sv.intl')
          : require.ensure(['../locales/sv'], (require) => {
            resolve(require('../locales/sv'));
          }, 'sv');
      case 'zh-HK':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/zh-Hant-HK', '../locales/zh-HK'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/zh-Hant-HK');
            resolve(require('../locales/zh-HK'));
          }, 'zh-hk.intl')
          : require.ensure(['../locales/zh-HK'], (require) => {
            resolve(require('../locales/zh-HK'));
          }, 'zh-hk');
      case 'zh':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/zh', '../locales/zh'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/zh');
            resolve(require('../locales/zh'));
          }, 'zh.intl')
          : require.ensure(['../locales/zh'], (require) => {
            resolve(require('../locales/zh'));
          }, 'zh');
      case 'en-flip':
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/en', '../locales/en', './common/flip-message'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/en');
            const flipAll = require('./common/flip-message').flipAll;
            resolve(flipAll(require('../locales/en')));
          }, 'en.intl')
          : require.ensure(['../locales/en', './common/flip-message'], (require) => {
            const flipAll = require('./common/flip-message').flipAll;
            resolve(flipAll(require('../locales/en')));
          }, 'en');
      case 'en':
      default:
        return isPolyfill
          ? require.ensure(['intl', 'intl/locale-data/jsonp/en'], (require) => {
            require('intl');
            require('intl/locale-data/jsonp/en');
            resolve();
          }, 'en.intl')
          : resolve();
    }
  });
}

(async () => {
  const locale = env.LANG;
  document.documentElement.lang = locale;
  const strings = await loadTranslations(locale);
  if (strings) {
    formatMessage.setup({
      locale,
      missingTranslation: 'ignore',
      translations: { [locale]: strings[locale] || strings }
    });
  }
  require('./main');
})().catch(console.error);
