import React from 'react';
import createReactClass from 'create-react-class';
import formatMessage from 'format-message';
import Announce from '../../common/announce';
import SadPanda from '../../common/sad-panda';

export default createReactClass({
  display: 'ConsortiumsNoResults',

  render () {
    const message = formatMessage('You don’t have any consortiums yet');
    return (
      <div className="ConsortiumsNoResults">
        <Announce message={message} />
        <p className="ConsortiumsNoResults-message">{message}</p>
        <SadPanda />
      </div>
    );
  }
});
