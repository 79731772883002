import React from 'react';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';
import lock from '../../../assets/svg/lock.svg';

export default function LoggedOut () {
  const status = formatMessage('You have been logged out from Commons');
  const action = formatMessage('Please refresh to continue working with Commons.');
  announce.assertively(status + action);
  return (
    <div className="lor-error-layout-sad-laptop">
      <img
        alt={formatMessage('A sad laptop.')}
        src={lock}
      />
      <h1>{status}</h1>
      <h2>{action}</h2>
    </div>

  );
}
