import classnames from 'classnames';

const getAttributesByPrefix = (props, prefix) => Object.keys(props).reduce((acc, key) => {
  if (key.startsWith(prefix)) {
    acc.push({
      key: key,
      value: props[key]
    });
  }
  return acc;
}, []);

export const addAttributesByPrefixToElement = (element, props, prefix) => getAttributesByPrefix(props, prefix).forEach(({ key, value }) => {
  element.setAttribute(key, value);
});

export const addAttributeByNameToElement = (element, props, name) => {
  if (props[name] !== undefined) {
    element.setAttribute(name, props[name]);
  }
};

export const addAttributeByVariableToElement = (element, variable, name) => {
  if (variable !== undefined) {
    element.setAttribute(name, variable);
  }
};

export const addClassNamesToElement = (element, className, defaultClassNames) => {
  const classNameList = classnames(
    ...(className ? className.split(' ') : []),
    ...defaultClassNames,
  );

  if (classNameList.length) {
    element.classList.add(...classNameList.split(' '));
  }
};

export const addBaseAttributes = (element, props, defaultClassNames = []) => {
  addClassNamesToElement(element, props.className, defaultClassNames);

  addAttributesByPrefixToElement(element, props, 'data-');
  addAttributesByPrefixToElement(element, props, 'aria-');
};
