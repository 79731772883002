import apiRequest from '../common/api-request';
import localizeErrors from './error-messages/updates';
import isErroneous from '../common/is-erroneous';

export function getUpdatesForUser (userId, cursor, done) {
  if (!userId) {
    return done(new Error('userId must be supplied'));
  }

  const path = `/users/${userId}/updates`;
  let query = null;
  if (cursor) {
    query = { cursor: JSON.stringify(cursor) };
  }
  const request = apiRequest({
    path,
    query
  });

  return request
    .end((err, res) => {
      if (isErroneous(err, res)) {
        localizeErrors(err);
        return done(err, res ? res.body : null);
      }

      done(null, res.body);
    });
}

export function getUpdatesForUserForLearningObject (userId, learningObjectId, done) {
  if (!userId || !learningObjectId) {
    return done(new Error('userId and resourceId must be supplied'));
  }

  const path = `/users/${userId}/updates/${learningObjectId}`;
  const request = apiRequest({
    path
  });

  return request
    .end((err, res) => {
      if (isErroneous(err, res)) {
        localizeErrors(err);
        return done(err, res ? res.body : null);
      }

      done(null, res.body);
    });
}

export function ignoreUpdateForCourses (userId, learningObjectId, courseIds, done) {
  if ((!courseIds && !Array.isArray(courseIds)) || !learningObjectId) {
    return done(new Error('courseId and resourceId must be supplied'));
  }

  const path = `/users/${userId}/updates/${learningObjectId}/ignore`;
  const request = apiRequest({
    method: 'post',
    path,
    body: { courseIds }
  });

  return request
    .end((err, res) => {
      if (isErroneous(err, res)) {
        localizeErrors(err);
        return done(err, res ? res.body : null);
      }

      done(null);
    });
}
