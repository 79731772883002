import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@instructure/ui-view';
import SearchActions from '../../actions/search';
import FilterPills from './filter-pills';
import SearchControls from './search-controls';

export default class SearchInfo extends React.Component {
  static propTypes = {
    consortiums: PropTypes.object,
    groups: PropTypes.object,
    router: PropTypes.object,
    session: PropTypes.object,
    canShowPublic: PropTypes.bool,
  }

  hasFilterPills () {
    if (!this.props.router || !this.props.router.query) {
      return false;
    }

    return this.props.router.query.authorIds ||
      this.props.router.query.authorName ||
      (this.props.router.query.accountId && this.props.router.query.accountName) ||
      this.props.router.query.contentCategoryTypes ||
      this.props.router.query.gradeIds ||
      this.props.router.query.types ||
      (this.props.router.query.scopeIds && /(group-|consortium-|:)/.test(this.props.router.query.scopeIds));
  }

  renderFilterPills () {
    return (
      <View as="div">
        <SearchControls query={this.props.router.query} />
        <View as="div" margin="small none none">
          <FilterPills
            query={this.props.router.query}
            account={this.props.session.account}
            consortiums={this.props.consortiums}
            groups={this.props.groups}
            canShowPublic={this.props.canShowPublic}
            onClose={SearchActions.update}
          />
        </View>
      </View>
    );
  }

  render () {
    return !this.hasFilterPills() ? null : (
      <View as="div" padding="medium none none none">
        {this.renderFilterPills()}
      </View>
    );
  }
}
