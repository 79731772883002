import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@instructure/ui-flex';
import { View } from '@instructure/ui-view';
import { getState } from '../../../store';
import ToggleResultsViewButton from '../toggle-results-view-button';
import CommonsResponsiveFlex from '../../common/responsive-flex';
import SearchStats from '../search-stats';

export default function SearchInfoStats (props) {
  const canShowToggleResultsViewButton = () => {
    const isListViewEnabled = getState().featureFlags.flags.search_page_list_view;
    return !props.results.store.showLoading() && props.includeToggleResultsViewButton && isListViewEnabled;
  };

  return (
    <View as="div" padding="medium none">
      <CommonsResponsiveFlex>
        <Flex.Item align="stretch">
          <SearchStats
            isLoading={props.results.store.showLoading()}
            count={props.results.count}
            message={props.statsMessage}
          />
        </Flex.Item>
        {canShowToggleResultsViewButton() && (
          <Flex.Item overflowY="visible">
            <ToggleResultsViewButton />
          </Flex.Item>
        )}
      </CommonsResponsiveFlex>
    </View>
  );
}

SearchInfoStats.propTypes = {
  results: PropTypes.object,
  statsMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
