import React, { useState, useEffect } from 'react';
import { getState } from '../../store';
import { SearchActions } from '../../actions';

export const withDefaultSearchFilters = (Component) => {
  function WithDefaultSearchFilters (props) {
    const {
      queryParams: { q, scopeIds, gradeIds, contentCategoryTypes, showPublic },
    } = props;
    const [checkingDefaultFilters, setCheckingDefaultFilters] = useState(true);
    const state = getState();

    const defaultSearchFilters = state.account.settings.defaultSearchFilters;
    const { enabled, value, ...filters } = defaultSearchFilters;

    useEffect(() => {
      setCheckingDefaultFilters(false);
      if (
        enabled &&
        Object.keys(filters).length > 0 &&
        !q &&
        !scopeIds &&
        !gradeIds &&
        !contentCategoryTypes &&
        !showPublic
      ) {
        SearchActions.update({
          ...filters,
          showPublic: state.account.settings.canShowPublic && showPublic,
        });
      }
    }, []);

    if (checkingDefaultFilters) {
      return <div />;
    }
    return <Component {...props} />;
  }
  WithDefaultSearchFilters.displayName = 'WithDefaultSearchFilters';
  return WithDefaultSearchFilters;
};
