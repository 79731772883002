import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import ButtonConfirm from '../common/button-confirm';
import UpdatesStore from '../../stores/updates';
import SessionStore from '../../stores/session';
import { UpdatesActions } from '../../actions';
import { useUniflowStore } from '../../hooks/use-uniflow-store';

export const DismissButton = ({ courses, children, onDismissal, learningObjectId }) => {
  const [dismissing, setDismissing] = useState(false);
  const updatesStore = useUniflowStore(UpdatesStore);
  const prompt = formatMessage(`
      Ignore this update for these courses?
      You will still be notified of future updates to this resource.
    `);
  const handleAccept = () => {
    if (onDismissal) {
      onDismissal(learningObjectId);
    }

    UpdatesActions.ignoreUpdateForCourses(
      SessionStore.state.user.id,
      learningObjectId,
      courses.map(course => course.id)
    );
    setDismissing(true);
  };

  useEffect(() => {
    if (!updatesStore.ignoreForCoursesPending) {
      setDismissing(false);
    }
  }, [updatesStore.ignoreForCoursesPending]);

  return (
    <div className="DismissButton">
      <ButtonConfirm
        buttonClassName="DismissUpdate-button"
        onAccept={handleAccept}
        prompt={prompt}
        disabled={dismissing || !courses.length}
      >
        {dismissing
          ? formatMessage('Dismissing...')
          : children}
      </ButtonConfirm>
    </div>
  );
};

DismissButton.propTypes = {
  onDismissal: PropTypes.func,
  children: PropTypes.node,
  learningObjectId: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired
};
