export function notify (messageType, params) {
  return { messageType, params };
}

export function ltiResourceImported (title, text, id, url, mediaType, placementAdvice, userNotified = true) {
  window.top.postMessage(notify('lti.resourceImported', { title, text, id, url, mediaType, placementAdvice, userNotified }), '*');
}

export function ltiResourceImportError (errorCode, errorMessage, url, placementAdvice) {
  window.top.postMessage(notify('lti.resourceImportError', { errorCode, errorMessage, url, placementAdvice }), '*');
}

export function commonsFavTrayClose () {
  window.top.postMessage(notify('commonsFavTrayClose'), '*');
}

export function requestTrayClose () {
  window.top.postMessage(notify('requestTrayClose'), '*');
}
