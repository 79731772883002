import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconRefreshLine } from '@instructure/ui-icons';
import Message from '../../common/message';
import ComplexMessage from '../../common/complex-message';
import Link from '../../common/link';
import downForRepair from '../../../../assets/svg/down-for-repair.svg';
import Button from '../../common/button';

export const ResultsError = ({ onRetryClick }) => {
  return (
    <Message type="error2 message-with-icon">
      <ComplexMessage>
        {formatMessage(
          'There was an error loading the results.\nYou can try again by {clicking_here}.',
          { clicking_here: ComplexMessage.placeholder('retry') }
        )}
        <Link key="retry" onClick={onRetryClick}>
          {formatMessage('clicking here')}
        </Link>
      </ComplexMessage>
    </Message>
  );
};

ResultsError.propTypes = {
  onRetryClick: PropTypes.func.isRequired
};

export const ResultsErrorWithImage = ({ onRetryClick }) => {
  const retryButtonRef = useRef(null);

  useEffect(() => {
    if (document.activeElement && document.activeElement.tagName.toLowerCase() !== 'input') {
      retryButtonRef.current.focus();
    }
  }, []);

  return (
    <div className="message-with-image">
      <img src={downForRepair} alt={formatMessage('Robots are fixing a broken spaceship')} />
      <section aria-live="assertive" role="alert">
        <h2>{formatMessage('Whoops... Looks like something went wrong')}</h2>
        <p>{formatMessage('There was an error loading the results.')}</p>
        <Button
          ref={retryButtonRef}
          color={Button.color.primary}
          renderIcon={IconRefreshLine}
          onClick={onRetryClick}
          withBackground={false}
          themeOverride={{
            borderWidth: 0
          }}
          tabIndex={1}
        >
          {formatMessage('Reload results')}
        </Button>
      </section>
    </div>
  );
};

ResultsErrorWithImage.propTypes = {
  onRetryClick: PropTypes.func.isRequired
};
