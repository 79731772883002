import React from 'react';
import { getCuratedBanner } from '../../common/util/approved-content';

export const withCuratedBanner = (Component) => (props) => {
  const {
    resource,
    allowApprovedContent,
    accountId,
    accountSettings,
  } = props;
  const curatedBanner = getCuratedBanner(resource, allowApprovedContent, accountId, accountSettings);

  return (
    <Component
      {...props}
      displayApprovedBanner={curatedBanner !== null}
      curatedBanner={curatedBanner}
    />
  );
};
