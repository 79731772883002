import Uniflow from 'uniflow';
import * as License from '../models/license';

export default Uniflow.createActions({
  getLicenses () {
    this.emit('licenses-retrieval-pending');
    License.find(this.handleResponse);
  },
  handleResponse (err, licenses) {
    if (err) {
      return this.emit('licenses-retrieval-error', err);
    }

    this.emit('licenses-retrieval-success', licenses);
  }
});
