import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';

import Button from './button';

export default class ButtonConfirm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.didBlur = this.didBlur.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.didAccept = this.didAccept.bind(this);
    this.didReject = this.didReject.bind(this);
  }

  componentWillUnmount () {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = null;
  }

  toggleOpen () {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  didBlur (event) {
    // must be async to ensure `activeElement` is set in firefox
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => {
      if (!this.refs.menu) return;
      var element = this.refs.menu;
      var active = event.relatedTarget || document.activeElement;
      if (!element.contains(active)) this.close();
    }, 1);
  }

  open () {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ isOpen: true }, () => {
      if (!this.refs.menu) return;
      this.refs.menu.focus();
    });
  }

  close () {
    if (this.props.onClose) this.props.onClose();
    this.setState({ isOpen: false });
  }

  didAccept () {
    this.props.onAccept();
    this.close();
  }

  didReject () {
    if (this.props.onReject) {
      this.props.onReject();
    }
    this.close();
  }

  render () {
    return (
      <div className={(this.props.className + ' ButtonConfirm-wrapper').trim()}>
        <Button
          ref="button"
          color={this.props.color}
          type={this.props.type}
          size={this.props.size}
          className={this.props.buttonClassName}
          interaction={this.props.disabled ? Button.interaction.disabled : Button.interaction.enabled}
          onClick={this.props.disabled ? null : this.toggleOpen}
        >
          {this.props.children}
        </Button>
        {this.renderMenu()}
      </div>
    );
  }

  renderMenu () {
    if (!this.state.isOpen) return null;

    return (
      <div
        ref="menu"
        className={(this.props.menuClassName + ' ButtonConfirm').trim()}
        tabIndex="-1"
        onBlur={this.didBlur}
      >
        <div className="ButtonConfirm-arrow" />
        <div className="ButtonConfirm-prompt">
          <p className="ButtonConfirm-message">{this.props.prompt}</p>
          <div className="ButtonConfirm-button-row">
            <Button
              className="ButtonConfirm-cancelButton"
              size={Button.size.small}
              interaction={this.props.disabled ? Button.interaction.disabled : Button.interaction.enabled}
              onBlur={this.didBlur}
              onClick={this.props.disabled ? null : this.didReject}
            >
              {formatMessage('Cancel')}
            </Button>
            <Button
              className="ButtonConfirm-acceptButton"
              size={Button.size.small}
              interaction={this.props.disabled ? Button.interaction.disabled : Button.interaction.enabled}
              onBlur={this.didBlur}
              onClick={this.props.disabled ? null : this.didAccept}
            >
              {formatMessage('Yes')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ButtonConfirm.displayName = 'ButtonConfirm';

ButtonConfirm.propTypes = {
  color: PropTypes.oneOf(Object.keys(Button.color)),
  type: PropTypes.oneOf(Object.keys(Button.type)),
  size: PropTypes.oneOf(Object.keys(Button.size)),
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onReject: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  prompt: PropTypes.string.isRequired
};

ButtonConfirm.defaultProps = {
  kind: 'default',
  className: '',
  buttonClassName: '',
  menuClassName: ''
};
