import React from 'react';
import YellowOops from './layouts/yellow-oops';
import formatMessage from 'format-message';
import * as announce from '../../common/announce';
import { getLocalizedUrl } from '../../common/localized-urls';

export default function NoEmail () {
  const message = formatMessage('Your Canvas account does not have an email address associated with it. Please add an email to your account to access Commons.');
  announce.politely(message);
  return (
    <YellowOops>
      <p>
        {message}
      </p>
      <a
        href={getLocalizedUrl('https://community.canvaslms.com/t5/Student-Guide/How-do-I-add-an-additional-email-address-as-a-contact-method-in/ta-p/412')}
        target="_blank"
        rel="noopener noreferrer">
        {formatMessage('How do I add an email address in Canvas?')}
      </a>
    </YellowOops>
  );
}
