import { getLocalStorageItem } from '../common/localstorage';
import { SEARCH_PAGE_RESULTS_VIEW_KEY, UPDATE_SEARCH_PAGE_RESULTS_VIEW_ACTION } from '../constants/local-storage';
import { CARD_VIEW } from '../constants/results-view';

const initialState = {
  searchPage: {
    resultsView: getLocalStorageItem(SEARCH_PAGE_RESULTS_VIEW_KEY, CARD_VIEW),
  },
};

const localStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_PAGE_RESULTS_VIEW_ACTION:
      return {
        ...state,
        searchPage: { resultsView: action.view }
      };
    default:
      return state;
  }
};

export default localStorageReducer;
